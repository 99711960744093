import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetRealizationResourcesStartAction,
    GetRealizationResourcesSuccessAction,
    GetRealizationResourcesErrorAction,
} from 'src/store/realization/actions';
import {selectRealizationLinksProjectId} from 'src/store/realization/selectors';
import { TRealizationLinks } from 'src/types/realization';

export function* getRealizationResourcesWorker(_action: ReturnType<typeof GetRealizationResourcesStartAction> ) {
    try {
        const projectId = yield select(selectRealizationLinksProjectId);

        let { data: responseData }: AxiosResponse<TRealizationLinks> = yield axios({
            method: 'GET',
            url: `/impl/projects/${projectId}/links/internet_resources`,
            headers: {},
        });

        yield put(GetRealizationResourcesSuccessAction(responseData));
    } catch (error) {
        yield put(GetRealizationResourcesErrorAction(error));
    }
}
