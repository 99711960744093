import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    EditVotingCommentStartAction,
    EditVotingCommentSuccessAction,
    EditVotingCommentErrorAction, GetVotingDetailedProjectStartAction, GetVotingDetailedDocumentStartAction,
} from 'src/store/voting/actions';

export function* editVotingCommentWorker(
    { comment, commentId, votingId, projectId }: ReturnType<typeof EditVotingCommentStartAction>,
) {
    try {

        yield axios({
            method: 'PUT',
            url: `/user/voting/comments/${commentId}`,
            headers: {},
            data: comment,
        });

        yield put(EditVotingCommentSuccessAction(comment, commentId));

        if (comment.votingBlockId) yield put(GetVotingDetailedDocumentStartAction(votingId, projectId))
        else yield put(GetVotingDetailedProjectStartAction(votingId, projectId));

    } catch (error) {
        yield put(EditVotingCommentErrorAction(error, commentId));
    }
}
