import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox } from 'antd';
import { Modal } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

import { TEditVotingRequestData } from 'src/types/vote';

import { selectVotingEditorRegents } from 'src/store/voting-editor/selectors';

import styles from './styles.module.scss';
import {LogsActionsStartAction} from "src/store/logs/actions";

type TSelectUsersModalProps = {
    isOpened: boolean;
    onClose: () => void;
    onSubmit: (users: TEditVotingRequestData['users']) => void;
    selectedUsers: TEditVotingRequestData['users'];
};

export const SelectUsersModal: FC<TSelectUsersModalProps> = ({
    isOpened,
    onClose,
    onSubmit,
    selectedUsers,
}) => {
    const put = useDispatch()
    const regents = useSelector(selectVotingEditorRegents);
    const [selected, setSelected] = useState<number[]>([]);

    const onChange = (id: number) => () => {
        if (selected.includes(id)) {
            setSelected(selected.filter((item) => (item !== id)));
        } else {
            setSelected([
                ...selected,
                id,
            ]);
        }
    };

    const handleChangeAll = () => {
        if (selected.length === regents.data.length) {
            setSelected([]);
        } else {
            setSelected(regents.data.map((item) => item.id));
        }
    };

    const handleClose = () => {
        put(LogsActionsStartAction('CANCEL_ADD_VOTING_USER'))
        onClose()
    };
    const handleSubmit = () => {
        const result = regents.data.filter((item) => (selected.includes(item.id)));
        put(LogsActionsStartAction('ADD_VOTING_USER'))
        onSubmit(result);
    };

    useEffect(() => {
        if (isOpened) {
            setSelected(selectedUsers.map((item) => (item.id)));
        } else {
            setSelected([]);
        }
    }, [isOpened]);

    return (
        <Modal
            show={isOpened}
            onHide={onClose}
            keyboard={true}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={styles.dialog}
        >
            <Modal.Body className={styles.body}>
                <h3 className={styles.header}>
                    Выбор члена Конкурсного комитета
                </h3>

                <div className={styles.content}>
                    <div className={styles.selectAll}>
                        <Checkbox
                            className={styles.checkBox}
                            checked={selected.length === regents.data.length}
                            onChange={handleChangeAll}
                        >Выбрать всех</Checkbox>
                    </div>
                    { regents.data.map((item) => {
                        const firstName = item.personalData.firstName || '';
                        const middleName = item.personalData.middleName || '';
                        const lastName = item.personalData.lastName || '';

                        return (
                            <div key={item.id} className={styles.list}>
                                <div className={styles.item} onClick={onChange(item.id)}>
                                    <Checkbox
                                        className={styles.checkBox}
                                        name={`${item.id}`}
                                        checked={selected.includes(item.id)}
                                    />
                                    <span>
                                        { `${firstName} ${middleName} ${lastName}` }
                                    </span>
                                </div>
                            </div>
                        );
                    }) }
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleSubmit}
                >Добавить</Button>
                <Button type="default" onClick={handleClose}>Отменить</Button>
            </Modal.Footer>
        </Modal>
    );
}
