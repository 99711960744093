import React, { ErrorInfo } from 'react';
import AlertComponent from "src/components/alerts";

export class ErrorBoundary extends React.Component<{children: any, submitF?: Function}, {hasError: boolean}> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.group('componentDidCatch')
        console.log('error', error);
        console.log('errorInfo', errorInfo);
        console.groupEnd()

    }

    render() {
        if (this.state.hasError) {
            return <AlertComponent
                submitF={this.props.submitF}
                type={'error'}
                message={'Ошибка'}
                description={'Извините, что-то случилось с сервисом, мы уже решаем проблему...'}/>
        }

        return this.props.children;
    }
}
