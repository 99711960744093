import React, {useState} from "react";
import {Alert, Button, Space} from "antd";

type TAlertProps = {
    type: 'success' | 'error' | 'warning' | 'info',
    message: string,
    description?: string,
    closeF?: any,
    submitF?: any
}
const AlertComponent: React.FC<TAlertProps> = ({
    type,
    message,
    description = '',
    closeF,
    submitF
}) => {
    const [state, setState] = useState({
        showDescription: false
    })
    switch (type) {
        case "success":
            return (
                <Alert
                    message={message}
                    type="success"
                    showIcon
                    closable
                />
            );
        case "warning":
            return (
                <Alert
                    message={message}
                    showIcon
                    description={state.showDescription ? description : ''}
                    type="error"
                    action={
                        <Space direction="vertical">
                            <Button size="small" type="primary" onClick={() => submitF()}>
                                Отправить
                            </Button>
                            <Button size="small" danger type="ghost" onClick={() => {
                                setState({...state, showDescription: !state.showDescription})
                            }}>
                                Подробнее
                            </Button>
                        </Space>
                    }
                    closable
                    onClose={(e: any) => closeF()}
                />
            )
        case "error":
            return (
                <Alert
                    message={message}
                    description={description}
                    type="error"
                    showIcon
                />
            )
        default:
            return null
    }
}

export default AlertComponent