import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    CreateDocumentStartAction,
    CreateDocumentSuccessAction,
    CreateDocumentErrorAction,
    SetDocumentAction,
} from 'src/store/voting-editor/actions';
import { TVotingBlock } from 'src/types/vote';
import { selectVotingEditorBlocks } from 'src/store/voting-editor/selectors';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* editorAddBlockWorker(
    { block }: ReturnType<typeof CreateDocumentStartAction>,
) {
    try {
        const { files: rawFiles, ...params } = block;

        const formData = new FormData();

        formData.append('votingBlock', JSON.stringify({
            ...params,
            fileIds: [],
        }));

        if (rawFiles?.length === 0) {
            formData.append('files', new Blob(undefined));
        } else {
            rawFiles?.forEach((file: any) => {
                formData.append(
                    'files',
                    file.originFileObj as Blob,
                );
            });
        }

        const { data: id }: AxiosResponse<number> = yield axios({
            method: 'POST',
            url: `/voting/blocks`,
            headers: {},
            data: formData,
        });

        if (id) {
            const { data }: AxiosResponse<TVotingBlock> = yield axios({
                method: 'GET',
                url: `/voting/blocks/${id}`,
                headers: {},
            });

            const blocks = yield select(selectVotingEditorBlocks);

            yield put(SetDocumentAction([
                ...blocks,
                data,
            ]));
            yield put(CreateDocumentSuccessAction(data));
        } else {
            throw new Error('Not created');
        }
        yield put(LogsActionsStartAction('CREATE_VOTING_BLOCK'))
    } catch (error) {
        yield put(CreateDocumentErrorAction(error));
    }
}
