import React, {FC, useEffect} from "react";
import {Slide} from "pure-react-carousel";
import styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {selectFile} from "src/store/files/selectiors";
import {GetFileRealizationStartAction} from "src/store/files/actions";
import {FieldNumberOutlined, FilePdfOutlined, PictureOutlined, PlayCircleOutlined} from "@ant-design/icons";
import {TTypeContent} from "src/features/realization-detailed-page/item-content";

type CustomCardSlideProps = {
    coverImage?: string
    type: TTypeContent,
    index: any,
    name: string,
    description?: string,
    handler: any,
    fileId?: number
    image?: string | null
}

const CustomCardSlide: FC<CustomCardSlideProps> = ({
    coverImage, type, index, name, handler, fileId, image
}) => {
    const put = useDispatch()
    // @ts-ignore
    const img = useSelector(selectFile(fileId ? `${fileId}` : undefined))
    useEffect(() => {
        // @ts-ignore
        if (!img && (type === 'image')) put(GetFileRealizationStartAction(fileId, 'image'))
    }, [fileId])

    const getImg = () => {
        switch (type) {
            case "audio":
                return '/images/audio.png';
            case "video":
                return '/images/video.png';
            case "text":
                return '/images/pdf.png'
            default:
                return '/images/no-img.png'
        }
    }
    const getIcon = () => {
        switch (type) {
            case "video" || "audio":
                return <PlayCircleOutlined />;
            case "image":
                return <PictureOutlined />;
            case "text":
                return <FilePdfOutlined />;
        }
    }

    const imageCard = () => {
        if (type === "text") {
            return <img src={getImg()} alt={name} />
        } else if (coverImage) {
            return <img src={coverImage} alt={name} />
        } else if (image) {
            return <img src={image} alt={name} />
        } else return <img src={typeof img === "string" ? img : getImg()} alt={name} />
    }


    return (
        // @ts-ignore
        <Slide
            index={index}
            onClick={event => handler(event, index)}
            className={styles.sliderItem}>
            <div className={styles.cardItem}>
                <div className={styles.cardHeader}>
                    {getIcon()}
                    <div className={styles.cardIcon}>
                        <FieldNumberOutlined/> {index + 1}
                    </div>
                </div>
                <div className={styles.image}>
                    {imageCard()}
                </div>
                <div className={styles.cardTitle}><strong>{name}</strong></div>
            </div>
        </Slide>
    )

};


export default CustomCardSlide;