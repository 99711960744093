import {SystemSettingActionTypes} from "./action-types";
import {TSettingsStore} from "./reducer";

export const SystemSettingStartAction = () => ({
    type: SystemSettingActionTypes.STAR_FETCH,
});
export const SystemSettingErrorAction = (error: any) => ({
    type: SystemSettingActionTypes.ERROR_FETCH,
    error
});

export const GetSystemSettingStartAction = () => ({
    type: SystemSettingActionTypes.GET_SYSTEM_SETTINGS_START,
});
export const GetSystemSettingSuccessAction = (settings: TSettingsStore) => ({
    type: SystemSettingActionTypes.GET_SYSTEM_SETTINGS_SUCCESS, settings
});

export const UpdateSystemSettingAction = (value: string) => ({
    type: SystemSettingActionTypes.UPDATE_SYSTEM_SETTINGS, value
});
