import React, { ChangeEvent, FC, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import {
    Button,
    Checkbox,
    Input,
    Select,
} from 'antd';

import { LeftArrowIcon } from 'src/assets/svg/left-arrow';

import { LogsActionsStartAction } from "src/store/logs/actions";
import {
    GetReportingListTriggerAction, GetReportingQuartersTriggerAction,
    ResetReportingFiltersAction,
    SetReportingFiltersAction,
    TriggerGetReportingFiltersAction,
} from 'src/store/reporting/actions';
import {
    selectReportingListFilters,
    selectReportingQuartersData,
    selectReportingQuartersStatus,
    selectReportingTypesData,
    selectReportingTypesStatus,
    selectReportingYearsData,
    selectReportingYearsStatus,
} from 'src/store/reporting/selectors';

import styles from 'src/components/reporting-list-sidebar/styles.module.scss';
import { QuartersNames } from 'src/constatnts/quarters';

type TProps = {
    isSidebarOpened: boolean;
    closeSidebar: (event: SyntheticEvent) => void;
};

const { Option } = Select;

export const ReportingListSidebar: FC<TProps> = ({
    closeSidebar,
}) => {
    const put = useDispatch();
    const filters = useSelector(selectReportingListFilters);
    const years = useSelector(selectReportingYearsData);
    const { isLoading: yearsIsLoading } = useSelector(selectReportingYearsStatus);
    const quarters = useSelector(selectReportingQuartersData);
    const { isLoading: quartersIsLoading } = useSelector(selectReportingQuartersStatus);
    const categories = useSelector(selectReportingTypesData);
    const { isLoading: categoriesIsLoading } = useSelector(selectReportingTypesStatus);

    const { year, quarter } = filters.filterData;

    const handleReset = () => {
        put(ResetReportingFiltersAction());
        put(TriggerGetReportingFiltersAction());
    };

    const onChangeYear = (year: string) => {
        put(SetReportingFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                year: +year,
            },
            page: 1,
        }));

        put(GetReportingQuartersTriggerAction({ withProjects: true }));
    };

    const onChangeQuarter = (value: string) => {
        put(SetReportingFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                quarter: +value,
            },
            page: 1,
        }));

        put(GetReportingListTriggerAction());
        // put(LogsActionsStartAction('SEARCH_PROJECT'));
    };

    const onChangeCheckbox = (categoryId: number) => (value: any) => {
        const isChecked = filters.filterData.reportTypeIds?.includes(categoryId);
        let categories: number[] = [ ...filters.filterData.reportTypeIds ];

        if (isChecked) {
            const index = categories.indexOf(categoryId);

            if (index !== -1) {
                categories.splice(index, 1);
            }
        } else {
            categories.push(categoryId);
        }

        put(SetReportingFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                reportTypeIds: categories,
            },
            page: 1,
        }));

        put(GetReportingListTriggerAction());
    }

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        put(SetReportingFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                searchString: event.target.value,
            },
            page: 1,
        }));
        put(LogsActionsStartAction('USE_SEARCH_BAR'));
    };

    const onSearch = () => {
        put(GetReportingListTriggerAction());
    };

    return (
        <div className={styles.sidebar}>
            <div className={styles.handle} onClick={closeSidebar}>
                <LeftArrowIcon className={styles.arrowIcon} />
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Название документа/Видеоматериала</div>
                <div className={styles.content}>
                    <Input.Search
                        className={styles.search}
                        placeholder="Введите"
                        onChange={handleChangeSearch}
                        onSearch={onSearch}
                        value={filters.filterData.searchString}
                    />
                    <div style={{marginTop: 15}}>Найдено: {filters.totalCount}</div>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Год</div>
                <div className={styles.content}>
                    <Select
                        className={styles.search}
                        allowClear={true}
                        placeholder="Выберите год"
                        optionFilterProp="children"
                        onChange={onChangeYear}
                        loading={yearsIsLoading}
                        value={year ? year.toString() : undefined}
                    >
                        { years.map((value) => (
                            <Option key={value} value={value}>{value}</Option>
                        )) }
                    </Select>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Квартал</div>
                <div className={styles.content}>
                    <Select
                        className={styles.search}
                        allowClear={true}
                        placeholder="Выберите квартал"
                        optionFilterProp="children"
                        onChange={onChangeQuarter}
                        loading={yearsIsLoading || quartersIsLoading}
                        value={quarter ? quarter.toString() : undefined}
                    >
                        { quarters.map((value) => (
                            <Option
                                key={value}
                                selected={value === quarter}
                                value={value}
                                // @ts-ignore
                            >{ value ? QuartersNames[`${value}`] : value }</Option>
                        )) }
                    </Select>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Тип</div>
                <div className={cn(styles.content, styles.checkboxes)}>
                    { categories.map(category => (
                        <Checkbox
                            key={category.id}
                            className={styles.checkbox}
                            checked={filters.filterData.reportTypeIds?.includes(category.id)}
                            onChange={onChangeCheckbox(category.id)}
                            disabled={categoriesIsLoading}
                        >{category.name}</Checkbox>
                    ))}
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.content}>
                    <Button type="text" onClick={handleReset}>
                        Сбросить фильтры
                    </Button>
                </div>
            </div>
        </div>
    );
};
