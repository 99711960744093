export const InternetResourcesActionTypes = {
    GET_INTERNET_RESOURCES_START: '[INTERNET_RESOURCES] GET_INTERNET_RESOURCES_START',
    GET_INTERNET_RESOURCES_SUCCESS: '[INTERNET_RESOURCES] GET_INTERNET_RESOURCES_SUCCESS',
    GET_INTERNET_RESOURCES_ERROR: '[INTERNET_RESOURCES] GET_INTERNET_RESOURCES_ERROR',

    GET_ONE_INTERNET_RESOURCES_START: '[INTERNET_RESOURCES] GET_ONE_INTERNET_RESOURCES_START',
    GET_ONE_INTERNET_RESOURCES_SUCCESS: '[INTERNET_RESOURCES] GET_ONE_INTERNET_RESOURCES_SUCCESS',
    GET_ONE_INTERNET_RESOURCES_ERROR: '[INTERNET_RESOURCES] GET_ONE_INTERNET_RESOURCES_ERROR',

    CREATE_INTERNET_RESOURCES_START: '[INTERNET_RESOURCES] CREATE_INTERNET_RESOURCES_START',
    CREATE_INTERNET_RESOURCES_SUCCESS: '[INTERNET_RESOURCES] CREATE_INTERNET_RESOURCES_SUCCESS',
    CREATE_INTERNET_RESOURCES_ERROR: '[INTERNET_RESOURCES] CREATE_INTERNET_RESOURCES_ERROR',

    UPDATE_INTERNET_RESOURCES_START: '[INTERNET_RESOURCES] UPDATE_INTERNET_RESOURCES_START',
    UPDATE_INTERNET_RESOURCES_SUCCESS: '[INTERNET_RESOURCES] UPDATE_INTERNET_RESOURCES_SUCCESS',
    UPDATE_INTERNET_RESOURCES_ERROR: '[INTERNET_RESOURCES] UPDATE_INTERNET_RESOURCES_ERROR',

    DELETE_INTERNET_RESOURCES_START: '[INTERNET_RESOURCES] DELETE_INTERNET_RESOURCES_START',
    DELETE_INTERNET_RESOURCES_SUCCESS: '[INTERNET_RESOURCES] DELETE_INTERNET_RESOURCES_SUCCESS',
    DELETE_INTERNET_RESOURCES_ERROR: '[INTERNET_RESOURCES] DELETE_INTERNET_RESOURCES_ERROR',
} as const;
