import { UsersActionTypes } from 'src/store/users/action-types';


export const CreateUserStartAction = (user: any) => ({
    type: UsersActionTypes.CREATE_USER_START,
    user
});
export const CreateUserSuccessAction = () => ({
    type: UsersActionTypes.CREATE_USER_SUCCESS
});
export const CreateUserErrorAction = (error: any) => ({
    type: UsersActionTypes.CREATE_USER_ERROR,
    error
});


export const GetUserByIdStartAction = (userId: string) => ({
    type: UsersActionTypes.GET_USER_START,
    userId
});
export const GetUserByIdSuccessAction = (user: any) => ({
    type: UsersActionTypes.GET_USER_SUCCESS,
    user
});
export const GetUserByIdErrorAction = (error: any) => ({
    type: UsersActionTypes.GET_USER_ERROR,
    error
});


export const GetUserActionsStartAction = (userId: string, params: any | null = null) => ({
    type: UsersActionTypes.GET_USER_ACTIONS_START,
    userId,
    params
});
export const GetUserActionsSuccessAction = (data: any) => ({
    type: UsersActionTypes.GET_USER_ACTIONS_SUCCESS,
    data
});
export const GetUserActionsErrorAction = (error: any) => ({
    type: UsersActionTypes.GET_USER_ACTIONS_ERROR,
    error
});

export const GetUsersStatisticStartAction = (params: any | null = null) => ({
    type: UsersActionTypes.GET_USER_STATISTIC_START,
    params
});
export const GetUsersStatisticSuccessAction = (statistic: any) => ({
    type: UsersActionTypes.GET_USER_STATISTIC_SUCCESS,
    statistic
});
export const GetUsersStatisticErrorAction = (error: any) => ({
    type: UsersActionTypes.GET_USER_STATISTIC_ERROR,
    error
});


export const GetUsersStartAction = (params: any | null) => ({
    type: UsersActionTypes.GET_USERS_START,
    params
});
export const GetUsersSuccessAction = (users: any) => ({
    type: UsersActionTypes.GET_USERS_SUCCESS,
    users
});
export const GetUsersErrorAction = (error: any) => ({
    type: UsersActionTypes.GET_USERS_ERROR,
    error
});


export const PutUserStartAction = (user: any, userId: number, params: string) => ({
    type: UsersActionTypes.PUT_USER_START,
    user,
    userId,
    params,
});
export const PutUserSuccessAction = () => ({
    type: UsersActionTypes.PUT_USER_SUCCESS
});
export const PutUserErrorAction = (error: any) => ({
    type: UsersActionTypes.PUT_USER_ERROR,
    error
});


export const DeleteUserStartAction = (userId: string) => ({
    type: UsersActionTypes.DELETE_USER_START,
    userId
});
export const DeleteUserSuccessAction = () => ({
    type: UsersActionTypes.DELETE_USER_SUCCESS
});
export const DeleteUserErrorAction = (error: any) => ({
    type: UsersActionTypes.DELETE_USER_ERROR,
    error
});