import {
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';

import { SessionsActionTypes } from 'src/store/sesssions/action-types';

import { sessionsWorker } from 'src/workers/sessions/sessions-worker';
import { addSessionWorker } from 'src/workers/sessions/add-session';
import { editSessionWorker } from 'src/workers/sessions/edit-session';
import { deleteSessionWorker } from 'src/workers/sessions/delete-session';
import { getAllSessionsWorker } from 'src/workers/sessions/get-all-sessions';

export default function* sessionsSaga() {
    yield takeLatest(SessionsActionTypes.GET_SESSIONS_START, sessionsWorker);
    yield takeLatest(SessionsActionTypes.ADD_SESSION_START, addSessionWorker);
    yield takeEvery(SessionsActionTypes.EDIT_SESSION_START, editSessionWorker);
    yield takeEvery(SessionsActionTypes.DELETE_SESSION_START, deleteSessionWorker);
    yield takeEvery(SessionsActionTypes.GET_ALL_SESSIONS_START, getAllSessionsWorker);
}
