import React, {FC, useMemo, useState} from 'react';
import styles from "./styles.module.scss";
import {NavLink} from "react-router-dom";
import {mainModuleRoutes} from "src/routing/projects-module";
import {useDispatch, useSelector} from "react-redux";
import {selectIsAdmin, selectIsModerator, selectUserData} from "src/store/auth/selectors";
import classNames from "classnames";
import {ToggleSidebarMenuAction} from "src/store/general/actions";
import {selectMenuInfo} from "src/store/general/selectors";
import {Collapse} from 'antd';

const { Panel } = Collapse;

type MenuItem = {
    label: React.ReactNode,
    path: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
}

const MenuComponent: FC<{isOpen: boolean;}> = ({isOpen}) => {
    const put = useDispatch();
    const [key, setKey] = useState('');
    const menuInfo = useSelector(selectMenuInfo);
    const { isSidebarOpen } = menuInfo;
    const userData = useSelector(selectUserData);
    const isModerator = useSelector(selectIsModerator);
    const isAdministrator = useSelector(selectIsAdmin);
    const user = (userData && userData.user) ? userData.user : {};

    const onClick = () => {
        if (isSidebarOpen) {
            if (window.innerWidth <= 992) {
                put(ToggleSidebarMenuAction())
            }
        }
    }
    function getItem({
        label,
        path,
        icon,
        children,
    }: MenuItem) {
        if (!!children?.length) {
            return (
                <Panel
                    extra={key === path ? '▴' : '▾'}
                    className={styles.sub}
                    header={<>{label} {icon}</>}
                    key={path}
                >
                    {children?.map((el) => getItem(el))}
                </Panel>
            )
        } else return (
            <NavLink  onClick={onClick} className={styles.link} to={path}>
                <>{label} {icon}</>
            </NavLink>
        )
    }

    const links = useMemo(() => {
        let result: any[] = [
            getItem({
                label: 'Отбор проектов',
                path: mainModuleRoutes.list.path,
            }),
        ];
        if (isModerator || isAdministrator) {
            result.push(getItem({
                label: 'Голосования',
                path: mainModuleRoutes['all-votes-list'].path,
            }))
        } else {
            result.push(getItem({
                label: 'Голосование',
                path: mainModuleRoutes.vote.path,
                // children: [
                //     {
                //         label: 'Текущее голосование',
                //         path: mainModuleRoutes.vote.path,
                //     },
                //     {
                //         label: 'Архив',
                //         path: mainModuleRoutes['archive-votes'].path
                //     },
                // ]
            }))
        }
        if (isAdministrator) {
            result.push(getItem({
                label: 'Пользователи',
                path: mainModuleRoutes.users.path,
                children: [
                    {
                        label: 'Список пользователей',
                        path: mainModuleRoutes.users.path
                    },
                    {
                        label: 'Действия пользователей',
                        path: mainModuleRoutes['users-statistic'].path
                    },
                ],
            }))
        }

        if (isModerator || isAdministrator) {
            result.push(getItem({
                label: 'Реализация проектов',
                path: mainModuleRoutes['realization'].path
            }));
        }


        if (!user.roles?.includes("ROLE_USER")) {
            result.push(getItem({
                label: 'Отчетность ИРИ',
                path: mainModuleRoutes['reporting-list'].path
            }))
        }

        if (isModerator) {
            const options: MenuItem[] = [];
            if (isAdministrator) {
                options.push(...[
                    {
                        label: 'Настройки действий пользователя',
                        path: mainModuleRoutes['user-action-settings'].path
                    },
                ])
            }
            result.push(getItem({
                label: 'Настройки',
                path: '/settings',
                children: [
                    {
                        label: 'Настройки интернет-ресурсов',
                        path: mainModuleRoutes['internet-resource-settings'].path,
                    },
                    ...options
                ]
            }))
        }

        return result;
    }, [isModerator, isAdministrator, isSidebarOpen, key]);

    const onChangeCollapse = (keyElement: string | string[]) => {
        if (typeof keyElement === 'string') setKey(keyElement)
        else setKey('')
    }

    return (
        <div className={classNames(styles.menu, { isSidebarOpen: isOpen })}>
            <Collapse onChange={onChangeCollapse} accordion bordered={false}>
                {links}
            </Collapse>

        </div>
    )
}

export default MenuComponent;