import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetReportingTypesTriggerAction,
    GetReportingTypesStartAction,
    GetReportingTypesSuccessAction,
    GetReportingTypesErrorAction,
} from 'src/store/reporting/actions';
import { TReportTypeItem } from 'src/types/reporting';

export function* getReportingTypesWorker(_: ReturnType<typeof GetReportingTypesTriggerAction>) {
    try {
        yield put(GetReportingTypesStartAction());
        const { data: responseData }: AxiosResponse<TReportTypeItem[]> = yield axios({
            method: 'GET',
            url: `/reporting/reports/types`,
            headers: {},
        });

        yield put(GetReportingTypesSuccessAction(responseData));
    } catch (error) {
        yield put(GetReportingTypesErrorAction(error));
    }
}
