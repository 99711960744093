import {
    put,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetYearsTriggerAction,
    GetYearsStartAction,
    GetYearsSuccessAction,
    GetYearsErrorAction,
} from 'src/store/years/actions';
import { GetWavesTriggerAction } from 'src/store/waves/actions';

export function* yearsWorker(action?: ReturnType<typeof GetYearsTriggerAction>) {
    try {
        yield put(GetYearsStartAction());
        const { data }: AxiosResponse<number[]> = yield axios({
            method: 'GET',
            url: '/sessions/years',
            headers: {},
        });

        yield put(GetYearsSuccessAction(data));

        if (action && action.isFirstLoad && data.length > 0) {
            // yield call(wavesWorker, GetWavesTriggerAction(data[data.length - 1].toString()))
            yield put(GetWavesTriggerAction())
        }
    } catch (error) {
        yield put(GetYearsErrorAction(error));
    }
}
