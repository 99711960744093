import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetInternetResourcesSuccessAction,
    GetInternetResourcesErrorAction,
    GetInternetResourcesStartAction
} from "src/store/internet-resources/actions";

export function* internetResourcesWorker(_action: ReturnType<typeof GetInternetResourcesStartAction>) {
    try {
        const { data }: AxiosResponse<any> = yield axios({
            method: 'GET',
            url: '/internet_resources',
            headers: {},
        });

        yield put(GetInternetResourcesSuccessAction(data));
    } catch (error) {
        yield put(GetInternetResourcesErrorAction(error));
    }
}
