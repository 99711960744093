import { InferValueTypes } from 'src/types/common';
import { ReportingActionTypes } from 'src/store/reporting/action-types';
import * as actions from '../actions';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TReportState = {
    report?: {
        id: number,
        description: string,
        name: string
    }
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TReportState = {
    report: undefined,
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function reportReducer(
    state: TReportState = initialState,
    action: ActionTypes,
): TReportState {
    switch (action.type) {
        case
            ReportingActionTypes.CREATE_REPORT_START ||
            ReportingActionTypes.UPDATE_REPORT_START ||
            ReportingActionTypes.DELETE_REPORT_START:
            return {
                ...initialState,
                isLoading: true,
            };
        case
            ReportingActionTypes.CREATE_REPORT_SUCCESS ||
            ReportingActionTypes.UPDATE_REPORT_SUCCESS ||
            ReportingActionTypes.DELETE_REPORT_SUCCESS:
            return {
                ...initialState,
                isLoading: false,
                isLoaded: true,
            };
        case
            ReportingActionTypes.CREATE_REPORT_ERROR ||
            ReportingActionTypes.UPDATE_REPORT_ERROR ||
            ReportingActionTypes.DELETE_REPORT_ERROR:
            return {
                ...initialState,
                error: action.error,
            };
        case ReportingActionTypes.GET_REPORT:
            return {
                ...state,
                report: action.report
            }
        default:
            return state;
    }
}
