import { InferValueTypes } from 'src/types/common';
import {FormatsActionTypes} from "src/store/formats/action-types";
import * as actions from './actions';
import {TFormats} from "src/types/formats";


type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TFormatsState = {
    data: TFormats[];
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TFormatsState = {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};


export default function reducer(
    state: TFormatsState = initialState,
    action: ActionTypes,
): TFormatsState {
    switch (action.type) {
        case FormatsActionTypes.GET_FORMATS_START:
            return {
                ...initialState,
                data: state.data,
                isLoading: true,
            };
        case FormatsActionTypes.GET_FORMATS_SUCCESS:
            return {
                ...initialState,
                data: action.formats,
                isLoading: false,
                isLoaded: true,
            };
        case FormatsActionTypes.GET_FORMATS_ERROR:
            return {
                ...initialState,
                error: action.error,
            };
        default:
            return state;
    }
}
