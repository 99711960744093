import React, {FC} from 'react';

import {UploadFile} from 'antd/es/upload/interface';


import {TProjectContent} from 'src/types/projects';

import EditProjectModal from "src/components/modals/projects-modal/eddit-project-modal";
import CreateProjectModal from "src/components/modals/projects-modal/create-project-modal";
import moment from "moment";


export type TSessionModalProps = {
    isOpened: boolean;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    data?: TProjectContent;
};

export type TProjectFormData = {
    budget: number;
    categoryId: number;
    description: string;
    expertise: string;
    formatDescription: string;
    formatIds: object[];
    internetResources: any;
    kpi: number;
    actualKpi: number;
    supportAmount: number;
    name: string;
    owner: string;
    presentationUrl: string;
    score: number;
    sessionId: string;
    teamExperience: string;
    year: number;
    imageIds?: [];
    fileIds?: [];
    image: UploadFile<any>[]
    presentationFile: UploadFile<any>[];
    requestFiles: UploadFile<any>[];
    examinationFiles: UploadFile<any>[];
    otherFiles: UploadFile<any>[];
    implEndDate: moment.Moment | null | undefined | '';
    implStartDate: moment.Moment | null | undefined | '';
    implTerms: number;
    requestNumber: string;
    recommendedCostReduction: number | null;
    achievements: string | null;
    ownProjectSite: string | null;
    cofinancing: string | null;
    isConfidential: boolean | null;

    existingImage?: TProjectContent['image'];
    existingPresentationFile?: TProjectContent['image'];
    existingRequestFiles?: TProjectContent['files'];
    existingExaminationFiles?: TProjectContent['files'];
    existingOtherFiles?: TProjectContent['files'];
};


export const ProjectModal: FC<TSessionModalProps> = ({
    isOpened,
    onClose,
    data = {},
}) => {
    const isEdit = data && ('id' in data);

    if (isEdit) {
        const props = { isOpened, onClose, data, isEdit}
        return <EditProjectModal {...props}/>
    } else {
        const props = { isOpened, onClose, isEdit}
        return <CreateProjectModal {...props}/>
    }
}