import React, {ChangeEvent, SyntheticEvent, useEffect, useMemo, useState,} from 'react';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {Button, Input, Table, Tooltip} from 'antd';
import {Key, TablePaginationConfig,} from 'antd/lib/table/interface';

import {mainModuleRoutes} from 'src/routing/projects-module';

import {ToggleSidebarAction} from 'src/store/general/actions';
import {GetRealizationListStartAction, SetRealizationFiltersAction,} from 'src/store/realization/actions';

import {selectIsSidebarActive} from 'src/store/general/selectors';
import {
    selectRealizationFilters,
    selectRealizationListData,
    selectRealizationListStatus,
} from 'src/store/realization/selectors';
import {selectIsModerator} from 'src/store/auth/selectors';
import {selectYears} from 'src/store/years/selectors';
import {selectAllSessions} from 'src/store/sesssions/selectors';

import {RealizationListSidebar} from 'src/components/realization-list-sidebar';
import {ErrorBoundary} from 'src/components/error-boundary';
import {RequestCell} from 'src/components/data-table/request-cell';

import styles from './styles.module.scss';
import {formatCurrency} from 'src/utils/currencyForematter';
import {LogsActionsStartAction} from "src/store/logs/actions";
import {NameCell} from "src/components/data-table/name-cell";
import {GetYearsTriggerAction} from "src/store/years/actions";
import {GetSessionsStartAction} from "src/store/sesssions/actions";
import {categoryCell} from "src/components/data-table/category-cell";
import {useDebounce} from "src/shared/lib/useDebounce";
import FormatDescriptionModal from 'src/components/modals/format-description';
import {FiltersIcon} from "src/shared/ui/icons";
import {Pagination} from "src/shared/lib/pagination";

export const RealizationListPage = () => {
    const put = useDispatch();

    const filters = useSelector(selectRealizationFilters);
    const { filterData } = filters;

    const [searchString, setSearchString] = useState<string>(filterData.searchString)
    const debouncedValue = useDebounce<string>(searchString, 1000)

    const [formatDescription, setFormatDescription] = useState<{data: null | string, visible: boolean}>({data: null, visible: false});

    const isModerator = useSelector(selectIsModerator);
    const data = useSelector(selectRealizationListData);
    const status = useSelector(selectRealizationListStatus);
    const isSidebarActive = useSelector(selectIsSidebarActive);

    const years = useSelector(selectYears);
    const allSessions = useSelector(selectAllSessions);

    useEffect(() => {
        put(SetRealizationFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                searchString: debouncedValue,
            },
            page: 1,
        }));
        put(GetRealizationListStartAction());
        put(LogsActionsStartAction('USE_SEARCH_BAR'));
    }, [debouncedValue])

    useEffect(() => {
        if (!years.length) put(GetYearsTriggerAction());
        if (!allSessions.length) put(GetSessionsStartAction());
    }, []);

    useEffect(() => {
        if (years.length > 0) {
            // set default year
            // const year = +years[years.length - 1];
            const year = filterData.year;
            // find session by year
            // const sessionsByCurrentYear = allSessions.length > 0
            //     ? allSessions.filter((item) => (
            //         +moment(item.endDate).format('yyyy') === year
            //     )) : [];
            // const sessionName: string = sessionsByCurrentYear && sessionsByCurrentYear.length > 0
            //     ? sessionsByCurrentYear[sessionsByCurrentYear.length - 1].name : '';

            put(SetRealizationFiltersAction({
                ...filters,
                filterData: {
                    ...filters.filterData,
                    year: year,
                },
            }));
        }
    }, [years, allSessions]);

    useEffect(() => {
        put(GetRealizationListStartAction());
    }, []);


    let columns = useMemo(() => {
        let result = [
            {
                title: 'Статус проекта',
                dataIndex: 'implStatus',
                key: 'implStatus',
                sorter: true,
                width: 150,
            },
            {
                title: 'Номер заявки',
                dataIndex: 'requestNumber',
                key: 'requestNumber',
                width: 160,
                sorter: true,
                className: styles.nameCell,
                render: RequestCell,
            }, {
                title: 'Наименование проекта',
                dataIndex: 'name',
                sorter: true,
                key: 'name',
                render: NameCell,
                width: 150,
            },
            {
                title: 'Направление',
                dataIndex: 'category',
                key: 'category',
                width: 200,
                render: categoryCell,
            },
            // {
            //     title: 'Формат',
            //     dataIndex: 'formats',
            //     key: 'formats',
            //     width: 120,
            //     render: FormatsCell,
            // },
            // {
            //     title: 'Описание формата',
            //     dataIndex: 'formatDescription',
            //     key: 'formatDescription',
            //     sorter: true,
            //     render: (text: string) => TextFormModalCell(onOpenFormatDescription, text),
            //     width: 120
            // },
            {
                title: 'Бюджет',
                dataIndex: 'budget',
                key: 'budget',
                sorter: true,
                width: 120,
                render: (budget: number) => {
                    return formatCurrency(budget)
                }
            },];

        return result;
    }, [isModerator]);
    const toggleSidebar = (value: boolean) => ((_event: SyntheticEvent) => {
        put(ToggleSidebarAction(value));
    });

    const handleRowClick = (id: number) => {
        put(push(mainModuleRoutes['realization-detailed'].path.replace(`:contentId`, `${id}`)));
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filter: Record<string, (Key | boolean)[] | null>,
        sorter: any,
    ) => {
        let newPagination = {};
        let newSorter = {
            sort: {
                field: 'id',
                direction: 'ASC',
            },
        };

        if (pagination) {
            newPagination = {
                page: pagination.current || 1,
                pageSize: pagination.pageSize || 20,
                totalCount: pagination.total || 0,
            }
        }

        if (sorter && sorter.order) {
            let direction = 'ASC';

            if (sorter.order === 'descend') {
                direction = 'DESC'
            }

            newSorter = {
                sort: {
                    field: sorter.field,
                    direction,
                },
            }
        }
        put(SetRealizationFiltersAction({
            ...filters,
            ...newPagination,
            ...newSorter,
        }));
        put(GetRealizationListStartAction());
    };


    const toggleReportingSidebar = () => {
        put(ToggleSidebarAction(!isSidebarActive));
    };
    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value)
    };

    const onSearch = (value: string) => {
        if (!value) return;
        put(SetRealizationFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                searchString: value,
            },
            page: 1,
        }));
        put(GetRealizationListStartAction());
        put(LogsActionsStartAction('USE_SEARCH_BAR'));
    };

    return (
        <ErrorBoundary>
            <div className={styles.container}>
                <div className={cn(styles.leftColumn, { [styles.isSidebarCollapsed]: !isSidebarActive })}>
                    <div className={styles.header}>
                        <div className={styles.header__filter}>
                            <div className={styles.searchContainer}>
                                <div className={styles.searchBox}>
                                    <Tooltip title={'Введите наименование проекта или участника отбора'}>
                                        <Input.Search
                                            className={styles.search}
                                            placeholder="Введите наименование проекта или участника отбора"
                                            onChange={handleChangeSearch}
                                            onSearch={onSearch}
                                            value={searchString}

                                        />
                                    </Tooltip>
                                    <Button
                                        type={'default'}
                                        onClick={toggleReportingSidebar}
                                        className={styles.openSidebar}
                                    >
                                        <FiltersIcon/> Фильтры
                                    </Button>
                                </div>
                                <div className={styles.searchCount}>Найдено: {filters.totalCount}</div>
                            </div>
                        </div>
                    </div>

                    <Table
                        scroll={{ x: 660 }}
                        sticky
                        columns={columns}
                        dataSource={data}
                        loading={status.isLoading}
                        pagination={{
                            current: filters.page,
                            pageSize: filters.pageSize,
                            total: filters.totalCount,
                            position: ['bottomCenter'],
                            showSizeChanger: true,
                            pageSizeOptions: Pagination()
                        }}
                        onChange={handleTableChange}
                        rowKey="id"
                        onRow={(record, _rowIndex) => {
                            return {
                                onClick: (_event) => { handleRowClick(record.id as number) },
                            };
                        }}
                    />
                </div>
                <div className={cn(styles.rightColumn, { [styles.isCollapsed]: !isSidebarActive })}>
                    <RealizationListSidebar
                        isSidebarOpened={isSidebarActive}
                        closeSidebar={toggleSidebar(false)}
                    />
                </div>
            </div>
            <FormatDescriptionModal
                visible={formatDescription.visible}
                data={formatDescription.data}
                onModalCancel={() => setFormatDescription({data: null, visible: false})}
            />
        </ErrorBoundary>
    );
};
