import {WavesActionTypes} from 'src/store/waves/action-types';

export const GetWavesTriggerAction = (year?: string) => ({
    type: WavesActionTypes.GET_WAVES_TRIGGER,
    year,
});

export const GetWavesStartAction = () => ({
    type: WavesActionTypes.GET_WAVES_START,
});

export const GetWavesSuccessAction = (waves: string[]) => ({
    type: WavesActionTypes.GET_WAVES_SUCCESS,
    waves,
});
export const GetWavesSetAction = () => ({
    type: WavesActionTypes.GET_WAVES_SET,
});

export const GetWavesErrorAction = (error: any) => ({
    type: WavesActionTypes.GET_WAVES_ERROR,
    error,
});
