import React, {FC, SyntheticEvent, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import {Button, Checkbox, Select,} from 'antd';

import {LeftArrowIcon} from 'src/assets/svg/left-arrow';

import {LogsActionsStartAction} from 'src/store/logs/actions';
import {
    GetRealizationListStartAction,
    ResetRealizationFiltersAction,
    SetRealizationFiltersAction
} from 'src/store/realization/actions';

import {selectYears, selectYearsStatus,} from 'src/store/years/selectors';
import {selectRealizationFilters} from 'src/store/realization/selectors';
import {selectSessionsStatus,} from 'src/store/sesssions/selectors';

import styles from './styles.module.scss';
import {selectFormats} from "src/store/formats/selectors";
import {GetFormatsStartAction} from "src/store/formats/actions";
import {selectWaves} from "src/store/waves/selectors";
import {GetWavesTriggerAction} from "src/store/waves/actions";
import {GetYearsTriggerAction} from "src/store/years/actions";
import {implStatusList} from "src/constatnts/implStatusList";

type TProps = {
    isSidebarOpened: boolean;
    closeSidebar: (event: SyntheticEvent) => void;
};

const { Option } = Select;

export const RealizationListSidebar: FC<TProps> = ({
    closeSidebar,
}) => {
    const put = useDispatch();
    const { isLoading: yearsIsLoading } = useSelector(selectYearsStatus);
    const { isLoading: wavesIsLoading } = useSelector(selectSessionsStatus);

    const filters = useSelector(selectRealizationFilters);
    // const allSessions = useSelector(selectAllSessions);
    const years = useSelector(selectYears);
    const waves = useSelector(selectWaves);
    const formats = useSelector(selectFormats);

    useEffect(() => {
        if (!formats.length) put(GetFormatsStartAction())
        if (!years.length) put(GetYearsTriggerAction(true))
        if (!waves.length) put(GetWavesTriggerAction())
    }, []);

    const { year, sessionName, implStatuses } = filters.filterData;

    const handleReset = () => {
        put(ResetRealizationFiltersAction());
        put(GetYearsTriggerAction(true))
        put(GetRealizationListStartAction());
    };

    const onChangeYear = (year: string) => {
        // const sessionsByCurrentYear = waves.length > 0
        //     ? waves.filter((item) => (
        //         +moment(item.endDate).format('yyyy') === +year
        //     )) : [];
        // const sessionName: string = sessionsByCurrentYear && sessionsByCurrentYear.length > 0
        //     ? sessionsByCurrentYear[sessionsByCurrentYear.length - 1].name : '';

        put(SetRealizationFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                year: +year,
                sessionName: sessionName,
            },
            page: 1,
        }));
        put(GetWavesTriggerAction(year))
        put(GetRealizationListStartAction());
    };
    const onChangeWave = (value: string) => {
        put(SetRealizationFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                sessionName: value,
            },
            page: 1,
        }));
        put(GetRealizationListStartAction());
        put(LogsActionsStartAction('SEARCH_PROJECT'));
    };
    const onChangeCheckbox = (categoryId: number) => (value: any) => {
        const isChecked = filters.filterData.formatIds?.includes(categoryId);
        let categories: number[] = [...filters.filterData.formatIds];

        if (isChecked) {
            const index = categories.indexOf(categoryId);

            if (index !== -1) {
                categories.splice(index, 1);
            }
        } else {
            categories.push(categoryId);
        }

        put(SetRealizationFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                formatIds: categories,
            },
            page: 1,
        }));
        put(GetRealizationListStartAction());
    }

    console.log('2', implStatuses)
    const onChangeImplStatus = (implStatusId: string) => (_value: any) => {
        console.log('implStatusId', implStatusId)
        console.log('implStatuses', implStatuses)
        const isChecked = implStatuses.includes(implStatusId);
        let formats: string[] = [...implStatuses];

        if (isChecked) {
            const index = formats.indexOf(implStatusId);
            if (index !== -1) {
                formats.splice(index, 1);
            }
        } else {
            formats.push(implStatusId);
        }
        put(SetRealizationFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                implStatuses: formats,
            },
            page: 1,
        }));
        put(GetRealizationListStartAction());
    }


    return (
        <div className={styles.sidebar}>
            <div className={styles.handle} onClick={closeSidebar}>
                <LeftArrowIcon className={styles.arrowIcon}/>
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Год проекта</div>
                <div className={styles.content}>
                    <Select
                        className={styles.search}
                        allowClear={true}
                        placeholder="Выберите год"
                        optionFilterProp="children"
                        onChange={onChangeYear}
                        loading={yearsIsLoading}
                        value={year ? `${year}` : undefined}
                        getPopupContainer={(trigger) => trigger.parentNode}
                    >
                        {years.map((value) => (
                            <Option key={value} value={value}>{value}</Option>
                        ))}
                    </Select>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Отбор</div>
                <div className={styles.content}>
                    <Select
                        className={styles.search}
                        allowClear={true}
                        placeholder="Выберите отбор"
                        optionFilterProp="children"
                        onChange={onChangeWave}
                        loading={yearsIsLoading || wavesIsLoading}
                        value={sessionName}
                        getPopupContainer={(trigger) => trigger.parentNode}
                    >
                        { waves.map((value) => (
                            <Option
                                key={value}
                                selected={value === sessionName}
                                value={value}
                            >{value}</Option>
                        )) }
                    </Select>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Направление</div>
                <div className={cn(styles.content, styles.checkboxes)}>
                    {formats && formats.map(format => (
                        <Checkbox
                            key={format.value}
                            className={styles.checkbox}
                            checked={filters.filterData.formatIds?.includes(format.value)}
                            onChange={onChangeCheckbox(format.value)}
                        >{format.label}</Checkbox>
                    ))}
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Статус реализации</div>
                <div className={cn(styles.content, styles.checkboxes)}>
                    {implStatusList.map((el) => (
                        <Checkbox
                            key={el.id}
                            className={styles.checkbox}
                            checked={implStatuses?.includes(el.id)}
                            onChange={onChangeImplStatus(el.id)}
                        >{el.name}</Checkbox>
                    ))}
                </div>
            </div>
            {/*formats*/}

            <div className={styles.section}>
                <div className={styles.content}>
                    <Button type="text" onClick={handleReset}>
                        Сбросить фильтры
                    </Button>
                </div>
            </div>
        </div>
    );
};
