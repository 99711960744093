import { FilesActionTypes } from 'src/store/files/action-types';

export const GetFileStartAction = (id: string, fileType?: string) => ({
    type: FilesActionTypes.GET_FILE_START,
    id,
    fileType,
});
export const GetFileReportStartAction = (id: string, fileType?: string) => ({
    type: FilesActionTypes.GET_FILE_REPORT_START,
    id,
    fileType,
});
export const GetFileRealizationStartAction = (id: string, fileType?: string) => ({
    type: FilesActionTypes.GET_FILE_REALIZATION_START,
    id,
    fileType,
});

export const GetFileSuccessAction = (id: string, file: any) => ({
    type: FilesActionTypes.GET_FILE_SUCCESS,
    id,
    file,
});

export const GetFileErrorAction = (id: string, error: any) => ({
    type: FilesActionTypes.GET_FILE_ERROR,
    id,
    error,
});
