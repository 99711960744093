import {put, select} from 'redux-saga/effects';
import axios from 'axios';

import {
    CrawlerStartAction,
    CrawlerSuccessAction,
    CrawlerErrorAction,
} from 'src/store/realization/actions';
import {selectUserData} from "src/store/auth/selectors";

export function* crawlerWorker( { ids }: ReturnType<typeof CrawlerStartAction> ) {
    try {
        const {user} = yield select(selectUserData)

        const socketId = user.username + '-crawler'

        yield axios({
            method: 'PUT',
            url: `/impl/projects/content/crawler`,
            headers: {},
            data: ids,
            params: {
                socketId: socketId
            }
        });


        yield put(CrawlerSuccessAction());
    } catch (error) {
        yield put(CrawlerErrorAction(error));
    }
}
