import React, {FC} from 'react';
import ReactPlayer from 'react-player';
import styles from './styles.module.scss'

export type SlideAudioProps = {
    file?: object
    url?: string
    img?: string
    name?: string
}

const SlideAudio:FC<SlideAudioProps> = ({file, url, img, name}) => {
    return (
        <div className={styles.audio}>
            <div className={styles.audioInfo}>
                {img && (<div className={styles.img}>
                    <img src={img} alt="1"/>
                </div>)}
                <div className={styles.info}>
                    <strong>{name}</strong>
                </div>
            </div>
            {url ?
                (<ReactPlayer url={url}/>) :
                (// @ts-ignore
                    <audio src={process.env.REACT_APP_API_ENDPOINT + `impl/projects/content/files/${file.id}`}
                        controls
                        controlsList="nodownload"
                        crossOrigin='use-credentials'
                        muted>
                    </audio>
                )
            }
        </div>
    )
};
export default SlideAudio;