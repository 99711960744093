import {InferValueTypes} from 'src/types/common';
import {SessionsActionTypes} from 'src/store/sesssions/action-types';
import * as actions from './actions';

import {TSessionFilters, TSessionResponse} from 'src/types/sessions';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TSessionsState = {
    filters: TSessionFilters;
    data?: TSessionResponse;
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
    allSessions?: TSessionResponse;
};

export const initialState: TSessionsState = {
    filters: {
        filterData: {
            endDate: '',
            name: '',
            startDate: '',
        },
        page: 1,
        pageSize: 20,
        totalCount: 0,
        sort: {
            direction: 'ASC',
            field: 'id',
        }
    },
    data: undefined,
    isLoading: false,
    isLoaded: false,
    error: null,
    allSessions: undefined,
};

// used for filtering projects
export default function reducer(
    state: TSessionsState = initialState,
    action: ActionTypes,
): TSessionsState {
    switch (action.type) {
        case SessionsActionTypes.SET_FILTERS:
            return {
                ...state,
                filters: action.filters,
            };
        case SessionsActionTypes.GET_SESSIONS_START:
            return {
                ...state,
                data: state.data,
                isLoading: true,
                error: null,
            };
        case SessionsActionTypes.GET_SESSIONS_SUCCESS:
            return {
                ...state,
                data: action.sessions,
                isLoading: false,
                isLoaded: true,
                error: null,
            };
        case SessionsActionTypes.GET_SESSIONS_ERROR:
            return {
                ...state,
                data: undefined,
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };

        case SessionsActionTypes.GET_ALL_SESSIONS_START:
            return {
                ...state,
                allSessions: state.allSessions,
            };
        case SessionsActionTypes.GET_ALL_SESSIONS_SUCCESS:
            return {
                ...state,
                allSessions: action.sessions,
            };
        case SessionsActionTypes.GET_ALL_SESSIONS_ERROR:
            return {
                ...initialState,
                allSessions: undefined,
            };
        default:
            return state;
    }
}
