import { combineReducers } from 'redux';

import usersProjectsReducer from './users-projects';
import adminsProjectsReducer from './projects-by-voting-id';
import adminsDocumentsReducer from './documents-by-voting-id';
import ActionsReducer from "src/store/projects/reducer/actions";

const reducer = combineReducers({
    usersProjects: usersProjectsReducer,
    adminsProjects: adminsProjectsReducer,
    adminsDocuments: adminsDocumentsReducer,
    actionsProjects: ActionsReducer
});

export type TProjectsState = ReturnType<typeof reducer>;

export default reducer;