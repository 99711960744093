import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getYearsState = (state: TApplicationState) => state.years;

export const selectYears = createSelector(
    getYearsState,
    (state) => state.data.map(year => year.toString()),
);
export const selectYearsStatus = createSelector(
    getYearsState,
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        error: state.error,
    }),
);
