import { ProjInfoActionTypes } from 'src/store/project-info/action-types';

export const GetProjInfoStartAction = (id: string) => ({
    type: ProjInfoActionTypes.GET_PROJECT_START,
    id,
});

export const GetProjInfoSuccessAction = (id: string, project: any) => ({
    type: ProjInfoActionTypes.GET_PROJECT_SUCCESS,
    id,
    project,
});

export const GetProjInfoErrorAction = (id: string, error: any) => ({
    type: ProjInfoActionTypes.GET_PROJECT_ERROR,
    id,
    error,
});
