import React, { ChangeEvent, FC, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import {
    Button,
    Checkbox,
    Input,
    InputNumber,
    Slider,
} from 'antd';

import { LeftArrowIcon } from 'src/assets/svg/left-arrow';

import {
    GetVotingProjectsStartAction,
    ResetVoteFiltersAction,
    SetVoteFiltersAction,
} from 'src/store/voting/actions';
import {
    GetDocumentsByVotingIdStartAction,
    GetProjectsByVotingIdStartAction, ResetAdminsDocumentsFiltersAction,
    ResetAdminsProjectsFiltersAction,
    SetAdminsDocumentsFiltersAction,
    SetAdminsProjectsFiltersAction,
} from 'src/store/projects/actions';
import { selectCategories } from 'src/store/categories/selectors';
import { selectVoteFilters } from 'src/store/voting/selectors';
import { selectAdminsDocumentsFilters, selectAdminsProjectsFilters } from 'src/store/projects/selectors';

import styles from './styles.module.scss';

type TProps = {
    isSidebarOpened: boolean;
    closeSidebar: (event: SyntheticEvent) => void;
    type?: 'vote' | 'all-projects-list' | 'all-documents-list',
    id?: number;
};

export const VoteSidebar: FC<TProps> = ({
    closeSidebar,
    type,
    id,
}) => {
    const put = useDispatch();
    const filters = useSelector(getFilters());
    const categories = useSelector(selectCategories);
    const { minScore, maxScore } = filters.filterData;

    function getFilters() {
        switch (type) {
            case 'all-projects-list':
                return selectAdminsProjectsFilters;
            case 'all-documents-list':
                return selectAdminsDocumentsFilters;
            default:
                return selectVoteFilters;
        }
    }

    function getAction() {
        switch (type) {
            case 'all-projects-list':
                // @ts-ignore
                return GetProjectsByVotingIdStartAction(+id);
            case 'all-documents-list':
                // @ts-ignore
                return GetDocumentsByVotingIdStartAction(+id);
            default:
                return GetVotingProjectsStartAction();
        }
    }

    function getSetFilters(): Function {
        switch (type) {
            case 'all-projects-list':
                return SetAdminsProjectsFiltersAction;
            case 'all-documents-list':
                return SetAdminsDocumentsFiltersAction;
            default:
                return SetVoteFiltersAction;
        }
    }

    const handleReset = () => {
        switch (type) {
            case 'all-projects-list':
                put(ResetAdminsProjectsFiltersAction());
                break;
            case 'all-documents-list':
                put(ResetAdminsDocumentsFiltersAction());
                break;
            default:
                put(ResetVoteFiltersAction());
        }
        put(getAction());
    };

    const calculateMinMax = (value: number, name: 'minScore' | 'maxScore'): number => {
        let result = value;

        try {
            result = +(value.toFixed(2));
        } catch (e) {}

        if (name === 'minScore') {
            if (result < 0) result = 0;
            if (result > maxScore) result = maxScore - 1;

            return result;
        }

        if (result > 10) result = 10;
        if (result < minScore) result = minScore + 1;

        return result;
    };

    const handleChangeScoreInputs = (name: 'minScore' | 'maxScore') => (value: any) => {
        put(getSetFilters()({
            ...filters,
            filterData: {
                ...filters.filterData,
                [name]: calculateMinMax(value, name),
            },
            page: 1,
        }));
        put(getAction());
    };

    const onChangeSlider = (value: number[]) => {
        const [minScore, maxScore] = value;

        put(getSetFilters()({
            ...filters,
            filterData: {
                ...filters.filterData,
                minScore,
                maxScore,
            },
            page: 1,
        }));
        put(getAction());
    };

    const onChangeCheckbox = (categoryId: number) => (value: any) => {
        const isChecked = filters.filterData.categoryIds?.includes(categoryId);
        let categories: number[] = [ ...filters.filterData.categoryIds ];

        if (isChecked) {
            const index = categories.indexOf(categoryId);

            if (index !== -1) {
                categories.splice(index, 1);
            }
        } else {
            categories.push(categoryId);
        }

        put(getSetFilters()({
            ...filters,
            filterData: {
                ...filters.filterData,
                categoryIds: categories,
            },
            page: 1,
        }));
        put(getAction());
    }

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        put(getSetFilters()({
            ...filters,
            filterData: {
                ...filters.filterData,
                searchString: event.target.value,
            },
            page: 1,
        }));
    };

    const onSearch = () => {
        put(getAction());
    };

    return (
        <div className={styles.sidebar}>
            <div className={styles.handle} onClick={closeSidebar}>
                <LeftArrowIcon className={styles.arrowIcon} />
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Название проекта/Юридического лица</div>
                <div className={styles.content}>
                    <Input.Search
                        className={styles.search}
                        placeholder="Введите"
                        onChange={handleChangeSearch}
                        onSearch={onSearch}
                        value={filters.filterData.searchString}
                    />
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Балл</div>
                <div className={styles.content}>
                    <div className={styles.range}>
                        <InputNumber
                            className={styles.rangeInput}
                            placeholder="0"
                            name="minScore"
                            min={0}
                            max={9}
                            value={minScore}
                            onChange={handleChangeScoreInputs('minScore')}
                        />
                        <span>-</span>
                        <InputNumber
                            className={styles.rangeInput}
                            placeholder="10"
                            name="maxScore"
                            min={1}
                            max={10}
                            value={maxScore}
                            onChange={handleChangeScoreInputs('maxScore')}
                        />
                    </div>

                    <Slider
                        className={styles.slider}
                        range
                        min={0}
                        max={10}
                        defaultValue={[minScore, maxScore]}
                        value={[minScore, maxScore]}
                        onChange={onChangeSlider}
                    />
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Направление</div>
                <div className={cn(styles.content, styles.checkboxes)}>
                    { categories.map(category => (
                        <Checkbox
                            key={category.id}
                            className={styles.checkbox}
                            checked={filters.filterData.categoryIds?.includes(category.id)}
                            onChange={onChangeCheckbox(category.id)}
                        >{category.name}</Checkbox>
                    ))}
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.content}>
                    <Button type="text" onClick={handleReset}>
                        Сбросить фильтры
                    </Button>
                </div>
            </div>
        </div>
    );
};
