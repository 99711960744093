import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GetRealizationProjectContentStartAction,
    UpdateRealizationProjectContentErrorAction,
    UpdateRealizationProjectContentStartAction,
    UpdateRealizationProjectContentSuccessAction,
} from "src/store/realization/actions";

export function* updateProjectContentWorker(
    {id, project}: ReturnType<typeof UpdateRealizationProjectContentStartAction>,
) {
    try {
        const newArray = project.items
            .map((el: { name: string; description: string | null; id: number; order: number; }) => {
                return  {
                    name: el.name,
                    description: el.description,
                    id: el.id,
                    order: el.order
                }
            });

        const newProject = {
            name: project.name,
            description: project.description ? project.description : '',
            items: newArray
        }

        yield axios({
            method: 'PUT',
            url: `/impl/projects/content/${id}`,
            headers: {},
            data: newProject

        });
        yield put(UpdateRealizationProjectContentSuccessAction());
        yield put(GetRealizationProjectContentStartAction(id));
    } catch (error) {
        yield put(UpdateRealizationProjectContentErrorAction(error));
    }
}
