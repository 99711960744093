import { InferValueTypes } from 'src/types/common';
import { RealizationActionTypes } from 'src/store/realization/action-types';
import * as actions from '../actions';
import { TRealizationLinkContent, TRealizationLinksFilters } from 'src/types/realization';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

type TResources = {
    id: number,
    name: string
}

export type TRealizationLinksState = {
    projectId: number | null;
    filters: TRealizationLinksFilters;
    data: TRealizationLinkContent[];
    resources: TResources[] | null
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TRealizationLinksState = {
    projectId: null,
    filters: {
        filterData: {
            internetResourceIds: [],
            searchString: '',
        },
        page: 1,
        pageSize: 20,
        totalCount: 0,
        sort: {
            direction: 'ASC',
            field: 'id',
        },
    },
    data: [],
    resources: null,
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function usersProjects(
    state: TRealizationLinksState = initialState,
    action: ActionTypes,
): TRealizationLinksState {
    switch (action.type) {
        case RealizationActionTypes.SET_LINKS_FILTERS:
            return {
                ...state,
                filters: { ...action.filters },
            };
        case RealizationActionTypes.RESET_LINKS_FILTERS:
            return {
                ...state,
                filters: { ...initialState.filters },
            };
        case RealizationActionTypes.GET_LINKS_START:
            return {
                ...state,
                projectId: action.projectId,
                data: initialState.data,
                isLoading: true,
            };
        case RealizationActionTypes.GET_LINKS_SUCCESS:
            const { content, ...pagination } = action.data;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...pagination,
                },
                data: content,
                isLoading: false,
                isLoaded: true,
            };
        case RealizationActionTypes.GET_LINKS_ERROR:
            return {
                ...initialState,
                error: action.error,
            };
        case
            RealizationActionTypes.CREATE_LINKS_START ||
            RealizationActionTypes.UPDATE_LINKS_START ||
            RealizationActionTypes.DELETE_LINKS_START ||
            RealizationActionTypes.GET_INTERNET_RESOURCES_START:
            return {
                ...state,
                isLoading: true,
            }
        case RealizationActionTypes.GET_INTERNET_RESOURCES_SUCCESS:
            return {
                ...state,
                resources: action.data,
                isLoading: false
            }
        case
            RealizationActionTypes.CREATE_LINKS_SUCCESS ||
            RealizationActionTypes.UPDATE_LINKS_SUCCESS ||
            RealizationActionTypes.DELETE_LINKS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            }
        case
            RealizationActionTypes.CREATE_LINKS_ERROR ||
            RealizationActionTypes.UPDATE_LINKS_ERROR ||
            RealizationActionTypes.DELETE_LINKS_ERROR ||
            RealizationActionTypes.GET_INTERNET_RESOURCES_ERROR:
            return {
                ...initialState,
                isLoading: false,
                isLoaded: false,
                error: action.error
            }
        default:
            return state;
    }
}
