import { InferValueTypes } from 'src/types/common';
import * as actions from './actions';
import {IntegrationActionTypes} from "src/store/integration/actions-types";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TIntegrationProjects = {
    id: number,
    name: string
}

export type TIntegrationState = {
    lastImport: string | null;
    projects: TIntegrationProjects[];
    sessions: string[] | null;
    sessionsYears: number[] | null
    importId: string | null;
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TIntegrationState = {
    lastImport: null,
    projects: [],
    importId: null,
    sessions: null,
    sessionsYears: null,
    isLoading: false,
    isLoaded: false,
    error: null
};

export default function reducer(
    state: TIntegrationState = initialState,
    action: ActionTypes,
): TIntegrationState {
    switch (action.type) {
        case IntegrationActionTypes.RESET_DATA:
            return {
                ...initialState
            }
        case IntegrationActionTypes.GET_LAST_IMPORT_START:
            return {
                ...state,
                isLoading: true
            }
        case IntegrationActionTypes.GET_LAST_IMPORT_SUCCESS:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case IntegrationActionTypes.GET_LAST_IMPORT_ERROR:
            return {
                ...initialState,
                error: action.error
            }
        case IntegrationActionTypes.GET_LIST_PROJECT_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case IntegrationActionTypes.GET_LIST_PROJECT_SUCCESS:
            return {
                ...state,
                projects: action.data,
                isLoading: false,
                isLoaded: true
            }
        case IntegrationActionTypes.GET_LIST_PROJECT_ERROR:
            return {
                ...initialState,
                error: action.error
            }
        case IntegrationActionTypes.IMPORT_PROJECTS_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case IntegrationActionTypes.IMPORT_PROJECTS_SUCCESS:
            return {
                ...state,
                importId: action.id,
                isLoading: false,
                isLoaded: true
            }
        case IntegrationActionTypes.IMPORT_PROJECTS_ERROR:
            return {
                ...initialState,
                error: action.error
            }
        case IntegrationActionTypes.GET_LIST_SESSIONS_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case IntegrationActionTypes.GET_LIST_SESSIONS_SUCCESS:
            return {
                ...state,
                sessions: action.data,
                isLoading: false,
                isLoaded: true
            }
        case IntegrationActionTypes.GET_LIST_SESSIONS_ERROR:
            return {
                ...initialState,
                error: action.error
            }
        case IntegrationActionTypes.GET_LIST_SESSIONS_YEARS_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case IntegrationActionTypes.GET_LIST_SESSIONS_YEARS_SUCCESS:
            return {
                ...state,
                sessionsYears: action.data,
                isLoading: false,
                isLoaded: true
            }
        case IntegrationActionTypes.GET_LIST_SESSIONS_YEARS_ERROR:
            return {
                ...initialState,
                error: action.error
            }
        default:
            return state;
    }
}
