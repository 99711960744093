import React, {FC, useMemo, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {Button} from 'antd';
import {CloseOutlined, LoadingOutlined, RotateLeftOutlined, ZoomInOutlined, ZoomOutOutlined,} from '@ant-design/icons';

import styles from './styles.module.scss'
import classNames from "classnames";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type PdfProps = {
    file: any
    onClose: () => void
}

const PDF: FC<PdfProps> = ({ file, onClose }) => {
    const [numPages, setNumPages] = useState<any>(null);
    const [rotate, setRotate] = useState<0 | 90 | 180| 270>(0);
    const [scale, setScale] = useState(0.8);

    // @ts-ignore
    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }
    const handlerScaleUp = () => {
        const val: number = scale + 0.2
        setScale(+val.toFixed(2))
    }
    const handlerScaleOut = () => {
        if (scale === 0.2) return
        const val: number = scale - 0.2
        setScale(+val.toFixed(2))
    }
    const handlerRotate = () => {
        switch (rotate) {
            case 0:
                setRotate(90)
                break
            case 90:
                setRotate(180)
                break
            case 180:
                setRotate(270)
                break
            case 270:
                setRotate(0)
                break
        }
    }
    const renderPages = useMemo(() => {
        if (!numPages) return;

        return (
            <>
                { Array.from(Array(numPages)).map((item, index) => (
                    <Page
                        key={index}
                        pageNumber={index + 1}
                        className={styles.pdfChild}
                        scale={scale}
                        width={window.innerWidth}/>
                )) }
            </>
        );
    }, [numPages, file, scale]);

    return (
        <div>
            <Document
                options={{
                    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                    cMapPacked: true,
                }}
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                rotate={rotate}
                className={styles.pdf}
                loading={(<LoadingOutlined/>)}
            >
                {renderPages}
            </Document>
            <div className={styles.nav}>
                <Button
                    onClick={handlerScaleUp}
                    className={styles.btn}
                    type="primary" shape="circle"
                    icon={<ZoomInOutlined />} />
                <Button
                    onClick={handlerScaleOut}
                    className={styles.btn}
                    type="primary" shape="circle"
                    icon={<ZoomOutOutlined />} />
                <Button
                    onClick={handlerRotate}
                    className={styles.btn}
                    type="primary" shape="circle"
                    icon={<RotateLeftOutlined />} />
                <Button
                    onClick={onClose}
                    className={classNames(styles.btn, styles.close)}
                    type="primary"
                    shape="circle"
                    icon={<CloseOutlined />} />
            </div>
        </div>
    );
}
export default PDF;