import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GetRealizationTypesErrorAction,
    GetRealizationTypesStartAction,
    GetRealizationTypesSuccessAction,
} from "src/store/realization/actions";

export function* getProjectContentTypesWorker(action: ReturnType<typeof GetRealizationTypesStartAction>) {
    try {
        const {data} = yield axios({
            method: 'GET',
            url: `/impl/projects/content/types`,
            headers: {},
        });
        yield put(GetRealizationTypesSuccessAction(data));

    } catch (error) {
        yield put(GetRealizationTypesErrorAction(error));
    }
}
