import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    CreateRealizationProjectContentErrorAction,
    CreateRealizationProjectContentStartAction,
    CreatRealizationProjectContentSuccessAction,
    GetRealizationProjectContentStartAction,
} from "src/store/realization/actions";

export function* createProjectContentWorker(
    {project}: ReturnType<typeof CreateRealizationProjectContentStartAction>,
) {
    try {
        const newProject = {
            contentTypeId: project.contentTypeId,
            name: project.name,
            description: project.description ? project.description : '',
            projectId: project.id
        }

        const {data} =  yield axios({
            method: 'POST',
            url: `/impl/projects/content`,
            headers: {},
            data: newProject

        });
        yield put(CreatRealizationProjectContentSuccessAction(data));
        yield put(GetRealizationProjectContentStartAction(data));
    } catch (error) {
        yield put(CreateRealizationProjectContentErrorAction(error));
    }
}
