import { GeneralActionTypes } from 'src/store/general/action-types';

export const ToggleSidebarAction = (payload?: boolean) => ({
    type: GeneralActionTypes.TOGGLE_SIDEBAR,
    payload,
});

export const ToggleProfileAction = (payload?: boolean) => ({
    type: GeneralActionTypes.TOGGLE_PROFILE,
    payload,
});

export const ToggleVoteFiltersAction = (payload?: boolean) => ({
    type: GeneralActionTypes.TOGGLE_VOTE_FILTERS,
    payload,
});
export const ToggleSidebarMenuAction = () => ({
    type: GeneralActionTypes.TOGGLE_SIDEBAR_MENU,
});