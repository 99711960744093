import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GetListProjectErrorAction,
    GetListProjectStartAction,
    GetListProjectSuccessAction
} from "src/store/integration/actions";

export function* getListProjectWorker({sessionName, year}: ReturnType<typeof GetListProjectStartAction>) {
    try {
        const {data} = yield axios({
            method: 'GET',
            url: `/kk/projects`,
            headers: {},
            params: {
                sessionName: sessionName,
                year: year,
            },
        });
        yield put(GetListProjectSuccessAction(data));
    } catch (error) {
        yield put(GetListProjectErrorAction(error));
    }
}
