import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getFormatsState = (state: TApplicationState) => state.formats;

export const selectFormats = createSelector(
    getFormatsState,
    (state) => state.data.map(el => {
        return { label: el.name, value: el.id}
    })
);
export const selectFormatsStatus = createSelector(
    getFormatsState,
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        error: state.error,
    }),
);