import {
    GetUserByIdErrorAction,
    GetUserByIdStartAction,
    GetUserByIdSuccessAction,
} from 'src/store/users/actions';
import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* getUserWorker({userId}: ReturnType<typeof GetUserByIdStartAction>) {
    try {
        const { data: user }: AxiosResponse<any> = yield axios({
            method: 'GET',
            url: `/users/${userId}`,
            headers: {},
        });

        yield put(GetUserByIdSuccessAction(user));
        yield put(LogsActionsStartAction('VIEW_PROFILE', `${userId}`));

    } catch (error) {
        yield put(GetUserByIdErrorAction(error));
    }
}