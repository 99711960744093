import React, {FC, SyntheticEvent, useEffect, useState,} from 'react';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {push} from 'connected-react-router';
import {Button, Table} from 'antd';
import {Key, TablePaginationConfig,} from 'antd/lib/table/interface';

import {mainModuleRoutes} from 'src/routing/projects-module';

import {GetProjectsByVotingIdStartAction, SetAdminsProjectsFiltersAction,} from 'src/store/projects/actions';
import {GetFormatsStartAction} from 'src/store/formats/actions';
import {GetAllProjectsReportStartAction, GetAllProjectsVoteSummaryStartAction,} from 'src/store/voting/actions';
import {DeleteVotingAction} from 'src/store/voting-editor/actions';
import {LogsActionsStartAction} from 'src/store/logs/actions';
import {ToggleSidebarAction} from 'src/store/general/actions';

import {
    selectAdminsProjects,
    selectAdminsProjectsFilters,
    selectAdminsProjectsStatus,
} from 'src/store/projects/selectors';
import {selectAllProjectsVoteSummary} from 'src/store/voting/selectors';
import {selectIsModerator} from 'src/store/auth/selectors';
import {selectIsSidebarActive} from 'src/store/general/selectors';

import {handleGoToVotingEditor} from 'src/utils/redirects';

import {budgetCell} from 'src/components/data-table/budget-cell';
import {statusVoteCell} from "src/components/data-table/status-vote-cell";
import {categoryCell} from 'src/components/data-table/category-cell';
import {NameCell} from 'src/components/data-table/name-cell';
import {RequestCell} from 'src/components/data-table/request-cell';
import {CountdownComponent} from 'src/components/countdown';
import {VoteSummaryTable} from 'src/components/vote-summary-table';
import {ConfirmModal} from 'src/components/modals/confirm-modal';
import {TooltipComponent} from 'src/components/tooltip';
import {ToParentPage} from 'src/components/to-parent-page';

import {VoteSidebar} from 'src/components/vote-sidebar';
import styles from './styles.module.scss';
import {ErrorBoundary} from "src/components/error-boundary";
import moment from "moment";
import {Pagination} from "src/shared/lib/pagination";

type TProps = {
    isArchive?: boolean;
};

export const AllProjectsListPage: FC<TProps> = (props) => {
    const [confirmDeleteIsOpened, setConfirmDeleteIsOpened] = useState(false);
    const put = useDispatch();
    const { pathname } = useLocation();
    const votingId = +pathname.split('/')[2];
    const projects = useSelector(selectAdminsProjects);
    const filters = useSelector(selectAdminsProjectsFilters);
    const summary = useSelector(selectAllProjectsVoteSummary);
    const isModerator = useSelector(selectIsModerator);
    // const projectsReport = useSelector(selectAllProjectsReportData);
    const isSidebarActive = useSelector(selectIsSidebarActive);
    const { isLoading: projectsIsLoading } = useSelector(selectAdminsProjectsStatus);

    const handleDelete = () => {
        put(DeleteVotingAction(votingId))
        put(LogsActionsStartAction('DELETE_VOTING'))
        setConfirmDeleteIsOpened(false);
    };

    const handleDownloadReport = (reportType: 'comments'| 'votes') => () => {
        put(GetAllProjectsReportStartAction(votingId, reportType));
        if (reportType === 'comments') put(LogsActionsStartAction('DOWNLOAD_FULL_VOTES_REPORT'))
        else put(LogsActionsStartAction('DOWNLOAD_FULL_COMMENTS_REPORT'))
    };
    const canVoting = () => {
        if (!!summary.endDateTime) {
            const now = moment(new Date());
            const end = moment(summary.endDateTime);
            if (end.diff(now, "minutes") > 0) return true
            return false
        } else return true
    }

    let columns = [{
        title: props.isArchive ? 'Статус голосования' : 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: statusVoteCell(canVoting()),
        width: 100,
    }, {
        title: 'Номер заявки',
        dataIndex: 'requestNumber',
        key: 'requestNumber',
        width: 150,
        sorter: true,
        className: styles.nameCell,
        render: RequestCell,
    }, {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        width: 200,
        className: styles.nameCell,
        render: NameCell,
    }, {
        title: 'Направление',
        dataIndex: 'category',
        key: 'category',
        render: categoryCell,
        width: 200,
    }, {
        title: 'Бюджет',
        dataIndex: 'budget',
        key: 'budget',
        sorter: true,
        render: budgetCell,
        width: 200,
    }, {
        title: 'Балл',
        dataIndex: 'score',
        key: 'score',
        sorter: true,
        width: 120,
    }];

    const toggleSidebar = (value: boolean) => ((_event: SyntheticEvent) => {
        put(ToggleSidebarAction(value));
    });

    const handleRowClick = (id: number) => {
        const url = (props.isArchive
            ? mainModuleRoutes['vote-detailed-archive'].path
            : mainModuleRoutes['vote-detailed'].path)
            .replace(':votingId', `${votingId}`)
            .replace(':projectId', `${id}`)
        put(push(url));
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filter: Record<string, (Key | boolean)[] | null>,
        sorter: any,
    ) => {
        const newSorting = {
            page: pagination.current ? pagination.current : 1,
            pageSize: pagination.pageSize ? pagination.pageSize : 20,
            sort: {
                direction: sorter.order === 'descend' ? 'DESC' : 'ASC',
                field: sorter.field ? sorter.field : 'id',
            },
        };

        put(SetAdminsProjectsFiltersAction({
            ...filters,
            ...newSorting,
        }));
        put(GetProjectsByVotingIdStartAction(votingId));
        put(LogsActionsStartAction('SORT_VOTING_PROJECT'))
    };

    useEffect(() => {
        put(GetProjectsByVotingIdStartAction(votingId));
        put(GetAllProjectsVoteSummaryStartAction(votingId));
        put(GetFormatsStartAction());

        put(LogsActionsStartAction('OPEN_PROJECT_VOTING'))
        put(LogsActionsStartAction('OPEN_VOTING'))
    }, []);

    console.log('summary', summary)

    return (
        <ErrorBoundary>
            <div className={styles.container}>
                <div className={cn(styles.leftColumn, { [styles.isSidebarCollapsed]: !isSidebarActive })}>
                    <ToParentPage text="" parentPath={props.isArchive
                        ? mainModuleRoutes['archive-votes'].path
                        : mainModuleRoutes['all-votes-list'].path} />
                    { isModerator && (
                        <div className={styles.actions}>
                            <TooltipComponent
                                isActive={Boolean(summary.activated)}
                                message="Деактивируйте голосование что бы редактировать"
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={Boolean(summary.activated)}
                                    onClick={handleGoToVotingEditor({
                                        put: put,
                                        type: 'projects',
                                        votingId: votingId.toString(),
                                    })}
                                >Редактировать</Button>

                                <Button
                                    type="default"
                                    disabled={Boolean(summary.activated)}
                                    onClick={() => {setConfirmDeleteIsOpened(true)}}
                                >Удалить</Button>
                            </TooltipComponent>

                            {confirmDeleteIsOpened && (
                                <ConfirmModal
                                    isOpened={confirmDeleteIsOpened}
                                    onClose={() => {setConfirmDeleteIsOpened(false)}}
                                    onSubmit={handleDelete}
                                    message={`Вы действительно хотите удалить это голосование?'`}/>
                            )}
                        </div>
                    ) }

                    <CountdownComponent endTime={summary.endDateTime || undefined} />

                    <table className={styles.infoTable}>
                        <thead>
                            <tr>
                                <th>Дата ознакомления</th>
                                <th>Начало голосования</th>
                                <th>Окончание голосования</th>
                                { !props.isArchive && (<th>Проголосовали за все документы</th>) }
                            </tr>
                        </thead>

                        <tbody>
                            <VoteSummaryTable voteSummaryData={summary} isAdmin={true} isArchive={props.isArchive} />
                        </tbody>
                    </table>

                    { isModerator && (
                        <div className={styles.reports}>
                            <Button
                                className={styles.report}
                                type="link"
                                onClick={handleDownloadReport('votes')}
                            >
                                Итоговое голосование
                            </Button>
                            <Button
                                className={styles.report}
                                type="link"
                                onClick={handleDownloadReport('comments')}
                            >
                                Особое мнение
                            </Button>
                        </div>
                    ) }

                    <Table
                        scroll={{ x: 820 }}
                        sticky
                        columns={columns}
                        // @ts-ignore
                        dataSource={projects}
                        loading={projectsIsLoading}
                        pagination={{
                            current: filters.page,
                            pageSize: filters.pageSize,
                            total: filters.totalCount,
                            showSizeChanger: true,
                            position: ['bottomCenter'],
                            pageSizeOptions: Pagination()
                        }}
                        onChange={handleTableChange}
                        rowKey="id"
                        onRow={(record, _rowIndex) => {
                            return {
                                onClick: (_event) => { handleRowClick(record.id as number) },
                            };
                        }}
                    />
                </div>

                <div className={cn(styles.rightColumn, { [styles.isCollapsed]: !isSidebarActive })}>
                    <VoteSidebar
                        isSidebarOpened={isSidebarActive}
                        closeSidebar={toggleSidebar(false)}
                        type={'all-projects-list'}
                        id={votingId}
                    />
                </div>
            </div>
        </ErrorBoundary>
    );
};
