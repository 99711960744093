import { InferValueTypes } from 'src/types/common';
import { ReportingActionTypes } from 'src/store/reporting/action-types';
import * as actions from '../actions';
import { TReportTypeItem } from 'src/types/reporting';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TReportingTypesState = {
    data: TReportTypeItem[];
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TReportingTypesState = {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function listReducer(
    state: TReportingTypesState = initialState,
    action: ActionTypes,
): TReportingTypesState {
    switch (action.type) {
        case ReportingActionTypes.GET_TYPES_START:
            return {
                ...initialState,
                data: state.data,
                isLoading: true,
            };
        case ReportingActionTypes.GET_TYPES_SUCCESS:
            return {
                ...initialState,
                data: action.data,
                isLoading: false,
                isLoaded: true,
            };
        case ReportingActionTypes.GET_TYPES_ERROR:
            return {
                ...initialState,
                error: action.error,
            };
        default:
            return state;
    }
}
