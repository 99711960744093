import {put} from 'redux-saga/effects';
import {push} from 'connected-react-router';

import {LogoutAction,} from 'src/store/auth/actions';
import {deleteAllCookies} from 'src/utils/cookies';
import keycloak from "src/Keycloak";

export function* logoutWorker(_: ReturnType<typeof LogoutAction>) {
    try {
        deleteAllCookies();
        keycloak.logout();
        localStorage.removeItem('user');
    } catch (error) {}

    yield put(push('/auth'));
}
