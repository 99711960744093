import { InferValueTypes } from 'src/types/common';
import { AuthActionTypes } from 'src/store/auth/action-types';
import * as actions from './actions';
import {TUser} from 'src/types/user';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TUserData = {
    user: TUser;
    basic: string
};

export type TAuthState = {
    isLoggedIn: boolean;
    user?: TUserData;
    error: any;
    isRecovered: boolean;
    isRecoverLoading: boolean;
    recoverError: any;
};

export const initialState: TAuthState = {
    isLoggedIn: false,
    user: undefined,
    error: null,
    isRecovered: false,
    isRecoverLoading: false,
    recoverError: null,
};

const user = localStorage.getItem('user');

let authState: TAuthState = {
    ...initialState
}

if (user) {
    authState = {
        ...authState,
        isLoggedIn: true,
        user: JSON.parse(user),
    }
}

export default function reducer(state: TAuthState = authState, action: ActionTypes): TAuthState {
    switch (action.type) {
        case AuthActionTypes.LOGIN_START:
            return { ...initialState };
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: action.user,
            };
        case AuthActionTypes.LOGIN_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case AuthActionTypes.LOGOUT:
            return { ...initialState };

        case AuthActionTypes.RECOVER_PWD_START:
            return {
                ...state,
                isRecovered: false,
                isRecoverLoading: true,
                recoverError: null,
            };
        case AuthActionTypes.RECOVER_PWD_SUCCESS:
            return {
                ...state,
                isRecovered: true,
                isRecoverLoading: false,
            };
        case AuthActionTypes.RECOVER_PWD_ERROR:
            return {
                ...state,
                isRecovered: false,
                isRecoverLoading: true,
                recoverError: action.error,
            };
        case AuthActionTypes.RECOVER_PWD_RESET:
            return {
                ...state,
                isRecovered: false,
                isRecoverLoading: false,
                recoverError: null,
            }
        default:
            return state;
    }
}
