import { TApplicationState } from 'src/store/application-state';
import {createSelector} from "reselect";

const getSystemSettingState = (state: TApplicationState) => state.systemSetting;
export const selectSystemSetting = createSelector(
    getSystemSettingState,
    (state) => state.settings
);
export const selectSystemSettingSA_Ak = createSelector(
    getSystemSettingState,
    (state) => state.settings?.supportAmount?.value === 'true' && state.settings.actualKpi?.value === 'true'
);
export const selectSystemSettingStatus = createSelector(
    getSystemSettingState,
    (state) => ({error: state.error, isLoading: state.isLoading})
);