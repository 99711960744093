export const UsersActionTypes = {
    CREATE_USER_START: '[USERS] CREATE_USER_START',
    CREATE_USER_SUCCESS: '[USERS] CREATE_USER_SUCCESS',
    CREATE_USER_ERROR: '[USERS] CREATE_USER_ERROR',

    GET_USER_START: '[USERS] GET_USER_START',
    GET_USER_SUCCESS: '[USERS] GET_USER_SUCCESS',
    GET_USER_ERROR: '[USERS] GET_USER_ERROR',

    GET_USER_ACTIONS_START: '[USERS] GET_USER_ACTIONS_START',
    GET_USER_ACTIONS_SUCCESS: '[USERS] GET_USER_ACTIONS_SUCCESS',
    GET_USER_ACTIONS_ERROR: '[USERS] GET_USER_ACTIONS_ERROR',

    GET_USER_STATISTIC_START: '[USERS] GET_USER_STATISTIC_START',
    GET_USER_STATISTIC_SUCCESS: '[USERS] GET_USER_STATISTIC_SUCCESS',
    GET_USER_STATISTIC_ERROR: '[USERS] GET_USER_STATISTIC_ERROR',

    GET_USERS_START: '[USERS] GET_USERS_START',
    GET_USERS_SUCCESS: '[USERS] GET_USERS_SUCCESS',
    GET_USERS_ERROR: '[USERS] GET_USERS_ERROR',

    PUT_USER_START: '[USERS] PUT_USER_START',
    PUT_USER_SUCCESS: '[USERS] PUT_USER_SUCCESS',
    PUT_USER_ERROR: '[USERS] PUT_USER_ERROR',

    DELETE_USER_START: '[USERS] DELETE_USER_START',
    DELETE_USER_SUCCESS: '[USERS] DELETE_USER_SUCCESS',
    DELETE_USER_ERROR: '[USERS] DELETE_USER_ERROR',


} as const;
