import axios from 'axios';
import {Dispatch} from 'redux';

import {LogoutAction} from 'src/store/auth/actions';
import keycloak from "src/Keycloak";

export const fetchersInterceptor = (dispatch: Dispatch) => {
    axios.interceptors.request.use(async function (request) {
        try {
            await keycloak.updateToken(15);
            const token = keycloak.token;
            if (token) {
                request.headers = {...request.headers, 'Authorization': `Bearer ${token}`};
            }
        } catch (error) {
            return Promise.reject(error);
        }

        return request;
    }, function (error) {
        return Promise.reject(error);
    });
    axios.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
    }, function (error) {
        if (401 === error.response.status) {
            dispatch(LogoutAction());
        }

        return Promise.reject(error);
    });
};
