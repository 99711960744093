import axios, { AxiosResponse } from 'axios';

import {
    GetAllDocumentsReportStartAction,
} from 'src/store/voting/actions';

export function* getAllDocumentsReportWorker(
    { votingId, reportType }: ReturnType<typeof GetAllDocumentsReportStartAction>
) {
    try {
        const response: AxiosResponse<any> = reportType === 'comments' ? yield axios({
            method: 'GET',
            url: `/voting/${votingId}/comments/report`,
            responseType: 'blob',
            headers: {},
        }) : yield axios({
            method: 'GET',
            url: `/voting/${votingId}/votes/report`,
            responseType: 'blob',
            headers: {},
        });

        const url = window.URL.createObjectURL(response.data);
        let a = document.createElement('a');
        a.href = url;
        a.download = response.headers['Content-Disposition']
            ? response.headers['Content-Disposition'].slice(12)
            : `${reportType}-report.${reportType === 'comments' ? 'xls' : 'xls'}`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        // yield put(GetAllDocumentsReportSuccessAction({comments, votes}));
    } catch (error) {
        // yield put(GetAllDocumentsReportErrorAction(error));
    }
}
