import {put, select} from 'redux-saga/effects';
import axios from 'axios';

import {
    ImportProjectErrorAction,
    ImportProjectStartAction,
    ImportProjectSuccessAction
} from "src/store/integration/actions";
import {selectUserData} from "src/store/auth/selectors";

export function* importProjectWorker({data}: ReturnType<typeof ImportProjectStartAction>) {
    try {
        const {user} = yield select(selectUserData)

        const socketId = user.username + '-content'
        const importData = {
            "importProjectIds": data.importProjectIds,
            "sessionId": data.sessionId
        }

        const { data: id } = yield axios({
            method: 'POST',
            url: `/kk/projects/import`,
            headers: {},
            data: importData,
            params: {
                socketId: socketId
            }
        });
        yield put(ImportProjectSuccessAction(id));
    } catch (error) {
        yield put(ImportProjectErrorAction(error));
    }
}
