import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getWavesState = (state: TApplicationState) => state.waves;

export const selectWaves = createSelector(
    getWavesState,
    (state) => state.data,
);
export const selectWavesStatus = createSelector(
    getWavesState,
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        error: state.error,
    }),
);
