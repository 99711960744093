import React from 'react';

import { TProjectContent } from 'src/types/projects';

import styles from './styles.module.scss';

export const RequestCell = (requestNumber: string, data: TProjectContent) => (
    <div className={styles.cell}>
        <span className={styles.name}>{requestNumber}</span>
    </div>
);
