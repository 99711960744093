import React, { FC } from 'react';
import { TSvgIcon } from 'src/types/svg-icon';

export const LeftArrowIcon: FC<TSvgIcon> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61.7513"
        height="42.7255"
        viewBox="0 0 61.7513 42.7255"
        preserveAspectRatio="xMidYMid meet"
        {...props}
    >
        <g id="Слой_2" data-name="Слой 2">
            <g id="Слой_1-2" data-name="Слой 1">
                <rect className="cls-1" x="5.2744" y="17.8962" width="56.4769" height="6.9332"/>
                <polygon
                    className="cls-1"
                    points="21.152 0 26.054 4.902 9.804 21.152 26.474 37.824 21.572 42.726 0 21.152 21.152 0"/>
            </g>
        </g>
    </svg>
);
