export const IntegrationActionTypes = {
    RESET_DATA: '[INTEGRATION] RESET_DATA',

    GET_LAST_IMPORT_START: '[INTEGRATION] GET_LAST_IMPORT_START',
    GET_LAST_IMPORT_SUCCESS: '[INTEGRATION] GET_LAST_IMPORT_SUCCESS',
    GET_LAST_IMPORT_ERROR: '[INTEGRATION] GET_LAST_IMPORT_ERROR',

    GET_LIST_PROJECT_START: '[INTEGRATION] GET_LIST_PROJECT_START',
    GET_LIST_PROJECT_SUCCESS: '[INTEGRATION] GET_LIST_PROJECT_SUCCESS',
    GET_LIST_PROJECT_ERROR: '[INTEGRATION] GET_LIST_PROJECT_ERROR',

    IMPORT_PROJECTS_START: '[INTEGRATION] IMPORT_PROJECTS_START',
    IMPORT_PROJECTS_SUCCESS: '[INTEGRATION] IMPORT_PROJECTS_SUCCESS',
    IMPORT_PROJECTS_ERROR: '[INTEGRATION] IMPORT_PROJECTS_ERROR',

    GET_LIST_SESSIONS_START: '[INTEGRATION] GET_LIST_SESSIONS_START',
    GET_LIST_SESSIONS_SUCCESS: '[INTEGRATION] GET_LIST_SESSIONS_SUCCESS',
    GET_LIST_SESSIONS_ERROR: '[INTEGRATION] GET_LIST_SESSIONS_ERROR',

    GET_LIST_SESSIONS_YEARS_START: '[INTEGRATION] GET_LIST_SESSIONS_YEARS_START',
    GET_LIST_SESSIONS_YEARS_SUCCESS: '[INTEGRATION] GET_LIST_SESSIONS_YEARS_SUCCESS',
    GET_LIST_SESSIONS_YEARS_ERROR: '[INTEGRATION] GET_LIST_SESSIONS_YEARS_ERROR',

} as const;
