import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GetInternetResourcesStartAction,
    UpdateInternetResourcesStartAction,
    UpdateInternetResourcesSuccessAction,
    UpdateInternetResourcesErrorAction
} from "src/store/internet-resources/actions";

export function* updateResourcesWorker({resource, resourceId}: ReturnType<typeof UpdateInternetResourcesStartAction>) {
    try {
        const newResource = {
            domainLink: resource.domainLink,
            image: resource.newImg ? resource.newImg.thumbUrl : resource.img,
            name: resource.label
        }

        yield axios({
            method: 'PUT',
            url: `/internet_resources/${resourceId}`,
            headers: {},
            data: newResource
        });

        yield put(UpdateInternetResourcesSuccessAction());
        yield put(GetInternetResourcesStartAction());
    } catch (error) {
        yield put(UpdateInternetResourcesErrorAction(error));
    }
}
