import {
    takeLatest
} from 'redux-saga/effects';

import { UsersActionTypes } from 'src/store/users/action-types';

import {addUserWorker} from "src/workers/users/add-user";
import {getUserWorker} from "src/workers/users/get-user";
import {getUsersWorker} from "src/workers/users/get-users";
import {updateUserWorker} from "src/workers/users/put-user";
import {deleteUserWorker} from "src/workers/users/delete-user";
import {getUserActionsWorker} from "src/workers/users/get-user-actions";
import {getUsersStatisticWorker} from "src/workers/users/get-users-statistic";

export default function* usersSaga() {
    yield takeLatest(UsersActionTypes.CREATE_USER_START, addUserWorker)
    yield takeLatest(UsersActionTypes.GET_USER_START, getUserWorker)
    yield takeLatest(UsersActionTypes.GET_USERS_START, getUsersWorker)
    yield takeLatest(UsersActionTypes.PUT_USER_START, updateUserWorker)
    yield takeLatest(UsersActionTypes.DELETE_USER_START, deleteUserWorker)
    yield takeLatest(UsersActionTypes.GET_USER_ACTIONS_START, getUserActionsWorker)
    yield takeLatest(UsersActionTypes.GET_USER_STATISTIC_START, getUsersStatisticWorker)
}