import {put} from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';
import {push} from 'connected-react-router';

import {GetCurrentUser, LoginErrorAction, LoginSuccessAction} from 'src/store/auth/actions';
import {mainModuleRoutes} from 'src/routing/projects-module';
import {TUser} from 'src/types/user';
import {TUserData} from 'src/store/auth/reducer';
import {notification} from "antd";

export function* GetCurrentUserWorker(_action: ReturnType<typeof GetCurrentUser>) {
    try {
        const { data }: AxiosResponse<TUser> = yield axios({
            method: 'GET',
            url: '/current_user',
        });

        const user: TUserData = {
            user: data,
            basic: Buffer.from(`${data.username}:`).toString("base64")
        };

        localStorage.setItem('user', JSON.stringify(user));
        yield put(LoginSuccessAction(user));
        yield put(push(mainModuleRoutes.list.path));
    } catch (error) {
        yield put(push('/error'));
        yield put(LoginErrorAction(error));
        const errorMessage = error.response?.data.message || 'Ошибка синхронизации';
        notification['error']({
            message: 'Ошибка',
            description: errorMessage
        });
    }
}
