import { InferValueTypes } from 'src/types/common';
import { VotingActionTypes } from 'src/store/voting/action-types';
import * as actions from '../actions';
import { TDataStatus } from 'src/types/data-status';
import { TAllVotesListResponse } from 'src/types/vote';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TAllVotesListState = {
    data: TAllVotesListResponse;
} & TDataStatus;

export const initialState: TAllVotesListState = {
    data: {
        content: [],
        page: 1,
        pageSize: 20,
        totalCount: 0,
        sort: {
            direction: 'ASC',
            field: 'id',
        },
    },
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function reducer(
    state: TAllVotesListState = initialState,
    action: ActionTypes,
): TAllVotesListState {
    switch (action.type) {
        case VotingActionTypes.SET_ALL_VOTES_LIST_SORTING:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data,
                }
            }
        case VotingActionTypes.GET_ALL_VOTES_LIST_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null,
            };
        case VotingActionTypes.GET_ALL_VOTES_LIST_SUCCESS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
                isLoaded: true,
                error: null,
            };
        case VotingActionTypes.GET_ALL_VOTES_LIST_ERROR:
            return {
                ...state,
                data: { ...initialState.data },
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };
        default:
            return state;
    }
}
