import { InferValueTypes } from 'src/types/common';
import { ReportingActionTypes } from 'src/store/reporting/action-types';
import * as actions from '../actions';
import { TReportingContentItem, TReportingOptions } from 'src/types/reporting';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TReportingListState = {
    filters: TReportingOptions;
    data: TReportingContentItem[];
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TReportingListState = {
    filters: {
        filterData: {
            searchString: '',
            year: undefined,
            quarter: undefined,
            reportTypeIds: [],
        },
        page: 1,
        pageSize: 20,
        totalCount: 0,
        sort: {
            direction: 'ASC',
            field: 'id',
        },
    },
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function listReducer(
    state: TReportingListState = initialState,
    action: ActionTypes,
): TReportingListState {
    switch (action.type) {
        case ReportingActionTypes.RESET_FILTERS:
            return {
                ...state,
                filters: { ...initialState.filters },
            };
        case ReportingActionTypes.SET_FILTERS:
            return {
                ...state,
                filters: action.filters,
            };
        case ReportingActionTypes.GET_LIST_START:
            return {
                ...initialState,
                data: state.data,
                filters: state.filters,
                isLoaded: state.isLoaded,
                isLoading: true,
            };
        case ReportingActionTypes.GET_LIST_SUCCESS:
            return {
                ...initialState,
                data: action.data,
                filters: state.filters,
                isLoading: false,
                isLoaded: true,
            };
        case ReportingActionTypes.GET_LIST_ERROR:
            return {
                ...initialState,
                filters: state.filters,
                error: action.error,
            };
        default:
            return state;
    }
}
