import React, {FC, SyntheticEvent, useState} from 'react';
import styles from "./styles.module.scss";
import {Modal} from "react-bootstrap";
import {Button, Select} from "antd";
import {useFormik} from "formik";
import * as Yup from "yup";
import ModalUpload from "src/components/form-components/modal-upload";
import ModalInput from "src/components/form-components/modal-input";
import cn from "classnames";
import TextArea from "antd/es/input/TextArea";
import {useDispatch, useSelector} from "react-redux";
import {
    selectReportingQuartersData, selectReportingQuartersStatus,
    selectReportingYearsData, selectReportingYearsStatus,
} from "src/store/reporting/selectors";
import {
    CreateReportStartAction,

} from "src/store/reporting/actions";
import {QuartersNames} from "src/constatnts/quarters";
const { Option } = Select;

type ReportModalProps = {
    onClose: any
}
type TState = {
    type: 'doc' | 'video'
}

const ReportModal: FC<ReportModalProps> = ({onClose}) => {
    const put = useDispatch();
    const quarters = useSelector(selectReportingQuartersData);
    const { isLoading: quartersIsLoading } = useSelector(selectReportingQuartersStatus);
    const years = useSelector(selectReportingYearsData);
    const { isLoading: yearsIsLoading } = useSelector(selectReportingYearsStatus);

    const [state, setState] = useState<TState>({
        type: 'doc'
    })

    const handler = (type: 'doc' | 'video') => (_event: SyntheticEvent) => {
        setState({...state, type: type})
    }

    const initialData = {
        name: '',
        reportFile: [],
        url: '',
        quarters: quarters ? quarters[0] : '',
        years: years ? years[0] : ''
    }

    const projectForm = useFormik({
        initialValues: {
            ...initialData,
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            quarters: Yup.number().required('Required'),
            years: Yup.number().required('Required'),
        }),
    });

    function onSubmit(values: any, _formikHelpers: any) {
        const typeId = (state.type === 'video') ? 1 : 2;
        put(CreateReportStartAction(values, typeId));
        onClose('close')();
    }
    function onChange(event: SyntheticEvent<HTMLTextAreaElement>) {
        let result = event.currentTarget.value;
        try {
            const doc = new DOMParser().parseFromString(result, 'text/html');
            const validIframe = doc.getElementsByTagName('iframe')[0];

            if (validIframe && validIframe.src) {
                result = validIframe.src;
            }
        } catch (_error) {}
        projectForm.setFieldValue('url', result);
    }

    const onChangeYear = (year: string) => {
        projectForm.setFieldValue('years', year);
    };
    const onChangeQuarter = (value: string) => {
        projectForm.setFieldValue('quarters', value);
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            keyboard={true}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={`${styles.dialog} ${styles.video}` }
        >
            <form
                name="projectForm"
                onSubmit={projectForm.handleSubmit}
                className={styles.form}
            >
                <Modal.Body className={styles.body}>
                    <h3 className={styles.header}>
                        Добавление отчета
                    </h3>

                    <div className={styles.message}>
                        <div className={styles.nav}>
                            <Button
                                onClick={handler('doc')}
                                type={state.type === 'doc' ? 'primary' : 'default'}>Документ</Button>
                            <Button
                                onClick={handler('video')}
                                type={state.type === 'video' ? 'primary' : 'default'}>Видеоматериал</Button>
                        </div>
                        <div className={styles.form}>
                            <div className={styles.select}>
                                <div className={styles.section}>
                                    <div className={styles.heading}>Год проекта</div>
                                    <div className={styles.content}>
                                        <Select
                                            className={styles.search}
                                            allowClear={true}
                                            placeholder="Выберите год"
                                            optionFilterProp="children"
                                            onChange={onChangeYear}
                                            loading={yearsIsLoading}
                                            value={`${projectForm.values.years}`}
                                        >
                                            { years.map((value) => (
                                                <Option key={value} value={value}>{value}</Option>
                                            )) }
                                        </Select>
                                    </div>
                                </div>

                                <div className={styles.section}>
                                    <div className={styles.heading}>Квартал</div>
                                    <div className={styles.content}>
                                        <Select
                                            className={styles.search}
                                            allowClear={true}
                                            placeholder="Выберите квартал"
                                            optionFilterProp="children"
                                            onChange={onChangeQuarter}
                                            loading={yearsIsLoading || quartersIsLoading}
                                            value={`${projectForm.values.quarters}`}
                                        >
                                            { quarters.map((value) => (
                                                <Option
                                                    key={value}
                                                    selected={value === projectForm.values.quarters}
                                                    value={value}
                                                    // @ts-ignore
                                                >{ value ? QuartersNames[`${value}`] : value }</Option>
                                            )) }
                                        </Select>
                                    </div>
                                </div>
                            </div>

                            <ModalInput
                                form={projectForm}
                                label={"name"}
                                text={state.type === 'doc' ? 'Название документа' : 'Название видеоматериала'} />
                            {!projectForm.values.url && (
                                <ModalUpload
                                    form={projectForm}
                                    label={'reportFile'}
                                    existingLabel={'existingReportFile'}
                                    text={'Добавить файл'}
                                    isEdit={false}
                                    type={'single'}
                                    isImg={false}
                                    typeUploadFile={state.type === 'video' ? 'video/*' : undefined}
                                />
                            )}
                            {(state.type === 'video') && !projectForm.values.reportFile.length && (
                                <TextArea
                                    rows={3}
                                    className={cn(styles.input)}
                                    name="presentationUrl"
                                    placeholder="Вставте код"
                                    value={projectForm.values.url}
                                    onChange={onChange}
                                    onBlur={onChange}
                                />
                            )}

                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button type="default" onClick={onClose('close')}>Отменить</Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!(!!projectForm.values.name && (projectForm.values.reportFile.length || !!projectForm.values.url))}
                    >Подтвердить</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
};
export default ReportModal;