import {
    all,
    takeLatest,
} from 'redux-saga/effects';

import { ProjectsActionTypes } from 'src/store/projects/action-types';

import { addProjectWorker } from 'src/workers/projects/add-projects';
import { editProjectWorker } from 'src/workers/projects/edit-project';
import { deleteProjectWorker } from 'src/workers/projects/delete-project';
import { getProjectsByVotingIdWorker } from 'src/workers/projects/get-projects-by-voting-id';
import { getDocumentsByVotingIdWorker } from 'src/workers/projects/get-documents-by-voting-id';
import {massActionWorker} from "src/workers/projects/mass-action";

export default function* projectsSaga() {
    yield all([
        yield takeLatest(ProjectsActionTypes.ADD_PROJECT_START, addProjectWorker),
        yield takeLatest(ProjectsActionTypes.EDIT_PROJECT_START, editProjectWorker),
        yield takeLatest(ProjectsActionTypes.DELETE_PROJECT_START, deleteProjectWorker),
        yield takeLatest(ProjectsActionTypes.GET_PROJECTS_BY_VOTING_ID_START, getProjectsByVotingIdWorker),
        yield takeLatest(ProjectsActionTypes.GET_DOCUMENTS_BY_VOTING_ID_START, getDocumentsByVotingIdWorker),
        yield takeLatest(ProjectsActionTypes.PUT_PROJECTS_ACTIONS_START, massActionWorker),
    ]);
}