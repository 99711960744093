import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, RouteProps, Switch,} from 'react-router-dom';

import {GetAllSessionsStartAction} from 'src/store/sesssions/actions';
import {GetCategoriesStartAction} from 'src/store/categories/actions';
import {GetVotingOptionsStartAction} from 'src/store/voting/actions';

import {selectCategoriesStatus} from 'src/store/categories/selectors';

import {MainHeader} from 'src/components/main-header';
import {PrivateRoute} from 'src/routing/private-route';
import {ProjectsListPage} from 'src/features/projects-list-page';
import {ProjectsDetailedPage} from 'src/features/project-detailed-page';
import {VotePage} from 'src/features/vote-page';
import {VoteDetailedPage} from 'src/features/vote-detailed-page';
import {DocumentDetailedPage} from 'src/features/document-detailed-page';
import {AllVotesListPage} from 'src/features/admin/all-votes-list-page';
import {VotingEditor} from 'src/features/admin/voting-editor';
import {AllProjectsListPage} from 'src/features/admin/all-projects-list-page';
import {AllDocumentsListPage} from 'src/features/admin/all-documents-list-page';

import UsersListPage from "src/features/users";
import UserDetailsPage from "src/features/user-details";
import UsersStatisticPage from "src/features/statistic";
import InternetResourceSettingsPage from "src/features/settings/internet-resource-settings";

import RealizationDetailedPage from "src/features/realization-detailed-page";
import {RealizationListPage} from 'src/features/realization-list';

import {LogsLoginStartAction, LogsLogoutStartAction} from "src/store/logs/actions";

import styles from './styles.module.scss';
import {ReportingListPage} from 'src/features/reporting-list';
import ActionsSettings from "src/features/settings/actions-settings";
import Main from 'src/features/main-template';

export type TMainModuleKeys = 'project'
| 'list'
| 'vote'
| 'vote-detailed'
| 'vote-detailed-archive'
| 'vote-document-archive'
| 'vote-document'
| 'all-projects-list'
| 'all-documents-list'
| 'all-votes-list'
| 'archive-votes'
| 'archive-projects'
| 'archive-documents'
| 'users'
| 'users-detailed'
| 'users-statistic'
| 'create-voting'
| 'edit-voting'
| 'user-action-settings'
| 'internet-resource-settings'
| 'realization'
| 'realization-detailed'
| 'realization-detailed-project'
| 'reporting-list';

type TRoutes = RouteProps & {
    path: string;
};

export type TMainModuleRoutes = Record<TMainModuleKeys, TRoutes>;

export const mainModuleRoutes: TMainModuleRoutes = {
    project: { path: '/list/:pageId', exact: true, children: <ProjectsDetailedPage /> },
    list: { path: '/list', exact: true, children: <ProjectsListPage /> },
    vote: { path: '/vote', exact: true, children: <VotePage /> },
    'vote-detailed': {
        path: '/vote-detailed/:votingId/:projectId',
        exact: true,
        children: <VoteDetailedPage />,
    },
    'vote-document': {
        path: '/vote-document/:votingId/:projectId',
        exact: true,
        children: <DocumentDetailedPage />,
    },
    'create-voting': { path: '/create-voting', exact: true, children: <VotingEditor /> },
    'edit-voting': { path: '/edit-voting/:votingId', exact: true, children: <VotingEditor isEdit={true} /> },
    'all-votes-list': { path: '/all-votes-list', exact: true, children: <AllVotesListPage /> },
    'all-projects-list': {
        path: '/all-projects-list/:votingId',
        exact: true,
        children: <AllProjectsListPage />,
    },
    'all-documents-list': {
        path: '/all-documents-list/:votingId',
        exact: true,
        children: <AllDocumentsListPage />,
    },
    //realization routes
    realization: { path: '/realization', exact: true, children: <RealizationListPage /> },
    'realization-detailed': { path: '/realization/:contentId', exact: true, children: <RealizationDetailedPage/> },
    'realization-detailed-project': { path: '/realization/:contentId/project', exact: true, children: <ProjectsDetailedPage/> },

    'reporting-list': { path: '/reporting', exact: true, children: <ReportingListPage /> },

    // user's archive routes
    'archive-votes': { path: '/archive-votes', exact: true, children: <AllVotesListPage isArchive={true} /> },
    'archive-projects': {
        path: '/archive-projects-list/:votingId',
        exact: true,
        children: <AllProjectsListPage isArchive={true} />,
    },
    'archive-documents': {
        path: '/archive-documents-list/:votingId',
        exact: true,
        children: <AllDocumentsListPage isArchive={true} />,
    },
    'vote-detailed-archive': {
        path: '/vote-detailed-archive/:votingId/:projectId',
        exact: true,
        children: <VoteDetailedPage isArchive={true} />,
    },
    'vote-document-archive': {
        path: '/vote-document-archive/:votingId/:projectId',
        exact: true,
        children: <DocumentDetailedPage isArchive={true} />,
    },
    users: { path: '/users', exact: true, children: <UsersListPage />},
    'users-detailed': { path: '/users/:userId', exact: true, children: <UserDetailsPage/>},
    'users-statistic': { path: '/users-statistic', exact: true, children: <UsersStatisticPage /> },
    //settings
    'internet-resource-settings': { path: '/settings/internet-resource-settings', exact: true, children: <InternetResourceSettingsPage/>},
    'user-action-settings': { path: '/settings/user-action-settings', exact: true, children: <ActionsSettings/>},
};

export const ProjectsModule = () => {
    const put = useDispatch();
    const {
        isLoaded: categoriesIsLoaded,
        isLoading: categoriesIsLoading,
    } = useSelector(selectCategoriesStatus);

    useEffect(() => {
        if (!categoriesIsLoaded && !categoriesIsLoading) {
            put(GetCategoriesStartAction());
        }

        put(GetAllSessionsStartAction());
        put(GetVotingOptionsStartAction());

        //TODO сделать проверку или авторизован уже
        put(LogsLoginStartAction())

        window.addEventListener("unload", function() {
            put(LogsLogoutStartAction())
        });
        return () => {
            put(LogsLogoutStartAction())
        }
    }, []);

    return (
        <Main>
            <MainHeader />
            <div className={styles.page}>
                <Switch>
                    { Object.values(mainModuleRoutes).map(({ children, ...rest }) => (
                        <PrivateRoute
                            key={`${rest.path}`}
                            children={children}
                            {...rest}
                        />
                    )) }
                    <Redirect to="/list" />
                </Switch>
            </div>
        </Main>
    );
};
