import {
    put,
    select,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TSession } from 'src/types/sessions';

import {
    GetProjectsStartAction,
    GetProjectsSuccessAction,
    GetProjectsErrorAction,
    SetProjectsFiltersAction,
} from 'src/store/projects/actions';
import { selectIsModerator } from 'src/store/auth/selectors';
import { selectUsersProjectsFilters } from 'src/store/projects/selectors';
import { selectAllSessions } from 'src/store/sesssions/selectors';

import { TProjectsData } from 'src/types/projects';
import { GetCurrentSessionStartAction } from 'src/store/current-session/actions';
import {GetSystemSettingStartAction} from "src/store/system-settings/actions";

export function* projectsWorker(_action: ReturnType<typeof GetProjectsStartAction>) {
    try {
        const isModerator = yield select(selectIsModerator);
        const allSessions: TSession[] = yield select(selectAllSessions);
        let filters = yield select(selectUsersProjectsFilters);

        yield put(GetSystemSettingStartAction());
        // on first load
        // get years and waves
        // add them to default filters
        // if (!yearsStatus.isLoaded && !yearsStatus.isLoading) {
        //     yield call(yearsWorker, GetYearsTriggerAction(true));
        //
        //     const years = yield select(selectYears);
        //     // const waves = yield select(selectWaves);
        //     let year = undefined;
        //     // let sessionName = undefined;
        //
        //     if (years.length > 0) {
        //         year = years[years.length - 1];
        //     }
        //
        //     // if (waves.length > 0) {
        //     //     sessionName = waves[waves.length - 1];
        //     // }
        //
        //     yield put(SetProjectsFiltersAction({
        //         ...filters,
        //         ...{
        //             filterData: {
        //                 ...filters.filterData,
        //                 year,
        //                 // sessionName,
        //             }
        //         },
        //     }));
        //     filters = yield select(selectUsersProjectsFilters);
        // }

        // get sessions for particular year
        // if (action.payload && action.payload.isYearChanged) {
        //     yield call(wavesWorker, GetWavesTriggerAction(filters.filterData.year));
        //
        //     // const waves = yield select(selectWaves);
        //     // let sessionName = undefined;
        //
        //     // if (waves.length > 0) {
        //     //     sessionName = waves[waves.length - 1];
        //     // }
        //
        //     yield put(SetProjectsFiltersAction({
        //         ...filters,
        //         ...{
        //             filterData: {
        //                 ...filters.filterData,
        //                 // sessionName,
        //             }
        //         },
        //     }));
        //     filters = yield select(selectUsersProjectsFilters);
        // }

        const { data }: AxiosResponse<TProjectsData> = yield axios({
            method: 'POST',
            url: isModerator ? '/projects/search' : '/user/projects/search',
            headers: {},
            data: {
                ...filters,
                page: filters.page - 1,
            }
        });

        const { content, ...pagination } = data;

        if (content.length > 0 || allSessions) {
            const session = allSessions.find((item) => (item.name === filters.filterData.sessionName));
            // eslint-disable-next-line
            const sessionId = content.length > 0 ? `${content[0].session?.id}` : (
                session ? `${session.id}` : ''
            );

            if (sessionId.length > 0) {
                yield put(GetCurrentSessionStartAction(sessionId));
            }
        }

        yield put(GetProjectsSuccessAction(content));
        yield put(SetProjectsFiltersAction({
            ...filters,
            ...{
                ...pagination,
                page: pagination.page + 1,
            },
        }));
    } catch (error) {
        yield put(GetProjectsErrorAction(error));
    }
}
