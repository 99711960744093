import React, {FC} from 'react';
import {LeftCircleOutlined, RightCircleOutlined} from "@ant-design/icons";
import styles from './styles.module.scss'

type CustomButtonProps = {
    type: 'left' | 'right',
    handle: any
}
const CustomButton: FC<CustomButtonProps> = ({type, handle}) => {
    switch (type) {
        case "left":
            return <LeftCircleOutlined className={styles.left} onClick={event => handle(type)}/>
        case "right":
            return <RightCircleOutlined className={styles.right} onClick={event => handle(type)}/>
    }
};
export default CustomButton;