import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetVotingDetailedProjectStartAction,
    GetVotingDetailedProjectSuccessAction,
    GetVotingDetailedProjectErrorAction,
} from 'src/store/voting/actions';
import {selectIsModerator} from 'src/store/auth/selectors';
import { GetFileStartAction } from 'src/store/files/actions';
import { TVoteDetailedProject } from 'src/types/vote';

export function* getVotingDetailedProjectWorker({ votingId, projectId }: ReturnType<typeof GetVotingDetailedProjectStartAction>) {
    try {
        const isModerator = yield select(selectIsModerator);
        const { data }: AxiosResponse<TVoteDetailedProject> = yield axios({
            method: 'GET',
            url: isModerator
                ? `/voting/${votingId}/projects/${projectId}`
                : `/user/voting/${votingId}/projects/${projectId}`,
            headers: {},
        });

        // get image
        if (data.image) {
            yield put(GetFileStartAction(`${data.image.id}`, 'image'));
        }

        // get files
        if (data.otherFiles && data.otherFiles.length > 0) {
            for (const file of data.otherFiles) {
                yield put(GetFileStartAction(`${file.id}`));
            }
        }
        if (data.requestFiles && data.requestFiles.length > 0) {
            for (const file of data.requestFiles) {
                yield put(GetFileStartAction(`${file.id}`));
            }
        }
        if (data.examinationFiles && data.examinationFiles.length > 0) {
            for (const file of data.examinationFiles) {
                yield put(GetFileStartAction(`${file.id}`));
            }
        }
        if (data.presentationFile && data.presentationFile.id) {
            yield put(GetFileStartAction(`${data.presentationFile.id}`));
        }

        yield put(GetVotingDetailedProjectSuccessAction(data));
    } catch (error) {
        yield put(GetVotingDetailedProjectErrorAction(error));
    }
}
