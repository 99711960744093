import {put} from 'redux-saga/effects';
import axios from 'axios';

import {
    DeleteRealizationProjectContentErrorAction,
    DeleteRealizationProjectContentStartAction,
    DeleteRealizationProjectContentSuccessAction,
} from "src/store/realization/actions";

export function* deleteProjectContentWorker({projectId}: ReturnType<typeof DeleteRealizationProjectContentStartAction>) {
    try {

        yield axios({
            method: 'DELETE',
            url: `/impl/projects/content/${projectId}`,
            headers: {}

        });
        yield put(DeleteRealizationProjectContentSuccessAction());
    } catch (error) {
        yield put(DeleteRealizationProjectContentErrorAction(error));
    }
}
