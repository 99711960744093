import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getLogsState = (state: TApplicationState) => state.logs;


export const selectLogsIp = createSelector(
    getLogsState,
    (state) => state.ip
);
export const selectLogsActions = createSelector(
    getLogsState,
    (state) => state.actions
);
export const selectLogsStatus = createSelector(
    getLogsState,
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        error: state.error,
    }),
);
export const selectActionsTypes = createSelector(
    getLogsState,
    (state) => state.actionsTypes
)