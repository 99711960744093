import React from 'react';

import { TRealizationLinkContent } from 'src/types/realization';

import styles from './styles.module.scss';

export const LinkCell = (name: string, data: TRealizationLinkContent) => (
    <div className={styles.cell}>
        <a
            className={styles.link}
            target='_blank'
            rel='noopener noreferrer'
            href={data.link}
        >{data.description ? data.description : data.link }</a>
    </div>
);
