import { InferValueTypes } from 'src/types/common';
import { SystemSettingActionTypes } from './action-types';
import * as actions from './actions';


type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;
export enum TSettingsKey  {
    actualKpi= 'actualKpi',
    supportAmount = 'supportAmount'
}
export type TSettings = {
    "code": string,
    "description": string,
    "id": number,
    "value": 'true' | 'false'
}
export type TSettingsStore = { [key in keyof typeof TSettingsKey]: TSettings | null };
export type TSettingsState = {
    settings: TSettingsStore
    isLoading: boolean;
    error: any;

};

export const initialState: TSettingsState = {
    settings: {supportAmount: null, actualKpi: null},
    isLoading: false,
    error: null,
};

// used for filtering projects
export default function reducer(
    state: TSettingsState = initialState,
    action: ActionTypes,
): TSettingsState {
    switch (action.type) {
        case SystemSettingActionTypes.STAR_FETCH:
            return {...state, isLoading: true};
        case SystemSettingActionTypes.ERROR_FETCH:
            return {...state, isLoading: false, error: action.error}
        case SystemSettingActionTypes.GET_SYSTEM_SETTINGS_START:
            return {...state, settings: initialState.settings}
        case SystemSettingActionTypes.GET_SYSTEM_SETTINGS_SUCCESS:
            return {...state, settings: action.settings, isLoading: false, error: null}
        default:
            return state;
    }
}
