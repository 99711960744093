import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Table} from 'antd';

import {GetRealizationLinksStartAction, SetRealizationLinksFiltersAction} from 'src/store/realization/actions';

import {
    selectRealizationLinksData,
    selectRealizationLinksFilters,
    selectRealizationLinksStatus,
} from 'src/store/realization/selectors';
import {LinkCell} from 'src/components/data-table/link-cell';

import styles from './styles.module.scss';
import {Key, TablePaginationConfig} from "antd/lib/table/interface";
import {Pagination} from "src/shared/lib/pagination";

type TProps = {
    projectId: number;
};


export const RealizationLinks: FC<TProps> = ({ projectId }) => {
    const put = useDispatch();
    const filters = useSelector(selectRealizationLinksFilters);
    const data = useSelector(selectRealizationLinksData);
    const status = useSelector(selectRealizationLinksStatus);


    const columns = [
        {
            title: 'Название',
            dataIndex: 'link',
            key: 'link',
            sorter: true,
            className: styles.linkCell,
            render: LinkCell,
        },
    ];

    useEffect(() => {
        put(GetRealizationLinksStartAction(projectId));
    }, [projectId]);

    const handleTableChange = ( pagination: TablePaginationConfig, filter: Record<string, (Key | boolean)[] | null>, sorter: any) => {
        const page = pagination.current;
        const pageSize = pagination.pageSize;

        let newSorter = {
            sort: {
                field: 'id',
                direction: 'ASC',
            },
        };

        if (sorter && sorter.order) {
            let direction = 'ASC';

            if (sorter.order === 'descend') {
                direction = 'DESC'
            }

            newSorter = {
                sort: {
                    field: sorter.field,
                    direction,
                },
            }
        }

        put(SetRealizationLinksFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData
            },
            pageSize: typeof pageSize === 'number' ? pageSize : filters.pageSize,
            page: page || 1,
            sort: newSorter.sort
        }));
        put(GetRealizationLinksStartAction(projectId));
    };

    return (
        <div className={styles.list}>
            <Table
                scroll={{ x: 860 }}
                sticky
                columns={columns}
                dataSource={data}
                loading={status.isLoading}
                pagination={{
                    current: filters.page,
                    pageSize: filters.pageSize,
                    total: filters.totalCount,
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    pageSizeOptions: Pagination()
                }}
                onChange={handleTableChange}
                rowKey="id"
            />
        </div>
    );
}
