import React, {SyntheticEvent, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ExportOutlined, LoadingOutlined,} from '@ant-design/icons/lib';
import qs from 'query-string';


import FilterSVG from 'src/assets/svg/filter.svg'
import PDFSVG from 'src/assets/svg/pdf.svg'

import {TPDFModal} from 'src/types/modals';

import {LogoutAction} from 'src/store/auth/actions';
import {LogsActionsStartAction} from 'src/store/logs/actions';

import {selectUserData} from 'src/store/auth/selectors';
import {
    ToggleProfileAction,
    ToggleSidebarAction,
    ToggleSidebarMenuAction,
    ToggleVoteFiltersAction
} from 'src/store/general/actions';
import {selectCurrentPresentation, selectCurrentSessionStatus} from 'src/store/current-session/selectors';
import {selectIsProfileActive, selectIsSidebarActive, selectIsVoteFiltersActive} from 'src/store/general/selectors';

import {mainModuleRoutes} from 'src/routing/projects-module';
import {PDFModal} from 'src/components/modals/pdf-modal';
import {ProfileSidebar} from 'src/components/profile-sidebar';

import styles from './styles.module.scss';
import classNames from "classnames";
import {MenuFoldIcon} from 'src/shared/ui/icons';
import env from "src/shared/config/env";
import {Button, Modal} from "antd";


export const MainHeader = () => {
    const [modalData, setModalData] = useState<TPDFModal>(null);
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const put = useDispatch();

    const presentation = useSelector(selectCurrentPresentation);
    const { isLoading } = useSelector(selectCurrentSessionStatus);

    const isSidebarActive = useSelector(selectIsSidebarActive);
    const isProfileActive = useSelector(selectIsProfileActive);
    const isVoteFiltersActive = useSelector(selectIsVoteFiltersActive);
    const userData = useSelector(selectUserData);

    const user = (userData && userData.user) ? userData.user : {};
    const { pathname, search } = useLocation();
    const { tab } = qs.parse(search);
    const isList = pathname === mainModuleRoutes.list.path && tab !== '2';
    const isVote = pathname.split('/')[1] === 'vote' && tab !== '2';
    const isReportingList = pathname === mainModuleRoutes['reporting-list'].path;
    const isAllProjectsListList = pathname.split('/')[1] === 'all-projects-list';
    const isAllDocumentsListList = pathname.split('/')[1] === 'all-documents-list';
    const isRealizationLinks = pathname.split('/')[1] === 'realization' && tab === '3';

    const userName = () => {
        if (user && user.personalData?.firstName && user.personalData?.lastName) {
            return user.personalData?.firstName.slice(0, 1).toUpperCase() + user.personalData?.lastName.slice(0, 1).toUpperCase()
        }
        return (user && user?.username) ? user?.username.slice(0, 2).toUpperCase() : '';
    };

    const openPDFModal = (_e: SyntheticEvent) => {
        const fileURL = window.URL.createObjectURL(presentation);

        setModalData({
            fileURL: fileURL,
            name: 'Отчетная презентация',
        });
        put(LogsActionsStartAction('VIEW_REPORTING_PRESENTATION'))
    };

    const closePDFModal = () => {
        setModalData(null);
    };

    const toggleReportingSidebar = () => {
        put(ToggleSidebarAction(!isSidebarActive));
    };
    const toggleProfile = () => {
        put(ToggleProfileAction(!isProfileActive));
    };
    const toggleVoteSidebar = () => {
        put(ToggleVoteFiltersAction(!isVoteFiltersActive));
    };
    const handleLogOut = () => {
        setModalIsOpen(true)
    };


    const presentationElem = () => {
        if (isLoading) {
            return (
                <div className={styles.pdf}>
                    <LoadingOutlined />
                </div>
            )
        }
        if (presentation) {
            return (
                <div
                    className={styles.pdf}
                    onClick={openPDFModal}
                >
                    <img src={PDFSVG} alt="pdf"/>
                    <span>
                        Презентация проектов
                    </span>
                </div>
            )
        }
    }
    const onClick = () => {
        put(ToggleSidebarMenuAction())
    }
    return (
        <header
            className={styles.header}
            data-name='header'
        >
            <div className={styles.leftBar}>
                <div className={classNames(styles.headerMain__toggle, 'menuInfo')} onClick={onClick}>
                    <MenuFoldIcon />
                </div>
            </div>
            <div className={styles.userInfo}>
                { isList && (
                    <>
                        {presentationElem()}
                    </>
                ) }

                { isVote && (
                    <div className={styles.filters} onClick={toggleVoteSidebar}>
                        <img src={FilterSVG} alt="filter" />
                    </div>
                ) }

                { (
                    isReportingList ||
                    isAllProjectsListList ||
                    isAllDocumentsListList ||
                    isRealizationLinks
                ) && (
                    <div className={styles.filters} onClick={toggleReportingSidebar}>
                        <img src={FilterSVG} alt="filter" />
                    </div>
                ) }

                <div className={styles.profile}>
                    <div className={styles.avatar} onClick={toggleProfile}>
                        {typeof user.personalData?.image === 'string' ? (
                            <img src={user.personalData?.image} alt={userName()}/>
                        ) : (
                            <span className={styles.name}>{userName()}</span>
                        )}
                    </div>
                </div>

                <div className={styles.logout} onClick={handleLogOut}>
                    {/*<img src={logoutImg} alt="Logout" />*/}
                    <ExportOutlined className={styles.logoutIcon} />
                </div>

            </div>

            <PDFModal
                fileURL={modalData?.fileURL as string}
                name={modalData?.name}
                isOpened={Boolean(modalData)}
                onClose={closePDFModal}
            />
            <Modal
                open={modalIsOpen}
                onCancel={() => {
                    setModalIsOpen(false);
                }}
                maskClosable
                className={styles.modal}
                title={<div className={styles.modal__title}>Подтвердите действие</div>}
                footer={(
                    <div className={styles.modal__buttons}>
                        <Button type={'default'} onClick={() => {
                            setModalIsOpen(false);
                        }}>Отмена</Button>
                        {env.keycloackRedirectUrl && (
                            <Button type={'primary'} onClick={() => {
                                window.location.replace(env.keycloackRedirectUrl as string)
                            }}>Выбрать другую систему</Button>
                        )}
                        <Button type={'primary'} onClick={() => {
                            put(LogoutAction());
                        }}>Подтвердить</Button>
                    </div>
                )}
            >
                <div className={styles.modal__text}>Завершить работу в системе</div>
            </Modal>
            <ProfileSidebar />
        </header>
    );
}
