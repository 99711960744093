import { ProjectsActionTypes } from 'src/store/projects/action-types';
import {
    TAdminProjectsOptions,
    TProjectContent,
    TProjectsOptions,
} from 'src/types/projects';
import { TProjectFormData } from 'src/components/modals/projects-modal';
import { TPagination, TSorting } from 'src/types/pageable-request';

export const SetProjectsFiltersAction = (filters: TProjectsOptions) => ({
    type: ProjectsActionTypes.SET_FILTERS,
    filters,
});
export const ResetProjectsFiltersAction = () => ({
    type: ProjectsActionTypes.RESET_FILTERS,
});

export const GetProjectsStartAction = (payload?: {
    isYearChanged?: boolean,
    votingId?: number,
}) => ({
    type: ProjectsActionTypes.GET_PROJECTS_START,
    payload,
});
export const GetProjectsSuccessAction = (projects: any) => ({
    type: ProjectsActionTypes.GET_PROJECTS_SUCCESS,
    projects,
});
export const GetProjectsErrorAction = (error: any) => ({
    type: ProjectsActionTypes.GET_PROJECTS_ERROR,
    error,
});

export const AddProjectStartAction = (project: TProjectFormData) => ({
    type: ProjectsActionTypes.ADD_PROJECT_START,
    project,
});
export const AddProjectSuccessAction = (project: any) => ({
    type: ProjectsActionTypes.ADD_PROJECT_SUCCESS,
    project,
});
export const AddProjectErrorAction = (error: any) => ({
    type: ProjectsActionTypes.ADD_PROJECT_ERROR,
    error,
});

export const EditProjectStartAction = (form: TProjectFormData, project: TProjectContent) => ({
    type: ProjectsActionTypes.EDIT_PROJECT_START,
    form,
    project,
});
export const EditProjectSuccessAction = (project: any) => ({
    type: ProjectsActionTypes.EDIT_PROJECT_SUCCESS,
    project,
});
export const EditProjectErrorAction = (error: any) => ({
    type: ProjectsActionTypes.EDIT_PROJECT_ERROR,
    error,
});

export const DeleteProjectStartAction = (id: number) => ({
    type: ProjectsActionTypes.DELETE_PROJECT_START,
    id,
});
export const DeleteProjectSuccessAction = (project: any) => ({
    type: ProjectsActionTypes.DELETE_PROJECT_SUCCESS,
    project,
});
export const DeleteProjectErrorAction = (error: any) => ({
    type: ProjectsActionTypes.DELETE_PROJECT_ERROR,
    error,
});

export const ResetAdminsProjectsFiltersAction = () => ({
    type: ProjectsActionTypes.RESET_ADMINS_PROJECTS_FILTERS,
});
export const SetAdminsProjectsFiltersAction = (filters: TAdminProjectsOptions) => ({
    type: ProjectsActionTypes.SET_ADMINS_PROJECTS_FILTERS,
    filters,
});
export const GetProjectsByVotingIdStartAction = (votingId: number) => ({
    type: ProjectsActionTypes.GET_PROJECTS_BY_VOTING_ID_START,
    votingId,
});
export const GetProjectsByVotingIdSuccessAction = (data: TProjectContent[], filters: TPagination & TSorting) => ({
    type: ProjectsActionTypes.GET_PROJECTS_BY_VOTING_ID_SUCCESS,
    data,
    filters,
});
export const GetProjectsByVotingIdErrorAction = (error: any) => ({
    type: ProjectsActionTypes.GET_PROJECTS_BY_VOTING_ID_ERROR,
    error,
});

export const ResetAdminsDocumentsFiltersAction = () => ({
    type: ProjectsActionTypes.RESET_ADMINS_DOCUMENTS_FILTERS,
});
export const SetAdminsDocumentsFiltersAction = (filters: any) => ({
    type: ProjectsActionTypes.SET_ADMINS_DOCUMENTS_FILTERS,
    filters,
});
export const GetDocumentsByVotingIdStartAction = (votingId: number) => ({
    type: ProjectsActionTypes.GET_DOCUMENTS_BY_VOTING_ID_START,
    votingId,
});
export const GetDocumentsByVotingIdSuccessAction = (data: TProjectContent[], filters: any) => ({
    type: ProjectsActionTypes.GET_DOCUMENTS_BY_VOTING_ID_SUCCESS,
    data,
    filters,
});
export const GetDocumentsByVotingIdErrorAction = (error: any) => ({
    type: ProjectsActionTypes.GET_DOCUMENTS_BY_VOTING_ID_ERROR,
    error,
});


export const PutProjectsActionsStartAction = (ids: [], typeAction: string) => ({
    type: ProjectsActionTypes.PUT_PROJECTS_ACTIONS_START,
    ids,
    typeAction
})
export const PutProjectsActionsSuccessAction = () => ({
    type: ProjectsActionTypes.PUT_PROJECTS_ACTIONS_SUCCESS,
})
export const PutProjectsActionsRejectAction = (ids: []) => ({
    type: ProjectsActionTypes.PUT_PROJECTS_ACTIONS_REJECT,
    ids
})
export const PutProjectsActionsErrorAction = (error: any) => ({
    type: ProjectsActionTypes.PUT_PROJECTS_ACTIONS_ERROR,
    error,
});
