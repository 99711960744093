import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    DeleteVotingCommentStartAction,
    DeleteVotingCommentSuccessAction,
    DeleteVotingCommentErrorAction, GetVotingDetailedProjectStartAction,
} from 'src/store/voting/actions';

export function* deleteVotingCommentWorker(
    { commentId, votingId, projectId }: ReturnType<typeof DeleteVotingCommentStartAction>,
) {
    try {
        yield axios({
            method: 'DELETE',
            url: `/user/voting/comments/${commentId}`,
            headers: {},
        });

        yield put(DeleteVotingCommentSuccessAction(commentId));
        yield put(GetVotingDetailedProjectStartAction(votingId, projectId));
    } catch (error) {
        yield put(DeleteVotingCommentErrorAction(error, commentId));
    }
}
