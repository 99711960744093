import { SessionsActionTypes } from 'src/store/sesssions/action-types';
import { TSessionFilters, TSessionResponse } from 'src/types/sessions';
import { TSessionFormData } from 'src/components/modals/session-modal';

export const SetSessionsFiltersAction = (filters: TSessionFilters) => ({
    type: SessionsActionTypes.SET_FILTERS,
    filters,
});

export const GetSessionsStartAction = () => ({
    type: SessionsActionTypes.GET_SESSIONS_START,
});
export const GetSessionsSuccessAction = (sessions: TSessionResponse) => ({
    type: SessionsActionTypes.GET_SESSIONS_SUCCESS,
    sessions,
});
export const GetSessionsErrorAction = (error: any) => ({
    type: SessionsActionTypes.GET_SESSIONS_ERROR,
    error,
});

export const GetAllSessionsStartAction = () => ({
    type: SessionsActionTypes.GET_ALL_SESSIONS_START,
});
export const GetAllSessionsSuccessAction = (sessions: TSessionResponse) => ({
    type: SessionsActionTypes.GET_ALL_SESSIONS_SUCCESS,
    sessions,
});
export const GetAllSessionsErrorAction = (error: any) => ({
    type: SessionsActionTypes.GET_ALL_SESSIONS_ERROR,
    error,
});

export const AddSessionStartAction = (data: TSessionFormData) => ({
    type: SessionsActionTypes.ADD_SESSION_START,
    data,
});

export const AddSessionSuccessAction = (data: any) => ({
    type: SessionsActionTypes.ADD_SESSION_SUCCESS,
    data,
});

export const AddSessionErrorAction = (error: any) => ({
    type: SessionsActionTypes.ADD_SESSION_ERROR,
    error,
});

export const EditSessionStartAction = (data: TSessionFormData, id: number, presentationId?: number) => ({
    type: SessionsActionTypes.EDIT_SESSION_START,
    data,
    id,
    presentationId,
});
export const EditSessionSuccessAction = (data: any) => ({
    type: SessionsActionTypes.EDIT_SESSION_SUCCESS,
    data,
});
export const EditSessionErrorAction = (error: any) => ({
    type: SessionsActionTypes.EDIT_SESSION_ERROR,
    error,
});

export const DeleteSessionStartAction = (id: number) => ({
    type: SessionsActionTypes.DELETE_SESSION_START,
    id,
});
export const DeleteSessionSuccessAction = (data: any) => ({
    type: SessionsActionTypes.DELETE_SESSION_SUCCESS,
    data,
});
export const DeleteSessionErrorAction = (error: any) => ({
    type: SessionsActionTypes.DELETE_SESSION_START,
    error,
});
