import React, {useEffect} from "react";
import {TProjectFormData} from "src/components/modals/projects-modal/index";
import ModalContent from "src/components/modals/projects-modal/modal";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {EditProjectStartAction} from "src/store/projects/actions";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {selectAllSessions} from "src/store/sesssions/selectors";
import {TProjectContent} from "src/types/projects";
import {LogsActionsStartAction} from "src/store/logs/actions";

type TEditProjectModalProps = {
    isOpened: boolean;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    data: TProjectContent;
    isEdit: boolean;
};

const EditProjectModal: React.FC<TEditProjectModalProps> = ({isOpened,onClose, data, isEdit}) => {
    const put = useDispatch();
    const sessions = useSelector(selectAllSessions);

    useEffect(() => {
        put(LogsActionsStartAction('OPEN_NEW_PROJECT_FORM'))
    }, [])

    const getInitialYear = (): number => {
        let projectYear = 2021;
        try {
            let session = sessions.find((item) => {
                return data && data.session && data.session?.id === item.id;
            });

            if (session) {
                projectYear = +moment(session.endDate).format('yyyy');
            }
            return projectYear;
        } catch (error) {
            return projectYear;
        }

    };

    const initialData: TProjectFormData = {
        budget: data.budget || 0,
        categoryId: data.category ? data.category.id : 1,
        description: data.description || '',
        expertise: data.expertise || '',
        formatDescription: data.formatDescription || '',
        formatIds: data.formats ? data.formats.map((el: { id: any; }) => el.id) : [],
        internetResources: data.internetResources ? data.internetResources : [],
        kpi: data.kpi || 0,
        supportAmount: data.supportAmount || 0,
        actualKpi: data.actualKpi || 0,
        name: data.name || '',
        owner: data.owner || '',
        presentationUrl: data.presentation && data.presentation.url ? data.presentation.url : '',
        score: data.score || 0,
        sessionId: data.session ? `${data.session?.id}` : '',
        teamExperience: data.teamExperience || '',
        year: getInitialYear(),
        imageIds: [],
        fileIds: [],
        image: [],
        presentationFile: [],
        requestFiles: [],
        examinationFiles: [],
        otherFiles: [],
        implEndDate: (data.implEndDate && moment(data.implEndDate)) || '',
        implStartDate: (data.implStartDate && moment(data.implStartDate)) || '',
        implTerms: data.implTerms || 0,
        requestNumber: data.requestNumber || '',
        recommendedCostReduction: data.recommendedCostReduction || null,
        achievements: data.achievements || null,
        ownProjectSite: data.ownProjectSite || null,
        cofinancing: data.cofinancing || null,
        isConfidential: data.isConfidential || null,

        existingImage: data.image,
        existingPresentationFile: data.presentationFile,
        existingRequestFiles: data.requestFiles,
        existingExaminationFiles: data.examinationFiles,
        existingOtherFiles: data.otherFiles,
    };
    const projectForm = useFormik({
        initialValues: {
            ...initialData,
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object({
            name: Yup.string().required('Поле обязательное'),
            owner: Yup.string().required('Поле обязательное'),
            requestNumber: Yup.string().required('Поле обязательное'),
            year: Yup.string().required('Поле обязательное'),
            sessionId: Yup.string().required('Поле обязательное'),
            description: Yup.string().required('Поле обязательное'),
            categoryId: Yup.string().required('Поле обязательное'),
            formatIds: Yup.array().required('Поле обязательное'),
            score: Yup.string().required('Поле обязательное'),
            kpi: Yup.string().required('Поле обязательное'),
            budget: Yup.string().required('Поле обязательное'),
            cofinancing: Yup.string().nullable(),
            isConfidential: Yup.boolean().nullable(),
        }),
    });

    function onSubmit(values: TProjectFormData, _formikHelpers: FormikHelpers<TProjectFormData>) {
        // @ts-ignore
        put(EditProjectStartAction(values, data));
        onClose();
    }

    return (
        <ModalContent isEdit={true} isOpened={isOpened} onClose={onClose} projectForm={projectForm}/>
    )
}
export default EditProjectModal