import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    RecoverPwdStartAction,
    RecoverPwdSuccessAction,
    RecoverPwdErrorAction,
} from 'src/store/auth/actions';
import { TUser } from 'src/types/user';

export function* recoverPwdWorker(action: ReturnType<typeof RecoverPwdStartAction>) {
    try {
        const { data }: AxiosResponse<TUser> = yield axios({
            method: 'POST',
            url: '/password-restore',
            data: {
                ...action.credentials
            },
        });

        yield put(RecoverPwdSuccessAction(data));
    } catch (error) {
        yield put(RecoverPwdErrorAction(error));
    }
}
