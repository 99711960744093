import { combineReducers } from 'redux';

import ListReducer from './list';
import TypesReducer from './types';
import ProjectReducer from './project';
import LinksReducer from './links';
import crawlerReducer from "./crawler";

const reducer = combineReducers({
    list: ListReducer,
    types: TypesReducer,
    project: ProjectReducer,
    links: LinksReducer,
    crawler: crawlerReducer
});

export type TRealizationState = ReturnType<typeof reducer>;

export default reducer;