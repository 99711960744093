export const SystemSettingActionTypes = {
    STAR_FETCH: '[SYSTEM_SETTINGS] STAR_FETCH',
    ERROR_FETCH: '[SYSTEM_SETTINGS] ERROR_FETCH',

    GET_SYSTEM_SETTINGS_START: '[SYSTEM_SETTINGS] GET_SYSTEM_SETTINGS_START',
    GET_SYSTEM_SETTINGS_SUCCESS: '[SYSTEM_SETTINGS] GET_SYSTEM_SETTINGS_SUCCESS',

    CREATE_SYSTEM_SETTINGS_START: '[SYSTEM_SETTINGS] CREATE_SYSTEM_SETTINGS_START',
    CREATE_SYSTEM_SETTINGS_SUCCESS: '[SYSTEM_SETTINGS] CREATE_SYSTEM_SETTINGS_SUCCESS',

    UPDATE_SYSTEM_SETTINGS: '[SYSTEM_SETTINGS] UPDATE_SYSTEM_SETTINGS',

    DELETE_SYSTEM_SETTINGS_START: '[SYSTEM_SETTINGS] DELETE_SYSTEM_SETTINGS_START',
    DELETE_SYSTEM_SETTINGS_SUCCESS: '[SYSTEM_SETTINGS] DELETE_SYSTEM_SETTINGS_SUCCESS',

    SEARCH_SYSTEM_SETTINGS_START: '[SYSTEM_SETTINGS] SEARCH_SYSTEM_SETTINGS_START',
    SEARCH_SYSTEM_SETTINGS_SUCCESS: '[SYSTEM_SETTINGS] SEARCH_SYSTEM_SETTINGS_SUCCESS',
} as const; 