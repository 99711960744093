import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import styles from "src/features/settings/styles.module.scss";
import {Button, Input} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {selectActionsTypes, selectLogsStatus} from "src/store/logs/selectors";
import {GetActionsStartAction, UpdateActionsStartAction} from "src/store/logs/actions";
import cn from "classnames";



type ItemPtops = {
    id: number,
    code: string,
    name: string,
}

type Tstate = {
    name: string,
    error: string
}

const Item: React.FC<ItemPtops> = ({id, code, name}) => {
    const put = useDispatch();
    const [state, setState] = useState<Tstate>({
        name: name,
        error: ''
    })
    const handlerChange = (e: any) => {
        setState({
            ...state,
            name: e.target.value
        })
    }

    const handlerUpdate = () => {
        if (!state.name) setState({...state, error: 'Поле не может быть пустым'})
        else put(UpdateActionsStartAction(id, {name: state.name}))
    }

    return (
        <>
            <div className={styles.group}>
                <div className={styles.formGroup}>
                    <small>Тип</small>
                    <span>{code}</span>
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="domainLink">Определение</label>
                    <Input value={state.name} name='name' onChange={handlerChange}/>
                    {state.error ? (<span className={styles.error}>{state.error}</span>) : null}
                </div>
                <div className={styles.control}>
                    <Button type="primary" onClick={handlerUpdate}>Сохранить</Button>
                </div>

            </div>
        </>
    )
}

const ActionsSettings = () => {
    const put = useDispatch()
    const actions = useSelector(selectActionsTypes)
    const {isLoading} = useSelector(selectLogsStatus)

    const [state, setState] = useState('')

    useEffect(() => {
        if (!actions?.length) {
            put(GetActionsStartAction())
        }
    }, [])

    const filds = (arr: any[]) => {
        if (state) {
            return (arr
                .filter(item => item.name.toLowerCase().indexOf(state.toLowerCase()) > -1)
                .map((el: any) => (
                    <div key={el.code} className={styles.item}>
                        <Item id={el.id} code={el.code} name={el.name}/>
                    </div>
                ))
            )
        } else {
            return (arr.map((el: any) => (
                <div key={el.code} className={styles.item}>
                    <Item id={el.id} code={el.code} name={el.name}/>
                </div>
            )))
        }
    }

    const onSearch = (e: any) => {
        setState(e.target.value)
    }

    return (
        <div className={styles.container}>
            <div className={cn(styles.leftColumn)}>
                <h2>Настройки действий пользователя</h2>
                <br/>
                <div className={styles.formGroup}>
                    <Input value={state} name='term' placeholder='Поиск' onChange={onSearch}/>
                </div>
                <br/>
                {(actions && !isLoading) ? filds(actions) : <LoadingOutlined />}
            </div>
        </div>
    )
};
export default ActionsSettings;