import React, {FC, useEffect, useState} from 'react';
import {Button} from 'antd';

import {Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';


import {TEditVotingRequestData, TVotingBlock} from 'src/types/vote';

import {CreateDocumentStartAction, EditDocumentStartAction} from 'src/store/voting-editor/actions';

import styles from './styles.module.scss';

import {LogsActionsStartAction} from "src/store/logs/actions";
import {useFormik} from "formik";
import * as Yup from "yup";
import ModalInput from "src/components/form-components/modal-input";
import ModalUpload from "src/components/form-components/modal-upload";
import TextAreaQuill from "src/components/form-components/textAreaQuill";


type TVotingEditDocumentModalProps = {
    isOpened: boolean;
    onClose: () => void;
    onSubmit: (projects: TEditVotingRequestData['projects']) => void;
    block?: TVotingBlock;
};


export const VotingEditDocumentModal: FC<TVotingEditDocumentModalProps> = ({
    isOpened,
    onClose,
    // onSubmit,
    block,
}) => {
    const put = useDispatch();
    const [isEdit, setIsEdit] = useState(false);


    const initialData = {
        name: block ? block.name : '',
        question: block ? block.question : '',
        files: [],
        existingFiles: block ? block.files : []
    }

    const projectForm = useFormik({
        initialValues: {
            ...initialData,
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object({
            name: Yup.string().required(),
            question: Yup.string().required(),
            files: Yup.array()
        }),
    });

    function onSubmit (values: any, _formikHelpers: any) {

        if (isEdit) {
            put(EditDocumentStartAction({
                id: block ? block.id : undefined,
                name: values.name,
                question: values.question,
                files: values.existingFiles,
            }, values.files));
        } else {
            put(CreateDocumentStartAction({
                ...block,
                files: values.files,
                name: values.name,
                question: values.question,
            }));
        }
        put(LogsActionsStartAction('EDIT_VOTING_BLOCK'))
        onClose();
    };

    const handleClose = () => {
        put(LogsActionsStartAction('CANCEL_EDIT_VOTING_BLOCK'))
        onClose()
    };

    useEffect(() => {
        if (isOpened) {
            setIsEdit(!!block);
            if (block) {
                projectForm.setFieldValue('name', block.name)
                projectForm.setFieldValue('question', block.question)
                projectForm.setFieldValue('existingFiles', block.files)
            }
        }
    }, [isOpened]);

    const disabledHandler = (): boolean => {
        if (!!projectForm.errors.name || !!projectForm.errors.question) return true
        return false
    }

    return (
        <Modal
            show={isOpened}
            onHide={onClose}
            keyboard={true}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={styles.dialog}
            className={styles.modalVote}
        >
            <form
                name="projectForm"
                onSubmit={projectForm.handleSubmit}
                className={styles.form}
            >
                <Modal.Body className={styles.body}>
                    <h3 className={styles.header}>
                        { isEdit
                            ? 'Редактирование блока голосования'
                            : 'Добавление блока голосования'
                        }
                    </h3>

                    <div className={styles.content}>
                        <ModalInput
                            form={projectForm}
                            label={"name"}
                            text={'Название вопроса голосования'} />
                        {projectForm.errors.name && (
                            <span className={styles.danger}>Поле не может быть пустым <br/></span>
                        )}
                        <TextAreaQuill form={projectForm} label={'question'} text={'Описание вопроса'}/>

                        <ModalUpload
                            type={'multi'}
                            isImg={false}
                            form={projectForm}
                            label={'files'}
                            existingLabel={'existingFiles'}
                            text={'Загрузить файл'}
                            isEdit={isEdit} />

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        type="primary"
                        disabled={disabledHandler()}
                        htmlType="submit"
                    >Добавить</Button>
                    <Button type="default" onClick={handleClose}>Отменить</Button>
                </Modal.Footer>
            </form>

        </Modal>
    );
}
