import { InferValueTypes } from 'src/types/common';
import { UserActionTypes } from 'src/store/user/action-types';
import * as actions from './actions';
import { TUser } from 'src/types/user';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TUserState = {
    data?: TUser;
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TUserState = {
    data: undefined,
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function reducer(
    state: TUserState = initialState,
    action: ActionTypes,
): TUserState {
    switch (action.type) {
        case UserActionTypes.GET_USER_START:
            return {
                ...initialState,
                data: state.data,
                isLoading: true,
            };
        case UserActionTypes.GET_USER_SUCCESS:
            return {
                ...initialState,
                data: action.user,
                isLoading: false,
                isLoaded: true,
            };
        case UserActionTypes.GET_USER_ERROR:
            return {
                ...initialState,
                error: action.error,
            };
        default:
            return state;
    }
}
