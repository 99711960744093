import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TVoteSummary } from 'src/types/vote';

import { VotingActionTypes } from 'src/store/voting/action-types';
import {
    GetVoteProjectsSummaryTriggerAction,
    GetVoteProjectsSummaryStartAction,
    GetVoteProjectsSummarySuccessAction,
    GetVoteProjectsSummaryErrorAction,
    GetVoteDocumentsSummaryTriggerAction,
    GetVoteDocumentsSummaryStartAction,
    GetVoteDocumentsSummarySuccessAction,
    GetVoteDocumentsSummaryErrorAction,
} from 'src/store/voting/actions';

export function* getVoteSummaryWorker(
    { id, type }: ReturnType<typeof GetVoteProjectsSummaryTriggerAction>
    | ReturnType<typeof GetVoteDocumentsSummaryTriggerAction>
) {
    try {
        type === VotingActionTypes.GET_VOTE_PROJECTS_SUMMARY_TRIGGER
            ? yield put(GetVoteProjectsSummaryStartAction())
            : yield put(GetVoteDocumentsSummaryStartAction());
        const { data }: AxiosResponse<TVoteSummary> = yield axios({
            method: 'GET',
            url: `/user/voting/${id}`,
            headers: {},
        });

        type === VotingActionTypes.GET_VOTE_PROJECTS_SUMMARY_TRIGGER
            ? yield put(GetVoteProjectsSummarySuccessAction(data))
            : yield put(GetVoteDocumentsSummarySuccessAction(data));
    } catch (error) {
        type === VotingActionTypes.GET_VOTE_PROJECTS_SUMMARY_TRIGGER
            ? yield put(GetVoteProjectsSummaryErrorAction(error))
            : yield put(GetVoteDocumentsSummaryErrorAction(error));
    }
}
