import { InferValueTypes } from 'src/types/common';
import * as actions from './actions';
import {InternetResourcesActionTypes} from "src/store/internet-resources/action-types";


type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TinternetResourcesState = {
    data: any;
    resource: null | object
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TinternetResourcesState = {
    data: [],
    resource: null,
    isLoading: false,
    isLoaded: false,
    error: null,
};


export default function reducer(
    state: TinternetResourcesState = initialState,
    action: ActionTypes,
): TinternetResourcesState {
    switch (action.type) {
        case InternetResourcesActionTypes.GET_INTERNET_RESOURCES_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case InternetResourcesActionTypes.GET_INTERNET_RESOURCES_SUCCESS:
            return {
                ...state,
                data: action.resources,
                isLoading: false,
                isLoaded: true,
            };
        case InternetResourcesActionTypes.GET_INTERNET_RESOURCES_ERROR:
            return {
                ...state,
                data: [],
                error: action.error,
                isLoading: false,
                isLoaded: true
            };
        case InternetResourcesActionTypes.GET_ONE_INTERNET_RESOURCES_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case InternetResourcesActionTypes.GET_ONE_INTERNET_RESOURCES_SUCCESS:
            return {
                ...state,
                resource: action.resource,
                isLoading: true,
                isLoaded: false
            };
        case InternetResourcesActionTypes.GET_ONE_INTERNET_RESOURCES_ERROR:
            return {
                ...state,
                data: [],
                resource: null,
                error: action.error,
                isLoading: false,
                isLoaded: true
            };
        case InternetResourcesActionTypes.CREATE_INTERNET_RESOURCES_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case InternetResourcesActionTypes.CREATE_INTERNET_RESOURCES_SUCCESS:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case InternetResourcesActionTypes.CREATE_INTERNET_RESOURCES_ERROR:
            return {
                ...state,
                data: [],
                resource: null,
                error: action.error,
                isLoading: false,
                isLoaded: true
            };
        case InternetResourcesActionTypes.UPDATE_INTERNET_RESOURCES_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case InternetResourcesActionTypes.UPDATE_INTERNET_RESOURCES_SUCCESS:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case InternetResourcesActionTypes.UPDATE_INTERNET_RESOURCES_ERROR:
            return {
                ...state,
                data: [],
                resource: null,
                error: action.error,
                isLoading: false,
                isLoaded: true
            };
        case InternetResourcesActionTypes.DELETE_INTERNET_RESOURCES_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case InternetResourcesActionTypes.DELETE_INTERNET_RESOURCES_SUCCESS:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case InternetResourcesActionTypes.DELETE_INTERNET_RESOURCES_ERROR:
            return {
                ...state,
                data: [],
                resource: null,
                error: action.error,
                isLoading: false,
                isLoaded: true
            };
        default:
            return state;
    }
}
