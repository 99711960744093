import React, {FC, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {push} from 'connected-react-router';
import {useDispatch, useSelector} from 'react-redux';
import {Button, DatePicker, Form, Input, Select, Switch} from 'antd';

import {FormikHelpers, useFormik,} from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import qs from 'query-string';
import moment from 'moment';

import {TEditVotingRequestData, TVotingParams} from 'src/types/vote';
import {DateAndTimeFormat} from 'src/constatnts/format';

import {GetVotingTypesTriggerAction} from 'src/store/voting/actions';
import {GetAllSessionsStartAction} from 'src/store/sesssions/actions';
import {GetYearsTriggerAction} from 'src/store/years/actions';
import {
    AddVotingAction,
    EditVotingAction,
    GetEditorDetailedProjectStartAction,
    GetEditorProjectsStartAction,
    GetEditorRegentsStartAction,
    SetDocumentAction,
} from 'src/store/voting-editor/actions';
import {LogsActionsStartAction} from 'src/store/logs/actions';

import {selectYears, selectYearsStatus} from 'src/store/years/selectors';
import {selectAllSessions} from 'src/store/sesssions/selectors';
import {selectVotingTypesData, selectVotingTypesStatus} from 'src/store/voting/selectors';
import {
    selectVotingEditorBlocks,
    selectVotingEditorDetailedProject,
    selectVotingEditorDetailedProjectStatus,
    selectVotingEditorProjects,
} from 'src/store/voting-editor/selectors';

import {mainModuleRoutes} from 'src/routing/projects-module';
import {ToParentPage} from 'src/components/to-parent-page';
import {SelectUsersModal} from 'src/components/modals/select-users';
import {SelectProjectsModal} from 'src/components/modals/select-projects';
import UsersList from './usersList';
import ProjectsList from './projectsList';
import BlocksList from './blocksList';

import styles from './styles.module.scss';

type TProps = {
    isEdit?: boolean;
};

export type TVotingEditorData = {
    activated: boolean;
    familiarizationDateTime: moment.Moment | null | undefined;
    startDateTime: moment.Moment | null | undefined;
    endDateTime: moment.Moment | null | undefined;
    name: string;
    sessionId: string | number;
    userIds: number[],
    votingBlockIds: number[],
    votingTypeId: string;
    year: string;
    users: TEditVotingRequestData['users'];
    projects: TEditVotingRequestData['projects'];
    votingBlocks: TEditVotingRequestData['votingBlocks'];
};

const initialData: TVotingEditorData = {
    activated: true,
    familiarizationDateTime: null,
    startDateTime: null,
    endDateTime: null,
    name: '',
    sessionId: '',
    userIds: [],
    votingBlockIds: [],
    votingTypeId: '',
    year: '',
    users: [],
    projects: [],
    votingBlocks: [],
};

const { Option } = Select;

export const VotingEditor: FC<TProps> = ({ isEdit }) => {
    const [isInit, setIsInit] = useState(true);
    const [isReset, setIsReset] = useState(true);
    const [isUsersModalActive, setIsUsersModalActive] = useState(false);
    const [isProjectsModalActive, setIsProjectsModalActive] = useState(false);
    const put = useDispatch();
    const { pathname, search } = useLocation();
    const votingId = pathname.split('/')[2];
    const { type } = qs.parse(search);
    const detailedProject = useSelector(selectVotingEditorDetailedProject);
    const detailedProjectStatus = useSelector(selectVotingEditorDetailedProjectStatus);
    const projects = useSelector(selectVotingEditorProjects);
    const years = useSelector(selectYears);
    const yearsStatus = useSelector(selectYearsStatus);
    const sessions = useSelector(selectAllSessions);
    const votingTypes = useSelector(selectVotingTypesData);
    const votingTypesStatus = useSelector(selectVotingTypesStatus);
    const blocks = useSelector(selectVotingEditorBlocks);

    const votingForm = useFormik({
        initialValues: {
            ...initialData,
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            votingTypeId: Yup.string().required('Required'),
            year: Yup.string(),
            sessionId: Yup.string(),
            familiarizationDateTime: Yup.string().required('Обязательное поле').test(
                'test1',
                'Не корректная дата',
                function (value){
                    return moment(value).isValid();
                }
            ),
            startDateTime: Yup.string().required('Обязательное поле').test(
                'test1',
                'Не корректная дата',
                function (value){
                    return moment(value).isValid();
                }
            ).test(
                'test2',
                'Начало не может быть раньше ознакомления',
                function(value){
                    let { familiarizationDateTime } = this.parent;
                    return moment(value).isSameOrAfter(moment(familiarizationDateTime), 'd');
                }
            ),
            endDateTime: Yup.string().required('Обязательное поле').test(
                'test1',
                'Не корректная дата',
                function (value){
                    return moment(value).isValid();
                }
            ).test(
                'test2',
                // 'endDateTime date must be after startDateTime',
                'Дата окончания должна быть после старта',
                function(value){
                    let { startDateTime } = this.parent;
                    return moment(value).isAfter(moment(startDateTime), 'h');
                }
            ),
        }),
    });

    function onSubmit(
        values: TVotingEditorData,
        _formikHelpers: FormikHelpers<TVotingEditorData>
    ) {
        const sessionId = votingForm.values.sessionId;
        const result: TVotingParams = {
            activated: votingForm.values.activated,
            endDateTime: moment(votingForm.values.endDateTime).format(),
            familiarizationDateTime: moment(votingForm.values.familiarizationDateTime).format(),
            startDateTime: moment(votingForm.values.startDateTime).format(),
            name: votingForm.values.name,
            projectIds: votingForm.values.projects
                ? votingForm.values.projects.map((item) => (+item.id))
                : [],
            sessionId: typeof sessionId === 'number' ?
                sessionId : !!sessionId ? +sessionId : null,
            userIds: votingForm.values.users.map((item) => (+item.id)),
            votingBlockIds: blocks
                ? blocks.map((item) => (+item.id))
                : [],
            votingTypeId: +votingForm.values.votingTypeId,
        };

        if (isEdit) {
            put(EditVotingAction(result, votingId));
        } else {
            put(AddVotingAction(result));
        }
    }

    const getProjectsList = (sessionId: string) => {
        if (sessionId.length === 0) return;
        const session = sessions.find((item) => (
            +item.id === +votingForm.values.sessionId
        ));
        put(GetEditorProjectsStartAction({
            params: {
                sessionName: session ? session.name : '',
                year: votingForm.values.year,
            },
            // @ts-ignore
            type: type,
            votingId,
        }, sessionId));
    };

    const findSessionsByYear = (year: string) => {
        return sessions.filter((session) => (
            moment(session.endDate).format('yyyy') === year
        ));
    };

    const handleCancel = () => {
        if (isEdit) put(LogsActionsStartAction('CANCEL_EDIT_VOTING'))
        else put(LogsActionsStartAction('CANCEL_CREATE_VOTING'))
        put(push(mainModuleRoutes['all-votes-list'].path));
    };

    const getNewType = (value: string) => {
        let newType = 'projects';
        if (value === "Голосование по заявкам") { newType = 'projects' }
        if (value === "Голосование по документам") { newType = 'documents' }

        return newType;
    };

    const handleChangeSelect = (fieldName: string) => (value: string, option: any) => {
        if (option && option.value) {
            votingForm.setFieldValue(fieldName, `${option.value}`);
        } else {
            votingForm.setFieldValue(fieldName, '');
        }

        if (fieldName === 'year') {
            const currentSessions = findSessionsByYear(value);
            votingForm.setFieldValue(
                'sessionId',
                currentSessions ? currentSessions[currentSessions.length - 1].id : undefined,
            );
            votingForm.setFieldValue('projects', []);
        }
        if (fieldName === 'sessionId') {
            votingForm.setFieldValue('projects', []);
        }

        if (fieldName === 'votingTypeId') {
            put(push({
                pathname,
                search: `?type=${getNewType(option.children)}`
            }));
        }
    };

    const handleChangeDate = (name: string) => (value: moment.Moment | null) => {
        votingForm.setFieldValue(name, value ? value : '');
    };

    // @ts-ignore
    const handleBlurDate = (_event: FocusEvent<HTMLInputElement>) => {
        // votingForm.setFieldValue(name, value ? value : '');
    };

    const onSubmitUsersModal = (users: TEditVotingRequestData['users']) => {
        votingForm.setFieldValue('users', users && Array.isArray(users) ? users : []);
        setIsUsersModalActive(false);
    };

    const onSubmitProjectsModal = (projects: TEditVotingRequestData['projects']) => {
        votingForm.setFieldValue('projects', projects);
        setIsProjectsModalActive(false);
    };

    const handleChangeActive = () => {
        votingForm.setFieldValue('activated', !votingForm.values.activated);
    };

    const fieldHasAnError = (fieldName: keyof TVotingEditorData): boolean => {
        return Boolean((isEdit && votingForm.errors[fieldName]) ||
        (!isEdit && votingForm.errors[fieldName] && votingForm.touched[fieldName]));
    };

    // initialize form
    useEffect(() => {
        if (isInit
            && !isReset
            && detailedProjectStatus.isLoaded
            && votingTypesStatus.isLoaded
            && yearsStatus.isLoaded
            && sessions.length > 0
            && isEdit) {
            const votingType = votingTypes.find((item) => {
                return (type === 'projects' && item.name === 'Голосование по заявкам')
                    || (type === 'documents' && item.name === 'Голосование по документам');
            });

            votingForm.setValues({
                ...initialData,
                ...detailedProject,
                votingTypeId: votingType ? `${votingType.id}` : '1',
                activated: Boolean(detailedProject.activated),
                familiarizationDateTime: moment(detailedProject.familiarizationDateTime),
                startDateTime: moment(detailedProject.startDateTime),
                endDateTime: moment(detailedProject.endDateTime),
                name: detailedProject.name || '',
                year: detailedProject.session
                    ? moment(detailedProject.session.endDate).format('yyyy')
                    : years[0],
                sessionId: detailedProject.session ? `${detailedProject.session.id}` : '',
            });
            put(SetDocumentAction(detailedProject.votingBlocks || []));
            setIsInit(false);
        }

        if (isInit
            && !isEdit
            && !isReset
            && votingTypesStatus.isLoaded
            && yearsStatus.isLoaded
            && sessions.length > 0
        ) {
            votingForm.setValues(initialData);
            put(SetDocumentAction([]));
            setIsInit(false);
        }
    }, [detailedProjectStatus, votingTypesStatus, yearsStatus, sessions, detailedProject]);
    useEffect(() => {
        if (votingTypesStatus.isLoaded) {
            const votingType = votingTypes.find((item) => {
                return item.id.toString() === votingForm.values.votingTypeId;
            });

            if (votingType) {
                put(push({
                    pathname,
                    search: `?type=${getNewType(votingType.name)}`
                }));
            }
        }
    }, [votingForm.values.votingTypeId]);
    useEffect(() => {
        if (isEdit && votingId.length === 0) {
            put(push(mainModuleRoutes['create-voting'].path));
        }
    }, [isEdit, votingId]);
    useEffect(() => {
        if (!type) {
            put(push(mainModuleRoutes['all-votes-list'].path));
        }
    }, [type]);
    useEffect(() => {
        put(GetVotingTypesTriggerAction());
        put(GetAllSessionsStartAction());
        put(GetYearsTriggerAction());
        put(GetEditorRegentsStartAction());

        if (isEdit && votingId) {
            put(GetEditorDetailedProjectStartAction(votingId));
        }

        setIsReset(false);
        if (isEdit) put(LogsActionsStartAction('OPEN_EDIT_VOTING_FORM'));
        else put(LogsActionsStartAction('OPEN_NEW_VOTING_FORM'));
    }, []);
    useEffect(() => {
        const { sessionId, year } = votingForm.values;
        if (sessionId && projects.sessionId !== sessionId && year && !isInit && !isReset && sessions.length > 0) {
            getProjectsList(`${sessionId}`);
        }
    }, [votingForm.values.sessionId, isInit, isReset, sessions]);

    const validateHandler = (): boolean => {
        if (votingForm.isValid) {
            if (type === 'documents' && blocks.length) return false
            else if (type === 'projects' && votingForm.values.projects.length) return false
            else return true
        }
        return true
    }


    return (
        <div className={styles.container}>
            <div className={styles.topBar}>
                <ToParentPage className={styles.toParentPage} text=""  />
                {/*parentPath={(type === 'projects'*/}
                {/*? mainModuleRoutes['all-projects-list'].path.replace(':votingId', `${votingId}`)*/}
                {/*: mainModuleRoutes['all-documents-list'].path.replace(':votingId', `${votingId}`))}*/}

                <header className={styles.pageTitle}>
                    { isEdit ? 'Редактирование голосования' : 'Добавление голосования' }
                </header>
            </div>

            <form
                name="votingForm"
                onSubmit={votingForm.handleSubmit}
                className={styles.form}
            >
                <Form.Item
                    className={styles.formItem}
                    validateStatus={ (
                        votingForm.submitCount > 0 && votingForm.errors.name
                    ) ? 'error' : '' }
                >
                    <label htmlFor="votingTypeId" className={styles.label}>
                        Тип голосования
                    </label>
                    <Select
                        allowClear={true}
                        value={votingForm.values.votingTypeId ?`${votingForm.values.votingTypeId}` : undefined}
                        onChange={handleChangeSelect('votingTypeId')}
                        disabled={isEdit}
                    >
                        { votingTypes.map((item) => (
                            <Option key={item.id} value={`${item.id}`}>{item.name}</Option>
                        )) }
                    </Select>
                </Form.Item>

                {type !== 'documents' && (
                    <>
                        <div className={styles.formRow}>
                            <div className={styles.formCell}>
                                <Form.Item
                                    className={styles.formItem}
                                    validateStatus={ (
                                        votingForm.submitCount > 0 && votingForm.errors.year
                                    ) ? 'error' : '' }
                                >
                                    <label htmlFor="year" className={styles.label}>
                                        Год проекта
                                    </label>
                                    <Select
                                        allowClear={true}
                                        value={votingForm.values.year ?`${votingForm.values.year}` : undefined}
                                        onChange={handleChangeSelect('year')}
                                        placeholder={'Выберите год'}
                                    >
                                        { years.map((item) => (
                                            <Option key={item} value={item}>{item}</Option>
                                        )) }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className={styles.formCell}>
                                <Form.Item
                                    className={styles.formItem}
                                    validateStatus={ (
                                        votingForm.submitCount > 0 && votingForm.errors.sessionId
                                    ) ? 'error' : '' }
                                >
                                    <label htmlFor="sessionId" className={styles.label}>
                                        Отбор
                                    </label>
                                    <Select
                                        allowClear={true}
                                        value={votingForm.values.sessionId ? `${votingForm.values.sessionId}` : undefined}
                                        onChange={handleChangeSelect('sessionId')}
                                        placeholder={'Выберите отбор'}
                                        disabled={!(!!votingForm.values.year)}
                                    >
                                        { sessions.filter((item) => {
                                            return !(years.length === 0 ||
                                                (moment(item.endDate)
                                                    .format('yyyy') !== `${votingForm.values.year}`));
                                        }).map((item) => (
                                            <Option key={item.id} value={`${item.id}`}>{item.name}</Option>
                                        )) }
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </>
                )}

                <Form.Item
                    className={styles.formItem}
                    validateStatus={ (
                        votingForm.submitCount > 0 && votingForm.errors.name
                    ) ? 'error' : '' }
                >
                    <label htmlFor="name" className={styles.label}>
                        Название голосования
                    </label>
                    <Input
                        className={cn(
                            styles.input,
                            { [`${styles.error}`]: votingForm.submitCount > 0 && votingForm.errors.name }
                        )}
                        name="name"
                        placeholder=""
                        value={votingForm.values.name}
                        onChange={votingForm.handleChange}
                        onBlur={votingForm.handleBlur}
                    />
                </Form.Item>

                <div className={styles.heading}>
                    Дата и время ознакомления
                </div>

                <Form.Item
                    className={styles.formItem}
                    validateStatus={ fieldHasAnError('familiarizationDateTime') ? 'error' : '' }
                >
                    <label htmlFor="familiarizationDateTime" className={styles.label}>
                        Дата и время ознакомления
                    </label>
                    {/*// @ts-ignore*/}
                    <DatePicker
                        className={styles.input}
                        name="familiarizationDateTime"
                        placeholder=""
                        format={DateAndTimeFormat}
                        showTime={true}
                        value={votingForm.values.familiarizationDateTime}
                        onChange={handleChangeDate('familiarizationDateTime')}
                        onBlur={handleBlurDate}
                    />
                    { fieldHasAnError('familiarizationDateTime') && (
                        <div className={styles.dateError}>
                            { votingForm.errors.familiarizationDateTime || 'Ошибка' }
                        </div>
                    ) }
                </Form.Item>

                <div className={styles.formRow}>
                    <div className={styles.formCell}>
                        <Form.Item
                            className={styles.formItem}
                            validateStatus={ fieldHasAnError('startDateTime') ? 'error' : '' }
                        >
                            <label htmlFor="startDateTime" className={styles.label}>
                                Дата и время начала
                            </label>
                            {/*// @ts-ignore*/}
                            <DatePicker
                                className={styles.input}
                                name="startDateTime"
                                placeholder=""
                                format={DateAndTimeFormat}
                                showTime={true}
                                value={votingForm.values.startDateTime}
                                onChange={handleChangeDate('startDateTime')}
                                onBlur={handleBlurDate}
                            />
                            { fieldHasAnError('startDateTime') && (
                                <div className={styles.dateError}>
                                    { votingForm.errors.startDateTime || 'Ошибка' }
                                </div>
                            ) }
                        </Form.Item>
                    </div>
                    <div className={styles.formCell}>
                        <Form.Item
                            className={styles.formItem}
                            validateStatus={ fieldHasAnError('endDateTime') ? 'error' : '' }
                        >
                            <label htmlFor="endDateTime" className={styles.label}>
                                Дата и время окончания
                            </label>
                            {/*// @ts-ignore*/}
                            <DatePicker
                                className={styles.input}
                                name="endDateTime"
                                placeholder=""
                                format={DateAndTimeFormat}
                                showTime={true}
                                value={votingForm.values.endDateTime}
                                onChange={handleChangeDate('endDateTime')}
                                onBlur={handleBlurDate}
                            />
                            { fieldHasAnError('endDateTime') && (
                                <div className={styles.dateError}>
                                    { votingForm.errors.endDateTime || 'Ошибка' }
                                </div>
                            ) }
                        </Form.Item>
                    </div>
                </div>

                <div className={styles.heading}>
                    Члены Конкурсного комитета
                    <Button
                        type="default"
                        shape="circle"
                        className={styles.add}
                        onClick={() => {
                            put(LogsActionsStartAction('OPEN_VOTING_USER'))
                            setIsUsersModalActive(true)
                        }}
                    >+</Button>
                </div>
                <UsersList users={votingForm.values.users} />

                { type === 'projects'
                    ? (
                        <>
                            <div className={styles.heading}>
                                Заявки на голосование
                                <Button
                                    type="default"
                                    shape="circle"
                                    className={styles.add}
                                    onClick={() => {
                                        put(LogsActionsStartAction('OPEN_SELECT_VOTING_PROJECT_FORM'))
                                        setIsProjectsModalActive(true)
                                    }}
                                >+</Button>
                            </div>
                            <ProjectsList projects={votingForm.values.projects} />
                        </>
                    ) : (
                        <BlocksList
                            votingForm={votingForm}
                        />
                    )
                }

                <div className={styles.activateBlock}>
                    <Switch
                        checked={votingForm.values.activated}
                        onChange={handleChangeActive}
                    />
                    Активировать голосование
                </div>

                <div className={styles.actions}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={validateHandler()}
                    >Сохранить</Button>
                    <Button type="default" onClick={handleCancel}>Отменить</Button>
                </div>

            </form>

            <SelectUsersModal
                isOpened={isUsersModalActive}
                onClose={() => { setIsUsersModalActive(false) }}
                onSubmit={onSubmitUsersModal}
                selectedUsers={votingForm.values.users}
            />

            <SelectProjectsModal
                isOpened={isProjectsModalActive}
                onClose={() => { setIsProjectsModalActive(false) }}
                onSubmit={onSubmitProjectsModal}
                selectedProjects={votingForm.values.projects}
            />
        </div>
    );
};
