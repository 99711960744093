import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getUsersState = (state: TApplicationState) => state.users;

export const selectUser = createSelector(
    getUsersState,
    (state) => state.user,
);
export const selectUsers = createSelector(
    getUsersState,
    (state) => state.users,
);
export const selectUserActions = createSelector(
    getUsersState,
    (state) => state.actions,
);
export const selectUsersStatistic = createSelector(
    getUsersState,
    (state) => state.statistic,
);
export const selectUsersStatus = createSelector(
    getUsersState,
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        error: state.error,
    }),
);
