export const VotingEditorActionTypes = {
    GET_DETAILED_PROJECT_START: '[VOTING_EDITOR] GET_DETAILED_PROJECT_START',
    GET_DETAILED_PROJECT_SUCCESS: '[VOTING_EDITOR] GET_DETAILED_PROJECT_SUCCESS',
    GET_DETAILED_PROJECT_ERROR: '[VOTING_EDITOR] GET_DETAILED_PROJECT_ERROR',

    GET_REGENTS_START: '[VOTING_EDITOR] GET_REGENTS_START',
    GET_REGENTS_SUCCESS: '[VOTING_EDITOR] GET_REGENTS_SUCCESS',
    GET_REGENTS_ERROR: '[VOTING_EDITOR] GET_REGENTS_ERROR',

    GET_PROJECTS_START: '[VOTING_EDITOR] GET_PROJECTS_START',
    GET_PROJECTS_SUCCESS: '[VOTING_EDITOR] GET_PROJECTS_SUCCESS',
    GET_PROJECTS_ERROR: '[VOTING_EDITOR] GET_PROJECTS_ERROR',

    ADD_VOTING: '[VOTING_EDITOR] ADD_VOTING',
    EDIT_VOTING: '[VOTING_EDITOR] EDIT_VOTING',
    DELETE_VOTING: '[VOTING_EDITOR] DELETE_VOTING',

    SET_DOCUMENTS: '[VOTING_EDITOR] SET_DOCUMENTS',

    CREATE_DOCUMENT_START: '[VOTING_EDITOR] CREATE_DOCUMENT_START',
    CREATE_DOCUMENT_SUCCESS: '[VOTING_EDITOR] CREATE_DOCUMENT_SUCCESS',
    CREATE_DOCUMENT_ERROR: '[VOTING_EDITOR] CREATE_DOCUMENT_ERROR',

    EDIT_DOCUMENT_START: '[VOTING_EDITOR] EDIT_DOCUMENT_START',
    EDIT_DOCUMENT_SUCCESS: '[VOTING_EDITOR] EDIT_DOCUMENT_SUCCESS',
    EDIT_DOCUMENT_ERROR: '[VOTING_EDITOR] EDIT_DOCUMENT_ERROR',

} as const;
