import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetInternetResourcesSuccessAction,
    GetOneInternetResourcesStartAction,
    GetOneInternetResourcesErrorAction
} from "src/store/internet-resources/actions";

export function* getOneResourcesWorker({resourceId}: ReturnType<typeof GetOneInternetResourcesStartAction>) {
    try {
        const { data }: AxiosResponse<any> = yield axios({
            method: 'GET',
            url: `/internet_resources/${resourceId}`,
            headers: {},
        });

        yield put(GetInternetResourcesSuccessAction(data));
    } catch (error) {
        yield put(GetOneInternetResourcesErrorAction(error));
    }
}
