import { put } from 'redux-saga/effects';

import {
    GetActionsStartAction,
    UpdateActionsErrorAction,
    UpdateActionsStartAction,
    UpdateActionsSuccessAction,
} from "src/store/logs/actions";
import axios from "axios";

export function* UpdateActionsWorker({actionTypeId, action}: ReturnType<typeof UpdateActionsStartAction>) {
    try {
        yield axios({
            method: 'PUT',
            url: `/users/actions/types/${actionTypeId}`,
            headers: {},
            data: action
        });
        yield put(UpdateActionsSuccessAction());
        yield put(GetActionsStartAction());
    } catch (error) {
        yield put(UpdateActionsErrorAction(error));
    }
}
