import { createSelector } from 'reselect';
import { TApplicationState } from 'src/store/application-state';

const getState = (state: TApplicationState) => state.votingEditor;

export const selectVotingEditorDetailedProject = createSelector(
    getState,
    (st) => st.detailedProject.data,
);
export const selectVotingEditorDetailedProjectStatus = createSelector(
    getState,
    (s) => ({
        isLoading: s.detailedProject.isLoading,
        isLoaded: s.detailedProject.isLoaded,
        error: s.detailedProject.error
    }),
);

export const selectVotingEditorRegents = createSelector(
    getState,
    (st) => st.regents,
);

export const selectVotingEditorProjects = createSelector(
    getState,
    (st) => st.projects,
);

export const selectVotingEditorBlocks = createSelector(
    getState,
    (st) => st.blocks.data,
);
