import axios, { AxiosResponse } from 'axios';

import {
    GetAllProjectsReportStartAction,
} from 'src/store/voting/actions';

export function* getAllProjectsReportWorker(
    { votingId, reportType, projectId }: ReturnType<typeof GetAllProjectsReportStartAction>
) {
    try {
        const response: AxiosResponse<any> = reportType === 'comments' ? yield axios({
            method: 'GET',
            url: projectId
                ? `/voting/${votingId}/projects/${projectId}/comments/report`
                : `/voting/${votingId}/comments/report`,
            responseType: 'blob',
            headers: {},
        }) : yield axios({
            method: 'GET',
            url: projectId
                ? `/voting/${votingId}/projects/${projectId}/votes/report`
                : `/voting/${votingId}/votes/report`,
            responseType: 'blob',
            headers: {},
        });

        const url = window.URL.createObjectURL(response.data);
        let a = document.createElement('a');
        a.href = url;
        a.download = response.headers['Content-Disposition']
            ? response.headers['Content-Disposition'].slice(12)
            : `${reportType}-report.${reportType === 'comments' ? 'xls' : 'xls'}`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    } catch (_error) {}
}
