import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GetRealizationLinksStartAction,
    CreateRealizationLinksSuccessAction,
    UpdateRealizationLinksStartAction,
    CreateRealizationLinksErrorAction,
} from 'src/store/realization/actions';

export function* updateRealizationLinksWorker(
    { link, linkId, projectId }: ReturnType<typeof UpdateRealizationLinksStartAction>
) {
    try {
        const data = {
            "description": link.description,
            "internetResourceId": link.internetResourceId[0],
            "link": link.link,
            "projectId": projectId
        }

        yield axios({
            method: 'PUT',
            url: `/impl/projects/links/${linkId}`,
            headers: {},
            data: data,
        });


        yield put(CreateRealizationLinksSuccessAction());
        yield put(GetRealizationLinksStartAction(projectId));
    } catch (error) {
        yield put(CreateRealizationLinksErrorAction(error));
    }
}
