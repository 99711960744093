import { createSelector } from 'reselect';

import { defaultStatus } from 'src/constatnts/default-status';
import { TApplicationState } from 'src/store/application-state';
import { TProjectData } from 'src/store/project-info/reducer';

const getProjInfoState = (state: TApplicationState) => state.projectInfo;

export const selectProjInfo = (id: string) => (
    createSelector(
        getProjInfoState,
        (state): TProjectData => {
            if (!state[id]) return {};

            return state[id].data;
        }
    ));
export const selectProjInfoStatus = (id: string) => (
    createSelector(
        getProjInfoState,
        (state) => {
            if (!state[id]) return defaultStatus;

            return {
                isLoading: state[id].isLoading,
                isLoaded: state[id].isLoaded,
                error: state[id].error,
            }
        },
    ));
