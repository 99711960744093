import { InferValueTypes } from 'src/types/common';
import { CurrentSessionActionTypes } from 'src/store/current-session/action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TCurrentSessionState = {
    session: any;
    presentation: any;
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TCurrentSessionState = {
    session: null,
    presentation: null,
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function reducer(
    state: TCurrentSessionState = initialState,
    action: ActionTypes,
): TCurrentSessionState {
    switch (action.type) {
        case CurrentSessionActionTypes.GET_CURRENT_SESSION_START:
            return {
                ...initialState,
                isLoading: true,
            };
        case CurrentSessionActionTypes.GET_CURRENT_SESSION_SUCCESS:
            return {
                ...initialState,
                session: action.session,
                presentation: action.presentation,
                isLoading: false,
                isLoaded: true,
            };
        case CurrentSessionActionTypes.GET_CURRENT_SESSION_ERROR:
            return {
                ...initialState,
                error: action.error,
            };
        default:
            return state;
    }
}
