import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { DeleteOutlined } from '@ant-design/icons/lib';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { AddSessionStartAction, EditSessionStartAction } from 'src/store/sesssions/actions';

import { DateFormat, FileSize } from 'src/constatnts/format';

import { TSession } from 'src/types/sessions';

import styles from './styles.module.scss';
import {LogsActionsStartAction} from "src/store/logs/actions";

export type TSessionModalProps = {
    isOpened: boolean;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    data?: TSession | Partial<TSession>;
}

export type TSessionFormData = {
    name: string;
    startDate: moment.Moment | undefined;
    endDate: moment.Moment | undefined;
    file: UploadFile<any>[];
    isPresentationDeleted?: boolean;
};

export const SessionModal: FC<TSessionModalProps> = ({
    isOpened,
    onClose,
    data,
}) => {
    const isEdit = data && ('id' in data);
    const put = useDispatch();

    const initialData: TSessionFormData = useMemo(() => {
        let result: TSessionFormData = {
            name: '',
            startDate: moment(Date.now()),
            endDate: moment(Date.now()).add(1, 'd'),
            file: [],
        };

        if (isEdit) {
            result.isPresentationDeleted = false;
        }

        return result;
    }, [isOpened]);

    const form = useFormik({
        initialValues: {
            ...initialData,
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            startDate: Yup.string().required('Required'),
            endDate: Yup.string().required('Required').test(
                'test1',
                'Should be a valid timestamp',
                function (value){
                    return moment(value).isValid();
                }
            ).test(
                'test2',
                'Start date must be before end date',
                function(value){
                    let { startDate } = this.parent;
                    return moment(value).isAfter(moment(startDate), 'd');
                }
            ),
        }),
    });

    function onSubmit(values: TSessionFormData) {
        if (form.isValid) {
            if (data?.id) {
                put(EditSessionStartAction(values, data.id, data.presentation?.id))
            } else {
                put(AddSessionStartAction(values));
            }

            onClose();
        }
    }

    const handleChangeDate = (name: string) => (value: moment.Moment | null) => {
        form.setFieldValue(name, value ? value : undefined);
    };

    const removePresentation = () => {
        form.setFieldValue('isPresentationDeleted', true);
    };

    const validateUpload = (_file: File) => {
        return false;
    };

    const uploadFile = (info: UploadChangeParam<UploadFile<any>>) => {
        try {
            const newFile = info.fileList[info.fileList.length - 1];
            const name = newFile.name.split('.');
            const extention = name[name.length - 1];

            if (extention !== 'pdf') {
                throw new Error('Incorrect file type');
            }

            // @ts-ignore
            if (newFile.size > FileSize) {
                throw new Error('File too big');
            }

            if (isEdit) {
                removePresentation();
            }

            form.setFieldValue('file', [newFile]);
        } catch (_error) {
            return false;
        }
    };

    const onRemoveFile = (fieldName: string) => (_file: any) => {
        form.setFieldValue(fieldName, []);
    };

    useEffect(() => {
        if (!isOpened) {
            form.resetForm();
        }

        if (isOpened) {
            form.setValues({
                ...initialData,
                ...(isEdit && data ? {
                    name: data?.name || '',
                    startDate: moment(data?.startDate),
                    endDate: moment(data?.endDate),
                } : {}),
            });
        }
    }, [isOpened]);

    return (
        <Modal
            show={isOpened}
            onHide={onClose}
            backdrop="static"
            keyboard={true}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={styles.dialog}
        >
            <form name="" onSubmit={form.handleSubmit}>
                <Modal.Body className={styles.body}>
                    <h3 className={styles.header}>
                        { isEdit ? 'Редактировать сессию' : 'Добавить сессию' }
                    </h3>

                    <Form.Item
                        className={styles.formItem}
                        validateStatus={ (
                            form.submitCount > 0 && form.errors.name
                        ) ? 'error' : '' }
                    >
                        <label htmlFor="name" className={styles.label}>
                            Название отбора
                        </label>
                        <Input
                            className={cn(
                                styles.input,
                                { [`${styles.error}`]: form.submitCount > 0 && form.errors.name }
                            )}
                            name="name"
                            placeholder=""
                            value={form.values.name}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                        />
                    </Form.Item>

                    <Form.Item
                        className={styles.formItem}
                        validateStatus={ (
                            form.submitCount > 0 && form.errors.startDate
                        ) ? 'error' : '' }
                    >
                        <label htmlFor="startDate" className={styles.label}>
                            Начало отбора
                        </label>
                        <DatePicker
                            className={styles.input}
                            name="startDate"
                            placeholder=""
                            format={DateFormat}
                            value={form.values.startDate}
                            onChange={handleChangeDate('startDate')}
                            onBlur={form.handleBlur}
                        />
                    </Form.Item>

                    <Form.Item
                        className={styles.formItem}
                        validateStatus={ (
                            form.submitCount > 0 && form.errors.endDate
                        ) ? 'error' : '' }
                    >
                        <label htmlFor="endDate" className={styles.label}>
                            Окончание отбора
                        </label>
                        <DatePicker
                            className={styles.input}
                            name="endDate"
                            placeholder=""
                            format={DateFormat}
                            value={form.values.endDate}
                            onChange={handleChangeDate('endDate')}
                            onBlur={form.handleBlur}
                        />
                    </Form.Item>

                    <Upload
                        accept="application/pdf"
                        className={styles.upload}
                        listType="text"
                        multiple={true}
                        beforeUpload={validateUpload}
                        onChange={uploadFile}
                        onRemove={onRemoveFile('file')}
                        fileList={form.values.file}
                    >
                        <Button icon={<UploadOutlined />}>
                            { isEdit ? 'Заменить презентацию' : 'Итоговая презентация' }
                        </Button>
                    </Upload>

                    { (
                        isEdit
                        && !form.values.isPresentationDeleted
                        && data
                        && data.presentation
                        && data.presentation.fileName
                    ) && (
                        <Form.Item className={styles.uploadedFilesList}>
                            <label
                                htmlFor="endDate"
                                className={cn(styles.label, styles.existingFileLabel)}
                            >
                                { data.presentation.fileName }
                                <Button
                                    type="default"
                                    size="small"
                                    className={styles.btn}
                                    icon={<DeleteOutlined />}
                                    onClick={removePresentation}
                                />
                            </label>
                        </Form.Item>
                    ) }

                </Modal.Body>

                <Modal.Footer>
                    <Button type="default" onClick={(e) => {
                        if (isEdit) put(LogsActionsStartAction('CANCEL_EDIT_SESSION'))
                        else put(LogsActionsStartAction('CANCEL_CREATE_SESSION'))
                        onClose(e)
                    }}>Отменить</Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => (form.handleSubmit)}
                    >Сохранить</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};
