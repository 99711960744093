import {all, fork, takeEvery, takeLatest,} from 'redux-saga/effects';

import {AuthActionTypes} from 'src/store/auth/action-types';
import {ProjectsActionTypes} from 'src/store/projects/action-types';
import {CategoriesActionTypes} from 'src/store/categories/action-types';
import {ProjInfoActionTypes} from 'src/store/project-info/action-types';
import {YearsActionTypes} from 'src/store/years/action-types';
import {WavesActionTypes} from 'src/store/waves/action-types';
import {FilesActionTypes} from 'src/store/files/action-types';
import {CurrentSessionActionTypes} from 'src/store/current-session/action-types';
import {UserActionTypes} from 'src/store/user/action-types';
import {FormatsActionTypes} from "src/store/formats/action-types";

import {loginWorker} from 'src/workers/login-worker';
import {recoverPwdWorker} from 'src/workers/recover-password-worker';
import {projectsWorker} from 'src/workers/projects-worker';
import {categoriesWorker} from 'src/workers/categories-worker';
import {projectInfoWorker} from 'src/workers/project-info-worker';
import {yearsWorker} from 'src/workers/years-worker';
import {wavesWorker} from 'src/workers/waves-worker';
import {fileWorker} from 'src/workers/file-worker';
import {fileReportWorker} from 'src/workers/file-report-worker';
import {fileRealizationWorker} from './file-realization-worker';
import {presentationWorker} from 'src/workers/presentation-worker';
import {userWorker} from 'src/workers/user-worker';
import {logoutWorker} from 'src/workers/logout';
import {formatsWorker} from "src/workers/formats-worker";

import usersSaga from "src/workers/users";
import sessionsSaga from 'src/workers/sessions';
import projectsSaga from 'src/workers/projects';
import votingSaga from 'src/workers/voting';
import votingEditorSaga from 'src/workers/voting-editor';
import internetResourcesSaga from "src/workers/internet-resource";
import logsSaga from "src/workers/logs";
import realizationSaga from "src/workers/realization";
import reportingSaga from 'src/workers/reporting';
import integrationSaga from "src/workers/integration";
import {SystemSettingActionTypes} from "src/store/system-settings/action-types";
import {GetSystemSettingWorker, UpdateSystemSettingWorker} from "src/workers/system-settings";
import {GetCurrentUserWorker} from "src/workers/current-user-worker";

function* rootSaga() {
    yield all([
        yield takeLatest(AuthActionTypes.LOGIN_START, loginWorker),
        yield takeLatest(AuthActionTypes.GET_CURRENT_USER, GetCurrentUserWorker),
        yield takeLatest(AuthActionTypes.LOGOUT, logoutWorker),
        yield takeLatest(AuthActionTypes.RECOVER_PWD_START, recoverPwdWorker),
        yield takeLatest(UserActionTypes.GET_USER_START, userWorker),
        yield takeLatest(ProjectsActionTypes.GET_PROJECTS_START, projectsWorker),
        yield takeLatest(CurrentSessionActionTypes.GET_CURRENT_SESSION_START, presentationWorker),
        yield takeLatest(CategoriesActionTypes.GET_CATEGORIES_START, categoriesWorker),
        yield takeLatest(FormatsActionTypes.GET_FORMATS_START, formatsWorker),
        yield takeLatest(ProjInfoActionTypes.GET_PROJECT_START, projectInfoWorker),
        yield takeLatest(YearsActionTypes.GET_YEARS_TRIGGER, yearsWorker),
        yield takeLatest(WavesActionTypes.GET_WAVES_TRIGGER, wavesWorker),
        yield takeEvery(FilesActionTypes.GET_FILE_START, fileWorker),
        yield takeEvery(FilesActionTypes.GET_FILE_REPORT_START, fileReportWorker),
        yield takeEvery(FilesActionTypes.GET_FILE_REALIZATION_START, fileRealizationWorker),
        yield takeLatest(SystemSettingActionTypes.GET_SYSTEM_SETTINGS_START, GetSystemSettingWorker),
        yield takeLatest(SystemSettingActionTypes.UPDATE_SYSTEM_SETTINGS, UpdateSystemSettingWorker),

        fork(sessionsSaga),
        fork(projectsSaga),
        fork(votingSaga),
        fork(votingEditorSaga),
        fork(usersSaga),
        fork(internetResourcesSaga),
        fork(logsSaga),
        fork(realizationSaga),
        fork(reportingSaga),
        fork(integrationSaga),
    ]);


}

export default rootSaga;
