import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TVoteSummary } from 'src/types/vote';

import {
    GetAllProjectsVoteSummaryStartAction,
    GetAllProjectsVoteSummarySuccessAction,
    GetAllProjectsVoteSummaryErrorAction,
} from 'src/store/voting/actions';
import { selectIsModerator } from 'src/store/auth/selectors';

export function* getAllProjectsVoteSummaryWorker({ votingId }: ReturnType<typeof GetAllProjectsVoteSummaryStartAction>) {
    try {
        const isModerator = yield select(selectIsModerator);
        const { data }: AxiosResponse<TVoteSummary> = yield axios({
            method: 'GET',
            url: isModerator ? `/voting/${votingId}` : `/user/voting/${votingId}`,
            headers: {},
        });

        yield put(GetAllProjectsVoteSummarySuccessAction(data));
    } catch (error) {
        yield put(GetAllProjectsVoteSummaryErrorAction(error));
    }
}
