import { call, put, select } from 'redux-saga/effects';

import {
    GetDocumentsVotingIdTriggerAction,
    GetDocumentsVotingListTriggerAction,
    GetVoteDocumentsSummaryTriggerAction,
    GetDocumentListStartAction,
} from 'src/store/voting/actions';

import {
    selectDocumentsVoteSummaryData,
    selectDocumentsVotingListData,
} from 'src/store/voting/selectors';

import { getVotingListWorker } from 'src/workers/voting/get-voting-list';
import { getVoteSummaryWorker } from 'src/workers/voting/get-vote-summary';

export function* getDocumentsVotingIdWorker(_action: ReturnType<typeof GetDocumentsVotingIdTriggerAction>) {
    try {
        // 1. load user's voting list
        let votingListData = yield select(selectDocumentsVotingListData);
        if (votingListData.length === 0) {
            yield call(getVotingListWorker, GetDocumentsVotingListTriggerAction());
            votingListData = yield select(selectDocumentsVotingListData);
        }

        if (votingListData.length === 0) {
            throw new Error('Voting list is empty');
        }

        // 2. get summary
        yield call(getVoteSummaryWorker, GetVoteDocumentsSummaryTriggerAction(votingListData[0].id));
        const voteSummaryData = yield select(selectDocumentsVoteSummaryData);

        if (!voteSummaryData.id) {
            throw new Error('Voting id is absent');
        }

        // 3. get projects
        yield put(GetDocumentListStartAction());
    } catch (error) {
        // console.log(`getDocumentsVotingIdWorker err`, error);
    }
}
