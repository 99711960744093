import React, {FC, SyntheticEvent, useEffect, useMemo, useState,} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Button, Tabs, Tooltip} from 'antd';

import {
    GetAllProjectsReportStartAction,
    GetVotingDetailedProjectStartAction,
    GetVotingReportProjectStartAction,
} from 'src/store/voting/actions';
import {
    selectProjectsVoteSummaryData,
    selectVotingDetailedProject,
    selectVotingDetailedProjectStatus,
} from 'src/store/voting/selectors';
import {selectFile, selectFiles} from 'src/store/files/selectiors';
import {selectIsModerator} from 'src/store/auth/selectors';

import {mainModuleRoutes} from 'src/routing/projects-module';
import {ToParentPage} from 'src/components/to-parent-page';
import {MultilineText} from 'src/components/detailed-page/multiline-text';
import {InternetResources} from 'src/components/detailed-page/internet-resources';
import {PDFModal} from 'src/components/modals/pdf-modal';
import {TVoteModalProps, VoteModal} from 'src/components/modals/vote-modal';
import {SpecialOpinion} from 'src/components/special-opinion';
import {ErrorBoundary} from 'src/components/error-boundary';

import {TPDFModal} from 'src/types/modals';
import {formatCurrency} from 'src/utils/currencyForematter';

import pdfImg from 'src/assets/img/pdf.png';
import viewDocumentImg from 'src/assets/img/view-document.png';

import styles from './styles.module.scss';
import moment, {Duration} from 'moment';
import PdfItem from "src/components/project-ui/pdf-item";
import {LogsActionsStartAction} from "src/store/logs/actions";
import {LoadingOutlined} from "@ant-design/icons";
import Confidencial from "src/components/confidencial";

const colors: Record<string, string> = {
    '1': 'videoContent',
    '2': 'blogSphere',
    '4': 'multiFormat',
};

type TProps = {
    isArchive?: boolean;
};

export const VoteDetailedPage: FC<TProps> = (props) => {
    const [modalData, setModalData] = useState<TPDFModal>(null);
    const [loading, setLoading] = useState(false);
    const [voteModalData, setVoteModalData] = useState<TVoteModalProps['data'] | undefined>(undefined);
    const { pathname } = useLocation();
    const voteProjectsSummaryData = useSelector(selectProjectsVoteSummaryData);
    const votingId = pathname.split('/')[2];
    const projectId = pathname.split('/')[3];
    const put = useDispatch();
    const data = useSelector(selectVotingDetailedProject);
    const isModerator = useSelector(selectIsModerator);

    const status = useSelector(selectVotingDetailedProjectStatus);
    const image = useSelector(selectFile(
        (data && data.image) ? `${data.image.id}` : undefined
    ));
    const files = useSelector(selectFiles(
        (data && data.otherFiles) ? data.otherFiles.map((file) => (`${file.id}`)) : undefined
    ));
    // @ts-ignore
    const presentationFile = useSelector(selectFile(
        (data && data.presentationFile) ? `${data.presentationFile.id}` : undefined
    ));
    const requestFiles = useSelector(selectFiles(
        (data && data?.requestFiles) ? data.requestFiles.map((file) => (`${file.id}`)) : undefined
    ));
    const examinationFiles = useSelector(selectFiles(
        (data && data?.examinationFiles) ? data.examinationFiles.map((file) => (`${file.id}`)) : undefined
    ));
    let colorStyle = '';

    if ((data && data.category) && `${data.category.id}` in colors) {
        colorStyle = colors[data.category.id];
    }


    const canVote: boolean = useMemo(() => {
        try {
            if (!data || data.voteStatus === 'VOTED') return false;
            if (data.voteStatus === 'NOT_VOTED') return true
            if (data.voteStatus === 'PART_VOTED' && data.votes && data.votes[0].dateTime) {
                const time: string = data.votes && data.votes[0].dateTime;
                const now = moment(new Date());
                const end = moment(time);
                const duration: Duration = moment.duration(now.diff(end));
                const minutes = duration.asMinutes();
                return minutes <= 10;
            }
        } finally {}

        return false;
    }, [data]);
    const canVoting = () => {
        if (!!voteProjectsSummaryData.startDateTime) {
            const now = moment(new Date());
            const end = moment(voteProjectsSummaryData.startDateTime);
            if (moment.duration(now.diff(end)).asMinutes() > 0) return true
            return false
        } else return true
    }

    const handleChangeTabs = (e: string) => {
        if (e === `${3}`) {
            if (presentationFile !== null) openPDFModal(presentationFile, 'Презентация')();
        }
        if (e === `${2}`) put(LogsActionsStartAction('VIEW_VIDEO_PRESENTATION', `${data.id}`, data.name))
    };

    const openPDFModal = (file: any, name: string) => (_e?: SyntheticEvent) => {
        try {
            let fileURL = window.URL.createObjectURL(file);

            setModalData({
                fileURL: fileURL,
                name,
            });
        } catch (error) {}
    };

    const closePDFModal = () => {
        setModalData(null);
    };

    const openVoteModal = () => {
        if (data) {
            setVoteModalData({
                id: +projectId,
                votingId: +votingId,
                type: 'project',
                name: data.name ? data.name : '',
                vote: data.votes && data.votes.length ? data.votes[0] : null,
            });
        }
    };

    const closeVoteModal = () => {
        setVoteModalData(undefined);
    };
    const submitVoting = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            put(GetVotingDetailedProjectStartAction(votingId, projectId));
        }, 1000)
    }

    const handleDownloadReport = (reportType: 'comments'| 'votes') => () => {
        put(GetAllProjectsReportStartAction(+votingId, reportType, +projectId));
    };

    const getPath = () => {
        if (isModerator) {
            return mainModuleRoutes['all-projects-list'].path.replace(':votingId', `${votingId}`)
        }

        return (props.isArchive
            ? mainModuleRoutes['archive-projects'].path.replace(':votingId', `${votingId}`)
            : mainModuleRoutes.vote.path);
    };

    useEffect(() => {
        if (votingId && projectId) {
            put(GetVotingDetailedProjectStartAction(votingId, projectId));
            put(GetVotingReportProjectStartAction(votingId, projectId));
        }
    }, [put, votingId, projectId]);

    if (status.isLoading) {
        return (
            <div className={styles.container}>
                Загрузка...
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.leftColumn}>
                <ToParentPage text="" parentPath={getPath()} />
            </div>
            <ErrorBoundary>
                {loading && (
                    <div className={`${styles.contentColumn} ${styles.leftSection}`}>
                        <LoadingOutlined />
                    </div>
                )}
                { !loading && data && status.isLoaded ? (
                    <>
                        <div className={`${styles.contentColumn} ${styles.leftSection}`}>
                            { isModerator && (
                                <div className={styles.reports}>
                                    <Button
                                        className={styles.report}
                                        type="link"
                                        onClick={handleDownloadReport('votes')}
                                    >
                                        Итоговое голосование
                                    </Button>
                                    <Button
                                        className={styles.report}
                                        type="link"
                                        onClick={handleDownloadReport('comments')}
                                    >
                                        Особое мнение
                                    </Button>
                                </div>
                            ) }

                            { (data.category) && (
                                <div className={`${styles.item} ${styles.multiFormat}`}>
                                    <div className={`${styles.title} ${colorStyle && styles[colorStyle]}`}>
                                        {data.category.name}
                                    </div>
                                </div>
                            ) }

                            <div className={styles.item}>
                                {/*<div className={styles.title}>Название проекта</div>*/}
                                <div className={`${styles.text} ${styles.name}`}>{ data.name || '' }</div>
                            </div>

                            <div className={styles.item}>
                                {/*<div className={styles.title}>Юридическое лицо</div>*/}
                                <div className={`${styles.text} ${styles.owner}`}>{ data.owner || '' }</div>
                            </div>

                            {!!data.recommendedCostReduction && (
                                <div className={styles.item}>
                                    <div className={`${styles.text} ${styles.recommendedCostReduction} ${styles.recommendedCostReductionSmall}`}>
                                        Поддержан с учетом снижения стоимости проекта на { formatCurrency(data.recommendedCostReduction || '' )} рублей
                                    </div>
                                </div>
                            )}

                            <div className={styles.row}>
                                <div className={styles.rowItem}>
                                    <div className={styles.title}>Бюджет</div>
                                    <div className={`${styles.text} ${styles.budget}`}>
                                        { formatCurrency(data.budget || '') }
                                    </div>
                                </div>

                                <div className={styles.rowItem}>
                                    <div className={styles.title}>Плановый КПЭ</div>
                                    <div className={`${styles.text} ${styles.budget}`}>
                                        { formatCurrency(data.kpi || '') }
                                    </div>
                                </div>
                            </div>

                            { (data.internetResources) && (
                                <div className={styles.item}>
                                    <div className={styles.title}>Интернет-ресурсы</div>
                                    <div className={`${styles.text} ${styles.socials}`}>
                                        <InternetResources data={data.internetResources} />
                                    </div>
                                </div>
                            ) }

                            { (requestFiles && requestFiles.length > 0) && (
                                // @ts-ignore
                                <PdfItem files={requestFiles} data={data} label={'requestFiles'} openPDFModal={openPDFModal} text={'Заявка'} />
                            ) }


                            { data.expertise && (
                                <div className={styles.item}>
                                    <div className={styles.title}>Юридическая экспертиза</div>
                                    <div className={styles.text}>
                                        <MultilineText text={data.expertise || ''} />
                                    </div>
                                </div>
                            ) }

                            { (examinationFiles && examinationFiles.length > 0) && (
                                // @ts-ignore
                                <PdfItem files={examinationFiles} data={data} label={'examinationFiles'} openPDFModal={openPDFModal} text={'Экспертиза'} />
                            ) }

                            { data.teamExperience && (
                                <div className={styles.item}>
                                    <div className={styles.title}>Команда проекта</div>
                                    <div className={styles.text}>
                                        <MultilineText text={data.teamExperience || ''} />
                                    </div>
                                </div>
                            ) }

                            { (files && files.length > 0) && (
                                // @ts-ignore
                                <PdfItem files={files} data={data} label={'otherFiles'} openPDFModal={openPDFModal} text={'Дополнения'} />
                            ) }

                        </div>
                        <div className={styles.contentColumn}>
                            {data.isConfidential && <Confidencial isAbsolute={false}/>}
                            <Tabs defaultActiveKey="1" onChange={handleChangeTabs}>
                                { data.image && (
                                    <Tabs.TabPane tab="Изображение" key="1">
                                        { (image) && (
                                            <img
                                                className={styles.image}
                                                src={image}
                                                alt="Изображение"
                                            />
                                        ) }
                                    </Tabs.TabPane>
                                ) }

                                { (data.presentation && data.presentation.url) && (
                                    <Tabs.TabPane tab="Видеопрезентация" key="2">
                                        <div className={styles.item}>
                                            <div className={styles.text}>
                                                <iframe
                                                    className={styles.video}
                                                    title="presentation"
                                                    width="510"
                                                    height="264"
                                                    src={data.presentation.url}
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen>
                                                </iframe>
                                            </div>
                                        </div>
                                    </Tabs.TabPane>
                                ) }
                                { (data && data.presentationFile && data.presentationFile.id) && (
                                    <Tabs.TabPane tab="Презентация" key="3">
                                        { (presentationFile !== null) && (
                                            <div
                                                className={styles.document}
                                                onClick={openPDFModal(presentationFile, 'Презентация')}>
                                                <img src={pdfImg} alt="pdf" className={styles.pdfImg} />
                                                <span>Презентация</span>
                                                <Tooltip placement="top" title="Просмотр документа">
                                                    <img
                                                        src={viewDocumentImg}
                                                        alt="document"
                                                        className={styles.documentImg} />
                                                </Tooltip>
                                            </div>
                                        ) }
                                    </Tabs.TabPane>
                                ) }
                            </Tabs>

                            { data.description && (
                                <div className={styles.item}>
                                    <div className={styles.title}>Описание проекта</div>
                                    <div className={styles.text}>
                                        <MultilineText text={data.description || ''} />
                                    </div>
                                </div>
                            ) }

                            { (data.formatDescription) && (
                                <div className={styles.item}>
                                    <div className={styles.title}>Формат контента</div>
                                    <div className={styles.text}>
                                        <MultilineText text={data.formatDescription || ''} />
                                    </div>
                                </div>
                            ) }

                            { !isModerator && canVoting() ? (
                                <>
                                    <SpecialOpinion
                                        data={data}
                                        type={'project'}
                                        projectId={+projectId}
                                        votingId={+votingId}
                                        isArchive={props.isArchive}
                                        handler={submitVoting}
                                    />

                                    <div className={styles.item}>
                                        {data && data.votes?.length && (
                                            <>
                                                <div className={styles.text}>
                                                    Вы проголосавали: {data?.votes[0].voteOption.name}
                                                </div> <br/>
                                            </>
                                        )}
                                    </div>

                                    { (!props.isArchive && canVote && canVoting()) && (
                                        <div className={styles.item}>
                                            <div className={styles.text}>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={openVoteModal}
                                                >Голосовать</Button>
                                            </div>
                                        </div>
                                    ) }
                                </>
                            ) : (
                                <div className={styles.item}>
                                    {data && data.votes?.length && (
                                        <>
                                            <div className={styles.text}>
                                                Вы проголосавали: {data?.votes[0].voteOption.name}
                                            </div> <br/>
                                        </>
                                    )}
                                </div>
                            ) }
                        </div>
                    </>
                ) : '' }
            </ErrorBoundary>


            <PDFModal
                fileURL={modalData?.fileURL as string}
                name={modalData?.name}
                isOpened={Boolean(modalData)}
                onClose={closePDFModal}
            />

            <VoteModal
                isOpened={Boolean(voteModalData)}
                data={voteModalData}
                onClose={closeVoteModal}
                handler={submitVoting}
            />
        </div>
    );
};
