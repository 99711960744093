import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetCurrentSessionStartAction,
    GetCurrentSessionSuccessAction,
    GetCurrentSessionErrorAction,
} from 'src/store/current-session/actions';
import { TProjectContent } from 'src/types/projects';

export function* presentationWorker({ id }: ReturnType<typeof GetCurrentSessionStartAction>) {
    try {
        const { data: sessionData }: AxiosResponse<TProjectContent['session']> = yield axios({
            method: 'GET',
            url: `/sessions/${id}`,
            headers: {},
        });

        let presentationResponse: AxiosResponse<any>;

        if (sessionData?.presentation && sessionData?.presentation.id) {
            const presentationId = sessionData?.presentation.id;

            presentationResponse = yield axios({
                method: 'GET',
                url: `/files/${presentationId}`,
                responseType: 'blob',
                headers: {},
            });
        } else {
            yield put(GetCurrentSessionSuccessAction(sessionData, null));
            throw new Error('Presentation id is not found');
        }

        yield put(GetCurrentSessionSuccessAction(sessionData, presentationResponse.data));
    } catch (error) {
        yield put(GetCurrentSessionErrorAction(error));
    }
}
