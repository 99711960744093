import React, {FC} from "react";
import styles from "src/components/modals/projects-modal/styles.module.scss";
import {Form, InputNumber} from "antd";
import cn from "classnames";

type ModalInputNumberProps = {
    form: any,
    label: string
    text: string
}

const ModalInputNumber: FC<ModalInputNumberProps> = ({form, label, text}) => {
    const handleChangeNumber = (fieldName: string) => (value: any) => {
        if (typeof value !== 'number') {
            form.setFieldValue(fieldName, 0);
            return;
        }
        form.setFieldValue(fieldName, value);
    };
    return (
        <Form.Item
            className={styles.formItem}
            validateStatus={ (
                form.submitCount > 0 && form.errors[label]
            ) ? 'error' : '' }
        >
            <label htmlFor={label} className={styles.label}>
                {text}
            </label>
            <InputNumber
                className={cn(
                    styles.input,
                    { [`${styles.error}`]: form.submitCount > 0 && form.errors[label] }
                )}
                min={0}
                max={99999999999}
                name={label}
                placeholder=""
                value={form.values[label]}
                onChange={handleChangeNumber(label)}
                onBlur={form.handleBlur}
            />
        </Form.Item>
    )
}
export default ModalInputNumber