import { createSelector } from 'reselect';
import { TApplicationState } from 'src/store/application-state';

const getVotingState = (state: TApplicationState) => state.voting;

export const selectVotingTypesData = createSelector(
    getVotingState,
    (st) => st.types.data,
);
export const selectVotingTypesStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.types.isLoading,
        isLoaded: s.types.isLoaded,
        error: s.types.error
    }),
);

export const selectProjectsVotingListData = createSelector(
    getVotingState,
    (st) => st.projectsList.data,
);
export const selectProjectsVotingListStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.projectsList.isLoading,
        isLoaded: s.projectsList.isLoaded,
        error: s.projectsList.error
    }),
);
export const selectDocumentsVotingListData = createSelector(
    getVotingState,
    (st) => st.documentsList.data,
);
export const selectDocumentsVotingListStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.documentsList.isLoading,
        isLoaded: s.documentsList.isLoaded,
        error: s.documentsList.error
    }),
);

export const selectProjectsVoteSummaryData = createSelector(
    getVotingState,
    (st) => st.voteProjectsSummary.data,
);
export const selectProjectsVoteSummaryStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.voteProjectsSummary.isLoading,
        isLoaded: s.voteProjectsSummary.isLoaded,
        error: s.voteProjectsSummary.error
    }),
);

export const selectDocumentsVoteSummaryData = createSelector(
    getVotingState,
    (st) => st.voteDocumentsSummary.data,
);
export const selectDocumentsVoteSummaryStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.voteDocumentsSummary.isLoading,
        isLoaded: s.voteDocumentsSummary.isLoaded,
        error: s.voteDocumentsSummary.error
    }),
);

export const selectVoteProjectsData = createSelector(
    getVotingState,
    (st) => st.projects.data,
);
export const selectVoteProjectsStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.projects.isLoading,
        isLoaded: s.projects.isLoaded,
        error: s.projects.error
    }),
);

export const selectVoteDocumentsData = createSelector(
    getVotingState,
    (st) => st.documents.data,
);
export const selectVoteDocumentsStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.documents.isLoading,
        isLoaded: s.documents.isLoaded,
        error: s.documents.error
    }),
);
export const selectVoteFilters = createSelector(
    getVotingState,
    (s) => s.filters,
);

export const selectVotingDetailedProjectStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.detailedProject.isLoading,
        isLoaded: s.detailedProject.isLoaded,
        error: s.detailedProject.error
    }),
);
export const selectVotingDetailedProject = createSelector(
    getVotingState,
    (s) => s.detailedProject.data,
);

export const selectVoteDetailedDocumentStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.detailedDocument.isLoading,
        isLoaded: s.detailedDocument.isLoaded,
        error: s.detailedDocument.error
    }),
);
export const selectVoteDetailedDocument = createSelector(
    getVotingState,
    (s) => s.detailedDocument.data,
);

//reports
export const selectVotingReportProject = createSelector(
    getVotingState,
    (s) => s.reportProject.data,
);
export const selectVotingReportDocument = createSelector(
    getVotingState,
    (s) => s.reportDocument.data,
);

export const selectVoteOptionsStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.options.isLoading,
        isLoaded: s.options.isLoaded,
        error: s.options.error,
    }),
);
export const selectVoteOptionsData = createSelector(
    getVotingState,
    (s) => s.options.data,
);

export const selectAllVotesStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.allVotes.isLoading,
        isLoaded: s.allVotes.isLoaded,
        error: s.allVotes.error,
    }),
);
export const selectAllVotesData = createSelector(
    getVotingState,
    (s) => s.allVotes.data,
);

export const selectAllDocumentsStatus = createSelector(
    getVotingState,
    (s) => ({
        isLoading: s.allDocuments.isLoading,
        isLoaded: s.allDocuments.isLoaded,
        error: s.allDocuments.error,
    }),
);
export const selectAllDocumentsData = createSelector(
    getVotingState,
    (s) => s.allDocuments.data,
);

export const selectAllProjectsVoteSummary = createSelector(
    getVotingState,
    (s) => s.allProjectsVoteSummary.data,
);
export const selectAllDocumentsVoteSummary = createSelector(
    getVotingState,
    (s) => s.allDocumentsVoteSummary.data,
);

export const selectAllProjectsReportData = createSelector(
    getVotingState,
    (s) => s.allProjectsReport.data,
);
export const selectAllDocumentsReportData = createSelector(
    getVotingState,
    (s) => s.allDocumentsReport.data,
);
