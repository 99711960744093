import {InferValueTypes} from 'src/types/common';
import {WavesActionTypes} from 'src/store/waves/action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TWavesState = {
    data: string[];
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TWavesState = {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};

// used for filtering projects
export default function reducer(
    state: TWavesState = initialState,
    action: ActionTypes,
): TWavesState {
    switch (action.type) {
        case WavesActionTypes.GET_WAVES_SET:
            return {...state, isLoading: false}
        case WavesActionTypes.GET_WAVES_START:
            return {
                ...initialState,
                data: state.data,
                isLoading: true,
            };
        case WavesActionTypes.GET_WAVES_SUCCESS:
            return {
                ...initialState,
                data: action.waves,
                isLoading: false,
                isLoaded: true,
            };
        case WavesActionTypes.GET_WAVES_ERROR:
            return {
                ...initialState,
                error: action.error,
            };
        default:
            return state;
    }
}
