import {put} from 'redux-saga/effects';
import axios from 'axios';

import {
    TriggerGetReportingFiltersAction,
    UpdateReportStartAction,
    UpdateReportSuccessAction,
} from 'src/store/reporting/actions';


export function* updateReportWorker({report}: ReturnType<typeof UpdateReportStartAction>) {
    try {

        const updateData = {
            description: report.description,
            name: report.name
        }

        yield axios({
            method: 'PUT',
            url: `/reporting/${report.id}`,
            headers: {},
            data: updateData
        });
        yield put(UpdateReportSuccessAction());
        yield put(TriggerGetReportingFiltersAction())
    } catch (error) {
        yield put(UpdateReportStartAction(error));
    }
}
