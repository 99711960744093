import {put, select} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetSystemSettingStartAction, GetSystemSettingSuccessAction,
    SystemSettingErrorAction,
    SystemSettingStartAction, UpdateSystemSettingAction
} from "src/store/system-settings/actions";
import {TSettings, TSettingsStore} from "src/store/system-settings/reducer";
import {selectSystemSetting} from "src/store/system-settings/selectors";

export function* GetSystemSettingWorker(_action: ReturnType<typeof GetSystemSettingStartAction>) {
    try {
        yield put(SystemSettingStartAction());

        const { data: actualKpi }: AxiosResponse<TSettings> = yield axios.get('system-settings', {
            params: {code: 'PROJECT_SELECTION_ACTUAL_KPI_HIDDEN'}
        });
        const { data: supportAmount }: AxiosResponse<TSettings> = yield axios.get('system-settings', {
            params: {code: 'PROJECT_SELECTION_SUPPORT_AMOUNT_HIDDEN'}
        });

        yield put(GetSystemSettingSuccessAction({actualKpi: actualKpi, supportAmount: supportAmount}));
    } catch (error) {
        yield put(SystemSettingErrorAction(error));
    }
}
export function* UpdateSystemSettingWorker({value}: ReturnType<typeof UpdateSystemSettingAction>) {
    try {
        const {actualKpi, supportAmount}: TSettingsStore =  yield select(selectSystemSetting);
        if (actualKpi?.id && supportAmount?.id) {
            yield put(SystemSettingStartAction());

            yield axios.put(`system-settings/${actualKpi?.id}`, {
                ...actualKpi,
                value: value
            });
            yield axios.put(`system-settings/${supportAmount?.id}`, {
                ...supportAmount,
                value: value
            });

            yield put(GetSystemSettingStartAction())
        }


    } catch (error) {
        yield put(SystemSettingErrorAction(error));
    }
}
