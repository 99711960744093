import { RealizationActionTypes } from 'src/store/realization/action-types';
import { TRealizationFilters, TRealizationLinks, TRealizationLinksFilters } from 'src/types/realization';
import { TPagination, TSorting } from 'src/types/pageable-request';
import {TProjectFormData} from "src/components/modals/projects-modal";
import {TProjectContent} from "src/types/projects";

export const SetRealizationFiltersAction = (filters: TRealizationFilters) => ({
    type: RealizationActionTypes.SET_FILTERS,
    filters,
});
export const ResetRealizationFiltersAction = () => ({
    type: RealizationActionTypes.RESET_FILTERS,
});

export const SetRealizationLinksFiltersAction = (filters: TRealizationLinksFilters) => ({
    type: RealizationActionTypes.SET_LINKS_FILTERS,
    filters,
});
export const ResetRealizationLinksFiltersAction = () => ({
    type: RealizationActionTypes.RESET_LINKS_FILTERS,
});

export const GetRealizationListStartAction = () => ({
    type: RealizationActionTypes.GET_LIST_START,
});
export const GetRealizationListSuccessAction = (
    content: any[],
    pagination: TPagination & TSorting,
) => ({
    type: RealizationActionTypes.GET_LIST_SUCCESS,
    content,
    pagination,
});
export const GetRealizationListErrorAction = (error: any) => ({
    type: RealizationActionTypes.GET_LIST_ERROR,
    error,
});

//types
export const GetRealizationTypesStartAction = () => ({
    type: RealizationActionTypes.GET_PROJECT_TYPES_START
})
export const GetRealizationTypesSuccessAction = (types: []) => ({
    type: RealizationActionTypes.GET_PROJECT_TYPES_SUCCESS,
    types
})
export const GetRealizationTypesErrorAction = (error: any) => ({
    type: RealizationActionTypes.GET_PROJECT_TYPES_ERROR,
    error,
});

//internet resources
export const GetRealizationResourcesStartAction = () => ({
    type: RealizationActionTypes.GET_INTERNET_RESOURCES_START
})
export const GetRealizationResourcesSuccessAction = (data: any) => ({
    type: RealizationActionTypes.GET_INTERNET_RESOURCES_SUCCESS,
    data
})
export const GetRealizationResourcesErrorAction = (error: any) => ({
    type: RealizationActionTypes.GET_INTERNET_RESOURCES_ERROR,
    error,
});

//GET_PROJECT
export const GetRealizationProjectStartAction = (projectId: any) => ({
    type: RealizationActionTypes.GET_PROJECT_START,
    projectId
})
export const GetRealizationProjectSuccessAction = (project: any, projectId?: number) => ({
    type: RealizationActionTypes.GET_PROJECT_SUCCESS,
    project,
    projectId
})
export const GetRealizationProjectErrorAction = (error: any) => ({
    type: RealizationActionTypes.GET_PROJECT_ERROR,
    error,
});

//GET_PROJECT_CONTENT
export const GetRealizationProjectContentStartAction = (id: any) => ({
    type: RealizationActionTypes.GET_PROJECT_CONTENT_START,
    id
})
export const GetRealizationProjectContentSuccessAction = (content: any) => ({
    type: RealizationActionTypes.GET_PROJECT_CONTENT_SUCCESS,
    content
})
export const GetRealizationProjectContentErrorAction = (error: any) => ({
    type: RealizationActionTypes.GET_PROJECT_CONTENT_ERROR,
    error,
});

//CREATE_PROJECT_Content
export const CreateRealizationProjectContentStartAction = (project: any) => ({
    type: RealizationActionTypes.CREATE_PROJECT_CONTENT_START,
    project
})
export const CreatRealizationProjectContentSuccessAction = (contentId: number) => ({
    type: RealizationActionTypes.CREATE_PROJECT_CONTENT_SUCCESS,
    contentId
})
export const CreateRealizationProjectContentErrorAction = (error: any) => ({
    type: RealizationActionTypes.CREATE_PROJECT_CONTENT_ERROR,
    error,
});

//ADD_PROJECT_CONTENT
export const AddRealizationProjectContentStartAction = (contentId: number, project: object) => ({
    type: RealizationActionTypes.ADD_PROJECT_CONTENT_START,
    contentId,
    project
})
export const AddRealizationProjectContentSuccessAction = () => ({
    type: RealizationActionTypes.ADD_PROJECT_CONTENT_SUCCESS,
})
export const AddRealizationProjectContentErrorAction = (error: any) => ({
    type: RealizationActionTypes.ADD_PROJECT_CONTENT_ERROR,
    error,
});

//UPDATE_PROJECT_CONTENT
export const UpdateRealizationProjectContentStartAction = (id: any, project: any) => ({
    type: RealizationActionTypes.UPDATE_PROJECT_CONTENT_START,
    id,
    project
})
export const UpdateRealizationProjectContentSuccessAction = () => ({
    type: RealizationActionTypes.UPDATE_PROJECT_CONTENT_SUCCESS,
})
export const UpdateRealizationProjectContentErrorAction = (error: any) => ({
    type: RealizationActionTypes.UPDATE_PROJECT_CONTENT_ERROR,
    error,
});

//DELETE_PROJECT
export const DeleteRealizationProjectContentStartAction = (projectId: number) => ({
    type: RealizationActionTypes.DELETE_PROJECT_CONTENT_START,
    projectId
})
export const DeleteRealizationProjectContentSuccessAction = () => ({
    type: RealizationActionTypes.DELETE_PROJECT_CONTENT_SUCCESS,
})
export const DeleteRealizationProjectContentErrorAction = (error: any) => ({
    type: RealizationActionTypes.DELETE_PROJECT_CONTENT_ERROR,
    error,
});


//Links
export const GetRealizationLinksStartAction = (projectId: number) => ({
    type: RealizationActionTypes.GET_LINKS_START,
    projectId,
});
export const GetRealizationLinksSuccessAction = (projectId: number, data: TRealizationLinks) => ({
    type: RealizationActionTypes.GET_LINKS_SUCCESS,
    projectId,
    data,
});
export const GetRealizationLinksErrorAction = (projectId: number, error: any) => ({
    type: RealizationActionTypes.GET_LINKS_ERROR,
    projectId,
    error,
});

export const CreateRealizationLinksStartAction = (link: any, projectId: number) => ({
    type: RealizationActionTypes.CREATE_LINKS_START,
    link, projectId
});
export const CreateRealizationLinksSuccessAction = () => ({
    type: RealizationActionTypes.CREATE_LINKS_SUCCESS,

});
export const CreateRealizationLinksErrorAction = (error: any) => ({
    type: RealizationActionTypes.CREATE_LINKS_ERROR,
    error,
});

export const UpdateRealizationLinksStartAction = (link: any, linkId: number, projectId: number) => ({
    type: RealizationActionTypes.UPDATE_LINKS_START,
    link,
    linkId,
    projectId
});
export const UpdateRealizationLinksSuccessAction = () => ({
    type: RealizationActionTypes.UPDATE_LINKS_SUCCESS,

});
export const UpdateRealizationLinksErrorAction = (error: any) => ({
    type: RealizationActionTypes.UPDATE_LINKS_ERROR,
    error,
});

export const DeleteRealizationLinksStartAction = (linkId: number, projectId: number) => ({
    type: RealizationActionTypes.DELETE_LINKS_START,
    linkId, projectId
});
export const DeleteRealizationLinksSuccessAction = () => ({
    type: RealizationActionTypes.DELETE_LINKS_SUCCESS,
});
export const DeleteRealizationLinksErrorAction = (error: any) => ({
    type: RealizationActionTypes.DELETE_LINKS_ERROR,
    error,
});

//crawler
export const CrawlerStartAction = (ids: number[]) => ({
    type: RealizationActionTypes.CRAWLER_START,
    ids
})
export const CrawlerSuccessAction = () => ({
    type: RealizationActionTypes.CRAWLER_SUCCESS
})
export const CrawlerErrorAction = (error: any) => ({
    type: RealizationActionTypes.CRAWLER_ERROR,
    error
})

//update project
export const UpdateProjectStartAction = (form: TProjectFormData, project: TProjectContent) => ({
    type: RealizationActionTypes.UPDATE_PROJECT_START,
    form,
    project
})
export const UpdateProjectSuccessAction = () => ({
    type: RealizationActionTypes.UPDATE_PROJECT_SUCCESS
})
export const UpdateProjectErrorAction = (error: any) => ({
    type: RealizationActionTypes.UPDATE_PROJECT_ERROR,
    error
})