import React from 'react';
import moment from "moment";

import styles from './styles.module.scss';

function dateParse(value: string) {
    if (!value) return null
    // @ts-ignore
    const { _data: data } = moment.duration(value)
    const hours = data.hours
    const minutes = data.minutes
    const seconds = data.seconds
    return `${hours < 9 ? '0' + hours : hours}:${minutes < 9 ? '0' + minutes : minutes}:${seconds < 9 ? '0' + seconds : seconds}`
}

export const DateDurationCell = (name: string, data: any) => {
    return (
        <div className={styles.cell}>
            <span className={styles.name}>{dateParse(data.websiteSpentTime)}</span>
        </div>
    )
}
