import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TVoteSummary } from 'src/types/vote';
import { TPagination, TSorting } from 'src/types/pageable-request';

import {
    GetAllDocumentsListStartAction,
    GetAllDocumentsListSuccessAction,
    GetAllDocumentsListErrorAction,
} from 'src/store/voting/actions';
import { selectIsModerator } from 'src/store/auth/selectors';
import {selectAllDocumentsData, selectVotingTypesData} from 'src/store/voting/selectors';

// admin's documents list
export function* getAllDocumentsListAdminWorker(
    _action?: ReturnType<typeof GetAllDocumentsListStartAction>
) {
    try {
        const votingTypes = yield select(selectVotingTypesData);
        const projectsData = yield select(selectAllDocumentsData);
        const isModerator = yield select(selectIsModerator);

        let { data: respData }: AxiosResponse<{
            content: TVoteSummary[];
        } & TPagination & TSorting> = yield axios({
            method: 'POST',
            url: isModerator
                ? `/voting/${votingTypes[1].id}/search`
                : `/user/voting/archive/${votingTypes[1].id}/search`,
            headers: {},
            data: {
                page: projectsData.page - 1,
                pageSize: projectsData.pageSize,
                totalCount: projectsData.totalCount,
                sort: projectsData.sort,
            },
        });

        // as page count starts from 0, add one
        if (respData) {
            respData.page = respData.page + 1;
        }

        yield put(GetAllDocumentsListSuccessAction(respData));
    } catch (error) {
        yield put(GetAllDocumentsListErrorAction(error));
    }
}
