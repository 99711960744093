import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetVotingProjectsStartAction,
    GetVotingProjectsSuccessAction,
    GetVotingProjectsErrorAction,
    SetVoteFiltersAction,
} from 'src/store/voting/actions';
import { selectProjectsVoteSummaryData, selectVoteFilters } from 'src/store/voting/selectors';

export function* getVotingProjectsWorker(_action: ReturnType<typeof GetVotingProjectsStartAction>) {
    try {
        const voteSummaryData = yield select(selectProjectsVoteSummaryData);
        const filters = yield select(selectVoteFilters);

        if (!voteSummaryData.id) {
            throw new Error('Voting id is absent');
        }

        let { data }: AxiosResponse<any> = yield axios({
            method: 'POST',
            url: `user/voting/${voteSummaryData.id}/projects/search`,
            headers: {},
            data: {
                filterData: {
                    categoryIds: filters.filterData.categoryIds,
                    minScore: filters.filterData.minScore,
                    maxScore: filters.filterData.maxScore,
                    searchString: filters.filterData.searchString
                },
                page: filters.page - 1,
                pageSize: filters.pageSize,
                totalCount: filters.totalCount,
                sort: filters.sort,
            }
        });

        if (data) {
            data.page = data.page + 1;
        }
        yield put(GetVotingProjectsSuccessAction(data));
        yield put(SetVoteFiltersAction({ ...filters, page: data.page, pageSize: data.pageSize, totalCount: data.totalCount}));
    } catch (error) {
        console.log('getVotingProjectsWorker error', error);
        yield put(GetVotingProjectsErrorAction(error));
    }
}
