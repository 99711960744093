import React, {useEffect, useState} from "react";
import {ConfirmModal} from "src/components/modals/confirm-modal";
import {PutUserStartAction} from "src/store/users/actions";

import {useDispatch} from "react-redux";
import {formData} from "src/components/modals/user-modal/index";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {RadioChangeEvent} from "antd/es/radio";
import ModalContent from "src/components/modals/user-modal/modal";
import {LogsActionsStartAction} from "src/store/logs/actions";


export type EditUserModalProps = {
    toggleModal: any,
    type: string,
    data: {
        id: number
        password: string,
        personalData: {
            "email": string,
            "firstName": string,
            "image": string,
            "lastName": string,
            "middleName": string,
            "organization": string,
            "position": string
        },
        "roles": Array<'ROLE_USER' | 'ROLE_ADMIN' | 'ROLE_MODERATOR'>,
        "status": 'ACTIVE' | 'BLOCKED',
        "username": string
    }
}

const EditUserModal: React.FC<EditUserModalProps> = ({data, toggleModal, type}) => {
    const put = useDispatch();
    const [modalConfirm, setModalConfirm] = useState<any>({
        isOpened: false,
        accept: false,
        toggle: false,
        data: null,
        id: null
    })

    useEffect(() => {
        const id = data.id ? `${data.id}` : ''
        put(LogsActionsStartAction('OPEN_EDIT_USER', id))
        return () => {
            put(LogsActionsStartAction('CANCEL_EDIT_USER', id))
        }
    }, [])



    const initialData = {
        email: data.personalData.email ?? '',
        firstName: data.personalData.firstName ?? '',
        lastName: data.personalData.lastName ?? '',
        middleName: data.personalData.middleName ?? '',
        organization: data.personalData.organization ?? '',
        position: data.personalData.position ?? '',
        roles: data.roles ?? ['ROLE_USER'],
        status: data.status ?? 'ACTIVE',
        username: data.username ?? '',
        existingImage: data.personalData.image ?? '',
        image: []
    };

    const validationSchema = Yup.object({
        email: Yup.string(),
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        middleName: Yup.string(),
        organization: Yup.string(),
        position: Yup.string(),
        roles: Yup.array().required('Required'),
        status: Yup.string().required('Required'),
        username: Yup.string().required('Required'),
    })
    const userForm = useFormik({
        initialValues: initialData,
        onSubmit: onSubmitForm,
        validationSchema: validationSchema,
    });

    function onSubmitForm(values: formData, formikHelpers: FormikHelpers<formData>) {
        console.log('SubmitForm', values);
        // @ts-ignore
        if (modalConfirm.toggle) { // check in blocked user
            setModalConfirm({...modalConfirm, isOpened: true, data: values, id: data.id})
        } else {
            const id = data.id;
            resetForm();
            if (type === 'edit-one') put(PutUserStartAction(values, id, 'edit-one'))
            else put(PutUserStartAction(values, id, 'edit'))
        }
    }
    function resetForm() {
        userForm.resetForm()
        toggleModal()
    }
    const handleChangeCategory = (e: RadioChangeEvent) => {
        if (e.target.name === 'roles') userForm.setFieldValue('roles', [e.target.value])
        else  {
            if (e.target.value === 'BLOCKED') {
                setModalConfirm({...modalConfirm, toggle: true})
            }
            userForm.setFieldValue('status', e.target.value)
        }
    };


    if (modalConfirm.isOpened) {
        return (
            <ConfirmModal
                isOpened={modalConfirm.isOpened}
                onClose={() => {
                    setModalConfirm({...modalConfirm, isOpened: false})
                    put(LogsActionsStartAction('CANCEL_DEACTIVATE_USER', `${data.id}`))
                }}
                onSubmit={() => {
                    const {data} = modalConfirm;
                    setModalConfirm(({...modalConfirm, isOpened: false, accept: true}))
                    resetForm();
                    if (type === 'edit-one') put(PutUserStartAction(data, modalConfirm.id, 'edit-one'))
                    else put(PutUserStartAction(data, modalConfirm.id, 'edit'))
                }}
                message={'Внимание! Пользователь больше не сможет зайти в систему'}/>
        )
    } else {
        return <ModalContent
            toggleModal={toggleModal}
            userForm={userForm}
            handleChangeCategory={handleChangeCategory}
            type={type}
        />
    }

}

export default EditUserModal