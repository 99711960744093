import React, { ChangeEvent, FC, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import {
    Button,
    Checkbox,
    Input,
} from 'antd';

import { LeftArrowIcon } from 'src/assets/svg/left-arrow';

import { LogsActionsStartAction } from 'src/store/logs/actions';
import {
    GetRealizationLinksStartAction,
    ResetRealizationLinksFiltersAction,
    SetRealizationLinksFiltersAction,
} from 'src/store/realization/actions';

import {
    selectRealizationLinksFilters, selectRealizationResourcesData,
} from 'src/store/realization/selectors';

import styles from './styles.module.scss';

type TProps = {
    isSidebarOpened: boolean;
    closeSidebar: (event: SyntheticEvent) => void;
    projectId: number;
};

export const LinksSidebar: FC<TProps> = ({
    closeSidebar,
    projectId,
}) => {
    const put = useDispatch();

    const filters = useSelector(selectRealizationLinksFilters);
    const internetResources = useSelector(selectRealizationResourcesData);

    const handleReset = () => {
        put(ResetRealizationLinksFiltersAction());
        put(GetRealizationLinksStartAction(projectId));
    };

    const onChangeCheckbox = (resourceId: number) => (value: any) => {
        const isChecked = filters.filterData.internetResourceIds?.includes(resourceId);
        let resources: number[] = [ ...filters.filterData.internetResourceIds ];

        if (isChecked) {
            const index = resources.indexOf(resourceId);
            if (index !== -1) {resources.splice(index, 1)}
        } else {resources.push(resourceId)}

        put(SetRealizationLinksFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                internetResourceIds: resources,
            },
            page: 1,
        }));
        put(GetRealizationLinksStartAction(projectId));
    };

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        put(SetRealizationLinksFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                searchString: event.target.value,
            },
            page: 1,
        }));
        put(LogsActionsStartAction('USE_SEARCH_BAR'));
    };

    const onSearch = () => {
        put(GetRealizationLinksStartAction(projectId));
    };

    return (
        <div className={styles.sidebar}>
            <div className={styles.handle} onClick={closeSidebar}>
                <LeftArrowIcon className={styles.arrowIcon} />
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Описание ссылки/ссылка на контент</div>
                <div className={styles.content}>
                    <Input.Search
                        className={styles.search}
                        placeholder="Введите"
                        onChange={handleChangeSearch}
                        onSearch={onSearch}
                        value={filters.filterData.searchString}
                    />
                    <div style={{marginTop: 15}}>Найдено: {filters.totalCount}</div>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Интернет-ресурсы</div>
                <div className={cn(styles.content, styles.checkboxes)}>
                    { internetResources && internetResources.map(item => (
                        <Checkbox
                            key={item.id}
                            className={styles.checkbox}
                            checked={filters.filterData.internetResourceIds?.includes(item.id)}
                            onChange={onChangeCheckbox(item.id)}
                        >{item.name}</Checkbox>
                    ))}
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.content}>
                    <Button type="text" onClick={handleReset}>
                        Сбросить фильтры
                    </Button>
                </div>
            </div>
        </div>
    );
};
