import React from 'react';
import { TProjectContent } from 'src/types/projects';
import styles from './styles.module.scss';
import createMarkup from '../../../utils/createMarkup'
import {TVoteDocumentsListContent} from "src/types/vote";

export const NameCell = (name: string, data: TProjectContent | TVoteDocumentsListContent) => (
    <div className={styles.cell}>
        <span className={styles.name} dangerouslySetInnerHTML={createMarkup(name)}/>
        {'owner' in data &&  data?.owner && (
            <span className={styles.owner} dangerouslySetInnerHTML={createMarkup(data.owner)}/>
        )}
    </div>
);
