import {
    DeleteUserErrorAction,
    DeleteUserStartAction,
    DeleteUserSuccessAction,
    GetUsersStartAction,
} from 'src/store/users/actions';
import { put } from 'redux-saga/effects';
import axios from 'axios';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* deleteUserWorker({userId}: ReturnType<typeof DeleteUserStartAction>) {
    try {
        yield axios({
            method: 'DELETE',
            url: `/users/${userId}`,
            headers: {},
        });

        yield put(DeleteUserSuccessAction());
        yield put(GetUsersStartAction(null));
        yield put(LogsActionsStartAction('DELETE_USER'))
    } catch (error) {
        yield put(DeleteUserErrorAction(error));
    }
}