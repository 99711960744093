import React, {FC, SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {Tooltip} from 'antd';
import {ReactComponent as Empty} from './empty.svg'

import styles from './styles.module.scss';
import {useDispatch} from "react-redux";
import {LogsActionsStartAction} from "src/store/logs/actions";
import ButtonMore from "src/components/button-more";

type Props = {
    data: {
        id: string,
        name: string,
        domainLink: string,
        image?: string
    }[],
    limit?: number
}
export const InternetResources: FC<Props> = ({data, limit = 10}) => {
    const put = useDispatch();
    const [showAll, setShowAll] = useState(false);
    const handleSocialLink = useCallback((link?: string) => (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let url = link;
        if (!url) return;
        if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
        }
        window.open(url, '_blank');
        put(LogsActionsStartAction('VIEW_INTERNET_RESOURCE'))
    }, []);

    const list = useMemo(() => {
        if (showAll) {
            return data;
        } else {
            if (!showAll && data?.length > limit) {
                const newList: Props['data'] = [...data];
                return newList.slice(0, limit);
            } else {
                return data;
            }
        }
    }, [limit, showAll]);

    return (
        <div className={styles.container}>
            {list.map((el) => {
                return (
                    <Tooltip key={el.id} placement="topLeft" title={el.name}>
                        <div className={styles.item}>
                            {el.image ? (
                                <img
                                    src={el.image ? el.image: '/images/no-icon.png'}
                                    alt={el.name}
                                    className={styles.icon}
                                    onClick={handleSocialLink(el.domainLink)}
                                />
                            ): <Empty className={styles.icon}/>}
                            <span>{el.name}</span>
                        </div>
                    </Tooltip>
                )
            })}
            {data?.length > limit && (
                <ButtonMore showAll={showAll} handler={() => {
                    setShowAll(!showAll)
                }} />
            )}

        </div>
    )
};