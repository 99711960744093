import { InferValueTypes } from 'src/types/common';
import * as actions from './actions';
import {NavigationActionTypes} from "src/store/navigation/action-types";


type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;


export type TNavigationState = {
    title: string | null
    path: string | null
};

export const initialState: TNavigationState = {
    title: 'Отбор проектов',
    path: null
};

export default function reducer(
    state: TNavigationState = initialState,
    action: ActionTypes,
): TNavigationState {
    switch (action.type) {
        case NavigationActionTypes.MENU_SET:
            return {
                ...state,
                title: action.title
            };

        default:
            return state;
    }
}
