import React, { FC } from 'react';
import SlideCountdown from './slide-countdown';
import { ErrorBoundary } from 'src/components/error-boundary';

import styles from './styles.module.scss';

type TProps = {
    endTime?: string;
}

export const CountdownComponent: FC<TProps> = ({ endTime }) => {


    return (
        <>
            { endTime && endTime.length > 0 && (
                <div className={styles.container}>
                    <ErrorBoundary>
                        <SlideCountdown
                            weeks={true}
                            deadline={endTime} />
                    </ErrorBoundary>
                </div>
            ) }
        </>
    );
}
