import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getSessionsState = (state: TApplicationState) => state.sessions;

export const selectSessions = createSelector(
    getSessionsState,
    (state) => ((state.data && state.data.content) ? state.data.content : []),
);
export const selectSessionsFilters = createSelector(
    getSessionsState,
    (state) => state.filters,
);
export const selectSessionsStatus = createSelector(
    getSessionsState,
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        error: state.error,
    }),
);

// temporary solution
// refactoring required
export const selectAllSessions = createSelector(
    getSessionsState,
    (state) => (
        (state.allSessions && state.allSessions.content) ? state.allSessions.content : []
    ),
);
