import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from 'axios';
import moment from 'moment';

import { DateAndTimeServerFormat } from 'src/constatnts/format';

import {
    EditVotingAction,
} from 'src/store/voting-editor/actions';

import { mainModuleRoutes } from 'src/routing/projects-module';

export function* editorEditVotingWorker(
    { voting, id }: ReturnType<typeof EditVotingAction>,
) {
    try {
        const data = {
            ...voting,
            familiarizationDateTime: moment(voting.familiarizationDateTime).format(DateAndTimeServerFormat),
            startDateTime: moment(voting.startDateTime).format(DateAndTimeServerFormat),
            endDateTime: moment(voting.endDateTime).format(DateAndTimeServerFormat)
        };

        yield axios({
            method: 'PUT',
            url: `/voting/${id}`,
            headers: {},
            data,
        });

        yield put(push(mainModuleRoutes['all-votes-list'].path));
    } catch (_error) {}
}
