import { InferValueTypes } from 'src/types/common';
import { VotingEditorActionTypes } from 'src/store/voting-editor/action-types';
import * as actions from '../actions';
import { TVotingBlock } from 'src/types/vote';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TVotingEditorBlocksState = {
    data: TVotingBlock[];
};

export const initialState: TVotingEditorBlocksState = {
    data: [],
};

export default function reducer(
    state: TVotingEditorBlocksState = initialState,
    action: ActionTypes,
): TVotingEditorBlocksState {
    switch (action.type) {
        case VotingEditorActionTypes.SET_DOCUMENTS:
            return {
                ...state,
                data: action.blocks,
            };
        default:
            return state;
    }
}
