import {
    takeLatest,
} from 'redux-saga/effects';

import { VotingEditorActionTypes } from 'src/store/voting-editor/action-types';

import { getRegentsWorker } from 'src/workers/voting-editor/get-regents';
import { editorGetDetailedProjectWorker } from 'src/workers/voting-editor/get-detailed-project';
import { editorGetProjectsWorker } from 'src/workers/voting-editor/get-projects';
import { editorAddBlockWorker } from 'src/workers/voting-editor/add-block';
import { editorEditBlockWorker } from 'src/workers/voting-editor/edit-block';
import { editorAddVotingWorker } from 'src/workers/voting-editor/add-voting';
import { editorEditVotingWorker } from 'src/workers/voting-editor/edit-voting';
import { editorDeleteVotingWorker } from 'src/workers/voting-editor/delete-voting';

export default function* votingEditorSaga() {
    yield takeLatest(VotingEditorActionTypes.GET_REGENTS_START, getRegentsWorker);
    yield takeLatest(VotingEditorActionTypes.GET_DETAILED_PROJECT_START, editorGetDetailedProjectWorker);
    yield takeLatest(VotingEditorActionTypes.GET_PROJECTS_START, editorGetProjectsWorker);
    yield takeLatest(VotingEditorActionTypes.CREATE_DOCUMENT_START, editorAddBlockWorker);
    yield takeLatest(VotingEditorActionTypes.EDIT_DOCUMENT_START, editorEditBlockWorker);
    yield takeLatest(VotingEditorActionTypes.ADD_VOTING, editorAddVotingWorker);
    yield takeLatest(VotingEditorActionTypes.EDIT_VOTING, editorEditVotingWorker);
    yield takeLatest(VotingEditorActionTypes.DELETE_VOTING, editorDeleteVotingWorker);
}
