import React from 'react';


import styles from './styles.module.scss';

export const UserDataCell = (name: string, data: any) => {
    const {firstName, lastName} = data.personalData ? data.personalData : {firstName: '', lastName: ''}
    return (
        <div className={styles.cell}>
            <span className={styles.name}>{firstName ?? firstName} {lastName ?? lastName}</span>
            <span className={styles.owner}>{data.username}</span>
        </div>
    )
}
