import React, {FC, ReactNode} from 'react';
import {Route, RouteProps,} from 'react-router-dom';

type TProps = {
    children: ReactNode;
} & Partial<RouteProps>;

export const PrivateRoute: FC<TProps> = ({ children, ...rest }) => {
    return (
        <Route {...rest} children={(props: any) => children } />
    );
};
