import React, {FC, SyntheticEvent, useEffect, useRef, useState,} from 'react';
import {Modal} from 'react-bootstrap';

import styles from './styles.module.scss';
import PDF from "src/components/pdf";

type TProps = {
    fileURL: any;
    name?: string;
    isOpened: boolean;
    onClose: any;
}

export const PDFModal: FC<TProps> = ({
    fileURL,
    name,
    isOpened,
    onClose,
}) => {
    const [print, setPrint] = useState(false)
    const body = useRef(null);
    const head = useRef(null);

    const handleRightClick = (e: SyntheticEvent) => {
        e.preventDefault();
    }
    const handleButtons = (e: KeyboardEvent) => {

        if(e.ctrlKey && (
            e.key.toString() === "p"
            || +e.charCode === 16
            || +e.charCode === 112
            || +e.keyCode === 80
        )){
            e.cancelBubble = true;
            e.preventDefault();

            e.stopImmediatePropagation();
        }
    }
    const handlePrint = () => {
        setPrint(prevState => !prevState)
    }

    useEffect(() => {
        if (isOpened && body && body.current) {
            // @ts-ignore
            body.current.addEventListener('contextmenu', handleRightClick);
            // @ts-ignore
            head.current.addEventListener('contextmenu', handleRightClick);

            window.addEventListener('keydown', handleButtons);
            window.addEventListener('beforeprint', handlePrint);
            window.addEventListener('afterprint', handlePrint);
            return () => {
                try {
                    // @ts-ignore
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    reader.current.removeEventListener('contextmenu', handleRightClick);
                    // @ts-ignore
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    head.current.removeEventListener('contextmenu', handleRightClick);
                    window.removeEventListener('keydown', handleButtons)
                    window.removeEventListener('beforeprint', handlePrint);
                    window.removeEventListener('afterprint', handlePrint);
                } catch (_e) {}
            }
        }
    }, [isOpened, body]);


    if (print) return null
    return (
        <Modal
            show={isOpened}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={styles.dialog}
        >
            <Modal.Header
                ref={head}
                closeButton
                className={styles.header}
            >
                <Modal.Title className={styles.title}>{name || ''}</Modal.Title>
            </Modal.Header>

            <Modal.Body className={styles.body} ref={body}>
                { fileURL && (
                    <PDF file={fileURL} onClose={onClose}/>
                ) }
            </Modal.Body>
        </Modal>
    );
};
