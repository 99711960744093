import React, {FC} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import styles from "../modals/projects-modal/styles.module.scss";
import {DatePicker, Form} from "antd";
import {DateAndTimeFormat, DateFormat} from "src/constatnts/format";
import moment from "moment";

type DatepickerComponentProps = {
    form: any,
    label: string,
    text: string,
    showTime?: boolean,
    format?: typeof DateFormat | typeof DateAndTimeFormat
}
const DatepickerComponent: FC<DatepickerComponentProps> = ({
    form,
    label,
    text,
    showTime = false,
    format = DateFormat
}) => {
    const handleChangeDate = (name: string) => (value: moment.Moment | null) => {
        form.setFieldValue(name, value ? value : '');
    };
    // @ts-ignore
    const handleBlurDate = (event: FocusEvent<HTMLInputElement>) => {
        // form.setFieldValue(name, value ? value : '');
    };
    const fieldHasAnError = (fieldName: string): boolean => {
        return Boolean((form.errors[fieldName]) ||
            (form.errors[fieldName] && form.touched[fieldName]));
    };

    return (
        <Form.Item
            className={styles.formItem}
            validateStatus={ fieldHasAnError('startDateTime') ? 'error' : '' }
        >
            <label htmlFor={label} className={styles.label}>
                {text}
            </label>
            <DatePicker
                className={styles.input}
                name={label}
                placeholder=""
                format={format}
                showTime={showTime}
                value={form.values[label]}
                onChange={handleChangeDate(label)}
                onBlur={handleBlurDate}
            />
            { fieldHasAnError(label) && (
                <div className={styles.dateError}>
                    { form.errors[label] || 'Ошибка' }
                </div>
            ) }
        </Form.Item>
    )
};
export default DatepickerComponent;