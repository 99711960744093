import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getCategoriesState = (state: TApplicationState) => state.categories;

export const selectCategories = createSelector(
    getCategoriesState,
    (state) => state.data,
);
export const selectCategoriesForYears = (year?: number | null) => (
    createSelector(
        getCategoriesState,
        (state) => {
            return state.data.filter((el) => {
                if (year !== 2020 && el?.code === 'INTERNET_MEDIA') {
                    return null
                } else if (year === 2020 && el?.code === 'MULTIFORMAT') {
                    return null
                } else return el
            })
        },
    ));
export const selectCategoriesStatus = createSelector(
    getCategoriesState,
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        error: state.error,
    }),
);
