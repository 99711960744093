import { InferValueTypes } from 'src/types/common';
import * as actions from './actions';
import {LogsActionTypes} from "src/store/logs/action-types";
import LogsData from "src/utils/logs-data";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TActionsTypes = {
    code: string,
    id: number,
    name: string
}

export type TLogsState = {
    ip: string;
    actions: any[];
    actionsTypes: TActionsTypes[] | null;
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TLogsState = {
    ip: '',
    actions: [],
    actionsTypes: null,
    isLoading: false,
    isLoaded: false,
    error: null
};

export default function reducer(
    state: TLogsState = initialState,
    action: ActionTypes,
): TLogsState {
    switch (action.type) {
        case LogsActionTypes.LOGS_LOGIN_START:
            return {
                ...state,
                isLoading: true
            };
        case LogsActionTypes.LOGS_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                ip: action.ip
            };
        case LogsActionTypes.LOGS_LOGIN_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.error
            };
        case LogsActionTypes.LOGS_ACTION_START:
            return {
                ...state,
                isLoading: false,
                actions: [...state.actions, LogsData(action.typeCode, state.ip, action.entityId, action.actionInformation)]
            }
        case LogsActionTypes.LOGS_ACTION_SEND:
            return {
                ...state,
                isLoading: true
            }
        case LogsActionTypes.LOGS_ACTION_CLEAN: {
            return {
                ...state,
                actions: [],
                isLoading: false
            }
        }
        case LogsActionTypes.LOGS_ACTION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            }
        }
        case LogsActionTypes.LOGS_ACTION_ERROR: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.error
            }
        }
        case LogsActionTypes.LOGS_LOGOUT_START:
            return {
                ...state,
                isLoading: true,
            };
        case LogsActionTypes.LOGS_LOGOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                ip: ''
            };
        case LogsActionTypes.LOGS_LOGOUT_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.error
            };
        case LogsActionTypes.GET_ACTIONS_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            };
        case LogsActionTypes.GET_ACTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                actionsTypes: action.actions
            };
        case LogsActionTypes.GET_ACTIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.error
            };
        case LogsActionTypes.UPDATE_ACTIONS_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            };
        case LogsActionTypes.UPDATE_ACTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
            };
        case LogsActionTypes.UPDATE_ACTIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.error
            };
        default:
            return state;
    }
}
