import React from 'react';


import styles from './styles.module.scss';

export const UserLoginNameCell = (name: string, data: any) => {
    return (
        <div className={styles.cell}>
            <span className={styles.name}>{!!data.userFullName ? `${data.userFullName} / ` : null}{data.username}</span>
        </div>
    )
}
