import React from 'react';

import styles from './styles.module.scss';

export const ActionCell = (name: string, data: any) => {
    const id = (`id: ${data.entityId} `);
    const info = (`name: ${data.actionInformation}`)
    return (
        <div className={styles.cell}>
            <span className={styles.name}>{name}</span>
            <span className={styles.owner}>{data.entityId && id}{data.actionInformation && info}</span>
        </div>
    )
}
