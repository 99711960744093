import {
    takeLatest,
} from 'redux-saga/effects';

import { VotingActionTypes } from 'src/store/voting/action-types';

import { getProjectsVotingIdWorker } from 'src/workers/voting/get-projects-voting-id';
import { getDocumentsVotingIdWorker } from 'src/workers/voting/get-documents-voting-id';
import { getVotingTypesWorker } from 'src/workers/voting/get-voting-types';
import { getVotingOptionsWorker } from 'src/workers/voting/get-voting-options';
import { getVotingProjectsWorker } from 'src/workers/voting/get-voting-projects';
import { getDocumentListWorker } from 'src/workers/voting/get-document-list';
import { getVotingDetailedProjectWorker } from 'src/workers/voting/get-voting-detailed-project';
import { getVotingDetailedDocumentWorker } from 'src/workers/voting/get-voting-detailed-document';
import { getVotingReportProjectWorker } from "src/workers/voting/get-voting-report-project";
import {getVotingReportDocumentWorker} from "src/workers/voting/get-voting-report-document";
import { voteWorker } from 'src/workers/voting/vote';
import { addVotingCommentWorker } from 'src/workers/voting/add-voting-comment';

// ADMIN
import { getAllVotesListAdminWorker } from 'src/workers/voting/admin/get-all-votes-list';
import { getAllDocumentsListAdminWorker } from 'src/workers/voting/admin/get-all-documents-list';
import { activateVotingWorker } from 'src/workers/voting/admin/activate-voting';
import { getAllProjectsVoteSummaryWorker } from 'src/workers/voting/admin/get-all-projects-vote-summary';
import { getAllDocumentsVoteSummaryWorker } from 'src/workers/voting/admin/get-all-documents-vote-summary';
import { getAllProjectsReportWorker } from 'src/workers/voting/admin/get-all-projects-report';
import { getAllDocumentsReportWorker } from 'src/workers/voting/admin/get-all-documents-report';
import { editVotingCommentWorker } from 'src/workers/voting/edit-voting-comment';
import { deleteVotingCommentWorker } from 'src/workers/voting/delete-voting-comment';
import { getDocumentReportWorker } from 'src/workers/voting/admin/get-document-report';

export default function* votingSaga() {
    yield takeLatest(VotingActionTypes.GET_PROJECTS_VOTING_ID_TRIGGER, getProjectsVotingIdWorker);
    yield takeLatest(VotingActionTypes.GET_DOCUMENTS_VOTING_ID_TRIGGER, getDocumentsVotingIdWorker);
    yield takeLatest(VotingActionTypes.GET_VOTING_TYPES_TRIGGER, getVotingTypesWorker);
    yield takeLatest(VotingActionTypes.GET_VOTING_OPTIONS_START, getVotingOptionsWorker);
    yield takeLatest(VotingActionTypes.VOTE_START, voteWorker);
    yield takeLatest(VotingActionTypes.GET_VOTING_PROJECTS_START, getVotingProjectsWorker);
    yield takeLatest(VotingActionTypes.GET_DOCUMENT_LIST_START, getDocumentListWorker);
    yield takeLatest(VotingActionTypes.GET_DETAILED_PROJECT_START, getVotingDetailedProjectWorker);
    yield takeLatest(VotingActionTypes.GET_DETAILED_DOCUMENT_START, getVotingDetailedDocumentWorker);
    yield takeLatest(VotingActionTypes.GET_REPORT_PROJECT_START, getVotingReportProjectWorker);
    yield takeLatest(VotingActionTypes.GET_REPORT_DOCUMENT_START, getVotingReportDocumentWorker);
    yield takeLatest(VotingActionTypes.ADD_VOTING_COMMENT_START, addVotingCommentWorker);
    yield takeLatest(VotingActionTypes.EDIT_VOTING_COMMENT_START, editVotingCommentWorker);
    yield takeLatest(VotingActionTypes.DELETE_VOTING_COMMENT_START, deleteVotingCommentWorker);

    // ADMIN
    yield takeLatest(VotingActionTypes.GET_ALL_VOTES_LIST_START, getAllVotesListAdminWorker);
    yield takeLatest(VotingActionTypes.GET_ALL_DOCUMENTS_LIST_START, getAllDocumentsListAdminWorker);
    yield takeLatest(VotingActionTypes.ACTIVATE_VOTING, activateVotingWorker);
    yield takeLatest(VotingActionTypes.GET_ALL_PROJECTS_VOTE_SUMMARY_START, getAllProjectsVoteSummaryWorker);
    yield takeLatest(VotingActionTypes.GET_ALL_DOCUMENTS_VOTE_SUMMARY_START, getAllDocumentsVoteSummaryWorker);
    yield takeLatest(VotingActionTypes.GET_ALL_PROJECTS_REPORT_START, getAllProjectsReportWorker);
    yield takeLatest(VotingActionTypes.GET_ALL_DOCUMENTS_REPORT_START, getAllDocumentsReportWorker);
    yield takeLatest(VotingActionTypes.GET_DOCUMENT_REPORT_START, getDocumentReportWorker);
}
