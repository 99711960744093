import React from 'react';
import cn from 'classnames';

import styles from 'src/components/data-table/status-cell/styles.module.scss';
import {TooltipComponent} from "src/components/tooltip";

export const statusCell = (status?: boolean | string) => {
    const active: boolean = (typeof status === 'boolean' && status)
        || (typeof status === 'string' && status === 'VOTED');

    const message = status ? 'Проект находится на реализации' : 'Проект находится на отборе'

    return (
        <div className={styles.cell}>
            <TooltipComponent
                isActive={true}
                message={message}
            >
                <div className={cn(styles.bullet, { [styles.active]: active })} />
            </TooltipComponent>
        </div>
    );
}
