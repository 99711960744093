import { combineReducers } from 'redux';

import detailedProjectReducer from './detailed-project';
import regentsReducer from './regents';
import projectsReducer from './projects';
import blocksReducer from './blocks';

const reducer = combineReducers({
    detailedProject: detailedProjectReducer,
    regents: regentsReducer,
    projects: projectsReducer,
    blocks: blocksReducer,
});

export type TVotingEditorState = ReturnType<typeof reducer>;

export default reducer;
