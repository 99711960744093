import React from 'react';
import {ReactComponent as Icon} from './icon.svg'
import {ReactComponent as Minimize} from './minimize.svg'
import styles from './styles.module.scss';

const ButtonMore = ({showAll, handler}: {showAll: boolean, handler: () => void}) => (
    <div onClick={handler} className={styles.button}>
        {showAll ? 'Свернуть' : 'Развернуть'}
        {showAll && <Minimize className={styles.icon}/>}
        {!showAll &&<Icon className={styles.icon}/>}

    </div>
);

export default ButtonMore;