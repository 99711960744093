import {
    put,
    select,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    TReportingItem,
    TReportingListResponse,
} from 'src/types/reporting';

import {
    GetReportingListTriggerAction,
    GetReportingListStartAction,
    GetReportingListSuccessAction,
    GetReportingListErrorAction, SetReportingFiltersAction, GetReportStartAction,

} from 'src/store/reporting/actions';
import { selectReportingListFilters } from 'src/store/reporting/selectors';

export function* getReportingProjectsListWorker(_?: ReturnType<typeof GetReportingListTriggerAction>) {
    try {
        yield put(GetReportingListStartAction());
        const { filterData, ...pagination } = yield select(selectReportingListFilters);

        const { data: responseReportingItem }: AxiosResponse<TReportingItem> = yield axios({
            method: 'GET',
            url: `/reporting`,
            headers: {},
            params: {
                year: +filterData.year,
                quarter: +filterData.quarter,
            },
        })

        const { data: responseReportingContentData }: AxiosResponse<TReportingListResponse> = yield axios({
            method: 'POST',
            url: `/reporting/${responseReportingItem.id}/reports/search`,
            headers: {},
            data: {
                filterData: {
                    reportTypeIds: filterData.reportTypeIds,
                    searchString: filterData.searchString,
                },
                ...pagination,
                page: pagination.page - 1,
            }
        });

        const { content, ...newPagination } = responseReportingContentData;

        yield put(GetReportingListSuccessAction(content));
        yield put(SetReportingFiltersAction({
            filterData,
            ...newPagination,
            page: newPagination.page + 1,
        }));

        yield put(GetReportStartAction(responseReportingItem))

    } catch (error) {
        yield put(GetReportingListErrorAction(error));
    }
}
