import {put, select} from 'redux-saga/effects';
import axios from 'axios';

import {
    AddRealizationProjectContentErrorAction,
    AddRealizationProjectContentStartAction,
    AddRealizationProjectContentSuccessAction,
    GetRealizationProjectContentStartAction,
} from "src/store/realization/actions";
import {selectUserData} from "src/store/auth/selectors";

export function* addProjectContentWorker(
    {contentId, project}: ReturnType<typeof AddRealizationProjectContentStartAction>,
) {
    try {
        const {user} = yield select(selectUserData)
        const formData = new FormData();
        // @ts-ignore
        const socketId = (project.file.length !== 0) ? user.username + '-content' : null

        // @ts-ignore
        formData.append('links', JSON.stringify(project.link));
        // @ts-ignore
        project.file.forEach((file) => {
            formData.append('files', file.originFileObj as Blob);
        });
        // @ts-ignore
        if (project.file.length === 0) {
            formData.append('files', new Blob([]),);
        }

        yield axios({
            method: 'POST',
            url: `/impl/projects/content/${contentId}/items`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            params: {
                socketId: socketId
            }
        });
        yield put(AddRealizationProjectContentSuccessAction());
        yield put(GetRealizationProjectContentStartAction(contentId));
    } catch (error) {
        yield put(AddRealizationProjectContentErrorAction(error));
    }
}
