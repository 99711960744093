import { all, call, put, } from 'redux-saga/effects';

import {
    GetReportingListTriggerAction,
    GetReportingTypesTriggerAction,
    GetReportingYearsTriggerAction,
    TriggerGetReportingFiltersAction,
} from 'src/store/reporting/actions';

import { getReportingTypesWorker } from 'src/workers/reporting/getTypes';
import { getReportingYearsWorker } from 'src/workers/reporting/getYears';

export function* getReportingFiltersWorker(_: ReturnType<typeof TriggerGetReportingFiltersAction>) {
    try {
        yield all([
            call(getReportingTypesWorker, GetReportingTypesTriggerAction()),
            call(getReportingYearsWorker, GetReportingYearsTriggerAction({})),
        ]);

        yield put(GetReportingListTriggerAction());
    } catch (error) {

    }
}
