import {
    takeLatest,
} from 'redux-saga/effects';

import {InternetResourcesActionTypes} from "src/store/internet-resources/action-types";


import { internetResourcesWorker } from 'src/workers/internet-resource/internet-resources-worker';
import {getOneResourcesWorker} from "src/workers/internet-resource/get-one-worker";
import {createResourcesWorker} from "src/workers/internet-resource/create-worker";
import {updateResourcesWorker} from "src/workers/internet-resource/update-worker";
import {deleteResourcesWorker} from "src/workers/internet-resource/delete-worker";

export default function* internetResourcesSaga() {
    yield takeLatest(InternetResourcesActionTypes.GET_INTERNET_RESOURCES_START, internetResourcesWorker)
    yield takeLatest(InternetResourcesActionTypes.GET_ONE_INTERNET_RESOURCES_START, getOneResourcesWorker)
    yield takeLatest(InternetResourcesActionTypes.CREATE_INTERNET_RESOURCES_START, createResourcesWorker)
    yield takeLatest(InternetResourcesActionTypes.UPDATE_INTERNET_RESOURCES_START, updateResourcesWorker)
    yield takeLatest(InternetResourcesActionTypes.DELETE_INTERNET_RESOURCES_START, deleteResourcesWorker)
}