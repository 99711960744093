import React, { SyntheticEvent } from 'react';
import { Switch } from 'antd';

import styles from './styles.module.scss';

export const ActivateCell = (
    onChange: Function,
) => (activated: boolean, rowData: any) => {
    const onClickCell = (e: SyntheticEvent) => {
        e.stopPropagation();
    };

    const onClick = (e: any) => {
        onChange(activated, rowData);
    };

    return (
        <div className={styles.cell} onClick={onClickCell}>
            <label>
                <Switch
                    size="small"
                    className={styles.switch}
                    checked={activated}
                    onClick={onClick}
                />
                Активировать
            </label>
        </div>
    );
};
