import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetDocumentListStartAction,
    GetDocumentListSuccessAction,
    GetDocumentListErrorAction,
} from 'src/store/voting/actions';
import {
    selectDocumentsVoteSummaryData,
    selectVoteDocumentsData
} from 'src/store/voting/selectors';
import { TVoteDocumentsListContent } from 'src/types/vote';
import { TPagination, TSorting } from 'src/types/pageable-request';

export function* getDocumentListWorker(_action: ReturnType<typeof GetDocumentListStartAction>) {
    try {
        const voteSummaryData = yield select(selectDocumentsVoteSummaryData);
        const projectsData = yield select(selectVoteDocumentsData);

        if (!voteSummaryData.id) {
            throw new Error('Voting id is absent');
        }

        let { data: respData }: AxiosResponse<{
            content: TVoteDocumentsListContent[];
        } & TPagination & TSorting> = yield axios({
            method: 'POST',
            url: `/user/voting/${voteSummaryData.id}/blocks/search`,
            headers: {},
            data: {
                filterData: {
                    categoryIds: [],
                    maxScore: 10,
                    minScore: 0,
                    searchString: '',
                },
                page: projectsData.page - 1,
                pageSize: projectsData.pageSize,
                totalCount: projectsData.totalCount,
                sort: projectsData.sort,
            },
        });

        if (respData) {
            respData.page = respData.page + 1;
        }

        yield put(GetDocumentListSuccessAction(respData));
    } catch (error) {
        console.log('getDocumentListWorker error', error);
        yield put(GetDocumentListErrorAction(error));
    }
}
