import { VotingEditorActionTypes } from 'src/store/voting-editor/action-types';
import { TRegent } from 'src/types/user';
import { TEditVotingRequestData, TVotingBlock, TVotingParams } from 'src/types/vote';
import { TProjectContent } from 'src/types/projects';

export const GetEditorDetailedProjectStartAction = (votingId: string) => ({
    type: VotingEditorActionTypes.GET_DETAILED_PROJECT_START,
    votingId,
});
export const GetEditorDetailedProjectSuccessAction = (data: TEditVotingRequestData) => ({
    type: VotingEditorActionTypes.GET_DETAILED_PROJECT_SUCCESS,
    data,
});
export const GetEditorDetailedProjectErrorAction = (error: any) => ({
    type: VotingEditorActionTypes.GET_DETAILED_PROJECT_ERROR,
    error,
});

export const GetEditorRegentsStartAction = () => ({
    type: VotingEditorActionTypes.GET_REGENTS_START,
});
export const GetEditorRegentsSuccessAction = (data: TRegent[]) => ({
    type: VotingEditorActionTypes.GET_REGENTS_SUCCESS,
    data,
});
export const GetEditorRegentsErrorAction = (error: any) => ({
    type: VotingEditorActionTypes.GET_REGENTS_ERROR,
    error,
});

export const GetEditorProjectsStartAction = (payload: {
    votingId: string,
    params: {
        // categoryIds: number[];
        // maxScore: number;
        // minScore: number;
        // searchString: string;
        sessionName: string;
        year: string;
    },
    type: 'projects' | 'documents',
}, sessionId: string) => ({
    type: VotingEditorActionTypes.GET_PROJECTS_START,
    sessionId,
    payload,
});
export const GetEditorProjectsSuccessAction = (data: Required<TProjectContent>[]) => ({
    type: VotingEditorActionTypes.GET_PROJECTS_SUCCESS,
    data,
});
export const GetEditorProjectsErrorAction = (error: any) => ({
    type: VotingEditorActionTypes.GET_PROJECTS_ERROR,
    error,
});

export const AddVotingAction = (voting: TVotingParams) => ({
    type: VotingEditorActionTypes.ADD_VOTING,
    voting,
});
export const EditVotingAction = (voting: TVotingParams, id: string) => ({
    type: VotingEditorActionTypes.EDIT_VOTING,
    voting,
    id,
});
export const DeleteVotingAction = (id: number) => ({
    type: VotingEditorActionTypes.DELETE_VOTING,
    id
});

export const SetDocumentAction = (blocks: TVotingBlock[]) => ({
    type: VotingEditorActionTypes.SET_DOCUMENTS,
    blocks,
});

export const CreateDocumentStartAction = (block: Partial<TVotingBlock>) => ({
    type: VotingEditorActionTypes.CREATE_DOCUMENT_START,
    block,
});
export const CreateDocumentSuccessAction = (block: TVotingBlock) => ({
    type: VotingEditorActionTypes.CREATE_DOCUMENT_SUCCESS,
    block,
});
export const CreateDocumentErrorAction = (error: any) => ({
    type: VotingEditorActionTypes.CREATE_DOCUMENT_ERROR,
    error,
});

export const EditDocumentStartAction = (block: Partial<TVotingBlock>, newFiles: any[]) => ({
    type: VotingEditorActionTypes.EDIT_DOCUMENT_START,
    block,
    newFiles,
});
export const EditDocumentSuccessAction = (block: TVotingBlock) => ({
    type: VotingEditorActionTypes.EDIT_DOCUMENT_SUCCESS,
    block,
});
export const EditDocumentErrorAction = (error: any) => ({
    type: VotingEditorActionTypes.EDIT_DOCUMENT_ERROR,
    error,
});
