import {
    GetUserActionsStartAction,
    GetUserActionsErrorAction,
    GetUserActionsSuccessAction,
} from "src/store/users/actions";
import { put } from 'redux-saga/effects';
import axios, {AxiosResponse} from "axios";
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* getUserActionsWorker({userId, params}: ReturnType<typeof GetUserActionsStartAction>) {
    try {
        yield put(LogsActionsStartAction('SORT_USER_ACTIONS'));
        const sort = params === null ? {
            direction: "DESC",
            field: "dateTime"
        } : params.newSorter.sort;
        const page = params === null ? 0 : params.newPagination.page - 1;
        const pageSize = params === null ? 20 : params.newPageSize.pageSize;
        const { data: userActions }: AxiosResponse<any> = yield axios({
            method: 'POST',
            url: `/users/${userId}/actions`,
            headers: {},
            data: {
                page: page,
                pageSize: pageSize,
                sort: sort
            }
        });
        yield put(GetUserActionsSuccessAction(userActions))

    } catch (error) {
        yield put(GetUserActionsErrorAction(error))
    }
}