import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetSessionsStartAction,
    GetSessionsSuccessAction,
    GetSessionsErrorAction,
} from 'src/store/sesssions/actions';

import { TSessionRequest, TSessionResponse } from 'src/types/sessions';
import { selectSessionsFilters } from 'src/store/sesssions/selectors';

export function* sessionsWorker(_action: ReturnType<typeof GetSessionsStartAction>) {
    try {
        const { filterData, ...filters } = yield select(selectSessionsFilters);
        const requestData: TSessionRequest = {
            ...filters,
            page: filters.page - 1,
        };

        const { data }: AxiosResponse<TSessionResponse> = yield axios({
            method: 'POST',
            url: '/sessions/search',
            headers: {},
            data: requestData
        });

        yield put(GetSessionsSuccessAction(data));
    } catch (error) {
        yield put(GetSessionsErrorAction(error));
    }
}
