import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TVotingType } from 'src/types/vote';

import {
    GetVotingTypesTriggerAction,
    GetVotingTypesStartAction,
    GetVotingTypesSuccessAction,
    GetVotingTypesErrorAction,
} from 'src/store/voting/actions';

export function* getVotingTypesWorker(_action?: ReturnType<typeof GetVotingTypesTriggerAction>) {
    try {
        yield put(GetVotingTypesStartAction());
        const { data }: AxiosResponse<TVotingType[]> = yield axios({
            method: 'GET',
            url: `/voting/types`,
            headers: {},
        });

        yield put(GetVotingTypesSuccessAction(data));
    } catch (error) {
        console.log('getVotingTypesWorker error', error);
        yield put(GetVotingTypesErrorAction(error));
    }
}
