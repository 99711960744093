export type KeyInfoType = {
    [key: string]: string
}
// const LogsInfo: KeyInfoType = {
//     'ENDPOINT_ACTION': 'Запрос эндпроинта',
//     'LOGIN': 'Авторизация',
//     'VIEW_PROFILE': 'Просмотр информации профиля',
//     'LOGOUT': 'Выход из системы',
//     'OPEN_SELECT_MODULE': 'Переход в модуль отбора проектов',
//     'OPEN_REALISE_MODULE': 'Переход в модуль реализации проектов',
//     'OPEN_REPORT_MODULE': 'Переход в модуль очетности',
//     'OPEN_VOTING_MODULE': 'Переход в модуль голосования',
//     'VIEW_REPORTING_PRESENTATION': 'Просмотр отчетной презентации проекта',
//     'OPEN_SEARCH_BAR': 'Открытие расширенного поиска',
//     'SEARCH_PROJECT': 'Использование расширенного поиска',
//     'USE_SEARCH_BAR': 'Использование поиска',
//     'SORT_PROJECT': 'Использование сортировки заявок',
//     'VIEW_PROJECT': 'Просмотр карточки проекта',
//     'VIEW_PROJECT_ATTACHMENTS': 'Просмотр вложений проекта',
//     'VIEW_VIDEO_PRESENTATION': 'Просмотр видеопрезентации',
//     'VIEW_INTERNET_RESOURCE': 'Просмотр интернет-ресурса',
//     'OPEN_PROJECT_VOTING_ARCHIVE': 'Переход в архив голосования по заявкам',
//     'OPEN_DOCUMENT_VOTING_ARCHIVE': 'Переход в архив голосования по документам',
//     'OPEN_PROJECT_VOTING': 'Переход в голосование по заявкам',
//     'OPEN_DOCUMENT_VOTING': 'Переход в голосование по документам',
//     'OPEN_VOTE_FORM': 'Открытие формы голосования',
//     'LEAVE_VOTE': 'Голосования за проект',
//     'LEAVE_COMMENT': 'Добавление особого мнение',
//     'EDIT_COMMENT': 'Редактирование особого мнение',
//     'DELETE_COMMENT': 'Удаление особое мнение',
//     'CHANGE_VOTE': 'Изменения голоса за проект',
//     'OPEN_PROJECT_TAB': 'Переход во вкладку Проекты',
//     'OPEN_SESSION_TAB': 'Переход во вкладку Сессии',
//     'OPEN_NEW_PROJECT_FORM': 'Переход в форму добавления нового проекта',
//     'EDIT_PROJECT': 'Редактирование заявки',
//     'DELETE_PROJECT': 'Удаление заявки',
//     'CREATE_PROJECT': 'Добавление заявки',
//     'EDIT_SESSION': 'Редактирование сессии',
//     'DELETE_SESSION': 'Удаление сессии',
//     'CREATE_SESSION': 'Добавление сессии',
//     'SORT_SESSION': 'Использование сортировки сессий',
//     'ADD_PROJECT_INTERNET_RESOURCE': 'Добавление интернет-ресурс заявки',
//     'ADD_PROJECT_VIDEO_PRESENTATION': 'Добавление видеопрезентции',
//     'CANCEL_CREATE_SESSION': 'Отмена создания сесиии',
//     'CANCEL_EDIT_SESSION': 'Отмена редактирования сессии',
//     'CANCEL_CREATE_PROJECT': 'Отмена добавления заявки',
//     'CANCEL_EDIT_PROJECT': 'Отмена редактирования заявки',
//     'CANCEL_ADD_PROJECT_INTERNET_RESOURCE': 'Отмена добавления интернет-ресурсов',
//     'CANCEL_ADD_PROJECT_VIDEO_PRESENTATION': 'Отмена добавления видеопрезентации',
//     'OPEN_VOTING': 'Открытие голосования',
//     'ACTIVATE_VOTING': 'Активирование голосования',
//     'OPEN_NEW_VOTING_FORM': 'Переход в форму добавления нового голосования',
//     'OPEN_EDIT_VOTING_FORM': 'Переход в форму редактирования голосования',
//     'DELETE_VOTING': 'Удаление голосования',
//     'DOWNLOAD_FULL_VOTES_REPORT': 'Скачивание отчета по итоговому голосованию всех ЧНС',
//     'DOWNLOAD_FULL_COMMENTS_REPORT': 'Скачивание отчета по особому мнению всех ЧНС',
//     'SEARCH_VOTING_PROJECT': 'Использование расширенного поиска для заявок голосования',
//     'USE_VOTING_SEARCH_BAR': 'Использование поиска для заявок голосования',
//     'SORT_VOTING_PROJECT': 'Использование сортировки заявок для заявок голосования',
//     'DOWNLOAD_COMMENTS_REPORT': 'Скачивание особого мнения всех ЧНС',
//     'DOWNLOAD_VOTES_REPORT': 'Скачивание бюллетеня голосования каждого ЧНС',
//     'EDIT_VOTING_BLOCK': 'Редактирование блока для голосования',
//     'CANCEL_EDIT_VOTING_BLOCK': 'Отмена редактирования блока для голосования Название вопроса голосования',
//     'CREATE_VOTING': 'Добавление голосования',
//     'CANCEL_CREATE_VOTING': 'Отмена добавления голосования',
//     'ADD_VOTING_USER': 'Добавление ЧНС в голосование',
//     'CANCEL_ADD_VOTING_USER': 'Отмена добавления ЧНС в голосование',
//     'CANCEL_EDIT_VOTING': 'Отмена редактирования голосования',
//     'CREATE_VOTING_BLOCK': 'Добавление блока для голосования',
//     'CANCEL_CREATE_VOTING_BLOCK': 'Отмена добавления блока для голосования',
//     'OPEN_VOTING_BLOCK': 'Переход в форму Блок для голосования',
//     'OPEN_VOTING_USER': 'Переход в форму Выбор члена Наблюдательного совета',
//     'OPEN_SELECT_VOTING_PROJECT_FORM': 'Переход в форму Выбор заявки на голосование',
//     'ADD_VOTING_PROJECT': 'Добавление заявки в голосование',
//     'CANCEL_ADD_VOTING_PROJECT': 'Отмена добавления заявок в голосование',
//     'OPEN_ADD_USER_FORM': 'Переход в форму Добавление пользователя',
//     'SORT_USER': 'Использование сортировки пользователей',
//     'OPEN_EDIT_USER': 'Открытие формы редактирования пользователя',
//     'OPEN_DELETE_USER': 'Открытие формы удаления пользователя',
//     'DELETE_USER': 'Удаление пользователя',
//     'CREATE_USER': 'Добавление пользователя',
//     'CANCEL_CREATE_USER': 'Отмена создания пользователя',
//     'EDIT_USER': 'Редактирование пользователя',
//     'CANCEL_EDIT_USER': 'Отмена редактирования',
//     'DEACTIVATE_USER': 'Деактивирование пользователя',
//     'ACTIVATE_USER': 'Активирование пользователя',
//     'CANCEL_DEACTIVATE_USER': 'Отмена деактивации пользователя',
//     'OPEN_USER_ACTIONS_TAB': 'Переход во вкладку Действия пользователя',
//     'OPEN_USER_INFO_TAB': 'Переход во вкладку Информация',
//     'OPEN_USER': 'Переход во вкладку Пользователи',
//     'SORT_USER_ACTIONS': 'Использование сортировки действий пользователей'
// }

export default function LogsData(type: keyof KeyInfoType, ip: string, entityId: string = '', actionInformation: string = ''){
    return {
        actionInformation: actionInformation,
        actionTypeCode: type,
        entityId: entityId,
        ipAddress: ip,
        dateTime: new Date()
    }
}