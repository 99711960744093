import React, {FC, SyntheticEvent, useState} from 'react';
import {TProjectContent} from "src/types/projects";
import {useDispatch, useSelector} from "react-redux";
import styles from './styles.module.scss';
import {formatCurrency, formatCurrencyPercent} from "src/utils/currencyForematter";
import {selectSystemSettingSA_Ak} from "src/store/system-settings/selectors";
import {InternetResources} from "src/components/detailed-page/internet-resources";
import {selectFile, selectFiles, selectFileStatus} from "src/store/files/selectiors";
import PdfItem from "./pdf-item";
import {LogsActionsStartAction} from "src/store/logs/actions";
import {TPDFModal} from "src/types/modals";
import {MultilineText} from "src/components/detailed-page/multiline-text";
import {PDFModal} from "src/components/modals/pdf-modal";
import classNames from "classnames";
import {Image, Spin} from "antd";
import {ErrorBoundary} from "src/components/error-boundary";

type Props = {
    project: TProjectContent
    type: 'project' | 'realization'
}
const Project: FC<Props> = ({project, type}) => {
    const put = useDispatch();
    const [modalData, setModalData] = useState<TPDFModal>(null);

    //only project
    const systemSetting = useSelector(selectSystemSettingSA_Ak);


    const files = useSelector(selectFiles(
        (project && project.otherFiles) ? project.otherFiles.map((file) => (`${file.id}`)) : undefined
    ));
    const requestFiles = useSelector(selectFiles(
        (project && project.requestFiles) ? project.requestFiles.map((file) => (`${file.id}`)) : undefined
    ));
    const examinationFiles = useSelector(selectFiles(
        (project && project.examinationFiles) ? project.examinationFiles.map((file) => (`${file.id}`)) : undefined
    ));
    const image = useSelector(selectFile(
        (project && project.image) ? `${project.image.id}` : undefined
    ));
    const {isLoading: isLoadingImage} = useSelector(selectFileStatus((project && project.image) ? `${project.image.id}` : undefined))

    const openPDFModal = (file: any, name: string) => (_e?: SyntheticEvent) => {
        try {
            let fileURL = window.URL.createObjectURL(file);

            setModalData({
                fileURL: fileURL,
                id: String(project.id),
                name,
            });
            put(LogsActionsStartAction(
                'VIEW_PROJECT_ATTACHMENTS',
                String(project.id),
                project.name
            ));
        } catch (error) {}
    };
    const closePDFModal = () => {
        setModalData(null);
    };


    return (
        <ErrorBoundary>
            <div className={styles.container}>
                <div className={styles.left}>

                    <div className={styles.name}>{project.name}</div>
                    <div className={styles.owner}>{project.owner}</div>

                    <br/>

                    {!!project?.session && (
                        <>
                            {/*<div className={styles.info}>*/}
                            {/*    <strong>Конкурс: </strong>*/}
                            {/*    <span>{project.session?.contest?.name}</span>*/}
                            {/*</div>*/}
                            <div className={styles.info}>
                                <strong>Отбор: </strong>
                                <span>{project.session?.name}</span>
                            </div>
                        </>
                    )}

                    {project?.category && (
                        <div className={styles.info}>
                            <strong>Направление: </strong>
                            <span>{project.category.name}</span>
                        </div>
                    )}

                    {type === 'realization' && project?.implStatus && (
                        <div className={styles.info}>
                            <strong>Статус: </strong>
                            <span>{project.implStatus}</span>
                        </div>
                    )}

                    {type === 'project' && !!project.recommendedCostReduction && (
                        <div className={classNames(styles.info, styles.recommendedCostReduction)}>Поддержан с учетом снижения стоимости проекта на
                            <strong> {formatCurrency(project.recommendedCostReduction || '' )} </strong>
                            рублей
                        </div>
                    )}

                    <hr/>

                    <div className={styles.item}>
                        <div className={styles.item__title}>Бюджет</div>
                        <ul className={styles.item__ul}>
                            <li><span>Бюджет проекта</span> <strong>{ formatCurrency(project.budget || '') }</strong></li>
                            {(type === 'project' ? !systemSetting && !!project.supportAmount : !!project.supportAmount) && <li><span>Размер поддержки</span> <strong>{ formatCurrency(project.supportAmount || '') }</strong></li>}
                            {(typeof project.supportAmount === 'number' && project.budget) && (type === 'project' ? !systemSetting && !!project.supportAmount : !!project.supportAmount) && (
                                <li><span>Процент поддержки</span> <strong>{formatCurrencyPercent(project.supportAmount, project.budget, 0)}</strong></li>
                            )}
                        </ul>
                    </div>

                    <hr/>

                    <div className={styles.item}>
                        <div className={styles.item__title}>КПЭ (просмотры)</div>
                        <ul className={styles.item__ul}>
                            <li><span>План</span> <strong>{ formatCurrency(project.kpi || '') }</strong></li>
                            {(type === 'project' ? !systemSetting && !!project.actualKpi : !!project.actualKpi) && <li><span>Факт</span> <strong>{ formatCurrency(project.actualKpi || '') }</strong></li>}
                            {(!!project.kpi && !!project.actualKpi) && (type === 'project' ? !systemSetting : !!project.actualKpi) && (
                                <li><span>Выполнение</span> <strong className={classNames(styles.item__value, {'red': project.actualKpi < project.kpi}, {'green': project.actualKpi >= project.kpi})}>{formatCurrencyPercent(project.actualKpi, project.kpi, 0)}</strong></li>
                            )}
                        </ul>
                    </div>

                    <hr/>

                    {project?.cofinancing && (
                        <div className={styles.info}>
                            <MultilineText text={project.cofinancing || ''} showShort={true} />
                        </div>
                    )}

                    {project?.ownProjectSite && (
                        <div className={styles.info}>
                            <strong>Собственный сайт проекта</strong>
                            <MultilineText text={project.ownProjectSite || ''} showShort={true}/>
                        </div>

                    )}

                    {project?.internetResources && (
                        <div className={styles.info}>
                            <strong>Интернет-ресурсы</strong>
                            <div className={styles.internetResources}>
                                <InternetResources data={project.internetResources} />
                            </div>
                        </div>
                    )}

                    {type === 'project' && (requestFiles && requestFiles.length > 0) &&  (
                        <PdfItem
                            files={requestFiles}
                            data={project} label={'requestFiles'}
                            openPDFModal={openPDFModal}
                            text={'Заявка'}
                        />
                    )}

                    {type === 'project' && project?.expertise && (
                        <div className={styles.info}>
                            <strong>Юридическая экспертиза</strong>
                            <MultilineText text={project.expertise || ''} showShort={true}/>
                        </div>
                    )}

                    {type === 'project' && (examinationFiles && examinationFiles.length > 0) && (
                        <PdfItem
                            files={examinationFiles}
                            data={project}
                            label={'examinationFiles'}
                            openPDFModal={openPDFModal}
                            text={'Экспертиза'}
                        />
                    )}

                    {type === 'project' && (files && files.length > 0) && (
                        <PdfItem
                            files={files}
                            data={project}
                            label={'otherFiles'}
                            openPDFModal={openPDFModal}
                            text={'Дополнения'}
                        />
                    )}

                    {project.achievements && (
                        <MultilineText text={project.achievements || ''} showShort={true}/>
                    )}

                </div>

                <div className={styles.right}>

                    <div className={styles.image}>
                        { (project.image?.id) && (
                            <Spin spinning={isLoadingImage}>
                                <Image className={styles.img}
                                    src={image}
                                    alt="Изображение"/>
                            </Spin>
                        ) }
                    </div>

                    {type === 'realization' && (project.implStartDate && project.implEndDate) && (
                        <div className={styles.info}>
                            <strong>Сроки реализации</strong>
                            <p>Начало реализации планируется на {project.implStartDate} <br/>
                                Окончание реализации планируется на {project.implEndDate} <br/>
                                {project.implTerms && (
                                    <>
                                        Срок реализации {declension(project.implTerms)}
                                    </>
                                )}</p>
                        </div>
                    )}

                    {project.description && (
                        <div className={styles.info}>
                            <strong>Описание проекта</strong>
                            <MultilineText text={project.description || ''} showShort={true}/>
                        </div>
                    )}

                    {project.formatDescription && (
                        <div className={styles.info}>
                            <strong>Формат контента</strong>
                            <MultilineText text={project.formatDescription || ''} showShort={true}/>
                        </div>
                    )}

                    {type === 'project' && project.teamExperience && (
                        <div className={styles.info}>
                            <strong>Команда проекта</strong>
                            <MultilineText text={project.teamExperience || ''} showShort={true}/>
                        </div>
                    )}

                </div>

                <PDFModal
                    fileURL={modalData?.fileURL as string}
                    name={modalData?.name}
                    isOpened={Boolean(modalData)}
                    onClose={closePDFModal}
                />
            </div>
        </ErrorBoundary>
    )
};
function declension(day: number) {
    const days = [' месяц',' месяца',' месяцев'];
    let count = day % 100;
    if (count >= 5 && count <= 20) {
        return day + days[2];
    } else {
        count = count % 10;
        if (count === 1) {
            return day + days[0];
        } else if (count >= 2 && count <= 4) {
            return day + days[1];
        } else {
            return day + days[2];
        }
    }
}
export default Project;