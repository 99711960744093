import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetRealizationListStartAction,
    GetRealizationListSuccessAction,
    GetRealizationListErrorAction,
} from 'src/store/realization/actions';
import { selectRealizationFilters } from 'src/store/realization/selectors';

export function* getRealizationListWorker(_: ReturnType<typeof GetRealizationListStartAction>) {
    try {
        const filters = yield select(selectRealizationFilters);
        const { data: responseData }: AxiosResponse<any> = yield axios({
            method: 'POST',
            url: `/impl/projects/search`,
            headers: {},
            data: {
                ...filters,
                filterData: {
                    ...filters.filterData,
                },
                page: filters.page - 1,
                pageSize: filters.pageSize
            },
        });

        const { content, ...pagination } = responseData;

        yield put(GetRealizationListSuccessAction(
            content,
            {
                ...pagination,
                page: pagination.page + 1,
            },
        ));
    } catch (error) {
        console.log('error', error);
        yield put(GetRealizationListErrorAction(error));
    }
}
