import {
    GetUserByIdStartAction,
    GetUsersStartAction,
    PutUserErrorAction,
    PutUserStartAction,
    PutUserSuccessAction,
} from 'src/store/users/actions';
import {put} from 'redux-saga/effects';
import axios from "axios";
import {LogsActionsStartAction} from "src/store/logs/actions";
import {notification} from "antd";

export function* updateUserWorker({user, userId, params}: ReturnType<typeof PutUserStartAction>) {
    try {

        const newUser = {
            "password": null,
            "personalData": {
                "email": user.email,
                "firstName": user.firstName,
                "image": !!user.image.length ? user.image[0].thumbUrl : user.existingImage,
                "lastName": user.lastName,
                "middleName": user.middleName,
                "organization": user.organization,
                "position": user.position
            },
            "roles": user.roles,
            "status": user.status,
            "username": user.username
        }
        console.log('updateUserWorker', newUser)


        if (user.status === 'BLOCKED') yield put(LogsActionsStartAction('DEACTIVATE_USER'))
        else yield put(LogsActionsStartAction('ACTIVATE_USER'))

        yield axios({
            method: 'PUT',
            url: `/users/${userId}`,
            headers: {},
            data: newUser,
        });
        yield put(PutUserSuccessAction())

        if (params === 'edit') yield put(GetUsersStartAction(null))
        else yield put(GetUserByIdStartAction(`${userId}`))
        yield put(LogsActionsStartAction('EDIT_USER'))
    } catch (error) {
        yield put(PutUserErrorAction(error));
        const errorMessage = error.response?.data.message || 'Ошибка обновления пользователя';
        notification['error']({
            message: 'Ошибка',
            description: errorMessage
        });
    }
}