import { YearsActionTypes } from 'src/store/years/action-types';

export const GetYearsTriggerAction = (isFirstLoad?: boolean) => ({
    type: YearsActionTypes.GET_YEARS_TRIGGER,
    isFirstLoad,
});

export const GetYearsStartAction = () => ({
    type: YearsActionTypes.GET_YEARS_START,
});

export const GetYearsSuccessAction = (years: number[]) => ({
    type: YearsActionTypes.GET_YEARS_SUCCESS,
    years,
});

export const GetYearsErrorAction = (error: any) => ({
    type: YearsActionTypes.GET_YEARS_ERROR,
    error,
});
