import {InferValueTypes} from 'src/types/common';
import {VotingActionTypes} from 'src/store/voting/action-types';
import * as actions from '../actions';
import {TDataStatus} from 'src/types/data-status';
import {TPagination, TSorting} from 'src/types/pageable-request';
import {TVoteSummary} from 'src/types/vote';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TAllDocumentsListState = {
    data: {
        content: TVoteSummary[];
    } & TPagination & TSorting;
} & TDataStatus;

export const initialState: TAllDocumentsListState = {
    data: {
        content: [],
        page: 1,
        pageSize: 20,
        totalCount: 0,
        sort: {
            direction: 'ASC',
            field: 'id',
        },
    },
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function reducer(
    state: TAllDocumentsListState = initialState,
    action: ActionTypes,
): TAllDocumentsListState {
    switch (action.type) {
        case VotingActionTypes.SET_DOCUMENT_LIST_FILTERS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data,
                }
            };
        case VotingActionTypes.GET_ALL_DOCUMENTS_LIST_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null,
            };
        case VotingActionTypes.GET_ALL_DOCUMENTS_LIST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data
                },
                isLoading: false,
                isLoaded: true,
                error: null,
            };
        case VotingActionTypes.GET_ALL_DOCUMENTS_LIST_ERROR:
            return {
                ...state,
                data: { ...initialState.data },
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };
        default:
            return state;
    }
}
