import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getReportingState = (state: TApplicationState) => state.reporting;

export const selectReportingListData = createSelector(
    getReportingState,
    (state) => state.list.data,
);
export const selectReportingListStatus = createSelector(
    getReportingState,
    (state) => ({
        isLoading: state.list.isLoading,
        isLoaded: state.list.isLoaded,
        error: state.list.error,
    }),
);
export const selectReportingListFilters = createSelector(
    getReportingState,
    (state) => state.list.filters,
);

export const selectReportingYearsData = createSelector(
    getReportingState,
    (state) => state.years.data,
);
export const selectReportingYearsStatus = createSelector(
    getReportingState,
    (state) => ({
        isLoading: state.years.isLoading,
        isLoaded: state.years.isLoaded,
        error: state.years.error,
    }),
);

export const selectReportingQuartersData = createSelector(
    getReportingState,
    (state) => state.quarters.data,
);
export const selectReportingQuartersStatus = createSelector(
    getReportingState,
    (state) => ({
        isLoading: state.quarters.isLoading,
        isLoaded: state.quarters.isLoaded,
        error: state.quarters.error,
    }),
);

export const selectReportingTypesData = createSelector(
    getReportingState,
    (state) => state.types.data,
);
export const selectReport = createSelector(
    getReportingState,
    (state) => state.report.report,
);
export const selectReportingTypesStatus = createSelector(
    getReportingState,
    (state) => ({
        isLoading: state.types.isLoading,
        isLoaded: state.types.isLoaded,
        error: state.types.error,
    }),
);
