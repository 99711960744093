import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GetListSessionsYearsErrorAction,
    GetListSessionsYearsStartAction,
    GetListSessionsYearsSuccessAction
} from "src/store/integration/actions";

export function* getListSessionsYearsWorker(action: ReturnType<typeof GetListSessionsYearsStartAction>) {
    try {
        const {data} = yield axios({
            method: 'GET',
            url: `/kk/sessions/years`,
            headers: {},
        });
        yield put(GetListSessionsYearsSuccessAction(data));
    } catch (error) {
        yield put(GetListSessionsYearsErrorAction(error));
    }
}
