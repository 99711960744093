import React from 'react';
import { Button } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons/lib';

import { TSession } from 'src/types/sessions';
import { TProjectData } from 'src/store/project-info/reducer';

import styles from './styles.module.scss';
import {TRealizationLinkContent} from "src/types/realization";

export const ActionsCell = (
    onEdit: Function,
    onDelete?: Function,
) => (id: number, rowData: TSession | TProjectData | TRealizationLinkContent | any) => {

    return (
        <div className={styles.cell}>
            <Button
                type="default"
                size="small"
                className={styles.btn}
                icon={<EditOutlined />}
                onClick={onEdit(id, rowData)}
            />
            {onDelete && (
                <Button
                    type="default"
                    size="small"
                    className={styles.btn}
                    icon={<CloseOutlined />}
                    onClick={onDelete(id, rowData)}
                />
            )}
        </div>
    );
};
