import {AuthActionTypes} from 'src/store/auth/action-types';
import {TRecoverData, TUserCredentials} from 'src/types/user';
import {TUserData} from 'src/store/auth/reducer';

export const LoginStartAction = (credentials: TUserCredentials) => ({
    type: AuthActionTypes.LOGIN_START,
    credentials
});
export const GetCurrentUser = () => ({
    type: AuthActionTypes.GET_CURRENT_USER,
});

export const LoginSuccessAction = (user: TUserData) => ({
    type: AuthActionTypes.LOGIN_SUCCESS,
    user,
});

export const LoginErrorAction = (error: any) => ({
    type: AuthActionTypes.LOGIN_ERROR,
    error,
});

export const LogoutAction = () => ({ type: AuthActionTypes.LOGOUT });

export const RecoverPwdStartAction = (credentials: TRecoverData) => ({
    type: AuthActionTypes.RECOVER_PWD_START,
    credentials
});

export const RecoverPwdSuccessAction = (data: any) => ({
    type: AuthActionTypes.RECOVER_PWD_SUCCESS,
    data
});

export const RecoverPwdErrorAction = (error: any) => ({
    type: AuthActionTypes.RECOVER_PWD_ERROR,
    error,
});

export const RecoverPwdResetAction = () => ({
    type: AuthActionTypes.RECOVER_PWD_RESET,
});
