import React, {useRef, useState} from 'react';
import cn from 'classnames';
import moment, {Duration} from 'moment';
import {ReactComponent as Expired} from "./expired.svg";
import {ReactComponent as Warning} from "./warning.svg";
import {ReactComponent as Done} from "./done.svg";
import {TooltipComponent} from 'src/components/tooltip';
import styles from './styles.module.scss';


function getTime(val: string) {
    const time: string = val;
    const now = moment(new Date());
    const end = moment(time);
    const duration: Duration = moment.duration(now.diff(end));
    const minutes = duration.asMinutes();
    return minutes
}
const text_forms = ['минута', 'минуты', 'минут']
function declOfNum(n: number) {
    const num = Math.abs(n) % 100;
    const n1 = num % 10;
    if (num > 10 && num < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 === 1) { return text_forms[0]; }
    return text_forms[2];
}
export const VoteStatusCell = (status: boolean | string, rowData: any) => {
    return <VoteComponent rowData={rowData}/>
}
function VoteComponent({rowData}: {rowData: any}) {
    const icon = useRef<string>('Warning');
    const message = useRef('Вы ещё не проголосовали');
    const text = useRef('Вы ещё не проголосовали');
    const timer = useRef(10);
    const [state, setState] = useState({
        change: true,
        count: 0
    });

    if (rowData.voteStatus === 'VOTED' ) {
        message.current = 'Ваш голос учтён';
        text.current = 'Ваш голос учтён';
        icon.current = 'Done';
    }
    if (rowData.voteStatus === 'PART_VOTED' && rowData.votes && rowData.votes[0].dateTime) {
        const minutes = getTime(rowData.votes && rowData.votes[0].dateTime);
        timer.current = Math.floor(10 - minutes)
        if (timer.current < 0) {
            message.current = 'Ваш голос учтён';
            text.current = 'Ваш голос учтён';
            icon.current = 'Done';
            if (state.count === 0) setState({...state, count: state.count + 1})
        } else {
            icon.current = 'Expired';
            message.current = `У вас осталось ${timer.current} ${declOfNum(timer.current)}.`
            text.current = 'Вы можете изменить ваш выбор в течение 10 минут.'
        }
    }

    const handler = () => {
        if (rowData.voteStatus === 'PART_VOTED' && rowData.votes && rowData.votes[0].dateTime) {
            timer.current = Math.floor(10 - getTime(rowData.votes && rowData.votes[0].dateTime))
            if (timer.current < 0) {
                message.current = 'Ваш голос учтён';
                if (state.change) setState({...state, count: state.count + 1, change: false})
            } else {
                icon.current = 'Expired';
                message.current = `Вы можете изменить ваш выбор в течение 10 минут. У вас осталось ${timer.current} ${declOfNum(timer.current)}.`
                setState({...state, count: state.count + 1})
            }
        }
    }
    return (
        <div className={styles.cell}>
            <TooltipComponent
                isActive={true}
                message={message.current}
            >
                <div onMouseEnter={handler} className={cn(styles.item)}>
                    {icon.current === 'Warning' && <Warning className={styles.icon} />}
                    {icon.current === 'Expired' && <Expired className={styles.icon} />}
                    {icon.current === 'Done' && <Done className={styles.icon} />}
                    <span className={styles.text}>{text.current}</span>
                </div>
            </TooltipComponent>

        </div>
    );
}