import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';
import { TProjectsOptions } from 'src/types/projects';

const getProjectsState = (state: TApplicationState) => state.projects;

export const selectUsersProjects = createSelector(
    getProjectsState,
    (state) => state.usersProjects.projects,
);
export const selectUsersProjectsStatus = createSelector(
    getProjectsState,
    (state) => ({
        isLoading: state.usersProjects.isLoading,
        isLoaded: state.usersProjects.isLoaded,
        error: state.usersProjects.error,
    }),
);
export const selectUsersProjectsFilters = createSelector(
    getProjectsState,
    (state): TProjectsOptions => (state.usersProjects.filters),
);

export const selectAdminsProjects = createSelector(
    getProjectsState,
    (state) => state.adminsProjects.projects,
);
export const selectAdminsProjectsStatus = createSelector(
    getProjectsState,
    (state) => ({
        isLoading: state.adminsProjects.isLoading,
        isLoaded: state.adminsProjects.isLoaded,
        error: state.adminsProjects.error,
    }),
);
export const selectAdminsProjectsFilters = createSelector(
    getProjectsState,
    (state) => state.adminsProjects.filters,
);

export const selectAdminsDocuments = createSelector(
    getProjectsState,
    (state) => state.adminsDocuments.documents,
);
export const selectAdminsDocumentsStatus = createSelector(
    getProjectsState,
    (state) => ({
        isLoading: state.adminsDocuments.isLoading,
        isLoaded: state.adminsDocuments.isLoaded,
        error: state.adminsDocuments.error,
    }),
);
export const selectAdminsDocumentsFilters = createSelector(
    getProjectsState,
    (state) => state.adminsDocuments.filters,
);

export const selectActionsProjectsRejected = createSelector(
    getProjectsState,
    (state) => state.actionsProjects.rejectedIds,
);
export const selectActionsProjectsRejectedStatus = createSelector(
    getProjectsState,
    (state) => ({
        isLoading: state.actionsProjects.isLoading,
        isLoaded: state.actionsProjects.isLoaded,
        error: state.actionsProjects.error,
    }),
);
