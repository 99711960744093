import {put} from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';

import {
    GetWavesErrorAction,
    GetWavesSetAction,
    GetWavesStartAction,
    GetWavesSuccessAction,
    GetWavesTriggerAction,
} from 'src/store/waves/actions';

export function* wavesWorker(action: ReturnType<typeof GetWavesTriggerAction>) {
    try {
        const { year } = action;

        yield put(GetWavesStartAction());
        if (year) {
            const { data }: AxiosResponse<string[]> = yield axios({
                method: 'GET',
                url: `/sessions/${year}/names`,
                headers: {},
            });

            yield put(GetWavesSuccessAction(data));
        } else {
            yield put(GetWavesSetAction())
        }

    } catch (error) {
        yield put(GetWavesErrorAction(error));
    }
}
