import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TRegent } from 'src/types/user';

import {
    GetEditorRegentsStartAction,
    GetEditorRegentsSuccessAction,
    GetEditorRegentsErrorAction,
} from 'src/store/voting-editor/actions';

export function* getRegentsWorker(_action?: ReturnType<typeof GetEditorRegentsStartAction>) {
    try {
        const { data }: AxiosResponse<TRegent[]> = yield axios({
            method: 'GET',
            url: `/users/regents`,
            headers: {},
        });

        yield put(GetEditorRegentsSuccessAction(data));
    } catch (error) {
        yield put(GetEditorRegentsErrorAction(error));
    }
}
