import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GetInternetResourcesStartAction,
    DeleteInternetResourcesStartAction,
    DeleteInternetResourcesSuccessAction,
    DeleteInternetResourcesErrorAction
} from "src/store/internet-resources/actions";

export function* deleteResourcesWorker({resourceId}: ReturnType<typeof DeleteInternetResourcesStartAction>) {
    try {
        yield axios({
            method: 'DELETE',
            url: `/internet_resources/${resourceId}`,
            headers: {},
        });

        yield put(DeleteInternetResourcesSuccessAction());
        yield put(GetInternetResourcesStartAction());
    } catch (error) {
        yield put(DeleteInternetResourcesErrorAction(error));
    }
}
