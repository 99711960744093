import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GetRealizationListStartAction,
    UpdateProjectErrorAction,
    UpdateProjectStartAction,
    UpdateProjectSuccessAction,
} from "src/store/realization/actions";
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* updateProjectWorker({form, project}: ReturnType<typeof UpdateProjectStartAction>) {
    try {

        const projectData: any = {
            "description": form.description,
            "formatDescription": form.formatDescription,
            "formatIds": form.formatIds,
            "internetResourcesIds": form.internetResources.map((el: { id?: number }) => {
                if (typeof el === 'object') return el.id
                else return el
            }),
            "name": form.name,
            "implEndDate": form.implEndDate,
            "implStartDate": form.implStartDate,
            "implTerms": form.implTerms,
        };

        yield axios({
            method: 'PUT',
            url: `/impl/projects/${project.id}`,
            headers: {},
            data: projectData,
        });

        yield put(UpdateProjectSuccessAction());
        yield put(GetRealizationListStartAction());
        yield put(LogsActionsStartAction('EDIT_PROJECT'));
    } catch (error) {
        yield put(UpdateProjectErrorAction(error));
    }
}
