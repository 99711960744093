import { InferValueTypes } from 'src/types/common';
import { VotingActionTypes } from 'src/store/voting/action-types';
import * as actions from '../actions';
import { TDataStatus } from 'src/types/data-status';
import { TVoteSummary } from 'src/types/vote';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TVoteListState = {
    data: Partial<TVoteSummary>[];
} & TDataStatus;

export const initialState: TVoteListState = {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function reducer(
    state: TVoteListState = initialState,
    action: ActionTypes,
): TVoteListState {
    switch (action.type) {
        case VotingActionTypes.GET_PROJECTS_VOTING_LIST_START:
            return {
                ...state,
                data: [],
                isLoading: true,
                isLoaded: false,
                error: null,
            };
        case VotingActionTypes.GET_PROJECTS_VOTING_LIST_SUCCESS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
                isLoaded: true,
                error: null,
            };
        case VotingActionTypes.GET_PROJECTS_VOTING_LIST_ERROR:
            return {
                ...state,
                data: [],
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };
        default:
            return state;
    }
}
