import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetRealizationLinksStartAction,
    GetRealizationLinksSuccessAction,
    GetRealizationLinksErrorAction, GetRealizationResourcesStartAction,
} from 'src/store/realization/actions';
import { selectRealizationLinksFilters } from 'src/store/realization/selectors';
import { TRealizationLinks } from 'src/types/realization';

export function* getRealizationLinksWorker(
    { projectId }: ReturnType<typeof GetRealizationLinksStartAction>
) {
    try {
        const filters = yield select(selectRealizationLinksFilters);

        let { data: responseData }: AxiosResponse<TRealizationLinks> = yield axios({
            method: 'POST',
            url: `/impl/projects/${projectId}/links`,
            headers: {},
            data: {
                filterData: filters.filterData,
                page: filters.page - 1,
                pageSize: filters.pageSize,
                sort: filters.sort,
            },
        });

        responseData.page = responseData.page + 1;

        yield put(GetRealizationLinksSuccessAction(projectId, responseData));
        yield put(GetRealizationResourcesStartAction())
    } catch (error) {
        yield put(GetRealizationLinksErrorAction(projectId, error));
    }
}
