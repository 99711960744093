import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// @ts-ignore
import { History } from 'history';

import { TApplicationState } from './application-state';
import generalReducer from './general/reducer';
import authReducer from './auth/reducer';
import projectsReducer from './projects/reducer/index';
import formatsReducer from './formats/reducer';
import internetResourcesReducer from './internet-resources/reducer';
import projInfoReducer from './project-info/reducer';
import categoriesReducer from './categories/reducer';
import yearsReducer from './years/reducer';
import wavesReducer from './waves/reducer';
import sessionsReducer from './sesssions/reducer';
import filesReducer from './files/reducer';
import currentSessionReducer from './current-session/reducer';
import userReducer from './user/reducer';
import usersReducer from './users/reducer';
import votingReducer from './voting/reducer/index';
import votingEditorReducer from './voting-editor/reducer/index';
import logsReducer from './logs/reducer'
import realizationReducer from './realization/reducer/index';
import reportingReducer from './reporting/reducer/index';
import integrationReducer from './integration/reducer';
import navigationReducer from './navigation/reducer';
import systemSettingReducer from './system-settings/reducer';

const store = (history: History) => combineReducers<TApplicationState>({
    systemSetting: systemSettingReducer,
    auth: authReducer,
    user: userReducer,
    users: usersReducer,
    router: connectRouter(history),
    general: generalReducer,
    projects: projectsReducer,
    formats: formatsReducer,
    internetResources: internetResourcesReducer,
    projectInfo: projInfoReducer,
    categories: categoriesReducer,
    currentSession: currentSessionReducer,
    years: yearsReducer,
    waves: wavesReducer,
    sessions: sessionsReducer,
    voting: votingReducer,
    votingEditor: votingEditorReducer,
    files: filesReducer,
    logs: logsReducer,
    realization: realizationReducer,
    reporting: reportingReducer,
    integration: integrationReducer,
    navigation: navigationReducer
});

export default store;
