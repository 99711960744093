import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    DeleteRealizationLinksStartAction,
    DeleteRealizationLinksSuccessAction,
    DeleteRealizationLinksErrorAction,
    GetRealizationLinksStartAction,
} from 'src/store/realization/actions';

export function* deleteRealizationLinksWorker(
    { linkId, projectId }: ReturnType<typeof DeleteRealizationLinksStartAction>
) {
    try {

        yield axios({
            method: 'DELETE',
            url: `/impl/projects/links/${linkId}`,
            headers: {},
        });

        yield put(DeleteRealizationLinksSuccessAction());
        yield put(GetRealizationLinksStartAction(projectId));
    } catch (error) {
        yield put(DeleteRealizationLinksErrorAction(error));
    }
}
