import { takeLatest } from 'redux-saga/effects';

import { ReportingActionTypes } from 'src/store/reporting/action-types';

import { getReportingTypesWorker } from 'src/workers/reporting/getTypes';
import { getReportingFiltersWorker } from 'src/workers/reporting/getFilters';
import { getReportingProjectsListWorker } from 'src/workers/reporting/getProjectsList';
import { getReportingQuartersWorker } from 'src/workers/reporting/getQuarters';
import { getReportingYearsWorker } from 'src/workers/reporting/getYears';
import { createReportWorker } from "src/workers/reporting/createReport-worker";
import { updateReportWorker } from "src/workers/reporting/updateReport-worker";
import {deleteReportWorker} from "src/workers/reporting/deleteReport-worker";

export default function* reportingSaga() {
    yield takeLatest(ReportingActionTypes.TRIGGER_GET_FILTERS, getReportingFiltersWorker);
    yield takeLatest(ReportingActionTypes.GET_TYPES_TRIGGER, getReportingTypesWorker);
    yield takeLatest(ReportingActionTypes.GET_YEARS_TRIGGER, getReportingYearsWorker);
    yield takeLatest(ReportingActionTypes.GET_QUARTERS_TRIGGER, getReportingQuartersWorker);
    yield takeLatest(ReportingActionTypes.GET_LIST_TRIGGER, getReportingProjectsListWorker);
    yield takeLatest(ReportingActionTypes.CREATE_REPORT_START, createReportWorker);
    yield takeLatest(ReportingActionTypes.UPDATE_REPORT_START, updateReportWorker);
    yield takeLatest(ReportingActionTypes.DELETE_REPORT_START, deleteReportWorker);
}
