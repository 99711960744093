import { InferValueTypes } from 'src/types/common';
import { CategoriesActionTypes } from 'src/store/categories/action-types';
import * as actions from './actions';
import { TCategory } from 'src/types/category';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TCategoriesState = {
    data: TCategory[];
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TCategoriesState = {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};


export default function reducer(
    state: TCategoriesState = initialState,
    action: ActionTypes,
): TCategoriesState {
    switch (action.type) {
        case CategoriesActionTypes.GET_CATEGORIES_START:
            return {
                ...initialState,
                data: state.data,
                isLoading: true,
            };
        case CategoriesActionTypes.GET_CATEGORIES_SUCCESS:
            return {
                ...initialState,
                data: action.categories,
                isLoading: false,
                isLoaded: true,
            };
        case CategoriesActionTypes.GET_CATEGORIES_ERROR:
            return {
                ...initialState,
                error: action.error,
            };
        default:
            return state;
    }
}
