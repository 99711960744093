import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getCurrentSessionState = (state: TApplicationState) => state.currentSession;

export const selectCurrentSession = createSelector(
    getCurrentSessionState,
    (state) => state.session,
);
export const selectCurrentPresentation = createSelector(
    getCurrentSessionState,
    (state) => state.presentation,
);
export const selectCurrentSessionStatus = createSelector(
    getCurrentSessionState,
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        error: state.error,
    }),
);
