import React, { SyntheticEvent } from 'react';
import { Button } from 'antd';
import moment, { Duration } from 'moment';

import voteIcon from 'src/assets/img/vote-icon.png';

import styles from './styles.module.scss';

export const VoteCell = (
    onClick: Function,
) => (id: number, rowData: any) => {
    const notVoted = rowData.voteStatus === 'NOT_VOTED';
    let hasTimeToChangeVote = false;

    if (rowData.voteStatus === 'PART_VOTED' && rowData.votes && rowData.votes[0].dateTime) {
        const time: string = rowData.votes && rowData.votes[0].dateTime;
        const now = moment(new Date());
        const end = moment(time);
        const duration: Duration = moment.duration(now.diff(end));
        const minutes = duration.asMinutes();
        hasTimeToChangeVote = minutes <= 10;
    }

    const onCellClick = (e: SyntheticEvent) => {
        e.stopPropagation();
    };

    const onButtonClick = () => {
        onClick(id, rowData);
    };

    return (
        <div className={styles.cell} onClick={onCellClick}>
            {  (notVoted || hasTimeToChangeVote) && (
                <Button
                    type="default"
                    size="small"
                    className={styles.btn}
                    onClick={onButtonClick}
                >
                    <img src={voteIcon} alt="vote" />
                </Button>
            )}
        </div>
    );
};
