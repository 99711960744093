import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetFileStartAction,
    GetFileSuccessAction,
    GetFileErrorAction,
} from 'src/store/files/actions';

export function* fileWorker({ id, fileType }: ReturnType<typeof GetFileStartAction>) {
    try {
        const response: AxiosResponse<any> = yield axios({
            method: 'GET',
            url: `/files/${id}`,
            responseType: 'blob',
            headers: {},
        });

        let result = response.data;

        if (fileType === 'image') {
            result = window.URL.createObjectURL(result);
        }

        yield put(GetFileSuccessAction(id, result));
    } catch (error) {
        yield put(GetFileErrorAction(id, error));
    }
}
