import { put, select } from 'redux-saga/effects';

import {
    LogsActionsErrorAction,
    LogsActionsSendAction,
    LogsActionsStartAction,
} from "src/store/logs/actions";
import {selectLogsActions} from "src/store/logs/selectors";

export function* actionsWorker(_action: ReturnType<typeof LogsActionsStartAction>) {
    try {
        const actions = yield select(selectLogsActions)

        if (actions.length >= 5) yield put(LogsActionsSendAction(actions))
        // else yield put(LogsActionsSuccessAction())

    } catch (error) {
        yield put(LogsActionsErrorAction(error));
    }
}
