import React from 'react';

import styles from './styles.module.scss';
import {TVoteDocumentsListContent} from "src/types/vote";
// Воздержался
export const VariantAnswerCell = (name: unknown, data: TVoteDocumentsListContent) => {

    const type = !!data?.votes?.length && data?.votes[0]?.voteOption.name;
    return (
        <div className={styles.cell}>
            <span className={styles.name}>{type === 'Воздержаться' ? 'Воздержался' : type }</span>
        </div>
    )
};
