export const VotingActionTypes = {
    SET_FILTERS: '[VOTING] SET_FILTERS',
    RESET_FILTERS: '[VOTING] RESET_FILTERS',

    GET_PROJECTS_VOTING_ID_TRIGGER: '[VOTING] GET_PROJECTS_VOTING_ID_TRIGGER',
    GET_DOCUMENTS_VOTING_ID_TRIGGER: '[VOTING] GET_DOCUMENTS_VOTING_ID_TRIGGER',

    GET_VOTING_TYPES_TRIGGER: '[VOTING] GET_VOTING_TYPES_TRIGGER',
    GET_VOTING_TYPES_START: '[VOTING] GET_VOTING_TYPES_START',
    GET_VOTING_TYPES_SUCCESS: '[VOTING] GET_VOTING_TYPES_SUCCESS',
    GET_VOTING_TYPES_ERROR: '[VOTING] GET_VOTING_TYPES_ERROR',

    GET_VOTING_OPTIONS_START: '[VOTING] GET_VOTING_OPTIONS_START',
    GET_VOTING_OPTIONS_SUCCESS: '[VOTING] GET_VOTING_OPTIONS_SUCCESS',
    GET_VOTING_OPTIONS_ERROR: '[VOTING] GET_VOTING_OPTIONS_ERROR',

    VOTE_START: '[VOTING] VOTE_START',
    VOTE_SUCCESS: '[VOTING] VOTE_SUCCESS',
    VOTE_ERROR: '[VOTING] VOTE_ERROR',

    GET_VOTE_PROJECTS_SUMMARY_TRIGGER: '[VOTING] GET_VOTE_PROJECTS_SUMMARY_TRIGGER',
    GET_VOTE_PROJECTS_SUMMARY_START: '[VOTING] GET_VOTE_PROJECTS_SUMMARY_START',
    GET_VOTE_PROJECTS_SUMMARY_SUCCESS: '[VOTING] GET_VOTE_PROJECTS_SUMMARY_SUCCESS',
    GET_VOTE_PROJECTS_SUMMARY_ERROR: '[VOTING] GET_VOTE_PROJECTS_SUMMARY_ERROR',

    GET_VOTE_DOCUMENTS_SUMMARY_TRIGGER: '[VOTING] GET_VOTE_DOCUMENTS_SUMMARY_TRIGGER',
    GET_VOTE_DOCUMENTS_SUMMARY_START: '[VOTING] GET_VOTE_DOCUMENTS_SUMMARY_START',
    GET_VOTE_DOCUMENTS_SUMMARY_SUCCESS: '[VOTING] GET_VOTE_DOCUMENTS_SUMMARY_SUCCESS',
    GET_VOTE_DOCUMENTS_SUMMARY_ERROR: '[VOTING] GET_VOTE_DOCUMENTS_SUMMARY_ERROR',

    GET_PROJECTS_VOTING_LIST_TRIGGER: '[VOTING] GET_PROJECTS_VOTING_LIST_TRIGGER',
    GET_PROJECTS_VOTING_LIST_START: '[VOTING] GET_PROJECTS_VOTING_LIST_START',
    GET_PROJECTS_VOTING_LIST_SUCCESS: '[VOTING] GET_PROJECTS_VOTING_LIST_SUCCESS',
    GET_PROJECTS_VOTING_LIST_ERROR: '[VOTING] GET_PROJECTS_VOTING_LIST_ERROR',

    GET_DOCUMENTS_VOTING_LIST_TRIGGER: '[VOTING] GET_DOCUMENTS_VOTING_LIST_TRIGGER',
    GET_DOCUMENTS_VOTING_LIST_START: '[VOTING] GET_DOCUMENTS_VOTING_LIST_START',
    GET_DOCUMENTS_VOTING_LIST_SUCCESS: '[VOTING] GET_DOCUMENTS_VOTING_LIST_SUCCESS',
    GET_DOCUMENTS_VOTING_LIST_ERROR: '[VOTING] GET_DOCUMENTS_VOTING_LIST_ERROR',

    GET_VOTING_PROJECTS_START: '[VOTING] GET_VOTING_PROJECTS_START',
    GET_VOTING_PROJECTS_SUCCESS: '[VOTING] GET_VOTING_PROJECTS_SUCCESS',
    GET_VOTING_PROJECTS_ERROR: '[VOTING] GET_VOTING_PROJECTS_ERROR',

    SET_DOCUMENT_LIST_FILTERS: '[VOTING] SET_DOCUMENT_LIST_FILTERS',
    RESET_DOCUMENT_LIST_FILTERS: '[VOTING] RESET_DOCUMENT_LIST_FILTERS',

    SET_PROJECTS_LIST_FILTERS: '[VOTING] SET_PROJECTS_LIST_FILTERS',
    RESET_PROJECTS_LIST_FILTERS: '[VOTING] RESET_PROJECTS_LIST_FILTERS',

    GET_DOCUMENT_LIST_START: '[VOTING] GET_DOCUMENT_LIST_START',
    GET_DOCUMENT_LIST_SUCCESS: '[VOTING] GET_DOCUMENT_LIST_SUCCESS',
    GET_DOCUMENT_LIST_ERROR: '[VOTING] GET_DOCUMENT_LIST_ERROR',

    GET_DETAILED_PROJECT_START: '[VOTING] GET_DETAILED_PROJECT_START',
    GET_DETAILED_PROJECT_SUCCESS: '[VOTING] GET_DETAILED_PROJECT_SUCCESS',
    GET_DETAILED_PROJECT_ERROR: '[VOTING] GET_DETAILED_PROJECT_ERROR',

    GET_DETAILED_DOCUMENT_START: '[VOTING] GET_DETAILED_DOCUMENT_START',
    GET_DETAILED_DOCUMENT_SUCCESS: '[VOTING] GET_DETAILED_DOCUMENT_SUCCESS',
    GET_DETAILED_DOCUMENT_ERROR: '[VOTING] GET_DETAILED_DOCUMENT_ERROR',

    // ADMIN | MODERATOR

    GET_REPORT_PROJECT_START: '[VOTING] GET_REPORT_PROJECT_START',
    GET_REPORT_PROJECT_SUCCESS: '[VOTING] GET_REPORT_PROJECT_SUCCESS',
    GET_REPORT_PROJECT_ERROR: '[VOTING] GET_REPORT_PROJECT_ERROR',

    GET_REPORT_DOCUMENT_START: '[VOTING] GET_REPORT_DOCUMENT_START',
    GET_REPORT_DOCUMENT_SUCCESS: '[VOTING] GET_REPORT_DOCUMENT_SUCCESS',
    GET_REPORT_DOCUMENT_ERROR: '[VOTING] GET_REPORT_DOCUMENT_ERROR',

    ACTIVATE_VOTING: '[VOTING] ACTIVATE_VOTING',

    SET_ALL_VOTES_LIST_SORTING: '[VOTING] SET_ALL_VOTES_LIST_SORTING',

    GET_ALL_VOTES_LIST_START: '[VOTING] GET_ALL_VOTES_LIST_START',
    GET_ALL_VOTES_LIST_SUCCESS: '[VOTING] GET_ALL_VOTES_LIST_SUCCESS',
    GET_ALL_VOTES_LIST_ERROR: '[VOTING] GET_ALL_VOTES_LIST_ERROR',

    SET_ALL_DOCUMENTS_LIST_SORTING: '[VOTING] SET_ALL_DOCUMENTS_LIST_SORTING',

    GET_ALL_DOCUMENTS_LIST_START: '[VOTING] GET_ALL_DOCUMENTS_LIST_START',
    GET_ALL_DOCUMENTS_LIST_SUCCESS: '[VOTING] GET_ALL_DOCUMENTS_LIST_SUCCESS',
    GET_ALL_DOCUMENTS_LIST_ERROR: '[VOTING] GET_ALL_DOCUMENTS_LIST_ERROR',

    GET_ALL_PROJECTS_VOTE_SUMMARY_START: '[VOTING] GET_ALL_PROJECTS_VOTE_SUMMARY_START',
    GET_ALL_PROJECTS_VOTE_SUMMARY_SUCCESS: '[VOTING] GET_ALL_PROJECTS_VOTE_SUMMARY_SUCCESS',
    GET_ALL_PROJECTS_VOTE_SUMMARY_ERROR: '[VOTING] GET_ALL_PROJECTS_VOTE_SUMMARY_ERROR',

    GET_ALL_DOCUMENTS_VOTE_SUMMARY_START: '[VOTING] GET_ALL_DOCUMENTS_VOTE_SUMMARY_START',
    GET_ALL_DOCUMENTS_VOTE_SUMMARY_SUCCESS: '[VOTING] GET_ALL_DOCUMENTS_VOTE_SUMMARY_SUCCESS',
    GET_ALL_DOCUMENTS_VOTE_SUMMARY_ERROR: '[VOTING] GET_ALL_DOCUMENTS_VOTE_SUMMARY_ERROR',

    GET_ALL_PROJECTS_REPORT_START: '[VOTING] GET_ALL_PROJECTS_REPORT_START',
    GET_ALL_PROJECTS_REPORT_SUCCESS: '[VOTING] GET_ALL_PROJECTS_REPORT_SUCCESS',
    GET_ALL_PROJECTS_REPORT_ERROR: '[VOTING] GET_ALL_PROJECTS_REPORT_ERROR',

    GET_DOCUMENT_REPORT_START: '[VOTING] GET_DOCUMENT_REPORT_START',

    GET_ALL_DOCUMENTS_REPORT_START: '[VOTING] GET_ALL_DOCUMENTS_REPORT_START',
    GET_ALL_DOCUMENTS_REPORT_SUCCESS: '[VOTING] GET_ALL_DOCUMENTS_REPORT_SUCCESS',
    GET_ALL_DOCUMENTS_REPORT_ERROR: '[VOTING] GET_ALL_DOCUMENTS_REPORT_ERROR',

    ADD_VOTING_COMMENT_START: '[VOTING] ADD_VOTING_COMMENT_START',
    ADD_VOTING_COMMENT_SUCCESS: '[VOTING] ADD_VOTING_COMMENT_SUCCESS',
    ADD_VOTING_COMMENT_ERROR: '[VOTING] ADD_VOTING_COMMENT_ERROR',

    EDIT_VOTING_COMMENT_START: '[VOTING] EDIT_VOTING_COMMENT_START',
    EDIT_VOTING_COMMENT_SUCCESS: '[VOTING] EDIT_VOTING_COMMENT_SUCCESS',
    EDIT_VOTING_COMMENT_ERROR: '[VOTING] EDIT_VOTING_COMMENT_ERROR',

    DELETE_VOTING_COMMENT_START: '[VOTING] DELETE_VOTING_COMMENT_START',
    DELETE_VOTING_COMMENT_SUCCESS: '[VOTING] DELETE_VOTING_COMMENT_SUCCESS',
    DELETE_VOTING_COMMENT_ERROR: '[VOTING] DELETE_VOTING_COMMENT_ERROR',
} as const;
