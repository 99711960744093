import React, {FC, useEffect, useState} from 'react';
import {Button, Checkbox, Form, Input} from 'antd';
import { Modal } from 'react-bootstrap';
import {useFormik} from 'formik';

import { TProjectFormData } from 'src/components/modals/projects-modal';

import styles from './styles.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {selectInternetResources} from "src/store/internet-resources/selectors";
import {GetInternetResourcesStartAction} from "src/store/internet-resources/actions";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {LogsActionsStartAction} from "src/store/logs/actions";

type TInternetResourcesModalProps = {
    isOpened: boolean;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onSubmit: (value: TProjectFormData['internetResources']) => void;
    value: TProjectFormData['internetResources'];
    single?: boolean
};

export const InternetResourcesModal: FC<TInternetResourcesModalProps> = ({
    isOpened,
    onClose,
    onSubmit,
    value,
    single = false
}) => {
    const put = useDispatch();
    const internetResources = useSelector(selectInternetResources);
    const [term, setTerm] = useState('');
    useEffect(() => {
        if (!internetResources.length) put(GetInternetResourcesStartAction())
    }, [internetResources.length, put])

    const iResForm = useFormik({
        initialValues: {internetResources: []},
        onSubmit: onSubmitForm,
    });
    useEffect(() => {
        if (isOpened && value && internetResources) {
            iResForm.setValues({
                internetResources: value ? value.map((el: { id?: any; }) => {
                    if (typeof el === "object") return el.id
                    else return el
                }) : [],
            });
            put(LogsActionsStartAction('ADD_PROJECT_INTERNET_RESOURCE'))
        } else {
            iResForm.resetForm();
        }
    }, [isOpened]);

    function onSubmitForm(values: any) {
        onSubmit(values.internetResources);
        onClose();
    }

    const handleChangeFormat = (values: CheckboxValueType[]) => {
        if (single) {
            iResForm.setFieldValue('internetResources', [values[values.length - 1]]);
        } else iResForm.setFieldValue('internetResources', values);
    };
    const handleChangeAll = (e: any) => {
        if (e.target.checked) iResForm.setFieldValue('internetResources', internetResources.map((el: { value: any; }) => el.value));
        else iResForm.setFieldValue('internetResources', []);
    };
    const handlerSearch =  (e: any) => {
        setTerm(e.target.value)
    }
    const getFields = (arr: any[]) => {
        const rus = arr
            .filter((el) => el.label.toLowerCase().trim().search(/[а-яё]/g) >= 0)
            .sort((a: any, b: any) => a.label.localeCompare(b.label))
        const en = arr
            .filter((el) => el.label.toLowerCase().trim().search(/[\w]/g) >= 0)
            .sort((a: any, b: any) => a.label.localeCompare(b.label))

        const affFiltered = Array.from(new Set([...rus, ...en]))
        return affFiltered
            .filter((el) => el.label.toLowerCase().includes(term.toLowerCase()))
            .map((el: any) => (
                <Checkbox key={el.value} value={el.value} className={styles.checkBoxGroup}>
                    {el.img ? (<img src={el.img} className={styles.img} alt={el.label}/>) : null }
                    { el.label }
                </Checkbox>
            ))
    }

    return (
        <Modal
            show={isOpened}
            onHide={onClose}
            keyboard={true}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={styles.dialog}
        >
            <form
                name="projectForm"
                onSubmit={iResForm.handleSubmit}
                className={styles.form}
            >
                <Modal.Header className={styles.modalHeader}>
                    <h3 className={styles.header}>
                        Добавление Интернет-Ресурсов
                    </h3>
                </Modal.Header>

                <Modal.Body className={styles.body}>
                    <Form.Item
                        className={styles.formItem}
                    >
                        <Input
                            allowClear={true}
                            value={term}
                            onChange={handlerSearch}
                            placeholder={'Поиск'}/>
                    </Form.Item>
                    <Form.Item
                        className={styles.formItem}
                        validateStatus={ (
                            iResForm.submitCount > 0 && iResForm.errors.internetResources
                        ) ? 'error' : '' }
                    >
                        {!single && (
                            <Checkbox onChange={handleChangeAll} className={styles.checkbox}>Выбрать всех</Checkbox>
                        )}
                        <label htmlFor="resource" className={styles.label}>Ресурсы</label>
                        <Checkbox.Group
                            name="resource"
                            onChange={handleChangeFormat}
                            value={iResForm.values.internetResources}
                            className={`${styles.checkBoxGroup} ${styles.list}`}
                            // options={internetResources}
                        >
                            {internetResources && getFields(internetResources) }
                        </Checkbox.Group>
                    </Form.Item>
                </Modal.Body>

                <Modal.Footer>
                    <Button type="default" onClick={(e) => {
                        onClose(e)
                        put(LogsActionsStartAction('CANCEL_ADD_PROJECT_INTERNET_RESOURCE'))
                    }}>Отменить</Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >Сохранить</Button>
                </Modal.Footer>
            </form>

        </Modal>
    );
}

