import { push } from 'connected-react-router';

import { mainModuleRoutes } from 'src/routing/projects-module';

type TParams = {
    put: Function,
    votingId?: string,
    type: 'projects' | 'documents',
};

export const handleGoToVotingEditor = (params: TParams) => (() => {
    const path = params.votingId
        ? mainModuleRoutes['edit-voting'].path.replace(':votingId', `${params.votingId}`)
        : mainModuleRoutes['create-voting'].path;

    params.put(push({
        pathname: path,
        search: `?type=${params.type}`
    }));
});
