import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TVotingBlock } from 'src/types/vote';

import {
    EditDocumentStartAction,
    EditDocumentSuccessAction,
    EditDocumentErrorAction,
    SetDocumentAction,
} from 'src/store/voting-editor/actions';

import { selectVotingEditorBlocks } from 'src/store/voting-editor/selectors';

export function* editorEditBlockWorker(
    { block, newFiles }: ReturnType<typeof EditDocumentStartAction>,
) {
    try {
        const { files, ...params } = block;

        const formData = new FormData();

        formData.append('votingBlock', JSON.stringify({
            ...params,
            fileIds: files?.map((file) => file.id),
        }));

        if (newFiles.length === 0) {
            formData.append('files', new Blob(undefined));
        } else {
            newFiles.forEach((file: any) => {
                formData.append(
                    'files',
                    file.originFileObj as Blob,
                );
            });
        }

        yield axios({
            method: 'PUT',
            url: `/voting/blocks/${block.id}`,
            headers: {},
            data: formData,
        });

        const { data }: AxiosResponse<TVotingBlock> = yield axios({
            method: 'GET',
            url: `/voting/blocks/${block.id}`,
            headers: {},
        });

        const blocks = yield select(selectVotingEditorBlocks);

        yield put(SetDocumentAction([
            ...blocks.map((item: any) => {
                return item.id === block.id ? data : item;
            }),
        ]));
        yield put(EditDocumentSuccessAction(data));

    } catch (error) {
        yield put(EditDocumentErrorAction(error));
    }
}
