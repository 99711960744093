import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetVotingReportDocumentStartAction,
    GetVotingReportDocumentSuccessAction,
    GetVotingReportDocumentErrorAction,
} from 'src/store/voting/actions';
import { TVoteReportVotes } from 'src/types/vote';
import { GetFileSuccessAction } from 'src/store/files/actions';

export function* getVotingReportDocumentWorker({ votingBlockId }: ReturnType<typeof GetVotingReportDocumentStartAction>) {
    try {
        const { data: comments }: AxiosResponse<any> = yield axios({
            method: 'GET',
            url: `/voting/blocks/${votingBlockId}/comments/report`,
            responseType: 'blob',
            headers: {},
        });

        const { data: votes }: AxiosResponse<TVoteReportVotes> = yield axios({
            method: 'GET',
            url: `/voting/blocks/${votingBlockId}/votes/report`,
            responseType: 'blob',
            headers: {},
        });

        yield put(GetFileSuccessAction(votingBlockId, {
            commnets: window.URL.createObjectURL(comments),
            votes: window.URL.createObjectURL(votes)
        }));

        yield put(GetVotingReportDocumentSuccessAction({comments, votes}));
    } catch (error) {
        yield put(GetVotingReportDocumentErrorAction(error));
    }
}
