import { UserActionTypes } from 'src/store/user/action-types';

export const GetUserStartAction = () => ({
    type: UserActionTypes.GET_USER_START,
});

export const GetUserSuccessAction = (user: any) => ({
    type: UserActionTypes.GET_USER_SUCCESS,
    user,
});

export const GetUserErrorAction = (error: any) => ({
    type: UserActionTypes.GET_USER_ERROR,
    error,
});
