import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    DeleteProjectErrorAction,
    DeleteProjectStartAction,
    DeleteProjectSuccessAction,
    GetProjectsStartAction,
} from 'src/store/projects/actions';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* deleteProjectWorker({ id }: ReturnType<typeof DeleteProjectStartAction>) {
    try {
        const { data: responseData }: AxiosResponse<any> = yield axios({
            method: 'DELETE',
            url: `/projects/${id}`,
            headers: {},
        });

        yield put(DeleteProjectSuccessAction(responseData));
        yield put(GetProjectsStartAction());
        yield put(LogsActionsStartAction('DELETE_PROJECT'));
    } catch (error) {
        yield put(DeleteProjectErrorAction(error));
    }
}
