import { CurrentSessionActionTypes } from 'src/store/current-session/action-types';

export const GetCurrentSessionTriggerAction = (year: string) => ({
    type: CurrentSessionActionTypes.GET_CURRENT_SESSION_TRIGGER,
    year,
});

export const GetCurrentSessionStartAction = (id: string) => ({
    type: CurrentSessionActionTypes.GET_CURRENT_SESSION_START,
    id,
});

export const GetCurrentSessionSuccessAction = (session: any, presentation: any) => ({
    type: CurrentSessionActionTypes.GET_CURRENT_SESSION_SUCCESS,
    session,
    presentation,
});

export const GetCurrentSessionErrorAction = (error: any) => ({
    type: CurrentSessionActionTypes.GET_CURRENT_SESSION_ERROR,
    error,
});
