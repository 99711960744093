import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GetRealizationLinksStartAction,
    CreateRealizationLinksStartAction,
    CreateRealizationLinksSuccessAction, CreateRealizationLinksErrorAction,
} from 'src/store/realization/actions';


export function* createRealizationLinksWorker(
    { link, projectId }: ReturnType<typeof CreateRealizationLinksStartAction>
) {
    try {
        const data = {
            "description": link.description,
            "internetResourceId": link.internetResourceId[0],
            "link": link.link,
            "projectId": projectId
        }

        yield axios({
            method: 'POST',
            url: `/impl/projects/links`,
            headers: {},
            data: data,
        });


        yield put(CreateRealizationLinksSuccessAction());
        yield put(GetRealizationLinksStartAction(projectId));
    } catch (error) {
        yield put(CreateRealizationLinksErrorAction(error));
    }
}
