import { InferValueTypes } from 'src/types/common';
import { ProjInfoActionTypes } from 'src/store/project-info/action-types';
import * as actions from './actions';
import { TDataStatus } from 'src/types/data-status';
import { TProjectContent } from 'src/types/projects';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TProjectData = TProjectContent | {};

export type TProjInfoState = Record<string, {
    data: TProjectData;
} & TDataStatus>;

export const initialState: TProjInfoState = {};

export default function reducer(
    state: TProjInfoState = initialState,
    action: ActionTypes,
): TProjInfoState {
    switch (action.type) {
        case ProjInfoActionTypes.GET_PROJECT_START:
            return {
                ...state,
                [action.id]: {
                    data: state[action.id] ? state[action.id].data : {},
                    isLoading: true,
                    isLoaded: false,
                    error: null,
                }
            };
        case ProjInfoActionTypes.GET_PROJECT_SUCCESS:
            return {
                ...state,
                [action.id]: {
                    data: action.project,
                    isLoading: false,
                    isLoaded: true,
                    error: null,
                }
            };
        case ProjInfoActionTypes.GET_PROJECT_ERROR:
            return {
                ...state,
                [action.id]: {
                    data: {},
                    isLoading: false,
                    isLoaded: false,
                    error: action.error,
                }
            };
        default:
            return state;
    }
}
