import React, { FC } from 'react';
import { Button } from 'antd';
import { Modal } from 'react-bootstrap';

import styles from './styles.module.scss';

type TConfirmModalProps = {
    isOpened: boolean;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onSubmit: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    message: string;
};

export const ConfirmModal: FC<TConfirmModalProps> = ({
    isOpened,
    onClose,
    onSubmit,
    message,
}) => (
    <Modal
        show={isOpened}
        onHide={onClose}
        keyboard={true}
        scrollable={true}
        backdropClassName={styles.backDrop}
        dialogClassName={styles.dialog}
    >
        <Modal.Body className={styles.body}>
            <h3 className={styles.header}>
                Подтвердите действие
            </h3>

            <div className={styles.message}>
                { message }
            </div>
        </Modal.Body>

        <Modal.Footer>
            <Button type="default" onClick={onClose}>Отменить</Button>
            <Button
                type="primary"
                htmlType="submit"
                onClick={onSubmit}
            >Подтвердить</Button>
        </Modal.Footer>
    </Modal>
);
