import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TProjectContent } from 'src/types/projects';

import {
    GetEditorProjectsStartAction,
    GetEditorProjectsSuccessAction,
    GetEditorProjectsErrorAction,
} from 'src/store/voting-editor/actions';

export function* editorGetProjectsWorker(
    { payload }: ReturnType<typeof GetEditorProjectsStartAction>,
) {
    try {
        const { data }: AxiosResponse<Required<TProjectContent>[]> = yield axios({
            method: 'POST',
            url: `/projects/all`,
            headers: {},
            data: {
                categoryIds: [],
                maxScore: 10,
                minScore: 0,
                searchString: '',
                sessionName: payload.params.sessionName,
                year: payload.params.year,
                userAvailable: true,
            }
        });

        yield put(GetEditorProjectsSuccessAction(data));
    } catch (error) {
        yield put(GetEditorProjectsErrorAction(error));
    }
}
