import {IntegrationActionTypes} from "src/store/integration/actions-types";
export const ResetDataAction = () => ({
    type: IntegrationActionTypes.RESET_DATA
})
//GET_LAST_IMPORT
export const GetLastImportStartAction = () => ({
    type: IntegrationActionTypes.GET_LAST_IMPORT_START
});
export const GetLastImportSuccessAction = (data: any) => ({
    type: IntegrationActionTypes.GET_LAST_IMPORT_SUCCESS,
    data
});
export const GetLastImportErrorAction = (error: any) => ({
    type: IntegrationActionTypes.GET_LAST_IMPORT_ERROR,
    error
});

//GET_LIST_PROJECT_START
export const GetListProjectStartAction = (sessionName: string, year: number) => ({
    type: IntegrationActionTypes.GET_LIST_PROJECT_START,
    sessionName, year
});
export const GetListProjectSuccessAction = (data: any) => ({
    type: IntegrationActionTypes.GET_LIST_PROJECT_SUCCESS,
    data
});
export const GetListProjectErrorAction = (error: any) => ({
    type: IntegrationActionTypes.GET_LIST_PROJECT_ERROR,
    error
});

//IMPORT_PROJECTS_START
export const ImportProjectStartAction = (data: {sessionId: number, importProjectIds: number[]}) => ({
    type: IntegrationActionTypes.IMPORT_PROJECTS_START,
    data
});
export const ImportProjectSuccessAction = (id: any) => ({
    type: IntegrationActionTypes.IMPORT_PROJECTS_SUCCESS,
    id
});
export const ImportProjectErrorAction = (error: any) => ({
    type: IntegrationActionTypes.IMPORT_PROJECTS_ERROR,
    error
});

//GET_LIST_SESSIONS_START
export const GetListSessionsStartAction = (year: number) => ({
    type: IntegrationActionTypes.GET_LIST_SESSIONS_START,
    year
});
export const GetListSessionsSuccessAction = (data: any) => ({
    type: IntegrationActionTypes.GET_LIST_SESSIONS_SUCCESS,
    data
});
export const GetListSessionsErrorAction = (error: any) => ({
    type: IntegrationActionTypes.GET_LIST_SESSIONS_ERROR,
    error
});

//GET_LIST_SESSIONS_YEARS_START
export const GetListSessionsYearsStartAction = () => ({
    type: IntegrationActionTypes.GET_LIST_SESSIONS_YEARS_START
});
export const GetListSessionsYearsSuccessAction = (data: any) => ({
    type: IntegrationActionTypes.GET_LIST_SESSIONS_YEARS_SUCCESS,
    data
});
export const GetListSessionsYearsErrorAction = (error: any) => ({
    type: IntegrationActionTypes.GET_LIST_SESSIONS_YEARS_ERROR,
    error
});
