import {
    takeLatest,
} from 'redux-saga/effects';

import {IntegrationActionTypes} from "src/store/integration/actions-types";


import { getLastImportWorker } from 'src/workers/integration/getLastImport-worker';
import {getListProjectWorker} from "src/workers/integration/getListProject-worker";
import {importProjectWorker} from "src/workers/integration/importProject-worker";
import {getListSessionsWorker} from "src/workers/integration/getListSessions-worker";
import {getListSessionsYearsWorker} from "src/workers/integration/getListSessionsYears-worker";


export default function* integrationSaga() {
    yield takeLatest(IntegrationActionTypes.GET_LAST_IMPORT_START, getLastImportWorker)
    yield takeLatest(IntegrationActionTypes.GET_LIST_PROJECT_START, getListProjectWorker)
    yield takeLatest(IntegrationActionTypes.IMPORT_PROJECTS_START, importProjectWorker)
    yield takeLatest(IntegrationActionTypes.GET_LIST_SESSIONS_START, getListSessionsWorker)
    yield takeLatest(IntegrationActionTypes.GET_LIST_SESSIONS_YEARS_START, getListSessionsYearsWorker)
}