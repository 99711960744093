import { call, put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { GetWavesTriggerAction } from 'src/store/waves/actions';
import {
    GetSessionsStartAction,
    DeleteSessionStartAction,
    DeleteSessionSuccessAction,
    DeleteSessionErrorAction,
} from 'src/store/sesssions/actions';
import { selectUsersProjectsFilters } from 'src/store/projects/selectors';

import { wavesWorker } from 'src/workers/waves-worker';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* deleteSessionWorker({ id }: ReturnType<typeof DeleteSessionStartAction>) {
    try {
        const projectFilters = yield select(selectUsersProjectsFilters);

        const { data: responseData }: AxiosResponse<any> = yield axios({
            method: 'DELETE',
            url: `/sessions/${id}`,
            headers: {},
        });

        yield put(DeleteSessionSuccessAction(responseData));
        yield put(GetSessionsStartAction());

        if (projectFilters.filterData.year) {
            yield call(wavesWorker, GetWavesTriggerAction(projectFilters.filterData.year));
        }
        yield put(LogsActionsStartAction('DELETE_SESSION', `${id}`))
    } catch (error) {
        yield put(DeleteSessionErrorAction(error));
    }
}
