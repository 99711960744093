import {InferValueTypes} from "src/types/common";
import * as actions from "src/store/projects/actions";
import {ProjectsActionTypes} from "src/store/projects/action-types";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TActions = {
    rejectedIds: [],
    isLoading: boolean,
    isLoaded: boolean,
    error: any,
}

export const initialState: any = {
    rejectedIds: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}
export default function ActionsReducer(
    state: TActions = initialState,
    action: ActionTypes
): TActions {
    switch (action.type) {
        case ProjectsActionTypes.PUT_PROJECTS_ACTIONS_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case ProjectsActionTypes.PUT_PROJECTS_ACTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            }
        case ProjectsActionTypes.PUT_PROJECTS_ACTIONS_REJECT:
            return {
                ...state,
                rejectedIds: action.ids,
                isLoading: false,
                isLoaded: true
            }
        case ProjectsActionTypes.PUT_PROJECTS_ACTIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: action.error
            }
        default:
            return state
    }
}