import React from "react";
import styles from "src/components/modals/projects-modal/styles.module.scss";
import {Button, Form, Upload} from "antd";
import cn from "classnames";
import {DeleteOutlined, UploadOutlined} from "@ant-design/icons";
import {UploadChangeParam} from "antd/es/upload";
import {UploadFile} from "antd/es/upload/interface";
import {FileSize} from "src/constatnts/format";

type ModalUploadProps = {
    type: 'multi' | 'single',
    isImg: boolean
    form: any,
    label: string,
    existingLabel: string,
    text: string,
    isEdit: boolean,
    typeUploadFile?: 'video/*' | 'audio/*' | 'image/*'
}


const ModalUpload: React.FC<ModalUploadProps> = ({
    type = 'multi',
    isImg = false,
    form,
    label,
    existingLabel,
    text,
    isEdit,
    typeUploadFile
}) => {
    const removeExistingFile = (id: string | number | null | undefined, label: string) => {
        if (form.values.existingFiles) {
            form.setFieldValue(
                'existingFiles',
                form.values.existingFiles.filter((item: { id: number; }) => item.id !== id),
            );
        }
        if (form.values[label]) {
            form.setFieldValue(
                label,
                form.values[label].filter((item: { id: number; }) => item.id !== id),
            );
        }
    };

    const removeExistingImage = (label: string) => {
        if (form.values[label]) form.setFieldValue(label, undefined,);
    };

    const validateUpload = (_file: File) => {
        return false;
    };

    const uploadImage = (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.fileList.length === 0) {
            return;
        }
        const size = info.file.size ? info.file.size : 0;
        if (size >= 10000000) return;
        const newFile = info.fileList[info.fileList.length - 1];
        try {
            // @ts-ignore
            const type = newFile.type.split('/')[0];
            if (type !== 'image') {
                throw new Error('File is not an image');
            }

            // @ts-ignore
            if (newFile.size > FileSize) {
                throw new Error('File too big');
            }
        } catch (_error) {
            return false;
        }

        removeExistingImage(existingLabel);
        form.setFieldValue('image', [newFile]);
    };

    const uploadFile = (info: UploadChangeParam<UploadFile<any>>, fileName: string) => {
        const size = info.file.size ? info.file.size : 0;
        if (size >= 500000000) return;
        if ((fileName === 'presentationFile') && form.values.presentationFile.length) {
            form.setFieldValue('presentationFile', [],);
            form.setFieldValue('existingPresentationFile', undefined,);
            return
        }
        if ((fileName === 'reportFile') && form.values.reportFile.length) {
            form.setFieldValue('reportFile', [],);
            form.setFieldValue('existingReportFile', undefined,);
            return
        }
        const files = info.fileList.filter((item) => {
            try {
                // @ts-ignore
                if (item.size > FileSize) {
                    throw new Error('File too big');
                }
                return true;
            } catch (_error) {
                return false;
            }
        });
        form.setFieldValue(fileName, files);
    };

    const onRemoveFile = (fieldName: string) => (_file: any) => {
        form.setFieldValue(fieldName, []);
    }

    const typeFile = typeUploadFile ? typeUploadFile : 'application/pdf';
    return (
        <>
            {isImg ? (
                <Upload
                    accept="image/*"
                    className={styles.upload}
                    listType="picture"
                    multiple={false}
                    beforeUpload={validateUpload}
                    onChange={uploadImage}
                    onRemove={onRemoveFile(label)}
                    fileList={form.values[label]}
                >
                    <Button icon={<UploadOutlined />}>{text}</Button>
                </Upload>
            ) : (
                <Upload
                    accept={typeFile}
                    className={styles.upload}
                    listType="text"
                    multiple={type === 'single' ? false : true }
                    beforeUpload={validateUpload}
                    onChange={(info) => uploadFile(info, label)}
                    onRemove={onRemoveFile(label)}
                    fileList={form.values[label]}
                >
                    <Button icon={<UploadOutlined />}>{text}</Button>
                </Upload>
            )}


            {type === 'multi' ? (
                isEdit && form.values[existingLabel] ) && (
                <Form.Item className={styles.uploadedFilesList}>
                    { form.values[existingLabel].map((item: { id: string | number | null | undefined; fileName: React.ReactNode; }) => (
                        <label
                            key={item.id}
                            htmlFor="endDate"
                            className={cn(styles.label, styles.existingFileLabel)}
                        >
                            { item.fileName }
                            <Button
                                type="default"
                                size="small"
                                className={styles.btn}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    removeExistingFile(item.id, existingLabel)
                                }}
                            />
                        </label>
                    )) }
                </Form.Item> ) : (
                isEdit && form.values[existingLabel] ) && (
                <Form.Item className={styles.uploadedFilesList}>
                    <label htmlFor="endDate" className={cn(styles.label, styles.existingFileLabel)}>
                        { form.values[existingLabel].fileName }
                        <Button
                            type="default"
                            size="small"
                            className={styles.btn}
                            icon={<DeleteOutlined />}
                            onClick={() => removeExistingImage(existingLabel)}
                        />
                    </label>
                </Form.Item> )
            }
        </>
    )
}
export default ModalUpload