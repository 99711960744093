import {FormatsActionTypes} from "src/store/formats/action-types";
import {TFormats} from "src/types/formats";


export const GetFormatsStartAction = () => ({
    type: FormatsActionTypes.GET_FORMATS_START,
});

export const GetFormatsSuccessAction = (formats: TFormats[]) => ({
    type: FormatsActionTypes.GET_FORMATS_SUCCESS,
    formats,
});

export const GetFormatsErrorAction = (error: any) => ({
    type: FormatsActionTypes.GET_FORMATS_ERROR,
    error,
});
