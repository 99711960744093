import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    ActivateVotingAction,
    GetAllVotesListStartAction,
    GetAllDocumentsListStartAction,
} from 'src/store/voting/actions';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* activateVotingWorker({ payload }: ReturnType<typeof ActivateVotingAction>) {
    try {
        yield axios({
            method: 'PUT',
            url: `/voting/${payload.id}/${payload.status}`,
            headers: {},
        });

        if (payload.type === 'projects') {
            yield put(GetAllVotesListStartAction());
        } else {
            yield put(GetAllDocumentsListStartAction());
        }
        yield put(LogsActionsStartAction('ACTIVATE_VOTING', `${payload.id}`))
    } catch (_error) {}
}

