import { combineReducers } from 'redux';

import allVotesListReducer from './all-votes-list';
import allDocumentsReducer from './all-documents-list';
import votingTypesReducer from './voting-types';
import votingOptionsReducer from './voting-options';
import votingProjectsListReducer from './voting-projects-list';
import votingDocumentsListReducer from './voting-documents-list';
import voteProjectsSummaryReducer from './vote-projects-summary';
import voteDocumentsSummaryReducer from './vote-documents-summary';
import votingFiltersReducer from './filters';
import votingProjectsReducer from './projects';
import votingDocumentListReducer from './document-list'
import detailedProjectReducer from './detailed-project';
import detailedDocumentReducer from './detailed-document';
import reportProjectReducer from './report-project';
import reportDocumentReducer from './report-document';
import allProjectsVoteSummaryReducer from './all-projects-vote-summary';
import allDocumentsVoteSummaryReducer from './all-documents-vote-summary';
import allProjectsReportReducer from './all-projects-report';
import allDocumentsReportReducer from './all-documents-report';

const reducer = combineReducers({
    allVotes: allVotesListReducer,
    allDocuments: allDocumentsReducer,
    types: votingTypesReducer,
    options: votingOptionsReducer,
    projectsList: votingProjectsListReducer,
    documentsList: votingDocumentsListReducer,
    voteProjectsSummary: voteProjectsSummaryReducer,
    voteDocumentsSummary: voteDocumentsSummaryReducer,
    filters: votingFiltersReducer,
    projects: votingProjectsReducer,
    documents: votingDocumentListReducer,
    detailedProject: detailedProjectReducer,
    detailedDocument: detailedDocumentReducer,
    reportProject: reportProjectReducer,
    reportDocument: reportDocumentReducer,
    allProjectsVoteSummary: allProjectsVoteSummaryReducer,
    allDocumentsVoteSummary: allDocumentsVoteSummaryReducer,
    allProjectsReport: allProjectsReportReducer,
    allDocumentsReport: allDocumentsReportReducer,
});

export type TVotingState = ReturnType<typeof reducer>;

export default reducer;
