import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GetRealizationProjectErrorAction,
    GetRealizationProjectStartAction,
    GetRealizationProjectSuccessAction,
} from "src/store/realization/actions";

export function* getRealizationProjectWorker({projectId}: ReturnType<typeof GetRealizationProjectStartAction>) {
    try {
        const {data} = yield axios({
            method: 'GET',
            url: `/impl/projects/${projectId}`,
            headers: {},
        });

        let idProject;
        try {
            const {data: respData} = yield axios({
                method: 'GET',
                url: `/projects`,
                headers: {},
                params: {
                    requestNumber: data.requestNumber
                }
            })
            idProject = respData
        } catch (e) {
            idProject = null
        }
        yield put(GetRealizationProjectSuccessAction(data, idProject));


    } catch (error) {
        yield put(GetRealizationProjectErrorAction(error));
    }
}