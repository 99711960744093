export const ReportingActionTypes = {
    TRIGGER_GET_FILTERS: '[REPORTING] TRIGGER_GET_FILTERS',
    SET_FILTERS: '[REPORTING] SET_FILTERS',
    RESET_FILTERS: '[REPORTING] RESET_FILTERS',

    GET_LIST_TRIGGER: '[REPORTING] GET_LIST_TRIGGER',
    GET_LIST_START: '[REPORTING] GET_LIST_START',
    GET_LIST_SUCCESS: '[REPORTING] GET_LIST_SUCCESS',
    GET_LIST_ERROR: '[REPORTING] GET_LIST_ERROR',

    GET_YEARS_TRIGGER: '[REPORTING] GET_YEARS_TRIGGER',
    GET_YEARS_START: '[REPORTING] GET_YEARS_START',
    GET_YEARS_SUCCESS: '[REPORTING] GET_YEARS_SUCCESS',
    GET_YEARS_ERROR: '[REPORTING] GET_YEARS_ERROR',

    GET_QUARTERS_TRIGGER: '[REPORTING] GET_QUARTERS_TRIGGER',
    GET_QUARTERS_START: '[REPORTING] GET_QUARTERS_START',
    GET_QUARTERS_SUCCESS: '[REPORTING] GET_QUARTERS_SUCCESS',
    GET_QUARTERS_ERROR: '[REPORTING] GET_QUARTERS_ERROR',

    GET_TYPES_TRIGGER: '[REPORTING] GET_TYPES_TRIGGER',
    GET_TYPES_START: '[REPORTING] GET_TYPES_START',
    GET_TYPES_SUCCESS: '[REPORTING] GET_TYPES_SUCCESS',
    GET_TYPES_ERROR: '[REPORTING] GET_TYPES_ERROR',

    CREATE_REPORT_START: '[REPORTING] CREATE_REPORT_START',
    CREATE_REPORT_SUCCESS: '[REPORTING] CREATE_REPORT_SUCCESS',
    CREATE_REPORT_ERROR: '[REPORTING] CREATE_REPORT_ERROR',

    GET_REPORT: '[REPORTING] GET_REPORT',

    UPDATE_REPORT_START: '[REPORTING] UPDATE_REPORT_START',
    UPDATE_REPORT_SUCCESS: '[REPORTING] UPDATE_REPORT_SUCCESS',
    UPDATE_REPORT_ERROR: '[REPORTING] UPDATE_REPORT_ERROR',

    DELETE_REPORT_START: '[REPORTING] DELETE_REPORT_START',
    DELETE_REPORT_SUCCESS: '[REPORTING] DELETE_REPORT_SUCCESS',
    DELETE_REPORT_ERROR: '[REPORTING] DELETE_REPORT_ERROR',
} as const;
