import React, {
    ChangeEvent,
    FC,
    useEffect,
    useState,
} from 'react';
import { Button, Input } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { EditOutlined, UndoOutlined } from '@ant-design/icons/lib';

import { TVotingComment } from 'src/types/vote';

import {
    AddVotingCommentStartAction,
    DeleteVotingCommentStartAction,
    EditVotingCommentStartAction, GetVotingDetailedDocumentStartAction,
} from 'src/store/voting/actions';

import { TVoteDetailedDocumentState } from 'src/store/voting/reducer/detailed-document';
import { TVotingDetailedProjectState } from 'src/store/voting/reducer/detailed-project';

import styles from 'src/features/document-detailed-page/styles.module.scss';

type TProps = {
    data: TVoteDetailedDocumentState['data'] | TVotingDetailedProjectState['data'];
    projectId: number;
    votingId: number;
    type: 'project' | 'document';
    isArchive?: boolean;
    handler?: any
}

const { TextArea } = Input;

export const SpecialOpinion: FC<TProps> = ({
    data,
    projectId,
    votingId,
    type,
    isArchive,
    handler
}) => {
    const [comment, setComment] = useState<string>('');
    const [isEditorActive, setIsEditorActive] = useState(false);
    const put = useDispatch();
    const commentId = data.comments && data.comments[0] && data.comments[0].id ? data.comments[0].id : null;

    const handleChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };

    const handleSaveComment = () => {
        if (!comment) return;

        let actionData: TVotingComment = {
            comment: comment,
            votingId: +votingId,
        }
        type === 'project' ? actionData.projectId = projectId : actionData.votingBlockId = projectId;
        if (commentId) {
            put(EditVotingCommentStartAction(actionData, commentId, `${votingId}`, `${projectId}`));
        } else {
            put(AddVotingCommentStartAction(actionData, `${votingId}`, `${projectId}`));
        }
        setIsEditorActive(false);
        if (typeof handler !== "undefined") handler();
    };

    const handleDeleteComment = () => {
        if (commentId) {
            put(DeleteVotingCommentStartAction(commentId, `${votingId}`, `${projectId}`));
            put(GetVotingDetailedDocumentStartAction(`${votingId}`, `${projectId}`));
        }
        setIsEditorActive(false);
        if (typeof handler !== "undefined") handler();
    };

    const initComment = () => {
        if (data && data.comments) {
            let myComment: string = '';

            if (data.comments && data.comments[0]) {
                myComment = data.comments[0].comment;
            }
            setComment(myComment);
        }
    };

    const activateEditor = () => {
        if (!isEditorActive) {
            setIsEditorActive(true);
        }
    };

    const undo = () => {
        setComment('');
        setIsEditorActive(false);
        initComment();
    };

    useEffect(() => {
        initComment();
    }, [data]);

    if (isArchive && comment.length === 0) {
        return null;
    }

    return (
        <div className={cn({
            [styles.item]: true,
            [styles.editable]: !isEditorActive
        })} >
            <div className={`${styles.title} ${styles.specialOpinionTitle}`}>
                Особое мнение

                { !isArchive && (
                    isEditorActive ? (
                        <Button
                            type="default"
                            size="small"
                            className={styles.btn}
                            icon={<UndoOutlined />}
                            onClick={undo}
                        />
                    ) : (
                        <Button
                            type="default"
                            size="small"
                            className={styles.btn}
                            icon={<EditOutlined />}
                            onClick={activateEditor}
                        />
                    )
                ) }
            </div>

            { (!isEditorActive || isArchive) ? (
                <div className={`${styles.text}`}>
                    <p className={styles.lineBreak}>
                        {comment}
                    </p>
                </div>
            ) : (
                <div className={`${styles.text} ${styles.name}`}>
                    <TextArea
                        rows={3}
                        className={cn(styles.input, styles.textArea)}
                        name="formatDescription"
                        placeholder="Текст особого мнения"
                        value={comment}
                        onChange={handleChangeTextArea}
                    />
                    <div className={styles.commentActions}>
                        <Button
                            type="primary"
                            onClick={handleSaveComment}
                        >Сохранить</Button>
                        { commentId && (
                            <Button type="default" onClick={handleDeleteComment}>Удалить</Button>
                        ) }
                    </div>
                </div>
            ) }

        </div>
    );
}
