import { InferValueTypes } from 'src/types/common';
import { VotingEditorActionTypes } from 'src/store/voting-editor/action-types';
import * as actions from '../actions';
import { TDataStatus } from 'src/types/data-status';
import { TProjectContent } from 'src/types/projects';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TVotingEditorProjectsState = {
    sessionId: string;
    data: Required<TProjectContent>[];
} & TDataStatus;

export const initialState: TVotingEditorProjectsState = {
    sessionId: '',
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function reducer(
    state: TVotingEditorProjectsState = initialState,
    action: ActionTypes,
): TVotingEditorProjectsState {
    switch (action.type) {
        case VotingEditorActionTypes.GET_PROJECTS_START:
            return {
                ...state,
                sessionId: action.sessionId,
                data: [],
                isLoading: true,
                isLoaded: false,
                error: null,
            };
        case VotingEditorActionTypes.GET_PROJECTS_SUCCESS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
                isLoaded: true,
                error: null,
            };
        case VotingEditorActionTypes.GET_PROJECTS_ERROR:
            return {
                ...state,
                data: [],
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };
        default:
            return state;
    }
}
