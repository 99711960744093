import React, { FC, SyntheticEvent, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { Modal } from 'react-bootstrap';
import cn from 'classnames';
import { FormikHelpers, useFormik } from 'formik';

import styles from './styles.module.scss';
import {useDispatch} from "react-redux";
import {LogsActionsStartAction} from "src/store/logs/actions";

type TConfirmModalProps = {
    isOpened: boolean;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onSubmit: (value: string) => void;
    value: string;
};

export type TVideoPresentationFormData = {
    presentationUrl: string;
};

const { TextArea } = Input;

export const VideoPresentationModal: FC<TConfirmModalProps> = ({
    isOpened,
    onClose,
    onSubmit,
    value,
}) => {
    const put = useDispatch();

    const videoPresentationForm = useFormik({
        initialValues: {
            presentationUrl: '',
        },
        onSubmit: onSubmitForm,
    });

    function onSubmitForm(
        values: TVideoPresentationFormData,
        _formikHelpers: FormikHelpers<TVideoPresentationFormData>
    ) {
        onSubmit(values.presentationUrl);
        onClose();
    }

    function onChange(event: SyntheticEvent<HTMLTextAreaElement>) {
        // const iframeRegex = new RegExp('/<iframe(.+)</iframe>/');
        let result = event.currentTarget.value;

        // try to parse src from iframe
        try {
            const doc = new DOMParser().parseFromString(result, 'text/html');
            const validIframe = doc.getElementsByTagName('iframe')[0];

            if (validIframe && validIframe.src) {
                result = validIframe.src;
            }
        } catch (_error) {}

        videoPresentationForm.setFieldValue('presentationUrl', result);
    }

    useEffect(() => {
        if (isOpened) {
            videoPresentationForm.setFieldValue('presentationUrl', value);
            put(LogsActionsStartAction('ADD_PROJECT_VIDEO_PRESENTATION'))
        } else {
            videoPresentationForm.resetForm();
        }
    }, [isOpened]);

    return (
        <Modal
            show={isOpened}
            onHide={onClose}
            keyboard={true}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={styles.dialog}
        >
            <form
                name="projectForm"
                onSubmit={videoPresentationForm.handleSubmit}
                className={styles.form}
            >
                <Modal.Header className={styles.modalHeader}>
                    <h3 className={styles.header}>
                        Добавление видеопрезентации
                    </h3>
                </Modal.Header>

                <Modal.Body className={styles.body}>
                    <Form.Item
                        className={styles.formItem}
                    >
                        <label htmlFor="presentationUrl" className={styles.label}>
                            Ссылка на видеопрезентацию
                        </label>
                        <TextArea
                            rows={3}
                            className={cn(styles.input)}
                            name="presentationUrl"
                            placeholder="Вставте код"
                            value={videoPresentationForm.values.presentationUrl}
                            onChange={onChange}
                            onBlur={videoPresentationForm.handleBlur}
                        />
                    </Form.Item>
                </Modal.Body>

                <Modal.Footer>
                    <Button type="default" onClick={(e) => {
                        onClose(e)
                        put(LogsActionsStartAction('CANCEL_ADD_PROJECT_VIDEO_PRESENTATION'))
                    }}>Отменить</Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >Сохранить</Button>
                </Modal.Footer>
            </form>

        </Modal>
    );
}
