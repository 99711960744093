export const LogsActionTypes = {
    LOGS_LOGIN_START: '[LOGS] LOGS_LOGIN_START',
    LOGS_LOGIN_SUCCESS: '[LOGS] LOGS_LOGIN_SUCCESS',
    LOGS_LOGIN_ERROR: '[LOGS] LOGS_LOGIN_ERROR',

    LOGS_ACTION_START: '[LOGS] LOGS_ACTION_START',
    LOGS_ACTION_SEND: '[LOGS] LOGS_ACTION_SEND',
    LOGS_ACTION_SUCCESS: '[LOGS] LOGS_ACTION_SUCCESS',
    LOGS_ACTION_CLEAN: '[LOGS] LOGS_ACTION_CLEAN',
    LOGS_ACTION_ERROR: '[LOGS] LOGS_ACTION_ERROR',

    LOGS_LOGOUT_START: '[LOGS] LOGS_LOGOUT_START',
    LOGS_LOGOUT_SUCCESS: '[LOGS] LOGS_LOGOUT_SUCCESS',
    LOGS_LOGOUT_ERROR: '[LOGS] LOGS_LOGOUT_ERROR',

    GET_ACTIONS_START: '[LOGS] GET_ACTIONS_START',
    GET_ACTIONS_SUCCESS: '[LOGS] GET_ACTIONS_SUCCESS',
    GET_ACTIONS_ERROR: '[LOGS] GET_ACTIONS_ERROR',

    UPDATE_ACTIONS_START: '[LOGS] UPDATE_ACTIONS_START',
    UPDATE_ACTIONS_SUCCESS: '[LOGS] UPDATE_ACTIONS_SUCCESS',
    UPDATE_ACTIONS_ERROR: '[LOGS] UPDATE_ACTIONS_ERROR',
} as const;
