import {InferValueTypes} from "src/types/common";
import { RealizationActionTypes } from 'src/store/realization/action-types';
import * as actions from "../actions";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TCrawler = {
    isLoading: boolean,
    isLoaded: boolean,
    error: any,
}

export const initialState: TCrawler = {
    isLoading: false,
    isLoaded: false,
    error: null,
}
export default function crawlerReducer(
    state: TCrawler = initialState,
    action: ActionTypes
): TCrawler {
    switch (action.type) {
        case RealizationActionTypes.CRAWLER_START:
            return {
                ...initialState,
                isLoading: true
            }
        case RealizationActionTypes.CRAWLER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            }
        case RealizationActionTypes.CRAWLER_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        default:
            return state
    }
}