import React, {
    FC,
    SyntheticEvent,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation} from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import moment, { Duration } from 'moment';

import pdfImg from 'src/assets/img/pdf.png';
import viewDocumentImg from 'src/assets/img/view-document.png';

import { TPDFModal } from 'src/types/modals';

import {
    GetDocumentReportStartAction,
    GetVotingDetailedDocumentStartAction,
} from 'src/store/voting/actions';
import { LogsActionsStartAction } from 'src/store/logs/actions';

import {
    selectDocumentsVoteSummaryData,
    selectVoteDetailedDocument,
    selectVoteDetailedDocumentStatus,
} from 'src/store/voting/selectors';
import { selectFiles } from 'src/store/files/selectiors';
import { selectIsModerator } from 'src/store/auth/selectors';

import { mainModuleRoutes } from 'src/routing/projects-module';
import { ToParentPage } from 'src/components/to-parent-page';
import { PDFModal } from 'src/components/modals/pdf-modal';
import { SpecialOpinion } from 'src/components/special-opinion';
import { ErrorBoundary } from 'src/components/error-boundary';
import { TVoteModalProps, VoteModal } from 'src/components/modals/vote-modal';

import styles from './styles.module.scss';
import {LoadingOutlined} from "@ant-design/icons";
import createMarkup from 'src/utils/createMarkup';

type TProps = {
    isArchive?: boolean;
};

export const DocumentDetailedPage: FC<TProps> = (props) => {
    const [modalData, setModalData] = useState<TPDFModal>(null);
    const [voteModalData, setVoteModalData] = useState<TVoteModalProps['data'] | undefined>(undefined);
    const { pathname } = useLocation();
    const votingId = pathname.split('/')[2];
    const projectId = pathname.split('/')[3];
    const put = useDispatch();
    const data = useSelector(selectVoteDetailedDocument);
    const voteDocumentsSummaryData = useSelector(selectDocumentsVoteSummaryData);
    const status = useSelector(selectVoteDetailedDocumentStatus);
    const files = useSelector(selectFiles(
        // @ts-ignore
        (data && data.files) ? data.files.map((file) => (`${file.id}`)) : undefined
    ));
    const isModerator = useSelector(selectIsModerator);

    const canVote: boolean = useMemo(() => {
        try {
            if (!data || data.voteStatus === 'VOTED') return false;
            if (data.voteStatus === 'NOT_VOTED') return true
            if (data.voteStatus === 'PART_VOTED' && data.votes && data.votes[0].dateTime) {
                const time: string = data.votes && data.votes[0].dateTime;
                const now = moment(new Date());
                const end = moment(time);
                const duration: Duration = moment.duration(now.diff(end));
                const minutes = duration.asMinutes();
                return minutes <= 10;
            }
        } finally {}

        return false;
    }, [data]);
    const canVoting = () => {
        if (!!voteDocumentsSummaryData.startDateTime) {
            const now = moment(new Date());
            const end = moment(voteDocumentsSummaryData.startDateTime);
            if (moment.duration(now.diff(end)).asMinutes() > 0) return true
            return false
        } else return true
    }
    const openPDFModal = (file: any, name: string) => (_e: SyntheticEvent) => {
        try {
            let fileURL = window.URL.createObjectURL(file);

            setModalData({
                fileURL: fileURL,
                name,
            });
        } catch (error) {}
    };

    const [loading, setLoading] = useState(false);
    const closePDFModal = () => {
        setModalData(null);
    };

    const submitVoting = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            put(GetVotingDetailedDocumentStartAction(votingId, projectId));
        }, 1000)
    }

    const handleDownloadReport = (reportType: 'comments'| 'votes') => () => {
        put(GetDocumentReportStartAction(reportType, +projectId));

        if (reportType === 'comments') {
            put(LogsActionsStartAction('DOWNLOAD_FULL_VOTES_REPORT', projectId, data.name));
        } else {
            put(LogsActionsStartAction('DOWNLOAD_FULL_COMMENTS_REPORT', projectId, data.name));
        }
    };

    const getPath = () => {
        if (isModerator) {
            return mainModuleRoutes['all-documents-list'].path.replace(':votingId', `${votingId}`)
        }

        return (props.isArchive
            ? mainModuleRoutes['archive-documents'].path.replace(':votingId', `${votingId}`)
            : mainModuleRoutes.vote.path);
    };

    const openVoteModal = () => {
        if (data) {
            setVoteModalData({
                id: +projectId,
                votingId: +votingId,
                type: 'document',
                name: data.name ? data.name : '',
                vote: data.votes && data.votes.length ? data.votes[0] : null,
            });
        }
    };

    const closeVoteModal = () => {
        setVoteModalData(undefined);
    };


    useEffect(() => {
        if (votingId && projectId) {
            put(GetVotingDetailedDocumentStartAction(votingId, projectId));
        }
    }, [votingId, projectId, voteModalData]);

    if (status.isLoading) {
        return (
            <div className={styles.container}>
                Загрузка...
            </div>
        );
    }
    return (
        <div className={styles.container}>
            <div className={styles.leftColumn}>
                <ToParentPage text="" parentPath={getPath()} />
            </div>
            <div className={`${styles.contentColumn} ${styles.leftSection}`}>

                { isModerator && (
                    <div className={styles.reports}>
                        <Button
                            className={styles.report}
                            type="link"
                            onClick={handleDownloadReport('votes')}
                        >
                            Итоговое голосование
                        </Button>
                        <Button
                            className={styles.report}
                            type="link"
                            onClick={handleDownloadReport('comments')}
                        >
                            Особое мнение
                        </Button>
                    </div>
                ) }

                <div className={styles.item}>
                    <div className={styles.title}>Голосование по документу</div>
                    <div className={`${styles.text} ${styles.name}`}>{ data.name || '' }</div>
                    { data.question && (<div className={`${styles.text}`} dangerouslySetInnerHTML={createMarkup(data.question)}/>)}
                    <hr/>
                </div>

                {loading ? <LoadingOutlined /> : (
                    <ErrorBoundary>
                        { (files && files.length > 0) && (
                            <div className={styles.item}>
                                <div className={styles.title}>Вложения</div>
                                <div className={styles.documentsList}>
                                    { files.map((file, index: number) => {
                                        let name: string = '';

                                        try {
                                            name = data.files
                                                ? data.files[index].fileName
                                                : '';
                                            name = name.split('.').slice(0, -1).join('.')
                                        } catch (_e) {
                                            name = data.files
                                                ? data.files[index].fileName
                                                : '';
                                        }
                                        return (
                                            <div
                                                key={`${index}_${name}`}
                                                className={styles.document}
                                                onClick={openPDFModal(file, name)}>
                                                <img src={pdfImg} alt="pdf" className={styles.pdfImg} />
                                                <span>{ name }</span>
                                                <Tooltip placement="top" title="Просмотр документа">
                                                    <img
                                                        src={viewDocumentImg}
                                                        alt="document"
                                                        className={styles.documentImg} />
                                                </Tooltip>
                                            </div>
                                        );
                                    }) }
                                </div>
                                <hr/>
                            </div>
                        ) }

                        { !isModerator && canVoting() && (
                            <SpecialOpinion
                                data={data}
                                type={'document'}
                                projectId={+projectId}
                                votingId={+votingId}
                                isArchive={props.isArchive}
                                handler={submitVoting}
                            />
                        ) }

                        {data && data.votes?.length && (
                            <>
                                <span>Вы проголосвали: {data.votes[0].voteOption.name}</span><br/>
                            </>
                        )}

                        { (!props.isArchive && canVote && canVoting()) && (
                            <div className={styles.item}>
                                <hr/>
                                <div className={styles.text}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        onClick={openVoteModal}
                                    >Голосовать</Button>
                                </div>
                            </div>
                        ) }
                    </ErrorBoundary>
                )}

            </div>

            <PDFModal
                fileURL={modalData?.fileURL as string}
                name={modalData?.name}
                isOpened={Boolean(modalData)}
                onClose={closePDFModal}
            />

            <VoteModal
                isOpened={Boolean(voteModalData)}
                data={voteModalData}
                onClose={closeVoteModal}
                handler={submitVoting}
            />
        </div>
    );
};
