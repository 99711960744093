import {put, select} from 'redux-saga/effects';
import axios from 'axios';

import {
    CreateReportStartAction,
    CreateReportErrorAction,
    CreateReportSuccessAction, TriggerGetReportingFiltersAction,

} from 'src/store/reporting/actions';
import {selectUserData} from "src/store/auth/selectors";


export function* createReportWorker({data, reportTypeId}: ReturnType<typeof CreateReportStartAction>) {
    try {
        const formData = new FormData();
        const {user} = yield select(selectUserData)

        const socketId = user.username + '-reporting'

        let report = {
            "year": data.years,
            "quarter": data.quarters,
            "reportTypeId": reportTypeId,
            "name": data.name,
            "url": data.url,
        }

        formData.append('report', JSON.stringify(report));

        data.reportFile.forEach((file: any) => {
            formData.append('file', file.originFileObj as Blob);
        });

        if (data.reportFile.length === 0) {
            formData.append('file', new Blob([]),);
        }

        yield axios({
            method: 'POST',
            url: `/reporting/reports`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            params: {
                socketId: socketId
            }
        });

        yield put(CreateReportSuccessAction());
        yield put(TriggerGetReportingFiltersAction())
    } catch (error) {
        yield put(CreateReportErrorAction(error));
    }
}
