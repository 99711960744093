import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getAuthState = (state: TApplicationState) => state.auth;

export const selectIsLoggedIn = createSelector(
    getAuthState,
    (state) => state.isLoggedIn,
);

export const selectAuthError = createSelector(
    getAuthState,
    (state) => state.error,
);

export const selectUserData = createSelector(
    getAuthState,
    (state) => state.user,
);

export const selectRecoverPwdStatus = createSelector(
    getAuthState,
    (state) => ({
        isRecovered: state.isRecovered,
        isRecoverLoading: state.isRecoverLoading,
        recoverError: state.recoverError,
    }),
);

export const selectIsModerator = createSelector(
    getAuthState,
    (state) => {
        return state.user &&
            state.user.user &&
            state.user.user.roles &&
            state.user.user.roles.length &&
            (
                state.user.user.roles.includes('ROLE_ADMIN') ||
                state.user.user.roles.includes('ROLE_MODERATOR')
            );
    },
);

export const selectIsAdmin = createSelector(
    getAuthState,
    (state) => {
        return state.user &&
            state.user.user &&
            state.user.user.roles &&
            state.user.user.roles.length &&
            state.user.user.roles.includes('ROLE_ADMIN');
    },
);
