import { createSelector } from 'reselect';
import { TRealizationState } from 'src/store/realization/reducer';
import { TApplicationState } from 'src/store/application-state';

const getRealizationState = (state: TApplicationState): TRealizationState => state.realization;

export const selectRealizationFilters = createSelector(
    getRealizationState,
    (st) => st.list.filters,
);

export const selectRealizationListData = createSelector(
    getRealizationState,
    (st) => st.list.data,
);
export const selectRealizationListStatus = createSelector(
    getRealizationState,
    (s) => ({
        isLoading: s.list.isLoading,
        isLoaded: s.list.isLoaded,
        error: s.list.error
    }),
);

export const selectRealizationTypesContent = createSelector(
    getRealizationState,
    (s) => ({
        typesContent: s.types.types,
        isLoading: s.types.isLoading,
        isLoaded: s.types.isLoaded,
        error: s.types.error
    })
)
export const selectRealizationProject = (id: string) => (
    createSelector(
        getRealizationState,
        (state) => {
            if (!!state.project.project.length) return {}
            return state.project.project
        }
    )
)
export const selectRealizationProjectId = createSelector(
    getRealizationState,
    (state) => {
        if (state.project.idProject) return state.project.idProject
        else return null
    }
)

export const selectRealizationProjectContent = createSelector(
    getRealizationState,
    (state) => state.project.projectContent,
)
export const selectRealizationProjectStatus = createSelector(
    getRealizationState,
    (state) => ({
        isLoading: state.project.isLoading,
        isLoaded: state.project.isLoaded,
        error: state.project.error
    })
)
export const selectRealizationLinksProjectId = createSelector(
    getRealizationState,
    (st) => st.links.projectId,
);
export const selectRealizationLinksLength = createSelector(
    getRealizationState,
    (st) => st.links.data.length,
);
export const selectRealizationLinksFilters = createSelector(
    getRealizationState,
    (st) => st.links.filters,
);
export const selectRealizationLinksData = createSelector(
    getRealizationState,
    (st) => st.links.data,
);
export const selectRealizationResourcesData = createSelector(
    getRealizationState,
    (st) => st.links.resources,
);
export const selectRealizationLinksStatus = createSelector(
    getRealizationState,
    (s) => ({
        isLoading: s.links.isLoading,
        isLoaded: s.links.isLoaded,
        error: s.links.error
    }),
);

