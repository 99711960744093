import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GetListSessionsErrorAction,
    GetListSessionsStartAction,
    GetListSessionsSuccessAction
} from "src/store/integration/actions";

export function* getListSessionsWorker({year}: ReturnType<typeof GetListSessionsStartAction>) {
    try {
        const {data} = yield axios({
            method: 'GET',
            url: `/kk/sessions/${year}/names`,
            headers: {},
        });
        yield put(GetListSessionsSuccessAction(data));
    } catch (error) {
        yield put(GetListSessionsErrorAction(error));
    }
}
