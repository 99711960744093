import { InferValueTypes } from 'src/types/common';
import { FilesActionTypes } from 'src/store/files/action-types';
import * as actions from './actions';
import { TDataStatus } from 'src/types/data-status';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TFilesState = Record<string, {
    data: any;
} & TDataStatus>;

export const initialState: TFilesState = {};

export default function reducer(
    state: TFilesState = initialState,
    action: ActionTypes,
): TFilesState {
    switch (action.type) {
        case
            FilesActionTypes.GET_FILE_START ||
            FilesActionTypes.GET_FILE_REPORT_START ||
            FilesActionTypes.GET_FILE_REALIZATION_START:
            return {
                ...state,
                [action.id]: {
                    data: state[action.id] ? state[action.id].data : null,
                    isLoading: true,
                    isLoaded: false,
                    error: null,
                }
            };
        case FilesActionTypes.GET_FILE_SUCCESS:
            return {
                ...state,
                [action.id]: {
                    data: action.file,
                    isLoading: false,
                    isLoaded: true,
                    error: null,
                }
            };
        case FilesActionTypes.GET_FILE_ERROR:
            return {
                ...state,
                [action.id]: {
                    data: {},
                    isLoading: false,
                    isLoaded: false,
                    error: action.error,
                }
            };
        default:
            return state;
    }
}
