import React, {FC} from 'react';
import ReactPlayer from "react-player";

export type SlideVideoProps = {
    file?: { fileName: string, id: number, type: string }
    url?: string
}
const SlideVideo:FC<SlideVideoProps> = ({file, url}) => {
    return (
        <>
            {url ?
                (<ReactPlayer  width='100%' url={url} controls={true}/>) :
                (
                    <video src={process.env.REACT_APP_API_ENDPOINT + `impl/projects/content/files/${file?.id}`}
                        width='100%'
                        height='auto'
                        controls
                        controlsList="nodownload"
                        crossOrigin='use-credentials'
                        muted
                        preload="metadata">
                    </video>
                )
            }
        </>
    )
};
export default SlideVideo;