import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TEditVotingRequestData } from 'src/types/vote';

import {
    GetEditorDetailedProjectStartAction,
    GetEditorDetailedProjectSuccessAction,
    GetEditorDetailedProjectErrorAction,
} from 'src/store/voting-editor/actions';

export function* editorGetDetailedProjectWorker(
    { votingId }: ReturnType<typeof GetEditorDetailedProjectStartAction>,
) {
    try {
        const { data }: AxiosResponse<TEditVotingRequestData> = yield axios({
            method: 'GET',
            url: `/voting/${votingId}`,
            headers: {},
        });

        yield put(GetEditorDetailedProjectSuccessAction(data));
    } catch (error) {
        yield put(GetEditorDetailedProjectErrorAction(error));
    }
}
