import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectUsersStatistic, selectUsersStatus} from "src/store/users/selectors";
import {GetUsersStatisticStartAction} from "src/store/users/actions";

import {Key, TablePaginationConfig} from "antd/lib/table/interface";
import styles from "./styles.module.scss";
import {NameCell} from "src/components/data-table/name-cell";
import {Table} from "antd";
import cn from "classnames";
import {UserLoginNameCell} from "src/components/data-table/userLoginAndName-cell";
import {DateCell} from "src/components/data-table/date-cell";
import {push} from "react-router-redux";
import {DateDurationCell} from "src/components/data-table/date-duration-cell";
import {ErrorBoundary} from "src/components/error-boundary";
import {Pagination} from "src/shared/lib/pagination";

export default function UsersStatisticPage() {
    const put = useDispatch();
    const usersStatistic = useSelector(selectUsersStatistic);
    const {isLoading} = useSelector(selectUsersStatus)

    useEffect(() => {
        put(GetUsersStatisticStartAction())
    }, [put]);

    const handleRowClick = (id: number) => {
        //открытие карточки
        put(push(`/users/${id}`));
    };

    // TODO: refactor this
    const handleTableChange = (_tableName: string) => (
        pagination: TablePaginationConfig,
        filter: Record<string, (Key | boolean)[] | null>,
        sorter: any,
    ) => {
        let newPageSize = {
            pageSize: 20
        }

        let newPagination = {
            page: 0
        };
        let newSorter = {
            sort: {
                direction: "DESC",
                field: "lastLoginDateTime"
            }
        };

        if (pagination) {
            newPagination = {
                page: pagination.current || 1
            }
            newPageSize = {
                pageSize: pagination.pageSize || 20
            }
        }
        if (sorter && sorter.order) {
            let direction = 'ASC';
            if (sorter.order === 'descend') {
                direction = 'DESC'
            }
            newSorter = {
                sort: {
                    field: sorter.field,
                    direction,
                },
            }
        }

        put(GetUsersStatisticStartAction({newPagination, newSorter, newPageSize}))
    };

    //render
    let columns = [
        {
            title: 'Дата последнего входа  на портал',
            dataIndex: 'lastLoginDateTime',
            key: 'lastLoginDateTime',
            sorter: true,
            width: 200,
            className: styles.nameCell,
            render: DateCell,
        },
        {
            title: 'Имя/Логин',
            dataIndex: `userFullName username`,
            width: 200,
            key: 'userFullName username',
            render: UserLoginNameCell,
        },
        {
            title: 'IP пользователя',
            dataIndex: 'ipAddress',
            key: 'ipAddress',
            width: 200,
            render: NameCell,
        },
        {
            title: 'Проведенное время на портале за сессию',
            dataIndex: 'websiteSpentTime',
            key: 'websiteSpentTime',
            width: 180,
            render: DateDurationCell,
        },
        {
            title: 'Количество  действий за сессию',
            dataIndex: 'actionsNumber',
            width: 120,
            key: 'actionsNumber',
            render: NameCell,
        }
    ];
    const renderProjectsTable = () => (
        <Table
            scroll={{ x: 900 }}
            sticky
            columns={columns}
            dataSource={usersStatistic.content}
            loading={isLoading}
            pagination={{
                current: usersStatistic.page + 1,
                pageSize: usersStatistic.pageSize,
                total: usersStatistic.totalCount,
                position: ['bottomCenter'],
                pageSizeOptions: Pagination()
            }}
            onChange={handleTableChange('statistic')}
            rowKey="lastLoginDateTime"
            onRow={(record, _rowIndex) => {
                return {
                    // @ts-ignore
                    onClick: (_event) => { handleRowClick(record.userId) },
                };
            }}
        />
    );

    return (
        <div className={styles.container}>
            <div className={cn(styles.leftColumn)}>
                <ErrorBoundary>
                    {usersStatistic && renderProjectsTable()}
                </ErrorBoundary>
            </div>

        </div>
    )
}
