import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetVotingDetailedDocumentStartAction,
    GetVotingDetailedDocumentSuccessAction,
    GetVotingDetailedDocumentErrorAction,
} from 'src/store/voting/actions';
import { selectIsModerator } from 'src/store/auth/selectors';
import { GetFileStartAction } from 'src/store/files/actions';
import { TVoteDetailedDocument } from 'src/types/vote';

export function* getVotingDetailedDocumentWorker({ votingId, projectId }: ReturnType<typeof GetVotingDetailedDocumentStartAction>) {
    try {
        const isModerator = yield select(selectIsModerator);
        const { data }: AxiosResponse<TVoteDetailedDocument> = yield axios({
            method: 'GET',
            url: isModerator
                ? `/voting/blocks/${projectId}`
                : `/user/voting/${votingId}/blocks/${projectId}`,
            headers: {},
        });

        // get files
        if (data.files && data.files.length > 0) {
            for (const file of data.files) {
                yield put(GetFileStartAction(`${file.id}`));
            }
        }

        yield put(GetVotingDetailedDocumentSuccessAction(data));
    } catch (error) {
        yield put(GetVotingDetailedDocumentErrorAction(error));
    }
}
