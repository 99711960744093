import {InferValueTypes} from "src/types/common";
import { RealizationActionTypes } from 'src/store/realization/action-types';
import * as actions from "../actions";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TTypesRealization = {
    types: [],
    isLoading: boolean,
    isLoaded: boolean,
    error: any,
}

export const initialState: TTypesRealization = {
    types: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}
export default function TypesRealizationReducer(
    state: TTypesRealization = initialState,
    action: ActionTypes
): TTypesRealization {
    switch (action.type) {
        case RealizationActionTypes.GET_PROJECT_TYPES_START:
            return {
                ...initialState,
                isLoading: true
            }
        case RealizationActionTypes.GET_PROJECT_TYPES_SUCCESS:
            return {
                ...state,
                types: action.types,
                isLoading: false,
                isLoaded: true
            }
        case RealizationActionTypes.GET_PROJECT_TYPES_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: action.error
            }
        default:
            return state
    }
}