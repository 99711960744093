import {InferValueTypes} from "src/types/common";
import { RealizationActionTypes } from 'src/store/realization/action-types';
import * as actions from "../actions";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TProjectRealization = {
    project: [],
    idProject?: number | null,
    projectContent: {
        id: number,
        type: {
            id: number,
            code: 'VIDEO_CONTENT' | 'AUDIO_CONTENT' | 'PHOTO_CONTENT',
            name: string
        }
        name: string,
        description: string
        items: any[]
    } | null,
    contentId: number | null,
    isLoading: boolean,
    isLoaded: boolean,
    error: any,
}

export const initialState: TProjectRealization = {
    project: [],
    idProject: null,
    contentId: null,
    projectContent: null,
    isLoading: false,
    isLoaded: false,
    error: null,
}

export default function ProjectReducer(
    state: TProjectRealization = initialState,
    action: ActionTypes
): TProjectRealization {
    switch (action.type) {
        case RealizationActionTypes.GET_PROJECT_START:
            return {
                ...state,
                isLoading: true
            }
        case RealizationActionTypes.GET_PROJECT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                project: action.project,
                idProject: action.projectId
            }
        case RealizationActionTypes.GET_PROJECT_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: action.error
            }

        case RealizationActionTypes.GET_PROJECT_CONTENT_START:
            return {
                ...state,
                isLoading: true
            }
        case RealizationActionTypes.GET_PROJECT_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                projectContent: action.content
            }
        case RealizationActionTypes.GET_PROJECT_CONTENT_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: action.error
            }

        case RealizationActionTypes.CREATE_PROJECT_CONTENT_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case RealizationActionTypes.CREATE_PROJECT_CONTENT_SUCCESS:
            return {
                ...state,
                contentId: action.contentId,
                isLoading: false,
                isLoaded: true
            }
        case RealizationActionTypes.CREATE_PROJECT_CONTENT_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: action.error
            }

        case RealizationActionTypes.ADD_PROJECT_CONTENT_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case RealizationActionTypes.ADD_PROJECT_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            }
        case RealizationActionTypes.ADD_PROJECT_CONTENT_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: action.error
            }

        case RealizationActionTypes.UPDATE_PROJECT_CONTENT_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case RealizationActionTypes.UPDATE_PROJECT_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            }
        case RealizationActionTypes.UPDATE_PROJECT_CONTENT_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: action.error
            }

        case RealizationActionTypes.DELETE_PROJECT_CONTENT_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case RealizationActionTypes.DELETE_PROJECT_CONTENT_SUCCESS:
            return {
                ...initialState,
                isLoading: false,
                isLoaded: true
            }
        case RealizationActionTypes.DELETE_PROJECT_CONTENT_ERROR:
            return {
                ...initialState,
                isLoading: false,
                isLoaded: true,
                error: action.error
            }
        default:
            return state
    }
}