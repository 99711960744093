import { put } from 'redux-saga/effects';

import {
    GetActionsErrorAction,
    GetActionsStartAction,
    GetActionsSuccessAction,
} from "src/store/logs/actions";
import axios from "axios";

export function* getActionsWorker(_action: ReturnType<typeof GetActionsStartAction>) {
    try {
        const { data } = yield axios({
            method: 'GET',
            url: `/users/actions/types`,
            headers: {},
        });
        yield put(GetActionsSuccessAction(data));
    } catch (error) {
        yield put(GetActionsErrorAction(error));
    }
}
