import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetProjectsStartAction,
    PutProjectsActionsErrorAction,
    PutProjectsActionsRejectAction,
    PutProjectsActionsStartAction,
    PutProjectsActionsSuccessAction,
} from 'src/store/projects/actions';

export function* massActionWorker({ ids, typeAction }: ReturnType<typeof PutProjectsActionsStartAction>) {
    try {
        const { data: responseData }: AxiosResponse<any> = yield axios({
            method: 'PUT',
            url: `/projects/bulk_actions/${typeAction}`,
            headers: {},
            data: ids
        });
        if (responseData.success) {
            yield put(PutProjectsActionsSuccessAction())
            yield put(GetProjectsStartAction())
        }
        else yield put(PutProjectsActionsRejectAction(responseData.failedIds))

    } catch (error) {
        yield put(PutProjectsActionsErrorAction(error));
    }
}
