import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

import { selectFile } from 'src/store/files/selectiors';

import styles from './styles.module.scss'

export type SlideImgProps = {
    file?: { fileName: string, id: number, type: string }
    url?: string
    name: string
}

const SlideImg:FC<SlideImgProps> = ({file, url, name}) => {
    // @ts-ignore
    const img = useSelector(selectFile(file ? `${file.id}` : undefined));
    if (!img) return <LoadingOutlined />;

    const fileUel = !!file?.id ? process.env.REACT_APP_API_ENDPOINT + `impl/projects/content/files/${file.id}` : '/images/no-img.png'

    return (
        <>
            {url ? (<img className={styles.imgContent} src={url} alt={name} />) :
                (
                    <img
                        className={styles.imgContent}
                        src={typeof img === "string" ? img : fileUel}
                        alt={name}
                    />
                )
            }
        </>
    )
};
export default SlideImg;