import React, {MouseEvent} from 'react';
import './index.scss';
import {Button} from "antd";
// import {ErrorAuthSvg} from "assets/svg";
import {useKeycloak} from "@react-keycloak/web";
import env from 'src/shared/config/env';

const ErrorAuth = () => {
    const {keycloak, initialized} = useKeycloak();
    const onButtonClick = (_event: MouseEvent<HTMLElement>) => {
        keycloak.login();
    }

    return (
        <div className="ErrorAuth">
            {/*<ErrorAuthSvg/>*/}
            <h2>Что-то пошло не так....</h2>
            <p>
                Произошла техническая ошибка. <br/>
                Попробуйте еще раз.
            </p>
            <div className={"ErrorAuth__btn"}>
                <Button
                    onClick={onButtonClick}
                    size={"large"}
                    type={"primary"}
                    disabled={!initialized}
                >
                    Повторить попытку
                </Button>
                {env.keycloackRedirectUrl && (
                    <Button type={'primary'} size={"large"}>
                        <a href={env.keycloackRedirectUrl}>Выбрать другую систему</a>
                    </Button>
                )}
            </div>

        </div>
    )
}

export default ErrorAuth;