import React, {SyntheticEvent, useEffect, useState,} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Spin, Tabs, Tooltip} from 'antd';

import pdfImg from "src/assets/img/pdf.png";
import viewDocumentImg from "src/assets/img/view-document.png";

import {GetProjInfoStartAction} from 'src/store/project-info/actions';
import {LogsActionsStartAction} from "src/store/logs/actions";

import {selectProjInfo, selectProjInfoStatus} from 'src/store/project-info/selectiors';
import {selectFile} from 'src/store/files/selectiors';

import {ErrorBoundary} from 'src/components/error-boundary';
import {mainModuleRoutes} from 'src/routing/projects-module';
import {ToParentPage} from 'src/components/to-parent-page';
import {PDFModal} from 'src/components/modals/pdf-modal';

import {TProjectContent} from 'src/types/projects';
import {TPDFModal} from 'src/types/modals';

import styles from './styles.module.scss';
import ProjectUi from "src/components/project-ui";
import Confidencial from "src/components/confidencial";


export const ProjectsDetailedPage = () => {
    const [modalData, setModalData] = useState<TPDFModal>(null);
    const [tabs, setTabs] = useState<string>('card');

    const { pathname } = useLocation();
    const id = pathname.split('/')[2];
    const put = useDispatch();

    const data: TProjectContent = useSelector(selectProjInfo(id));
    const {isLoading} = useSelector(selectProjInfoStatus(id));

    const presentationFile = useSelector(selectFile(
        (data && data.presentationFile) ? `${data.presentationFile.id}` : undefined
    ));

    useEffect(() => {
        if (id) put(GetProjInfoStartAction(id));
    }, [id]);

    const openPDFModal = (file: any, name: string) => (_e?: SyntheticEvent) => {

        try {
            let fileURL = window.URL.createObjectURL(file);
            setModalData({
                fileURL: fileURL,
                id: String(data.id),
                name,
            });
            put(LogsActionsStartAction(
                'VIEW_PROJECT_ATTACHMENTS',
                String(data.id),
                data.name
            ));
        } catch (error) {}
    };
    const closePDFModal = () => {
        setModalData(null);
    };


    const handleChangeTabs = (key: string) => {
        setTabs(key)
        if (key === 'pdf') {
            if (presentationFile !== null) {
                openPDFModal(presentationFile, 'Презентация')();
                setTabs('card')
            }
        }
        if (key === 'video') put(LogsActionsStartAction('VIEW_VIDEO_PRESENTATION', id, data.name))
    };


    return (
        <ErrorBoundary>
            <div className={styles.container}>
                <div className={styles.return}>
                    <ToParentPage text="" parentPath={mainModuleRoutes.list.path} />
                </div>
                <div className={styles.content}>
                    {data.isConfidential && <Confidencial/>}

                    <Tabs
                        activeKey={tabs}
                        onChange={handleChangeTabs}
                    >
                        <Tabs.TabPane tab="Карточка проекта" key="card">
                            <Spin spinning={isLoading}>
                                {data && <ProjectUi project={data} type={'project'}/>}
                            </Spin>
                        </Tabs.TabPane>

                        {data.presentation && data.presentation.url && (
                            <Tabs.TabPane tab="Видеопрезентация" key="video">
                                <div className={styles.video}>
                                    <iframe
                                        className={styles.video}
                                        title="presentation"
                                        width="510"
                                        height="264"
                                        src={data.presentation.url}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                                    </iframe>
                                </div>
                            </Tabs.TabPane>
                        )}



                        {data.presentationFile?.id && (
                            <Tabs.TabPane tab="Презентация" key="pdf">
                                <div
                                    className={styles.document}
                                    onClick={openPDFModal(presentationFile, 'Презентация')}>
                                    <img src={pdfImg} alt="pdf" className={styles.pdfImg} />
                                    <span>Презентация</span>
                                    <Tooltip placement="top" title="Просмотр документа">
                                        <img
                                            src={viewDocumentImg}
                                            alt="document"
                                            className={styles.documentImg} />
                                    </Tooltip>
                                </div>
                            </Tabs.TabPane>
                        )}
                    </Tabs>

                </div>
                <PDFModal
                    fileURL={modalData?.fileURL as string}
                    name={modalData?.name}
                    isOpened={Boolean(modalData)}
                    onClose={closePDFModal}
                />
            </div>
        </ErrorBoundary>
    );
};
