import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from 'axios';

import {
    DeleteVotingAction,
} from 'src/store/voting-editor/actions';

import { mainModuleRoutes } from 'src/routing/projects-module';

export function* editorDeleteVotingWorker(
    { id }: ReturnType<typeof DeleteVotingAction>,
) {
    try {
        yield axios({
            method: 'DELETE',
            url: `/voting/${id}`,
            headers: {},
        });

        yield put(push(mainModuleRoutes['all-votes-list'].path));
    } catch (_error) {}
}
