import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    VoteStartAction,
    VoteSuccessAction,
    VoteErrorAction,
    GetProjectsVotingIdTriggerAction,
    GetDocumentsVotingIdTriggerAction,
} from 'src/store/voting/actions';

export function* voteWorker({ data: { voteDetails, id, isList } }: ReturnType<typeof VoteStartAction>) {
    try {
        id ?
            yield axios({
                method: 'PUT',
                url: `/user/voting/votes/${id}`,
                headers: {},
                data: voteDetails,
            })
            : yield axios({
                method: 'POST',
                url: `/user/voting/votes`,
                headers: {},
                data: voteDetails,
            });

        yield put(VoteSuccessAction());

        if (isList) {
            yield put(GetProjectsVotingIdTriggerAction());
            yield put(GetDocumentsVotingIdTriggerAction());
        }
    } catch (error) {
        console.log('voteWorker', error);
        yield put(VoteErrorAction(error));
    }
}
