export const ProjectsActionTypes = {
    GET_PROJECTS_START: '[PROJECTS] GET_PROJECTS_START',
    GET_PROJECTS_SUCCESS: '[PROJECTS] GET_PROJECTS_SUCCESS',
    GET_PROJECTS_ERROR: '[PROJECTS] GET_PROJECTS_ERROR',

    SET_FILTERS: '[PROJECTS] SET_FILTERS',
    RESET_FILTERS: '[PROJECTS] RESET_FILTERS',

    ADD_PROJECT_START: '[PROJECTS] ADD_PROJECT_START',
    ADD_PROJECT_SUCCESS: '[PROJECTS] ADD_PROJECT_SUCCESS',
    ADD_PROJECT_ERROR: '[PROJECTS] ADD_PROJECT_ERROR',

    EDIT_PROJECT_START: '[PROJECTS] EDIT_PROJECT_START',
    EDIT_PROJECT_SUCCESS: '[PROJECTS] EDIT_PROJECT_SUCCESS',
    EDIT_PROJECT_ERROR: '[PROJECTS] EDIT_PROJECT_ERROR',

    DELETE_PROJECT_START: '[PROJECTS] DELETE_PROJECT_START',
    DELETE_PROJECT_SUCCESS: '[PROJECTS] DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_ERROR: '[PROJECTS] DELETE_PROJECT_ERROR',

    RESET_ADMINS_PROJECTS_FILTERS: '[PROJECTS] RESET_ADMINS_PROJECTS_FILTERS',
    SET_ADMINS_PROJECTS_FILTERS: '[PROJECTS] SET_ADMINS_PROJECTS_FILTERS',
    GET_PROJECTS_BY_VOTING_ID_START: '[PROJECTS] GET_PROJECTS_BY_VOTING_ID_START',
    GET_PROJECTS_BY_VOTING_ID_SUCCESS: '[PROJECTS] GET_PROJECTS_BY_VOTING_ID_SUCCESS',
    GET_PROJECTS_BY_VOTING_ID_ERROR: '[PROJECTS] GET_PROJECTS_BY_VOTING_ID_ERROR',

    RESET_ADMINS_DOCUMENTS_FILTERS: '[PROJECTS] RESET_ADMINS_DOCUMENTS_FILTERS',
    SET_ADMINS_DOCUMENTS_FILTERS: '[PROJECTS] SET_ADMINS_DOCUMENTS_FILTERS',
    GET_DOCUMENTS_BY_VOTING_ID_START: '[PROJECTS] GET_DOCUMENTS_BY_VOTING_ID_START',
    GET_DOCUMENTS_BY_VOTING_ID_SUCCESS: '[PROJECTS] GET_DOCUMENTS_BY_VOTING_ID_SUCCESS',
    GET_DOCUMENTS_BY_VOTING_ID_ERROR: '[PROJECTS] GET_DOCUMENTS_BY_VOTING_ID_ERROR',

    PUT_PROJECTS_ACTIONS_START: '[PROJECTS] PUT_PROJECTS_ACTIONS_START',
    PUT_PROJECTS_ACTIONS_SUCCESS: '[PROJECTS] PUT_PROJECTS_ACTIONS_SUCCESS',
    PUT_PROJECTS_ACTIONS_REJECT: '[PROJECTS] PUT_PROJECTS_ACTIONS_REJECT',
    PUT_PROJECTS_ACTIONS_ERROR: '[PROJECTS] PUT_PROJECTS_ACTIONS_ERROR',

} as const;
