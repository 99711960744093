import React, {FC, useEffect, useMemo, useState} from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styles from './styles.module.scss'
import {CarouselProvider, Slider} from "pure-react-carousel";
import CustomCardSlide from "src/components/slider-component/card-slides";
import CustomButton from "src/components/slider-component/CustomButton";
import {TTypeContent} from "src/features/realization-detailed-page/item-content";

export type TSlide = {
    name: string,
    description?: string,
    id: number,
    order: number,
    url?: string,
    coverImage?: string
    file?: {
        fileName: string,
        id: number,
        type: string
    }
}

type SliderComponentProps = {
    type: TTypeContent,
    data: TSlide[],
    handler: any
    image?: string | null
}

const visibleSlides = () => {
    const w = window.innerWidth
    if (w >= 1500) return 5
    if (w >= 1280) return 4
    if (w >= 920) return 3
    if (w >= 900) return 2
    if (w >= 500) return 1
}

const SliderComponent: FC<SliderComponentProps> = ({
    type, data, handler, image
}) => {
    const count: number = useMemo(() => {
        let counter = 0;
        data.forEach((el) => {
            if (el.file || el.url) counter += 1
        })
        return counter;
    }, [data]);
    const [mainIndex, setMainIndex] = useState(0);

    const handleCart = (e: any, index: number) => {
        handler(type, data[index], e)
    }

    const handleButton = (type: 'left' | 'right') => {
        switch (type) {
            case "left":
                if (mainIndex === 0) {
                    setMainIndex(count - 1);
                    break
                }
                setMainIndex(prevState => prevState - 1);
                break
            case "right":
                if (mainIndex === count - 1) {
                    setMainIndex(0);
                    break
                }
                setMainIndex(prevState => prevState + 1);
                break
        }

    }
    useEffect(() => {
        handler(type, data[mainIndex])
    }, [mainIndex])


    const slides = (
        <>
            {/*// @ts-ignore*/}
            <Slider className={styles.sliderItems}>
                {data && data.map((el, idx) => {
                    if (el.file || el.url) {
                        return (
                            <CustomCardSlide
                                fileId={el.file?.id}
                                coverImage={el.coverImage}
                                type={type}
                                key={el.id}
                                index={idx}
                                name={el.name}
                                description={el.description}
                                handler={handleCart}
                                image={image}
                            />
                        )
                    } else return null
                })}
            </Slider>
        </>
    )
    return (
        <div className={`${styles.container} ${styles.containerCarousel}`}>
            {/*// @ts-ignore*/}
            <CarouselProvider
                naturalSlideWidth={240}
                naturalSlideHeight={300}
                totalSlides={count}
                visibleSlides={visibleSlides()}
                className={styles.slider}
                currentSlide={mainIndex}
            >
                {slides}
                <CustomButton type={"left"} handle={handleButton}/>
                <CustomButton type={"right"} handle={handleButton}/>
            </CarouselProvider>
        </div>
    )
};
export default SliderComponent;