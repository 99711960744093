import React from "react";
import styles from "./pdf.module.scss";
import {Tooltip} from "antd";
import {ReactComponent as PDF} from './PDF.svg'
import classNames from "classnames";


type PdfItemProps = {
    files: any[],
    data: object,
    label: string,
    openPDFModal: any,
    text: string
}

const PdfItem:React.FC<PdfItemProps> = ({files, data, label, openPDFModal, text}) => {
    return (
        <div className={styles.item}>
            <div className={classNames(styles.title, styles.strong)}>{text}</div>
            <div className={styles.documentsList}>
                { files.map((file, index: number) => {
                    let name: string = '';

                    try {
                        // @ts-ignore
                        name = data[label] ? data[label][index].fileName : '';
                        name = name.split('.').slice(0, -1).join('.')
                    } catch (_e) {
                        // @ts-ignore
                        name = data[label] ? data[label][index].fileName : '';
                    }
                    return (
                        <Tooltip placement="top" title="Просмотр документа">
                            <div
                                key={`${index}_${name}`}
                                className={styles.document}
                                onClick={openPDFModal(file, name)}
                            >

                                <PDF className={styles.documentImg}/>

                                <span>{ name }</span>
                            </div>
                        </Tooltip>
                    );
                }) }
            </div>
        </div>
    )
}

export default PdfItem;