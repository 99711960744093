import {
    takeLatest
} from 'redux-saga/effects';

import {RealizationActionTypes} from "src/store/realization/action-types";

import {getProjectContentTypesWorker} from "src/workers/realization/get-projectContentTypes-worker";
import {getRealizationProjectWorker} from "src/workers/realization/get-project-worker";
import {getRealizationProjectContentWorker} from "src/workers/realization/get-projectContent-worker";
import {addProjectContentWorker} from "src/workers/realization/add-projectContent-worker";
import {createProjectContentWorker} from "src/workers/realization/create-projectContent-worker";
import {updateProjectContentWorker} from "src/workers/realization/update-projectContent-worker";
import { getRealizationListWorker } from 'src/workers/realization/get-realization-list';
import { getRealizationLinksWorker } from 'src/workers/realization/get-realization-links';
import {deleteProjectContentWorker} from "src/workers/realization/delete-projectContent-worker";
import {crawlerWorker} from "src/workers/realization/crawler-worker";
import {updateProjectWorker} from "src/workers/realization/update-project-worker";
import {createRealizationLinksWorker} from "src/workers/realization/create-realization-links";
import {updateRealizationLinksWorker} from "src/workers/realization/update-realization-links";
import {deleteRealizationLinksWorker} from "src/workers/realization/delete-realization-links";
import {getRealizationResourcesWorker} from "src/workers/realization/get-resources-worker";

export default function* realizationSaga() {
    yield takeLatest(RealizationActionTypes.GET_PROJECT_TYPES_START, getProjectContentTypesWorker)
    yield takeLatest(RealizationActionTypes.GET_PROJECT_START, getRealizationProjectWorker)
    yield takeLatest(RealizationActionTypes.GET_PROJECT_CONTENT_START, getRealizationProjectContentWorker)
    yield takeLatest(RealizationActionTypes.ADD_PROJECT_CONTENT_START, addProjectContentWorker)
    yield takeLatest(RealizationActionTypes.CREATE_PROJECT_CONTENT_START, createProjectContentWorker)
    yield takeLatest(RealizationActionTypes.UPDATE_PROJECT_CONTENT_START, updateProjectContentWorker)
    yield takeLatest(RealizationActionTypes.UPDATE_PROJECT_START, updateProjectWorker)
    yield takeLatest(RealizationActionTypes.DELETE_PROJECT_CONTENT_START, deleteProjectContentWorker)
    yield takeLatest(RealizationActionTypes.GET_LIST_START, getRealizationListWorker)
    yield takeLatest(RealizationActionTypes.GET_LINKS_START, getRealizationLinksWorker)
    yield takeLatest(RealizationActionTypes.CREATE_LINKS_START, createRealizationLinksWorker)
    yield takeLatest(RealizationActionTypes.UPDATE_LINKS_START, updateRealizationLinksWorker)
    yield takeLatest(RealizationActionTypes.DELETE_LINKS_START, deleteRealizationLinksWorker)
    yield takeLatest(RealizationActionTypes.CRAWLER_START, crawlerWorker)
    yield takeLatest(RealizationActionTypes.GET_INTERNET_RESOURCES_START, getRealizationResourcesWorker)
}



