function getMode(): TMode {
    const url = new URL(window.location.href).hostname;

    if (url === 'localhost') return 'local';
    if (url === 'kk-1.iri.center') return 'test';
    return 'prod'
}

function getKeycloackConfig(mode: TMode): TEnv["keycloack"] {
    if (mode !== 'prod') {
        return {
            KEYCLOAK_URL: 'https://sso-dev-keycloak-01.iri.center',
            KEYCLOAK_REALM: 'iri',
            KEYCLOAK_CLIENT: 'kk-dev',
        }
    }
    return {
        KEYCLOAK_URL: 'https://xn--b1ae3a1a.xn--h1aax.xn--p1ai/',
        KEYCLOAK_REALM: 'iri',
        KEYCLOAK_CLIENT: 'kk',
    }
}
function getKeycloackRedirectUri(mode: TMode): TEnv["keycloackRedirectUrl"] {
    if (mode === 'prod') return 'https://xn--b1ae3a1a.xn--h1aax.xn--p1ai/'
    return 'https://sso-dev-front-01.iri.center/'
}

export type TMode = 'prod' | 'test' | 'local';
type TEnv = {
    mode: TMode,
    keycloack: {
        KEYCLOAK_URL: string
        KEYCLOAK_REALM: string
        KEYCLOAK_CLIENT: string
    }
    keycloackRedirectUrl: string | null
}
class Env implements TEnv {
    readonly mode: TEnv['mode'];
    readonly keycloack: TEnv['keycloack'];
    readonly keycloackRedirectUrl: TEnv['keycloackRedirectUrl'];
    constructor() {
        const mode = getMode();
        console.log('mode', mode)
        this.mode = mode;
        this.keycloack = getKeycloackConfig(mode);
        this.keycloackRedirectUrl = getKeycloackRedirectUri(mode);
    }
}
const env = new Env();
export default env;