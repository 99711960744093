import {InferValueTypes} from 'src/types/common';
import {ProjectsActionTypes} from 'src/store/projects/action-types';
import * as actions from 'src/store/projects/actions';
import {TProjectContent, TProjectsOptions} from 'src/types/projects';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TProjectsState = {
    filters: TProjectsOptions;
    projects: TProjectContent[];
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TProjectsState = {
    filters: {
        filterData: {
            categoryIds: [],
            notSelected: false,
            implemented: false,
            userAvailable: false,
            unloaded: false,
            minScore: 0,
            maxScore: 10,
            searchString: '',
            sessionName: undefined,
            year: undefined,
        },
        page: 1,
        pageSize: 20,
        totalCount: 0,
        sort: {
            direction: 'ASC',
            field: 'id',
        }
    },
    projects: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function usersProjects(
    state: TProjectsState = initialState,
    action: ActionTypes,
): TProjectsState {
    switch (action.type) {
        case ProjectsActionTypes.RESET_FILTERS:
            return {
                ...state,
                filters: { ...initialState.filters },
            };
        case ProjectsActionTypes.SET_FILTERS:
            return {
                ...state,
                filters: action.filters,
            };
        case ProjectsActionTypes.GET_PROJECTS_BY_VOTING_ID_START:
        case ProjectsActionTypes.GET_PROJECTS_START:
            return {
                ...initialState,
                projects: state.projects,
                filters: state.filters,
                isLoading: true,
            };
        case ProjectsActionTypes.GET_PROJECTS_SUCCESS:
            return {
                ...initialState,
                projects: action.projects,
                filters: state.filters,
                isLoading: false,
                isLoaded: true,
            };
        case ProjectsActionTypes.GET_PROJECTS_ERROR:
            return {
                ...initialState,
                filters: state.filters,
                error: action.error,
            };
        default:
            return state;
    }
}
