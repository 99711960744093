export const SessionsActionTypes = {
    SET_FILTERS: '[SESSIONS] SET_FILTERS',

    GET_SESSIONS_START: '[SESSIONS] GET_SESSIONS_START',
    GET_SESSIONS_SUCCESS: '[SESSIONS] GET_SESSIONS_SUCCESS',
    GET_SESSIONS_ERROR: '[SESSIONS] GET_SESSIONS_ERROR',

    GET_ALL_SESSIONS_START: '[SESSIONS] GET_ALL_SESSIONS_START',
    GET_ALL_SESSIONS_SUCCESS: '[SESSIONS] GET_ALL_SESSIONS_SUCCESS',
    GET_ALL_SESSIONS_ERROR: '[SESSIONS] GET_ALL_SESSIONS_ERROR',

    ADD_SESSION_START: '[SESSIONS] ADD_SESSION_START',
    ADD_SESSION_SUCCESS: '[SESSIONS] ADD_SESSION_SUCCESS',
    ADD_SESSION_ERROR: '[SESSIONS] ADD_SESSION_ERROR',

    EDIT_SESSION_START: '[SESSIONS] EDIT_SESSION_START',
    EDIT_SESSION_SUCCESS: '[SESSIONS] EDIT_SESSION_SUCCESS',
    EDIT_SESSION_ERROR: '[SESSIONS] EDIT_SESSION_ERROR',

    DELETE_SESSION_START: '[SESSIONS] DELETE_SESSION_START',
    DELETE_SESSION_SUCCESS: '[SESSIONS] DELETE_SESSION_SUCCESS',
    DELETE_SESSION_ERROR: '[SESSIONS] DELETE_SESSION_ERROR',
} as const;
