import {InternetResourcesActionTypes} from "src/store/internet-resources/action-types";

export const GetInternetResourcesStartAction = () => ({
    type: InternetResourcesActionTypes.GET_INTERNET_RESOURCES_START,
});
export const GetInternetResourcesSuccessAction = (resources: any) => ({
    type: InternetResourcesActionTypes.GET_INTERNET_RESOURCES_SUCCESS,
    resources,
});
export const GetInternetResourcesErrorAction = (error: any) => ({
    type: InternetResourcesActionTypes.GET_INTERNET_RESOURCES_ERROR,
    error,
});

export const GetOneInternetResourcesStartAction = (resourceId: string | number) => ({
    type: InternetResourcesActionTypes.GET_ONE_INTERNET_RESOURCES_START,
    resourceId
});
export const GetOneInternetResourcesSuccessAction = (resource: any) => ({
    type: InternetResourcesActionTypes.GET_ONE_INTERNET_RESOURCES_SUCCESS,
    resource,
});
export const GetOneInternetResourcesErrorAction = (error: any) => ({
    type: InternetResourcesActionTypes.GET_ONE_INTERNET_RESOURCES_ERROR,
    error,
});

export const CreateInternetResourcesStartAction = (resource: any) => ({
    type: InternetResourcesActionTypes.CREATE_INTERNET_RESOURCES_START,
    resource
});
export const CreateInternetResourcesSuccessAction = () => ({
    type: InternetResourcesActionTypes.CREATE_INTERNET_RESOURCES_SUCCESS,
});
export const CreateInternetResourcesErrorAction = (error: any) => ({
    type: InternetResourcesActionTypes.CREATE_INTERNET_RESOURCES_ERROR,
    error,
});

export const UpdateInternetResourcesStartAction = (resource: any, resourceId: string | number) => ({
    type: InternetResourcesActionTypes.UPDATE_INTERNET_RESOURCES_START,
    resource,
    resourceId
});
export const UpdateInternetResourcesSuccessAction = () => ({
    type: InternetResourcesActionTypes.UPDATE_INTERNET_RESOURCES_SUCCESS,
});
export const UpdateInternetResourcesErrorAction = (error: any) => ({
    type: InternetResourcesActionTypes.UPDATE_INTERNET_RESOURCES_ERROR,
    error,
});

export const DeleteInternetResourcesStartAction = (resourceId: string | number) => ({
    type: InternetResourcesActionTypes.DELETE_INTERNET_RESOURCES_START,
    resourceId
});
export const DeleteInternetResourcesSuccessAction = () => ({
    type: InternetResourcesActionTypes.DELETE_INTERNET_RESOURCES_SUCCESS,
});
export const DeleteInternetResourcesErrorAction = (error: any) => ({
    type: InternetResourcesActionTypes.DELETE_INTERNET_RESOURCES_ERROR,
    error,
});