import { InferValueTypes } from 'src/types/common';
import { VotingActionTypes } from 'src/store/voting/action-types';
import * as actions from '../actions';
import { TDataStatus } from 'src/types/data-status';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TVotingProjectsState = {
    data: any;
} & TDataStatus;

export const initialState: TVotingProjectsState = {
    data: {
        content: [],
        page: 1,
        pageSize: 20,
        totalCount: 0,
        sort: {
            direction: 'ASC',
            field: 'id',
        },
    },
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function reducer(
    state: TVotingProjectsState = initialState,
    action: ActionTypes,
): TVotingProjectsState {
    switch (action.type) {
        case VotingActionTypes.SET_PROJECTS_LIST_FILTERS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data
                }
            };
        case VotingActionTypes.RESET_PROJECTS_LIST_FILTERS:
            return {
                ...state,
                data: {
                    ...initialState.data
                }
            };
        case VotingActionTypes.GET_VOTING_PROJECTS_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null,
            };
        case VotingActionTypes.GET_VOTING_PROJECTS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data
                },
                isLoading: false,
                isLoaded: true,
                error: null,
            };
        case VotingActionTypes.GET_VOTING_PROJECTS_ERROR:
            return {
                ...state,
                data: {},
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };
        default:
            return state;
    }
}
