import {
    call,
    put,
    select,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetReportingYearsTriggerAction,
    GetReportingYearsStartAction,
    GetReportingYearsSuccessAction,
    GetReportingYearsErrorAction,
    GetReportingQuartersTriggerAction, SetReportingFiltersAction,
} from 'src/store/reporting/actions';
import { selectReportingListFilters } from 'src/store/reporting/selectors';

import { getReportingQuartersWorker } from 'src/workers/reporting/getQuarters';

export function* getReportingYearsWorker({ options }: ReturnType<typeof GetReportingYearsTriggerAction>) {
    try {
        yield put(GetReportingYearsStartAction());
        const { data: responseData }: AxiosResponse<number[]> = yield axios({
            method: 'GET',
            url: `/reporting/years`,
            headers: {},
        });

        yield put(GetReportingYearsSuccessAction(responseData));

        const filters = yield select(selectReportingListFilters);

        if (responseData.length > 0) {
            yield put(SetReportingFiltersAction({
                ...filters,
                filterData: {
                    ...filters.filterData,
                    year: responseData[responseData.length - 1],
                }
            }));
        }

        yield call(getReportingQuartersWorker, GetReportingQuartersTriggerAction(options));
    } catch (error) {
        yield put(GetReportingYearsErrorAction(error));
    }
}
