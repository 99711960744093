import { ReportingActionTypes } from 'src/store/reporting/action-types';
import {
    TReportingContentItem,
    TReportingOptions,
    TReportTypeItem,
} from 'src/types/reporting';

export const TriggerGetReportingFiltersAction = () => ({
    type: ReportingActionTypes.TRIGGER_GET_FILTERS,
});
export const SetReportingFiltersAction = (filters: TReportingOptions) => ({
    type: ReportingActionTypes.SET_FILTERS,
    filters,
});
export const ResetReportingFiltersAction = () => ({
    type: ReportingActionTypes.RESET_FILTERS,
});

export const GetReportingListTriggerAction = () => ({
    type: ReportingActionTypes.GET_LIST_TRIGGER,
});
export const GetReportingListStartAction = () => ({
    type: ReportingActionTypes.GET_LIST_START,
});
export const GetReportingListSuccessAction = (data: TReportingContentItem[]) => ({
    type: ReportingActionTypes.GET_LIST_SUCCESS,
    data,
});
export const GetReportingListErrorAction = (error: any) => ({
    type: ReportingActionTypes.GET_LIST_ERROR,
    error,
});

export const GetReportingYearsTriggerAction = (options: {
    withProjects?: boolean;
}) => ({
    type: ReportingActionTypes.GET_YEARS_TRIGGER,
    options,
});
export const GetReportingYearsStartAction = () => ({
    type: ReportingActionTypes.GET_YEARS_START,
});
export const GetReportingYearsSuccessAction = (data: number[]) => ({
    type: ReportingActionTypes.GET_YEARS_SUCCESS,
    data,
});
export const GetReportingYearsErrorAction = (error: any) => ({
    type: ReportingActionTypes.GET_YEARS_ERROR,
    error,
});

export const GetReportingQuartersTriggerAction = (options: {
    withProjects?: boolean;
}) => ({
    type: ReportingActionTypes.GET_QUARTERS_TRIGGER,
    options,
});
export const GetReportingQuartersStartAction = () => ({
    type: ReportingActionTypes.GET_QUARTERS_START,
});
export const GetReportingQuartersSuccessAction = (data: number[]) => ({
    type: ReportingActionTypes.GET_QUARTERS_SUCCESS,
    data,
});
export const GetReportingQuartersErrorAction = (error: any) => ({
    type: ReportingActionTypes.GET_QUARTERS_ERROR,
    error,
});

export const GetReportingTypesTriggerAction = () => ({
    type: ReportingActionTypes.GET_TYPES_TRIGGER,
});
export const GetReportingTypesStartAction = () => ({
    type: ReportingActionTypes.GET_TYPES_START,
});
export const GetReportingTypesSuccessAction = (data: TReportTypeItem[]) => ({
    type: ReportingActionTypes.GET_TYPES_SUCCESS,
    data,
});
export const GetReportingTypesErrorAction = (error: any) => ({
    type: ReportingActionTypes.GET_TYPES_ERROR,
    error,
});

export const CreateReportStartAction = (data: any, reportTypeId: number) => ({
    type: ReportingActionTypes.CREATE_REPORT_START,
    data,
    reportTypeId
});
export const CreateReportSuccessAction = () => ({
    type: ReportingActionTypes.CREATE_REPORT_SUCCESS
});
export const CreateReportErrorAction = (error: any) => ({
    type: ReportingActionTypes.CREATE_REPORT_ERROR,
    error,
});

export const GetReportStartAction = (report: any) => ({
    type: ReportingActionTypes.GET_REPORT,
    report
})

export const UpdateReportStartAction = (report: any) => ({
    type: ReportingActionTypes.UPDATE_REPORT_START,
    report
})

export const UpdateReportSuccessAction = () => ({
    type: ReportingActionTypes.UPDATE_REPORT_SUCCESS,
})
export const UpdateReportErrorAction = (error: any) => ({
    type: ReportingActionTypes.UPDATE_REPORT_ERROR,
    error
})

export const DeleteReportStartAction = (reportId: number) => ({
    type: ReportingActionTypes.DELETE_REPORT_START,
    reportId
})

export const DeleteReportSuccessAction = () => ({
    type: ReportingActionTypes.DELETE_REPORT_SUCCESS,
})
export const DeleteReportErrorAction = (error: any) => ({
    type: ReportingActionTypes.DELETE_REPORT_ERROR,
    error
})