export const AuthActionTypes = {
    GET_CURRENT_USER: '[AUTH] GET_CURRENT_USER',
    LOGIN_START: '[AUTH] LOGIN_START',
    LOGIN_SUCCESS: '[AUTH] LOGIN_SUCCESS',
    LOGIN_ERROR: '[AUTH] LOGIN_ERROR',
    LOGOUT: '[AUTH] LOGOUT',

    RECOVER_PWD_START: '[AUTH] RECOVER_PWD_START',
    RECOVER_PWD_SUCCESS: '[AUTH] RECOVER_PWD_SUCCESS',
    RECOVER_PWD_ERROR: '[AUTH] RECOVER_PWD_ERROR',
    RECOVER_PWD_RESET: '[AUTH] RECOVER_PWD_RESET',
} as const;
