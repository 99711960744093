import React, { FC } from 'react';

import { TEditVotingRequestData } from 'src/types/vote';

import styles from './styles.module.scss';

type TProps = {
    users: TEditVotingRequestData['users'];
}

const UsersList: FC<TProps> = ({ users }) => {
    const usersList: any[] = users && Array.isArray(users) ? users : [];

    return (
        <div className={styles.usersList}>
            <div className={styles.listHeading}>
                ФИО и логин
            </div>
            { usersList.map((item) => {
                try {
                    const { personalData, username } = item;
                    const { firstName, middleName, lastName } = personalData;

                    return (
                        <div key={item.id} className={styles.usersListItem}>
                            <span className={styles.fio}>
                                { `${firstName} ${middleName} ${lastName}` }
                            </span>
                            <span className={styles.login}>
                                { username }
                            </span>
                        </div>
                    );
                } catch (error) {
                    return ('');
                }
            }) }
        </div>
    );
};

export default UsersList;
