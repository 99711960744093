import { LogsActionTypes } from 'src/store/logs/action-types';
import {KeyInfoType} from "src/utils/logs-data";
import {TActionsTypes} from "src/store/logs/reducer";

export const LogsLoginStartAction = () => ({
    type: LogsActionTypes.LOGS_LOGIN_START,
});
export const LogsLoginSuccessAction = (ip: string) => ({
    type: LogsActionTypes.LOGS_LOGIN_SUCCESS,
    ip
});
export const LogsLoginErrorAction = (error: any) => ({
    type: LogsActionTypes.LOGS_LOGIN_ERROR,
    error
});

export const LogsActionsStartAction = (typeCode: keyof KeyInfoType, entityId?: string, actionInformation?: string ) => ({
    type: LogsActionTypes.LOGS_ACTION_START,
    typeCode,
    entityId,
    actionInformation
});
export const LogsActionsSendAction = (actions: []) => ({
    type: LogsActionTypes.LOGS_ACTION_SEND,
    actions
});
export const LogsActionsCleanAction = () => ({
    type: LogsActionTypes.LOGS_ACTION_CLEAN,
});
export const LogsActionsSuccessAction = () => ({
    type: LogsActionTypes.LOGS_ACTION_SUCCESS,
});
export const LogsActionsErrorAction = (error: any) => ({
    type: LogsActionTypes.LOGS_ACTION_ERROR,
    error
});

export const LogsLogoutStartAction = () => ({
    type: LogsActionTypes.LOGS_LOGOUT_START,
});
export const LogsLogoutSuccessAction = () => ({
    type: LogsActionTypes.LOGS_LOGOUT_SUCCESS,
});
export const LogsLogoutErrorAction = (error: any) => ({
    type: LogsActionTypes.LOGS_LOGOUT_ERROR,
    error
});

export const GetActionsStartAction = () => ({
    type: LogsActionTypes.GET_ACTIONS_START,
});
export const GetActionsSuccessAction = (actions: TActionsTypes[]) => ({
    type: LogsActionTypes.GET_ACTIONS_SUCCESS,
    actions
});
export const GetActionsErrorAction = (error: any) => ({
    type: LogsActionTypes.GET_ACTIONS_ERROR,
    error
});

export const UpdateActionsStartAction = (actionTypeId: number, action: {name: string}) => ({
    type: LogsActionTypes.UPDATE_ACTIONS_START,
    actionTypeId,
    action
});
export const UpdateActionsSuccessAction = () => ({
    type: LogsActionTypes.UPDATE_ACTIONS_SUCCESS
});
export const UpdateActionsErrorAction = (error: any) => ({
    type: LogsActionTypes.UPDATE_ACTIONS_ERROR,
    error
});
