import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetAllSessionsStartAction,
    GetAllSessionsSuccessAction,
    GetAllSessionsErrorAction,
} from 'src/store/sesssions/actions';
import { TSessionRequest, TSessionResponse } from 'src/types/sessions';

export function* getAllSessionsWorker(_action: ReturnType<typeof GetAllSessionsStartAction>) {
    try {
        const requestData: TSessionRequest = {
            filterData: {},
            pageSize: 1000,
            totalCount: 0,
            sort: {
                direction: 'ASC',
                field: 'id',
            },
            page: 0,
        };

        const { data }: AxiosResponse<TSessionResponse> = yield axios({
            method: 'POST',
            url: '/sessions/search',
            headers: {},
            data: requestData,
            withCredentials: true,
        });

        yield put(GetAllSessionsSuccessAction(data));
    } catch (error) {
        yield put(GetAllSessionsErrorAction(error));
    }
}
