import React from 'react';

import { formatCurrency } from 'src/utils/currencyForematter';

import styles from './styles.module.scss';

export const budgetCell = (budget?: string) => {
    const data = budget ? formatCurrency(budget) : '';

    return (
        <div className={styles.container}>
            { data }
        </div>
    );
};
