export const RealizationActionTypes = {
    SET_FILTERS: '[REALIZATION] SET_FILTERS',
    RESET_FILTERS: '[REALIZATION] RESET_FILTERS',

    SET_LINKS_FILTERS: '[REALIZATION] SET_LINKS_FILTERS',
    RESET_LINKS_FILTERS: '[REALIZATION] RESET_LINKS_FILTERS',

    GET_LIST_START: '[REALIZATION] GET_LIST_START',
    GET_LIST_SUCCESS: '[REALIZATION] GET_LIST_SUCCESS',
    GET_LIST_ERROR: '[REALIZATION] GET_LIST_ERROR',

    GET_PROJECT_TYPES_START: '[REALIZATION] GET_PROJECT_TYPES_START',
    GET_PROJECT_TYPES_SUCCESS: '[REALIZATION] GET_PROJECT_TYPES_SUCCESS',
    GET_PROJECT_TYPES_ERROR: '[REALIZATION] GET_PROJECT_TYPES_ERROR',

    GET_PROJECT_START: '[REALIZATION] GET_PROJECT_START',
    GET_PROJECT_SUCCESS: '[REALIZATION] GET_PROJECT_SUCCESS',
    GET_PROJECT_ERROR: '[REALIZATION] GET_PROJECT_ERROR',

    GET_PROJECT_CONTENT_START: '[REALIZATION] GET_PROJECT_CONTENT_START',
    GET_PROJECT_CONTENT_SUCCESS: '[REALIZATION] GET_PROJECT_CONTENT_SUCCESS',
    GET_PROJECT_CONTENT_ERROR: '[REALIZATION] GET_PROJECT_CONTENT_ERROR',

    CREATE_PROJECT_START: '[REALIZATION] CREATE_PROJECT_START',
    CREATE_PROJECT_SUCCESS: '[REALIZATION] CREATE_PROJECT_SUCCESS',
    CREATE_PROJECT_ERROR: '[REALIZATION] CREATE_PROJECT_ERROR',

    CREATE_PROJECT_CONTENT_START: '[REALIZATION] CREATE_PROJECT_CONTENT_START',
    CREATE_PROJECT_CONTENT_SUCCESS: '[REALIZATION] CREATE_PROJECT_CONTENT_SUCCESS',
    CREATE_PROJECT_CONTENT_ERROR: '[REALIZATION] CREATE_PROJECT_CONTENT_ERROR',

    ADD_PROJECT_CONTENT_START: '[REALIZATION] ADD_PROJECT_CONTENT_START',
    ADD_PROJECT_CONTENT_SUCCESS: '[REALIZATION] ADD_PROJECT_CONTENT_SUCCESS',
    ADD_PROJECT_CONTENT_ERROR: '[REALIZATION] ADD_PROJECT_CONTENT_ERROR',

    UPDATE_PROJECT_CONTENT_START: '[REALIZATION] UPDATE_PROJECT_CONTENT_START',
    UPDATE_PROJECT_CONTENT_SUCCESS: '[REALIZATION] UPDATE_PROJECT_CONTENT_SUCCESS',
    UPDATE_PROJECT_CONTENT_ERROR: '[REALIZATION] UPDATE_PROJECT_CONTENT_ERROR',

    DELETE_PROJECT_CONTENT_START: '[REALIZATION] DELETE_PROJECT_CONTENT_START',
    DELETE_PROJECT_CONTENT_SUCCESS: '[REALIZATION] DELETE_PROJECT_CONTENT_SUCCESS',
    DELETE_PROJECT_CONTENT_ERROR: '[REALIZATION] DELETE_PROJECT_CONTENT_ERROR',

    GET_LINKS_START: '[REALIZATION] GET_LINKS_START',
    GET_LINKS_SUCCESS: '[REALIZATION] GET_LINKS_SUCCESS',
    GET_LINKS_ERROR: '[REALIZATION] GET_LINKS_ERROR',

    GET_INTERNET_RESOURCES_START: '[REALIZATION] GET_INTERNET_RESOURCES_START',
    GET_INTERNET_RESOURCES_SUCCESS: '[REALIZATION] GET_INTERNET_RESOURCES_SUCCESS',
    GET_INTERNET_RESOURCES_ERROR: '[REALIZATION] GET_INTERNET_RESOURCES_ERROR',

    CREATE_LINKS_START: '[REALIZATION] CREATE_LINKS_START',
    CREATE_LINKS_SUCCESS: '[REALIZATION] CREATE_LINKS_SUCCESS',
    CREATE_LINKS_ERROR: '[REALIZATION] CREATE_LINKS_ERROR',

    UPDATE_LINKS_START: '[REALIZATION] UPDATE_LINKS_START',
    UPDATE_LINKS_SUCCESS: '[REALIZATION] UPDATE_LINKS_SUCCESS',
    UPDATE_LINKS_ERROR: '[REALIZATION] UPDATE_LINKS_ERROR',

    DELETE_LINKS_START: '[REALIZATION] DELETE_LINKS_START',
    DELETE_LINKS_SUCCESS: '[REALIZATION] DELETE_LINKS_SUCCESS',
    DELETE_LINKS_ERROR: '[REALIZATION] DELETE_LINKS_ERROR',

    CRAWLER_START: '[REALIZATION] CRAWLER_START',
    CRAWLER_SUCCESS: '[REALIZATION] CRAWLER_SUCCESS',
    CRAWLER_ERROR: '[REALIZATION] CRAWLER_ERROR',

    UPDATE_PROJECT_START: '[REALIZATION] UPDATE_PROJECT_START',
    UPDATE_PROJECT_SUCCESS: '[REALIZATION] UPDATE_PROJECT_CONTENT_SUCCESS',
    UPDATE_PROJECT_ERROR: '[REALIZATION] UPDATE_PROJECT_CONTENT_ERROR',
} as const;
