import React from 'react';
import {Redirect, Route, Switch,} from 'react-router-dom';


import {ProjectsModule} from 'src/routing/projects-module';

import styles from './styles.module.scss';
import ErrorAuth from "src/features/error-auth";
import KeycloakChecker from 'src/components/keycloak-checker';

export const RootRoutes = () => {
    return (
        <main className={styles.mainContainer}>
            <KeycloakChecker>
                <Switch>
                    <Route path={'/error'} >
                        <ErrorAuth/>
                    </Route>
                    <Route path="/">
                        <ProjectsModule />
                    </Route>
                    <Redirect to="/list"/>
                </Switch>
            </KeycloakChecker>
        </main>
    );
};
