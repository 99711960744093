import {
    call,
    put,
    select,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetReportingQuartersTriggerAction,
    GetReportingQuartersStartAction,
    GetReportingQuartersSuccessAction,
    GetReportingQuartersErrorAction,
    GetReportingListTriggerAction,
    SetReportingFiltersAction,
} from 'src/store/reporting/actions';
import { selectReportingListFilters } from 'src/store/reporting/selectors';

import { getReportingProjectsListWorker } from 'src/workers/reporting/getProjectsList';

export function* getReportingQuartersWorker({ options }: ReturnType<typeof GetReportingQuartersTriggerAction>) {
    try {
        yield put(GetReportingQuartersStartAction());
        const filters = yield select(selectReportingListFilters);
        const { data: responseData }: AxiosResponse<number[]> = yield axios({
            method: 'GET',
            url: `/reporting/${filters.filterData.year}/quarters`,
            headers: {},
        });

        if (responseData.length > 0) {
            yield put(SetReportingFiltersAction({
                ...filters,
                filterData: {
                    ...filters.filterData,
                    quarter: responseData[responseData.length - 1],
                }
            }));
        }

        yield put(GetReportingQuartersSuccessAction(responseData));
    } catch (error) {
        yield put(GetReportingQuartersErrorAction(error));
    }

    if (options.withProjects) {
        try {
            yield call(getReportingProjectsListWorker, GetReportingListTriggerAction());
        } catch (_) {}
    }
}
