import React from 'react';

import styles from './styles.module.scss';

export const VoteOptionCell = (cellData: any, rowData: any) => {
    const vote: string = rowData.votes && rowData.votes[0]
        ? rowData.votes[0].voteOption.name
        : '';

    return (
        <div className={styles.cell}>
            { vote }
        </div>
    );
};
