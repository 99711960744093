import {
    GetUsersErrorAction,
    GetUsersStartAction,
    GetUsersSuccessAction,
} from 'src/store/users/actions';
import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* getUsersWorker({params = null}: ReturnType<typeof GetUsersStartAction>) {
    try {
        const sort = params === null ? {
            direction: 'ASC',
            field: 'status',
        } : params.newSorter.sort;

        if (params === null) yield put(LogsActionsStartAction('OPEN_USER'));

        const page = params === null ? 0 : params.newPagination.page - 1;
        const pageSize = params === null ? 20 : params.newPagination.pageSize;

        const {data: users}: AxiosResponse<any> = yield axios({
            method: 'POST',
            url: '/users/search',
            headers: {},
            data: {
                page: page,
                pageSize: pageSize,
                sort: sort,
            },
        });

        yield put(GetUsersSuccessAction(users));

    } catch (error) {
        yield put(GetUsersErrorAction(error));
    }
}