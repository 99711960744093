import { put } from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';

import {
    GetRealizationProjectContentErrorAction,
    GetRealizationProjectContentStartAction,
    GetRealizationProjectContentSuccessAction,
} from "src/store/realization/actions";
import {TRealizationProject} from "src/types/realization-project";

export function* getRealizationProjectContentWorker({id}: ReturnType<typeof GetRealizationProjectContentStartAction>) {
    try {
        const {data}: AxiosResponse<TRealizationProject> = yield axios({
            method: 'GET',
            url: `/impl/projects/content/${id}`,
            headers: {},
        });
        yield put(GetRealizationProjectContentSuccessAction(data));

    } catch (error) {
        yield put(GetRealizationProjectContentErrorAction(error));
    }
}
