import React, { FC } from 'react';
import { Button } from 'antd';
import { Modal } from 'react-bootstrap';

import styles from './styles.module.scss';

type TVideoModalProps = {
    isOpened: boolean;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    name: string;
    url: string;
    link?: boolean
};

export const VideoModal: FC<TVideoModalProps> = ({
    isOpened,
    onClose,
    name,
    url,
    link
}) => (
    <Modal
        show={isOpened}
        onHide={onClose}
        keyboard={true}
        scrollable={true}
        backdropClassName={styles.backDrop}
        dialogClassName={`${styles.dialog} ${styles.video}` }
    >
        <Modal.Body className={styles.body}>
            <h3 className={styles.header}>
                {name} {link}
            </h3>

            <div className={styles.message}>
                {link ? (
                    <video src={process.env.REACT_APP_API_ENDPOINT + `reporting/reports/files/${url}`}
                        width='100%'
                        height='100%'
                        controls
                        controlsList="nodownload"
                        crossOrigin='use-credentials'
                        muted>
                    </video>
                ) : (
                    <iframe
                        className={styles.videoIframe}
                        title="presentation"
                        width="100%"
                        height="100%"
                        src={url}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                )}
            </div>
        </Modal.Body>

        <Modal.Footer>
            <Button type="default" onClick={onClose}>Закрыть</Button>
        </Modal.Footer>
    </Modal>
);
