import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectInternetResources} from "src/store/internet-resources/selectors";
import {
    CreateInternetResourcesStartAction, DeleteInternetResourcesStartAction,
    GetInternetResourcesStartAction,
    UpdateInternetResourcesStartAction
} from "src/store/internet-resources/actions";
import styles from './styles.module.scss'
import {Button, Input, Upload} from "antd";
import cn from "classnames";
import {UploadOutlined} from "@ant-design/icons";
import {FileSize} from "src/constatnts/format";

type ItemPtops = {
    value: string | null,
    img: string | null,
    label: string,
    domainLink: string
}

type Tstate = {
    value: string | null,
    img: string | null,
    label: string,
    labelError: null | string,
    domainLink: string,
    domainLinkError: null | string,
    newImg: null | []
}

const Item: React.FC<ItemPtops> = ({value, img, label, domainLink}) => {
    const put = useDispatch();
    const [state, setState] = useState<Tstate>({
        value: value,
        img: img,
        label: label,
        labelError: null,
        domainLink: domainLink,
        domainLinkError: null,
        newImg: null
    })
    const handlerChange = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    //загрузка изображения
    const validateUpload = (_file: File) => {
        return false;
    };
    const removeExistingImage = () => {
        if (state.img) {
            setState({
                ...state,
                img: null
            })
        }
    };
    const uploadImage = (info: any) => {
        if (info.fileList.length === 0) {
            return;
        }
        const newFile = info.fileList[info.fileList.length - 1];
        try {
            const type = newFile.type.split('/')[0];
            if (type !== 'image') {
                throw new Error('File is not an image');
            }
            if (newFile.size > FileSize) {
                throw new Error('File too big');
            }
        } catch (_error) {
            return false;
        }
        removeExistingImage();
        setState({...state, newImg: newFile})
    };
    const onRemoveFile = (fieldName: string) => (_file: any) => {
        setState({...state, newImg: null})
    }

    const handlerUpdate = () => {
        if (!state.label.length) setState({...state, labelError: 'Поле не может быть пустым'})
        if (!state.domainLink.length) setState({...state, domainLinkError: 'Поле не может быть пустым'})
        if (state.label.length && state.domainLink.length) {
            if (state.value) {
                put(UpdateInternetResourcesStartAction(state, state.value))
            } else put(CreateInternetResourcesStartAction(state))
        }
    }
    const handlerDelete = () => {
        if (state.value) put(DeleteInternetResourcesStartAction(state.value))
    }

    return (
        <>
            <div className={styles.content_img}>
                <img src={state.img ? state.img : '/images/no-icon.png' } alt={state.label}/>
                <Upload
                    accept="image/*"
                    className={styles.upload}
                    listType="picture"
                    multiple={false}
                    beforeUpload={validateUpload}
                    onChange={uploadImage}
                    onRemove={onRemoveFile('image')}
                >
                    <Button icon={<UploadOutlined />}>
                        {state.img ? 'Изменить' : 'Добавить'}
                    </Button>
                </Upload>
            </div>
            <div className={styles.group}>
                <div className={styles.formGroup}>
                    <label htmlFor="label">Название ресурса</label>
                    <Input value={state.label} name='label' onChange={handlerChange}/>
                    {state.labelError ? (<span className={styles.error}>{state.labelError}</span>) : null}
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="domainLink">Ссылка на ресурс</label>
                    <Input value={state.domainLink} name='domainLink' onChange={handlerChange}/>
                    {state.domainLinkError ? (<span className={styles.error}>{state.domainLinkError}</span>) : null}
                </div>
                <div className={styles.control}>
                    <Button type="default" onClick={handlerDelete}>Удалить</Button>
                    <Button type="primary" onClick={handlerUpdate}>Сохранить</Button>
                </div>

            </div>
        </>
    )
}
type TstateObj = {
    value: null,
    img: string | null,
    label: string,
    domainLink: string
}
type TstateNew = TstateObj[]
export default function InternetResourceSettingsPage () {
    const [state, setState] = useState<TstateNew>([])


    const put = useDispatch();
    const internetResources = useSelector(selectInternetResources)


    useEffect(() => {
        if (!internetResources.length) put(GetInternetResourcesStartAction())
        setState([])
    }, [internetResources.length, put])


    const addResource = () => {
        setState(prevState => [...prevState, {
            value: null,
            img: null,
            label: '',
            domainLink: '',
        }])
    }

    const fields = useMemo(() => (arr: any[]) => {
        const rus = arr
            .filter((el) => el.label.toLowerCase().trim().search(/[а-яё]/g) >= 0)
            .sort((a: any, b: any) => a.label.localeCompare(b.label))
        const en = arr
            .filter((el) => el.label.toLowerCase().trim().search(/[\w]/g) >= 0)
            .sort((a: any, b: any) => a.label.localeCompare(b.label))
        return ([...rus, ...en].map((el: any) => (
            <div key={el.value} className={styles.item}>
                <Item value={el.value} img={el.img} label={el.label} domainLink={el.domainLink}/>
            </div>
        )))
    }, [internetResources]);

    const staticFields = (arr: any[]) => (arr.map((el: any) => (
        <div key={el.value} className={styles.item}>
            <Item value={el.value} img={el.img} label={el.label} domainLink={el.domainLink}/>
        </div>
    )))

    return (
        <div className={styles.container}>
            <div className={cn(styles.leftColumn)}>
                <h2>Настройки интернет-ресурсов</h2>
                <div className={styles.header}>
                    <h3 className={styles.title}>Добавить ресурс</h3>
                    <Button
                        type="default"
                        shape="circle"
                        className={styles.add}
                        onClick={addResource}
                    >+</Button>
                </div>

                {state.length ? staticFields(state) : null}
                {internetResources.length ? fields(internetResources) : 'Loading'}

            </div>
        </div>
    )
}