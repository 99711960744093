import React from 'react';
import {ReactComponent as Done} from "./done.svg";
import {ReactComponent as Expired} from "./expired.svg";
import {ReactComponent as Finish} from "./finish.svg";
import styles from './styles.module.scss';
import classNames from "classnames";

export const statusVoteCell = (time?: boolean) => (status?: boolean | string, ) => {
    const active: boolean = (typeof status === 'boolean' && status)
        || (typeof status === 'string' && status === 'VOTED');

    const message = () => {
        if ((typeof time !== 'undefined') && !time) return <span><Done/> Голосование закончено</span>
        if (status) return <span><Finish/> Все участники голосования проголосовали</span>
        return <span><Expired/> Идет голосование</span>
    }

    return (
        <div className={styles.cell}>
            <div className={classNames(styles.item, {'active': active})}>
                {message()}
            </div>
        </div>
    );
}
