import React, {FC} from 'react';
import {Button, Modal} from "antd";
import styles from "./styles.module.scss";
import createMarkup from "src/utils/createMarkup";

type Props = {
    visible: boolean
    data: null | string
    onModalCancel: (e: React.MouseEvent) => void
}
const FormatDescriptionModal: FC<Props> = ({visible, data, onModalCancel}) => {
    return (
        <Modal
            title={'Описание формата контента'}
            open={visible}
            onOk={onModalCancel}
            onCancel={onModalCancel}
            footer={null}
            className={styles.modal}
        >
            <div className={styles.container}>
                {data && (
                    <div dangerouslySetInnerHTML={createMarkup(data)}/>
                )}
                <div className={styles.btn}>
                    <Button type={"default"} onClick={onModalCancel}>Закрыть</Button>
                </div>

            </div>

        </Modal>
    )
}

export default FormatDescriptionModal;