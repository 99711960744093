import currency from 'currency.js';

export const formatCurrency = (value: string | number): string => {
    return currency(
        `${value}`,
        { separator: ' ', precision: 0, symbol: '' }
    ).format();
};

export const formatCurrencyPercent = (valueFirst: string | number, valueSecond: string | number, flor: number | undefined = 2): string => {
    const sum = +valueFirst / +valueSecond;
    return `${(sum * 100).toFixed(flor)} %`
};