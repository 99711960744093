import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    AddVotingCommentStartAction,
    AddVotingCommentSuccessAction,
    AddVotingCommentErrorAction,
    GetVotingDetailedProjectStartAction,
    GetVotingDetailedDocumentStartAction,
} from 'src/store/voting/actions';

export function* addVotingCommentWorker(
    action: ReturnType<typeof AddVotingCommentStartAction>,
) {
    try {
        const { votingId, projectId } = action;

        const { data: id }: AxiosResponse<number> = yield axios({
            method: 'POST',
            url: `/user/voting/comments`,
            headers: {},
            data: action.comment,
        });

        yield put(AddVotingCommentSuccessAction(action.comment, id));
        //todo (проверка док или проект)
        yield put(GetVotingDetailedProjectStartAction(votingId, projectId));
        yield put(GetVotingDetailedDocumentStartAction(votingId, projectId));
    } catch (error) {
        yield put(AddVotingCommentErrorAction(error));
    }
}
