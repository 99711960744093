import React, {
    SyntheticEvent,
    useEffect, useState,
} from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty, Pagination, Spin } from 'antd';

import { ToggleSidebarAction } from 'src/store/general/actions';
import {
    GetReportingListTriggerAction,
    SetReportingFiltersAction,
    TriggerGetReportingFiltersAction,
} from 'src/store/reporting/actions';
import {
    selectReport,
    selectReportingListData,
    selectReportingListFilters,
    selectReportingListStatus,
} from 'src/store/reporting/selectors';

import { selectIsSidebarActive } from 'src/store/general/selectors';

import { ReportingListSidebar } from 'src/components/reporting-list-sidebar';
import { ErrorBoundary } from 'src/components/error-boundary';

import styles from './styles.module.scss';
import {PDFModal} from "src/components/modals/pdf-modal";
import {TPDFModal} from "src/types/modals";
import {FilePdfOutlined, PlayCircleOutlined} from "@ant-design/icons";
import {selectFilesObj} from "src/store/files/selectiors";
import {GetFileReportStartAction} from "src/store/files/actions";
import {VideoModal} from "src/components/modals/video-modal";
import ReportModal from "src/components/modals/report-modal";
import createMarkup from 'src/utils/createMarkup';

export const ReportingListPage = () => {
    const put = useDispatch();
    const data = useSelector(selectReportingListData);
    const status = useSelector(selectReportingListStatus);
    const filters = useSelector(selectReportingListFilters);
    const isSidebarActive = useSelector(selectIsSidebarActive);
    const reportInfo = useSelector(selectReport);

    const files = useSelector(selectFilesObj(
        data ? data.filter(item => item.file).map((item) => (`${item.file.id}`)) : undefined
    ));

    const [modalData, setModalData] = useState<TPDFModal>(null);
    const [reportModal, setReportModal] = useState({
        isOpened: false,
    });
    const [videoModal, setVideoModal] = useState({
        isOpened: false,
        name: '',
        url: '',
        link: false
    })

    useEffect(() => {
        put(TriggerGetReportingFiltersAction());
    }, []);
    useEffect(() => {
        if (data && files.every(item => item === null)) {
            const filesWithFile = data.filter(item => item.file)
            filesWithFile.forEach(item => put(GetFileReportStartAction(`${item.file.id}`)))
        }
    }, [data])

    const openPDFModal = (file: any, name: string) => (_e?: SyntheticEvent) => {
        try {
            // @ts-ignore
            let fileURL = window.URL.createObjectURL(files.find((el) => el.id === `${file.id}`).file);
            setModalData({fileURL: fileURL, name});
        } catch (error) {}
    };
    const openVideoModal = (name: string, url: string, link?: boolean) =>  (_e?: SyntheticEvent) => {
        setVideoModal({
            ...videoModal,
            isOpened: true,
            name,
            url,
            link: link ? link : false
        })
    }
    const closePDFModal = () => {
        setModalData(null);
    };
    const closeVideoModal = () => {
        setVideoModal({...videoModal, isOpened: false, link: false});
    };
    const toggleSidebar = (value: boolean) => ((_event: SyntheticEvent) => {
        put(ToggleSidebarAction(value));
    });
    const handlerReportModal = (type: string) => (_event?: SyntheticEvent) => {
        if (type === 'open') setReportModal({...reportModal, isOpened: true})
        if (type === 'close') setReportModal({...reportModal, isOpened: false})
    }


    // TODO: refactor this
    const handleTableChange = (
        page: number, pageSize?: number | undefined
    ) => {
        put(SetReportingFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData
            },
            pageSize: typeof pageSize === 'number' ? pageSize : filters.pageSize,
            page: page,
        }));
        put(GetReportingListTriggerAction());
    };

    const viewBtn = (item: any) => {
        const btn = (handler: any) => (<Button type='primary' className={styles.watchBtn} onClick={handler}>Смотреть</Button>);
        if (!item.file && item.type.id === 1) return btn(openVideoModal(item.name, item.url))
        if (item.type.id === 1 && !!item.file) return btn(openVideoModal(item.name, item.file?.id, true))
        return btn(openPDFModal(item.file, 'Презентация'))
    }
    return (
        <ErrorBoundary>
            <div className={styles.container}>
                <div className={cn(styles.leftColumn, { [styles.isSidebarCollapsed]: !isSidebarActive })}>

                    <div className={styles.header}>
                        <h3 className={styles.title}>Отчётность</h3>
                    </div>

                    {reportInfo && !status.isLoading && (
                        <div className={styles.info}>
                            <span className={styles.title}>{reportInfo.name}</span>
                            <p dangerouslySetInnerHTML={createMarkup(reportInfo.description)}/>
                        </div>
                    )}


                    {data.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    <div className={styles.list}>
                        {status.isLoading && (
                            <div className={styles.loader}>
                                <Spin size="large" />
                            </div>
                        )}
                        { data.length !== 0 && !status.isLoading && data.map((item) => {
                            let prewiew: any = '';

                            if (item.type.id === 1) {
                                prewiew = (
                                    <PlayCircleOutlined />
                                );
                            } else prewiew = (<FilePdfOutlined />)

                            return (
                                <div key={item.id} className={styles.item}>
                                    <div className={styles.preview} >
                                        { prewiew }
                                    </div>
                                    {viewBtn(item)}
                                    <div className={styles.description}>{item.name}</div>
                                </div>
                            );
                        }) }
                    </div>

                    <div className={styles.pagination}>
                        <Pagination
                            showSizeChanger
                            onChange={handleTableChange}
                            current={filters.page}
                            total={filters.totalCount}
                            pageSize={filters.pageSize}
                            pageSizeOptions={["50", "100", "500", "1000", "3000"]}
                        />
                    </div>
                </div>
                <div className={cn(styles.rightColumn, { [styles.isCollapsed]: !isSidebarActive })}>
                    <ReportingListSidebar
                        isSidebarOpened={isSidebarActive}
                        closeSidebar={toggleSidebar(false)}
                    />
                </div>
            </div>
            {Boolean(modalData) && (
                <PDFModal
                    fileURL={modalData?.fileURL as string}
                    name={modalData?.name}
                    isOpened={Boolean(modalData)}
                    onClose={closePDFModal}
                />
            )}
            {videoModal.isOpened && (
                <VideoModal
                    name={videoModal.name}
                    url={videoModal.url}
                    isOpened={videoModal.isOpened}
                    onClose={closeVideoModal}
                    link={videoModal.link}
                />
            )}
            {reportModal.isOpened && (
                <ReportModal
                    onClose={handlerReportModal}
                />
            )}

        </ErrorBoundary>
    );
};
