import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TVoteSummary } from 'src/types/vote';

import {
    GetAllDocumentsVoteSummaryStartAction,
    GetAllDocumentsVoteSummarySuccessAction,
    GetAllDocumentsVoteSummaryErrorAction,
} from 'src/store/voting/actions';
import { selectIsModerator } from 'src/store/auth/selectors';

export function* getAllDocumentsVoteSummaryWorker({ votingId }: ReturnType<typeof GetAllDocumentsVoteSummaryStartAction>) {
    try {
        const isModerator = yield select(selectIsModerator);

        const { data }: AxiosResponse<TVoteSummary> = yield axios({
            method: 'GET',
            url: `${isModerator ? '' : '/user'}/voting/${votingId}`,
            headers: {},
        });

        yield put(GetAllDocumentsVoteSummarySuccessAction(data));
    } catch (error) {
        yield put(GetAllDocumentsVoteSummaryErrorAction(error));
    }
}
