import { CategoriesActionTypes } from 'src/store/categories/action-types';
import { TCategory } from 'src/types/category';

export const GetCategoriesStartAction = () => ({
    type: CategoriesActionTypes.GET_CATEGORIES_START,
});

export const GetCategoriesSuccessAction = (categories: TCategory[]) => ({
    type: CategoriesActionTypes.GET_CATEGORIES_SUCCESS,
    categories,
});

export const GetCategoriesErrorAction = (error: any) => ({
    type: CategoriesActionTypes.GET_CATEGORIES_ERROR,
    error,
});
