import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetUserStartAction,
    GetUserSuccessAction,
    GetUserErrorAction,
} from 'src/store/user/actions';
import { selectUserData } from 'src/store/auth/selectors';
import { TCategory } from 'src/types/category';

export function* userWorker(_action: ReturnType<typeof GetUserStartAction>) {
    try {
        const { user } = yield select(selectUserData);

        const { data }: AxiosResponse<TCategory[]> = yield axios({
            method: 'GET',
            url: `/users/${user.id}`,
            headers: {},
        });

        yield put(GetUserSuccessAction(data));
    } catch (error) {
        yield put(GetUserErrorAction(error));
    }
}
