import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    LogsActionsCleanAction,
    LogsLoginErrorAction,
    LogsLoginStartAction,
    LogsLoginSuccessAction
} from "src/store/logs/actions";
import publicIp from "public-ip";

export function* loginWorker(action: ReturnType<typeof LogsLoginStartAction>) {
    try {
        const ip = yield publicIp.v4();

        const data = [{
            actionTypeCode: 'LOGIN',
            ipAddress: ip,
            dateTime: new Date()
        }]
        yield put(LogsActionsCleanAction())

        yield axios({
            method: 'POST',
            url: `/users/actions`,
            headers: {},
            data: data
        });
        yield put(LogsLoginSuccessAction(ip))

    } catch (error) {
        yield put(LogsLoginErrorAction(error));
    }
}
