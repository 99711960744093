import React, {FC} from 'react';
import styles from "src/components/modals/projects-modal/styles.module.scss";
import ReactQuill from "react-quill";
import {Form} from "antd";
import cn from "classnames";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";

type TTextAreaQuill = {
    form: any,
    label: string,
    text: string,
    handler?: any
}

const TextAreaQuill: FC<TTextAreaQuill> = ({form, label, text, handler}) => {
    const handleQuillField = (fieldName: string) => (content: string) => {
        if (content === "<p><br></p>") {
            form.setFieldValue(fieldName, '');
            return
        }
        form.setFieldValue(fieldName, content);
    };
    return (
        <Form.Item
            className={cn(
                styles.formItem,
                { [`${styles.errorEditor}`]: form.submitCount > 0 && form.errors[label] }
            )}
            validateStatus={ (
                form.submitCount > 0 && form.errors[label]
            ) ? 'error' : '' }
        >
            <label htmlFor={label} className={styles.label}>
                {text}
            </label>
            <ErrorBoundary description=''>
                <ReactQuill
                    value={form.values[label] || ''}
                    onChange={!!handler ? handler : handleQuillField(label)}
                    theme="snow"
                />
            </ErrorBoundary>
        </Form.Item>
    )
};
export default TextAreaQuill;