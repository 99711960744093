import React from 'react';


import styles from './styles.module.scss';

function dateParse(value: string) {
    if (!value) return null
    const date = new Date(value);
    const day = date.getDate();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month < 9 ? '0' + month : month}-${day < 9 ? '0' + day : day} 
    ${hours < 9 ? '0' + hours : hours}:${minutes < 9 ? '0' + minutes : minutes}:${seconds < 9 ? '0' + seconds : seconds}`
}

export const DateCell = (name: string, data: any) => {
    return (
        <div className={styles.cell}>
            <span className={styles.name}>{data.dateTime ? dateParse(data.dateTime) : dateParse(data.lastLoginDateTime)}</span>
        </div>
    )
}
