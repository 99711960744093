import { put, select } from 'redux-saga/effects';
import axios from 'axios';

import {
    LogsLogoutErrorAction,
    LogsLogoutStartAction,
    LogsLogoutSuccessAction
} from "src/store/logs/actions";
import {selectLogsActions, selectLogsIp} from "src/store/logs/selectors";

export function* logoutWorker(_action: ReturnType<typeof LogsLogoutStartAction>) {
    try {
        const ip = yield select(selectLogsIp);
        const actions = yield select(selectLogsActions)

        const data = [...actions, {
            actionTypeCode: 'LOGOUT',
            ipAddress: ip,
            dateTime: new Date()
        }]
        // navigator.sendBeacon("/users/actions", JSON.stringify(data));

        yield axios({
            method: 'POST',
            url: `/users/actions`,
            headers: {},
            data: data
        });
        yield put(LogsLogoutSuccessAction())

    } catch (error) {
        yield put(LogsLogoutErrorAction(error));
    }
}
