import { InferValueTypes } from 'src/types/common';
import { RealizationActionTypes } from 'src/store/realization/action-types';
import * as actions from '../actions';
import { TRealizationFilters } from 'src/types/realization';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TRealizationListState = {
    filters: TRealizationFilters;
    data: any[];
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TRealizationListState = {
    filters: {
        filterData: {
            formatIds: [],
            searchString: '',
            implStatuses: [],
            sessionName: '',
            year: null,
        },
        page: 1,
        pageSize: 20,
        totalCount: 0,
        sort: {
            direction: 'ASC',
            field: 'id',
        }
    },
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function usersProjects(
    state: TRealizationListState = initialState,
    action: ActionTypes,
): TRealizationListState {
    switch (action.type) {
        case RealizationActionTypes.RESET_FILTERS:
            return {
                ...state,
                filters: { ...initialState.filters },
            };
        case RealizationActionTypes.SET_FILTERS:
            return {
                ...state,
                filters: action.filters,
            };
        case RealizationActionTypes.GET_LIST_START:
            return {
                ...initialState,
                data: state.data,
                filters: state.filters,
                isLoading: true,
            };
        case RealizationActionTypes.GET_LIST_SUCCESS:
            return {
                ...initialState,
                data: action.content,
                filters: {
                    ...state.filters,
                    ...action.pagination,
                },
                isLoading: false,
                isLoaded: true,
            };
        case RealizationActionTypes.GET_LIST_ERROR:
            return {
                ...initialState,
                filters: state.filters,
                error: action.error,
            };
        case RealizationActionTypes.UPDATE_PROJECT_START:
            return {
                ...state,
                isLoading: true,
            }
        case RealizationActionTypes.UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            }
        case RealizationActionTypes.UPDATE_PROJECT_ERROR:
            return {
                ...initialState,
                filters: state.filters,
                error: action.error,
            };
        default:
            return state;
    }
}
