import {put} from 'redux-saga/effects';
import axios from 'axios';

import {
    DeleteReportErrorAction,
    DeleteReportStartAction, DeleteReportSuccessAction,
    TriggerGetReportingFiltersAction,
} from 'src/store/reporting/actions';


export function* deleteReportWorker({reportId}: ReturnType<typeof DeleteReportStartAction>) {
    try {

        yield axios({
            method: 'DELETE',
            url: `/reporting/reports/${reportId}`,
            headers: {},
        });
        yield put(DeleteReportSuccessAction());
        yield put(TriggerGetReportingFiltersAction())
    } catch (error) {
        yield put(DeleteReportErrorAction(error));
    }
}
