import React, {FC} from 'react';
import {Table} from 'antd';

import {TEditVotingRequestData} from 'src/types/vote';

import {NameCell} from 'src/components/data-table/name-cell';
import {categoryCell} from 'src/components/data-table/category-cell';
import {budgetCell} from 'src/components/data-table/budget-cell';

import styles from './styles.module.scss';

type TProps = {
    projects: TEditVotingRequestData['projects'];
}

const ProjectsList: FC<TProps> = ({ projects }) => {
    const columns = [{
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        width: '40%',
        className: styles.nameCell,
        render: NameCell,
    }, {
        title: 'Направление',
        dataIndex: 'category',
        key: 'category',
        render: categoryCell,
    }, {
        title: 'Бюджет',
        dataIndex: 'budget',
        key: 'budget',
        render: budgetCell,
    }, {
        title: 'Балл',
        dataIndex: 'score',
        key: 'score',
    }];

    return (
        <div className={styles.projectsList}>
            <Table
                columns={columns}
                dataSource={projects}
                pagination={false}
                rowKey="id"
            />
        </div>
    );
};

export default ProjectsList;
