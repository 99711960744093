import {
    put,
    select,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetProjectsByVotingIdStartAction,
    GetProjectsByVotingIdSuccessAction,
    GetProjectsByVotingIdErrorAction,
} from 'src/store/projects/actions';
import { selectIsModerator } from 'src/store/auth/selectors';
import { selectAdminsProjectsFilters } from 'src/store/projects/selectors';
import { TProjectsData } from 'src/types/projects';

export function* getProjectsByVotingIdWorker(action: ReturnType<typeof GetProjectsByVotingIdStartAction>) {
    try {
        const isModerator = yield select(selectIsModerator);
        const { filterData, ...sorting } = yield select(selectAdminsProjectsFilters);

        let { data }: AxiosResponse<TProjectsData> = yield axios({
            method: 'POST',
            url: isModerator
                ? `/voting/${action.votingId}/projects/search`
                : `/user/voting/${action.votingId}/projects/search`,
            headers: {},
            data: {
                filterData: {
                    categoryIds: filterData.categoryIds,
                    minScore: filterData.minScore,
                    maxScore: filterData.maxScore,
                    searchString: filterData.searchString,
                },
                ...sorting,
                page: sorting.page - 1,
            },
        });

        const { content, ...pagination } = data;
        pagination.page = pagination.page + 1;

        yield put(GetProjectsByVotingIdSuccessAction(content, pagination));
    } catch (error) {
        yield put(GetProjectsByVotingIdErrorAction(error));
    }
}
