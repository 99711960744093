import React from 'react';
import { Badge } from 'antd';

import { TCategory } from 'src/types/category';

import styles from './styles.module.scss';

const colors: Record<string, string> = {
    '1': 'videoContent',
    '2': 'blogSphere',
    '4': 'multiFormat',
};

export const categoryCell = (status?: TCategory) => {
    let colorStyle = '';

    if (status && `${status.id}` in colors) {
        colorStyle = colors[status.id];
    }

    return (
        <div className={styles.cell}>
            { status && (
                <Badge size="default" className={ colorStyle && styles[colorStyle] }>
                    { status.name }
                </Badge>
            ) }
        </div>
    );
};
