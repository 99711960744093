import {
    takeLatest
} from 'redux-saga/effects';

import {LogsActionTypes} from "src/store/logs/action-types";

import {loginWorker} from "src/workers/logs/login-worker";
import {logoutWorker} from "src/workers/logs/logout-worker";
import {actionsWorker} from "src/workers/logs/actions-worker";
import {sendWorker} from "src/workers/logs/send-worker";
import {getActionsWorker} from "src/workers/logs/get-actions-worker";
import {UpdateActionsWorker} from "src/workers/logs/update-actions-worker";

export default function* logsSaga() {
    yield takeLatest(LogsActionTypes.LOGS_LOGIN_START, loginWorker)
    yield takeLatest(LogsActionTypes.LOGS_LOGOUT_START, logoutWorker)
    yield takeLatest(LogsActionTypes.LOGS_ACTION_START, actionsWorker)
    yield takeLatest(LogsActionTypes.LOGS_ACTION_SEND, sendWorker)
    yield takeLatest(LogsActionTypes.GET_ACTIONS_START, getActionsWorker)
    yield takeLatest(LogsActionTypes.UPDATE_ACTIONS_START, UpdateActionsWorker)
}