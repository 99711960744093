import { combineReducers } from 'redux';

import listReducer from './list';
import yearsReducer from './years';
import quartersReducer from './quarters';
import typesReducer from './types';
import reportReducer from "src/store/reporting/reducer/report";

const reducer = combineReducers({
    list: listReducer,
    years: yearsReducer,
    quarters: quartersReducer,
    types: typesReducer,
    report: reportReducer
});

export type TReportingState = ReturnType<typeof reducer>;

export default reducer;