import {
    CreateUserErrorAction,
    CreateUserStartAction,
    CreateUserSuccessAction,
    GetUsersStartAction,
} from 'src/store/users/actions';
import {put} from 'redux-saga/effects';
import axios from 'axios';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* addUserWorker({ user }: ReturnType<typeof CreateUserStartAction>) {
    try {
        const newUser = {
            'password': null,
            'personalData': {
                'email': user.email,
                'firstName': user.firstName,
                'image': user.image,
                'lastName': user.lastName,
                'middleName': user.middleName,
                'organization': user.organization,
                'position': user.position,
            },
            'roles': user.roles,
            'status': user.status,
            'username': user.username,
        };

        yield axios({
            method: 'POST',
            url: '/users',
            headers: {},
            data: newUser,
        });

        yield put(CreateUserSuccessAction());
        yield put(GetUsersStartAction(null));
        yield put(LogsActionsStartAction('CREATE_USER'))
    } catch (error) {
        yield put(CreateUserErrorAction(error));
    }
}