import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetFormatsStartAction,
    GetFormatsSuccessAction,
    GetFormatsErrorAction,
} from 'src/store/formats/actions';
import { TFormats } from 'src/types/formats';

export function* formatsWorker(_action: ReturnType<typeof GetFormatsStartAction>) {
    try {
        const { data }: AxiosResponse<TFormats[]> = yield axios({
            method: 'GET',
            url: '/projects/formats',
            headers: {},
        });

        yield put(GetFormatsSuccessAction(data));
    } catch (error) {
        yield put(GetFormatsErrorAction(error));
    }
}
