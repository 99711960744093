import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getGeneralState = (state: TApplicationState) => state.general;

export const selectIsSidebarActive = createSelector(
    getGeneralState,
    (state) => state.isSidebarActive,
);

export const selectIsProfileActive = createSelector(
    getGeneralState,
    (state) => state.isProfileActive,
);

export const selectIsVoteFiltersActive = createSelector(
    getGeneralState,
    (state) => state.isVoteFiltersActive,
);
export const selectMenuInfo = createSelector(getGeneralState, (state) => ({
    isSidebarOpen: state.isSidebarOpen,
    isMobile: state.isMobile,
})); 