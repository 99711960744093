import React, {useEffect} from "react";

import {formData} from "src/components/modals/user-modal/index";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {CreateUserStartAction,} from "src/store/users/actions";
import {useDispatch} from "react-redux";
import {RadioChangeEvent} from "antd/es/radio";
import ModalContent from "src/components/modals/user-modal/modal";
import {LogsActionsStartAction} from "src/store/logs/actions";
import {getBase64Async} from "src/shared/lib/get-base64";


export type CreateUserModalProps = {
    toggleModal: any,
    type: string
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({toggleModal, type, }) => {
    const put = useDispatch();

    useEffect(() => {
        put(LogsActionsStartAction('OPEN_ADD_USER_FORM'))
        return () => {
            put(LogsActionsStartAction('CANCEL_CREATE_USER'))
        }
    }, [])

    const initialData: formData = {
        password: '',
        email: '',
        existingImage: '',
        firstName: '',
        lastName: '',
        middleName: '',
        organization: '',
        position: '',
        roles: ['ROLE_USER'],
        status: 'ACTIVE',
        username: '',
        image: []
    };

    const userForm = useFormik({
        initialValues: {
            ...initialData
        },
        onSubmit: onSubmitForm,
        validationSchema: Yup.object({
            password: Yup.string().nullable(),
            email: Yup.string(),
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            middleName: Yup.string(),
            organization: Yup.string(),
            position: Yup.string(),
            roles: Yup.array().required('Required'),
            status: Yup.string().required('Required'),
            username: Yup.string().required('Required'),
        }),
    });

    async function onSubmitForm({image, ...rest}: formData, formikHelpers: FormikHelpers<formData>) {
        const img = !!image?.length ? await getBase64Async(image[0].originFileObj) : null;
        put(CreateUserStartAction({...rest, image: img}))
        resetForm();
    }
    function resetForm() {
        userForm.resetForm()
        toggleModal()
    }

    const handleChangeCategory = (e: RadioChangeEvent) => {
        if (e.target.name === 'roles') userForm.setFieldValue('roles', [e.target.value])
        else userForm.setFieldValue('status', e.target.value)
    };

    return (
        <ModalContent
            handleChangeCategory={handleChangeCategory}
            toggleModal={toggleModal}
            userForm={userForm}
            type={type}
        />
    )
}

export default CreateUserModal