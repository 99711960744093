import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetProjInfoStartAction,
    GetProjInfoSuccessAction,
    GetProjInfoErrorAction,
} from 'src/store/project-info/actions';
import { GetFileStartAction } from 'src/store/files/actions';
import { TProjectContent } from 'src/types/projects';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* projectInfoWorker({ id }: ReturnType<typeof GetProjInfoStartAction>) {
    try {
        const { data }: AxiosResponse<TProjectContent> = yield axios({
            method: 'GET',
            url: `/projects/${id}`,
            headers: {},
        });

        // get image
        if (data.image) {
            yield put(GetFileStartAction(`${data.image.id}`, 'image'));
        }

        // get files
        if (data.otherFiles && data.otherFiles.length > 0) {
            for (const file of data.otherFiles) {
                yield put(GetFileStartAction(`${file.id}`));
            }
        }
        if (data.requestFiles && data.requestFiles.length > 0) {
            for (const file of data.requestFiles) {
                yield put(GetFileStartAction(`${file.id}`));
            }
        }
        if (data.examinationFiles && data.examinationFiles.length > 0) {
            for (const file of data.examinationFiles) {
                yield put(GetFileStartAction(`${file.id}`));
            }
        }
        if (data.presentationFile && data.presentationFile.id) {
            yield put(GetFileStartAction(`${data.presentationFile.id}`));
        }

        yield put(GetProjInfoSuccessAction(id, data));
        yield put(LogsActionsStartAction('VIEW_PROJECT', id))
    } catch (error) {
        yield put(GetProjInfoErrorAction(id, error));
    }
}
