import { put } from 'redux-saga/effects';
import axios from 'axios';

import {
    LogsActionsCleanAction,
    LogsActionsErrorAction,
    LogsActionsSendAction,
    LogsActionsSuccessAction,
} from "src/store/logs/actions";

export function* sendWorker({actions}: ReturnType<typeof LogsActionsSendAction>) {
    try {
        const data = [...actions]
        yield put(LogsActionsCleanAction())
        yield axios({
            method: 'POST',
            url: `/users/actions`,
            headers: {},
            data: data
        });
        yield put(LogsActionsSuccessAction())
    } catch (error) {
        yield put(LogsActionsErrorAction(error));
    }
}
