import { InferValueTypes } from 'src/types/common';
import { VotingActionTypes } from 'src/store/voting/action-types';
import * as actions from '../actions';
import { TDataStatus } from 'src/types/data-status';
import {TVoteReport} from 'src/types/vote';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TAllDocumentsReportState = {
    data: TVoteReport;
} & TDataStatus;

export const initialState: TAllDocumentsReportState = {
    data: {
        comments: {},
        votes: {}
    },
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function reducer(
    state: TAllDocumentsReportState = initialState,
    action: ActionTypes,
): TAllDocumentsReportState {
    switch (action.type) {
        case VotingActionTypes.GET_ALL_DOCUMENTS_REPORT_START:
            return {
                ...state,
                data: {...state.data},
                isLoading: true,
                isLoaded: false,
                error: null,
            };
        case VotingActionTypes.GET_ALL_DOCUMENTS_REPORT_SUCCESS:
            return {
                ...state,
                data: {...action.data},
                isLoading: false,
                isLoaded: true,
                error: null,
            };
        case VotingActionTypes.GET_ALL_DOCUMENTS_REPORT_ERROR:
            return {
                ...state,
                data: {
                    comments: {},
                    votes: {}
                },
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };
        default:
            return state;
    }
}
