import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons/lib';

import { TEditVotingRequestData, TVotingBlock } from 'src/types/vote';

import pdfImg from 'src/assets/img/pdf.png';

import { SetDocumentAction } from 'src/store/voting-editor/actions';
import { selectVotingEditorBlocks } from 'src/store/voting-editor/selectors';

import { VotingEditDocumentModal } from 'src/components/modals/voting-edit-document';

import styles from './styles.module.scss';
import createMarkup from "src/utils/createMarkup";

type TProps = {
    votingForm: any;
};

const BlocksList: FC<TProps> = ({ votingForm }) => {
    const put = useDispatch();
    const [block, setBlock] = useState<TVotingBlock | undefined>(undefined);
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const blocks = useSelector(selectVotingEditorBlocks);

    const onCloseModal = () => {
        setIsOpened(false);
        setBlock(undefined);
    };

    const onSubmitModal = (projects: TEditVotingRequestData['projects']) => {
        // votingForm.setFieldValue('projects', projects);
        onCloseModal();
    };

    const openModal = (block?: TVotingBlock) => () => {
        setIsOpened(true);
        setBlock(block);
    };

    const removeBlock = (block: TVotingBlock) => () => {
        put(SetDocumentAction(
            blocks.filter((item) => (item.id !== block.id)))
        );
    };

    return (
        <>
            <div className={styles.heading}>
                Блок для голосования
                <Button
                    type="default"
                    shape="circle"
                    className={styles.add}
                    onClick={openModal(undefined)}
                >+</Button>
            </div>

            <div className={styles.blocksList}>
                { blocks && blocks.map((item) => (
                    <div key={item.id} className={styles.blockItem}>
                        <div className={styles.blockHeader}>
                            { item.name }

                            <div className={styles.blockActions}>
                                <Button
                                    type="default"
                                    size="small"
                                    className={styles.btn}
                                    icon={<EditOutlined />}
                                    onClick={openModal(item)}
                                />
                                <Button
                                    type="default"
                                    size="small"
                                    className={styles.btn}
                                    icon={<CloseOutlined />}
                                    onClick={removeBlock(item)}
                                />
                            </div>
                        </div>

                        <div className={styles.blockBody}>
                            <div className={styles.blockQuestion} dangerouslySetInnerHTML={createMarkup(item.question)}/>
                            <div className={styles.blockFiles}>
                                { item.files && item.files.map((file) => (
                                    <div
                                        key={`${file.id}`}
                                        className={styles.document}
                                        // onClick={openPDFModal(file, name)}
                                    >
                                        <img src={pdfImg} alt="pdf" className={styles.pdfImg} />
                                        <span>{ file.fileName }</span>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                )) }

                {isOpened && (
                    <VotingEditDocumentModal
                        isOpened={isOpened}
                        onClose={onCloseModal}
                        onSubmit={onSubmitModal}
                        block={block}
                    />
                )}
            </div>
        </>

    );
};

export default BlocksList;
