import { createSelector } from 'reselect';

import { defaultStatus } from 'src/constatnts/default-status';
import { TApplicationState } from 'src/store/application-state';


const getImageState = (state: TApplicationState) => state.files;

export const selectFile = (id?: string) => (
    createSelector(
        getImageState,
        state => {
            if (!id || !state[id]) return null;
            return state[id].data;
        }
    ));
export const selectFiles = (ids?: string[]) => (
    createSelector(
        getImageState,
        state => {
            if (!ids || ids.length === 0) return [];

            return ids.map((id) => {
                if (!state[id]) return null;

                return state[id].data;
            });
        }
    ));
export const selectFilesObj = (ids?: string[]) => (
    createSelector(
        getImageState,
        state => {
            if (!ids || ids.length === 0) return [];

            return ids.map((id) => {
                if (!state[id]) return null;

                return {id, file: state[id].data}
            });
        }
    ));

export const selectFileStatus = (id?: string) => (
    createSelector(
        getImageState,
        (state) => {
            if (!id || !state[id]) return defaultStatus;

            return {
                isLoading: state[id].isLoading,
                isLoaded: state[id].isLoaded,
                error: state[id].error,
            }
        },
    ));

export const selectFilesStatuses = (ids?: string[]) => (
    createSelector(
        getImageState,
        (state) => {
            if (!ids || ids.length === 0) return [];

            return ids.map((id) => {
                if (!state[id]) return defaultStatus;

                return {
                    isLoading: state[id].isLoading,
                    isLoaded: state[id].isLoaded,
                    error: state[id].error,
                }
            });
        },
    ));
