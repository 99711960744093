import { InferValueTypes } from 'src/types/common';
import { ProjectsActionTypes } from 'src/store/projects/action-types';
import * as actions from 'src/store/projects/actions';
import { TAdminProjectsOptions, TProjectContent } from 'src/types/projects';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TDocumentsByVotingIdState = {
    votingId?: number;
    filters: TAdminProjectsOptions;
    documents: TProjectContent[];
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TDocumentsByVotingIdState = {
    votingId: undefined,
    filters: {
        filterData: {
            categoryIds: [],
            minScore: 0,
            maxScore: 10,
            searchString: '',
            sessionName: undefined,
            year: undefined,
        },
        page: 1,
        pageSize: 20,
        totalCount: 0,
        sort: {
            direction: 'ASC',
            field: 'id',
        },
    },
    documents: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function adminsProjects(
    state: TDocumentsByVotingIdState = initialState,
    action: ActionTypes,
): TDocumentsByVotingIdState {
    switch (action.type) {
        case ProjectsActionTypes.RESET_ADMINS_PROJECTS_FILTERS:
            return {
                ...state,
                filters: { ...initialState.filters },
            };
        case ProjectsActionTypes.SET_ADMINS_PROJECTS_FILTERS:
            return {
                ...state,
                filters: { ...action.filters },
            };
        case ProjectsActionTypes.SET_ADMINS_DOCUMENTS_FILTERS:
            return {
                ...state,
                filters: { ...action.filters },
            };
        case ProjectsActionTypes.GET_DOCUMENTS_BY_VOTING_ID_START:
            return {
                ...state,
                isLoading: true,
            };
        case ProjectsActionTypes.GET_DOCUMENTS_BY_VOTING_ID_SUCCESS:
            return {
                ...state,
                documents: action.data,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
                isLoading: false,
                isLoaded: true,
                error: null,
            };
        case ProjectsActionTypes.GET_DOCUMENTS_BY_VOTING_ID_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };
        default:
            return state;
    }
}
