import {
    GetUsersStatisticErrorAction,
    GetUsersStatisticStartAction,
    GetUsersStatisticSuccessAction,
} from "src/store/users/actions";
import { put } from 'redux-saga/effects';
import axios, {AxiosResponse} from "axios";


export function* getUsersStatisticWorker({params}: ReturnType<typeof GetUsersStatisticStartAction>) {
    try {
        const sort = params === null ? {
            direction: "DESC",
            field: "lastLoginDateTime"
        } : params.newSorter.sort;
        const page = params === null ? 0 : params.newPagination.page - 1;
        const pageSize = params === null ? 20 : params.newPageSize.pageSize;
        const { data: statistic }: AxiosResponse<any> = yield axios({
            method: 'POST',
            url: `/users/actions/statistic`,
            headers: {},
            data: {
                page: page,
                pageSize: pageSize,
                sort: sort
            }
        });

        yield put(GetUsersStatisticSuccessAction(statistic))

    } catch (error) {
        yield put(GetUsersStatisticErrorAction(error))
    }
}