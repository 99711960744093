import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';

import {
    LoginErrorAction,
    LoginStartAction,
    LoginSuccessAction,
} from 'src/store/auth/actions';
import { mainModuleRoutes } from 'src/routing/projects-module';
import { TUser } from 'src/types/user';
import { TUserData } from 'src/store/auth/reducer';

export function* loginWorker(action: ReturnType<typeof LoginStartAction>) {
    try {
        const { data }: AxiosResponse<TUser> = yield axios({
            method: 'POST',
            url: '/login',
            headers: {},
            data: action.credentials
        });

        const user: TUserData = {
            user: data,
            basic: Buffer.from(`${action.credentials.username}:${action.credentials.password}`).toString("base64")
        };

        localStorage.setItem('user', JSON.stringify(user));

        yield put(LoginSuccessAction(user));
        yield put(push(mainModuleRoutes.list.path));
    } catch (error) {
        yield put(LoginErrorAction(error));
    }
}
