import { InferValueTypes } from 'src/types/common';
import { VotingEditorActionTypes } from 'src/store/voting-editor/action-types';
import * as actions from '../actions';
import { TDataStatus } from 'src/types/data-status';
import { TRegent } from 'src/types/user';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TVoteEditorRegentsState = {
    data: TRegent[];
} & TDataStatus;

export const initialState: TVoteEditorRegentsState = {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function reducer(
    state: TVoteEditorRegentsState = initialState,
    action: ActionTypes,
): TVoteEditorRegentsState {
    switch (action.type) {
        case VotingEditorActionTypes.GET_REGENTS_START:
            return {
                ...state,
                data: [],
                isLoading: true,
                isLoaded: false,
                error: null,
            };
        case VotingEditorActionTypes.GET_REGENTS_SUCCESS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
                isLoaded: true,
                error: null,
            };
        case VotingEditorActionTypes.GET_REGENTS_ERROR:
            return {
                ...state,
                data: [],
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };
        default:
            return state;
    }
}
