import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getInternetResourcesState = (state: TApplicationState) => state.internetResources;

export const selectInternetResources = createSelector(
    getInternetResourcesState,
    (state) => state.data.map((el: { domainLink: string; image: string; id: any; name: string}) => {
        return { value: el.id, label: el.name, domainLink: el.domainLink, img: el.image}
    })
);
export const selectInternetResource = createSelector(
    getInternetResourcesState,
    (state) => state.resource
);
export const selectInternetResourcesStatus = createSelector(
    getInternetResourcesState,
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
        error: state.error,
    }),
);