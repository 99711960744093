import React, {useEffect} from "react";
import {TProjectFormData} from "src/components/modals/projects-modal/index";
// @ts-ignore
import {FormikHelpers, useFormik} from "formik";
// @ts-ignore
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {selectAllSessions} from "src/store/sesssions/selectors";
import {selectYears} from "src/store/years/selectors";
import {AddProjectStartAction} from "src/store/projects/actions";
import ModalContent from "src/components/modals/projects-modal/modal";
import {LogsActionsStartAction} from "src/store/logs/actions";

type TCreateProjectModalProps = {
    isOpened: boolean;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    isEdit: boolean
};

const CreateProjectModal: React.FC<TCreateProjectModalProps> = ({isOpened, onClose, isEdit}) => {
    const put = useDispatch();
    const sessions = useSelector(selectAllSessions);
    const years = useSelector(selectYears);

    useEffect(() => {
        put(LogsActionsStartAction('OPEN_NEW_PROJECT_FORM'))
    }, [])

    const initialData: TProjectFormData = {
        budget: 0,
        categoryId: 1,
        description: '',
        expertise: '',
        formatDescription: '',
        formatIds: [],
        internetResources: [],
        kpi: 0,
        supportAmount: 0,
        actualKpi: 0,
        name: '',
        owner: '',
        presentationUrl: '',
        score: 0,
        sessionId: '1',
        teamExperience: '',
        year: 2021,
        image: [],
        implEndDate: '',
        implStartDate: '',
        implTerms: 0,
        requestNumber: '',
        recommendedCostReduction: null,
        achievements: null,
        ownProjectSite: null,
        cofinancing: null,
        isConfidential: null,

        presentationFile: [],
        requestFiles: [],
        examinationFiles: [],
        otherFiles: [],
    };
    const projectForm = useFormik({
        initialValues: {
            ...initialData,
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object({
            name: Yup.string().required('Поле обязательное'),
            owner: Yup.string().required('Поле обязательное'),
            requestNumber: Yup.string().required('Поле обязательное'),
            year: Yup.string().required('Поле обязательное'),
            sessionId: Yup.string().required('Поле обязательное'),
            description: Yup.string().required('Поле обязательное'),
            categoryId: Yup.string().required('Поле обязательное'),
            formatIds: Yup.array().required('Поле обязательное'),
            score: Yup.string().required('Поле обязательное'),
            kpi: Yup.string().required('Поле обязательное'),
            budget: Yup.string().required('Поле обязательное'),
        }),
    });

    function onSubmit(values: TProjectFormData, _formikHelpers: FormikHelpers<TProjectFormData>) {
        put(AddProjectStartAction(values));
        onClose();
    }

    useEffect(() => {
        // set default year and session
        if (years.length > 0 && sessions.length > 0) {
            projectForm.setFieldValue('year', +years[0]);
            projectForm.setFieldValue('sessionId', +sessions[0]);
        }
    }, [years, sessions]);



    return (
        <ModalContent isEdit={false} isOpened={isOpened} onClose={onClose} projectForm={projectForm}/>
    )
}
export default CreateProjectModal