import React, {
    FC,
    ReactNode,
} from 'react';
import { Tooltip } from 'antd';

type TProps = {
    children: ReactNode;
    isActive: boolean;
    message: string;
}

export const TooltipComponent: FC<TProps> = (props) => (
    <>
        { props.isActive
            ? (
                <Tooltip title={props.message} placement='bottom'>
                    { props.children }
                </Tooltip>
            )
            : props.children
        }
    </>

);
