import React from 'react';
import moment from 'moment';

import { SummaryDateFormat, TimeFormat } from 'src/constatnts/format';

import styles from './styles.module.scss';

export const DateTimeCell = (cellData: string, rowData: any) => {
    const date = moment(cellData).format(SummaryDateFormat);
    const time = moment(cellData).format(TimeFormat);

    return (
        <div className={styles.cell}>
            <span className={styles.date}>{date}</span>
            <span className={styles.time}>{time}</span>
        </div>
    );
}
